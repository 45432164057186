import api from './index';
import { PaginationProp } from '../../utils/globalInterface/GlobalInterfaces';

const addStudentSignup = (data: any) => api.post('/student_signup', data);
const updateStudentSignup = (data: any, id: string) =>
  api.put(`/student_signup/${id}`, data);
const getStudentSignup = () => api.get('/student_signup');
const getStudentConfirmation = (studentConfirmationEmail: any) =>
  api.post(`/student_signup/student_confirmation`, {
    studentConfirmationEmail,
  });

const getAssessmentStudent = (data: any) =>
  api.get(`/student_signup/student/${data.id}/${data.type}`);
const activateStudentPassword = (data: any) =>
  api.post('/student_signup/activate-password', data);

export {
  addStudentSignup,
  updateStudentSignup,
  getStudentSignup,
  activateStudentPassword,
  getStudentConfirmation,
  getAssessmentStudent,
};
