import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  MenuItem,
  InputLabel,
  FormControl,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../reduxStore/Store';
import { getOrganizationName } from '../../reduxStore/reducer/organisationReducer';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { MdModeEdit, MdDelete } from 'react-icons/md';
import { BsSearch } from 'react-icons/bs';
import Autocomplete, {
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { AgGridReact } from 'ag-grid-react';
import {
  getProgramData,
  addProgramData,
  editProgramData,
  deleteProgramData,
} from '../../reduxStore/reducer/programReducer';
import {
  CellClickedEvent,
  GridApi,
  GridReadyEvent,
  ICellRendererParams,
  RowClickedEvent,
} from 'ag-grid-community';
import { useNavigate } from 'react-router-dom';
import Organisation from '../organisation/Organisation';
import { Organization } from '../organisation/OrganizationForm';
import { createServerSideDatasource } from '../../utils/gridMethod/createServerSideDatasource';
import { AiOutlineClose } from 'react-icons/ai';
import GridHeader2 from '../../utils/gridHeader/GridHeader2';
import { ColumnApi } from 'ag-grid-community';
import debounce from 'lodash/debounce';
import { ServerSideGrid } from '../../utils/MasterGrid/ServerSideGrid';
import { getUserById } from '../../reduxStore/reducer/registerReducer';
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import api from '../../reduxStore/route';

const StudentAssessment = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery);

  const debounceSearch = debounce((value: string) => {
    setDebouncedSearchQuery(value);
  }, 500);
  const onFilterTextBoxChanged = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchQuery(e.target.value);
    const value = e.target.value;
    debounceSearch(value);
  };

  const textFieldProps = {
    value: searchQuery,
    onChange: onFilterTextBoxChanged,
  };
  interface StudentDef {
    headerName: string;
    field: string;
    filter: string;
    cellRenderer?: string;
    minWidth?: number;
    maxWidth?: number;
    filterParams?: any;
    valueFormatter?: (params: any) => any;
  }

  // Function to map 'term' values to seasons
  function mapValueToSeason(value: number): string {
    const seasonMap: Record<number, string> = {
      1: 'Fall',
      2: 'Spring',
      3: 'Summer',
      4: 'Winter',
    };

    return seasonMap[value] || 'unknown';
  }

  // Define the studentDefs array
  const studentDefs: StudentDef[] = [
    {
      headerName: 'Course name',
      field: 'course_name',
      filter: 'agTextColumnFilter',
      cellRenderer: 'agGroupCellRenderer',
    },
    {
      headerName: 'Assessment name',
      field: 'assessment_name',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Term',
      field: 'term',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: ['Fall', 'Spring', 'Summer', 'Winter'],
      },
      valueFormatter: (params: any) => mapValueToSeason(params.value),
    },
    {
      headerName: 'Year',
      field: 'year',
      filter: 'agTextColumnFilter',
    },
  ];

  // Output the studentDefs array
  //

  const [gridApi, setGridApi] = useState<GridApi>();
  const [columnApi, setColumnApi] = useState<ColumnApi>();
  const dispatch = useDispatch<AppDispatch>();
  const [masterGridparams, setMasterGridParams] = useState<any>(null);

  const token: any = localStorage.getItem('token');
  const userData: {
    id: string;
    type: string;
    user: string;
  } = jwt_decode(token);

  //

  const handleNavigate = (data: any) => {
    //
    const { administration_number, course_assessment, course_student_id } =
      data;

    navigate(
      `/studentAssessment/addStudent/${course_student_id}/${course_assessment}/${administration_number}`,
      {
        state: {
          signUp: true,
        },
      }
    );
  };
  const gridOptions = {
    onCellClicked: (e: CellClickedEvent) => {
      if (e.colDef.headerName !== 'Action')
        e.node.setExpanded(!e.node.expanded);
    },
  };

  const [assessmentGrid, setAssessmentGrid] = useState({
    detailGridOptions: {
      columnDefs: [
        {
          headerName: 'Administration number',
          field: 'administration_number',
          filter: 'agTextColumnFilter',

          width: 353,
        },
        {
          headerName: 'Start date time',
          field: 'start_date_time',
          width: 353,
          cellRenderer: (row: ICellRendererParams) => {
            const startDate = moment(row.data.start_date_time);
            if (startDate.isValid()) {
              return startDate.local().format('MM-DD-YYYY hh:mm a');
            } else {
              return 'Not yet started';
            }
          },
        },

        {
          headerName: 'End date time',
          field: 'end_date_time',
          width: 353,
          cellRenderer: (row: ICellRendererParams) => {
            const startDate = moment(row.data.end_date_time);
            if (startDate.isValid()) {
              return startDate.local().format('MM-DD-YYYY hh:mm a');
            } else {
              return 'Not yet started';
            }
          },
        },
        {
          headerName: 'Assessment status',
          field: ' test_status',
          width: 353,
          cellRenderer: (row: ICellRendererParams) => {
            return row.data.test_status ? 'Taken' : 'Not taken';
          },
        },
        {
          headerName: 'Take assessment',
          cellRenderer: (row: ICellRendererParams) => {
            return (
              <Button
                sx={{ textTransform: 'capitalize' }}
                onClick={() => {
                  // if(row.data.start_date_time){

                  // }else{

                  // }
                  //
                  handleNavigate(row.data);
                }}
                size='small'
                variant='contained'
                disabled={
                  row.data.start_date_time === '' ||
                  row.data.is_active === false ||
                  row.data.test_status === true ||
                  (row.data.end_date_time &&
                    moment(row.data.end_date_time) < moment()) ||
                  moment(row.data.start_date_time) > moment()
                }
              >
                Take assessment
              </Button>
            );
          },
        },
      ],
      // detailRowHeight: 400,
      defaultColDef: {
        suppressCopyRowsToClipboard: false,
        flex: 1,
        resizable: true,
        sortable: true,
        width: 100,
      },
      getContextMenuItems: (params: any) => null,
      onRowClicked: (e: RowClickedEvent) => {
        // navigate(`/administration/${e.data.id}/${e.data.courseId}`);
      },
    },
    getDetailRowData: (params: any) => {
      getAssessmentByCourse(params);
    },
  });

  interface Administration {
    responses: string;
    avg_score: string;
    start_date_time: string;
    end_date_time: string;
    last_reminder_sent: string;
    is_active: any;
    student: Student[];
  }

  interface Student {
    course_student_id: string;
    created_at: string;
    option_selected: string;
    test_answers: string;
    test_status: boolean;
  }

  interface Assessment {
    official_name: string;
    label: string;
    start_date_time: string;
    is_active: any;
    end_date_time: string;
    test_status: boolean;
  }
  const getAssessmentByCourse = (params: any): void => {
    setMasterGridParams(params);

    api
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/student_signup/get-assessment-by-course-assessment/${params.data.id}`
      )
      .then(
        (result) => {
          const assessments: Assessment[] = result?.data?.records?.flatMap(
            (record: any) => {
              const administrations: Administration[] = Object.values(
                record.administrations
              );
              //

              return administrations.map((administration) => {
                const student = administration.student.find(
                  (d) => d.course_student_id === params.data.course_student_id
                );

                return {
                  official_name: record.official_name,
                  label: record.label,
                  start_date_time: administration.start_date_time,
                  end_date_time: administration.end_date_time,
                  is_active: administration.is_active,
                  course_assessment: record.id,
                  course_student_id: params.data.course_student_id,
                  test_status: student?.test_status,
                  administration_number: Object.keys(
                    record.administrations
                  ).find(
                    (key) => record.administrations[key] === administration
                  ),
                };
              });
            }
          );
          //

          params.successCallback(assessments);
        },
        (error) => {
          params.successCallback([]);
        }
      );
  };

  return (
    <main>
      <header className='header'>Assessment</header>
      {/* <GridHeader2 textFieldProps={textFieldProps} buttonProps={buttonProps} /> */}

      <ServerSideGrid
        rowDataUrl='/student_signup/'
        debouncedSearchQuery={searchQuery}
        columnDefs={studentDefs}
        gridApi={gridApi}
        columnApi={columnApi}
        setGridApi={setGridApi}
        setColumnApi={setColumnApi}
        masterDetail
        detailRowHeight={350}
        detailCellRendererParams={assessmentGrid}
        gridOptions={gridOptions}
      />
    </main>
  );
};

export default StudentAssessment;
