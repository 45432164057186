import React from 'react';
import Grid from '@mui/material/Grid';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormGroup,
  TextField,
  Tooltip,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Form, Formik } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import { initialValues } from '../MyAccount/InitialValues';
import { RenderCheckBoxList } from './RenderCheckBoxList';
import { saveContentMLearningOValue } from '../../reduxStore/reducer/administrationReducer';
import Alert from '../../utils/Alert/Alert';

function ContentModulesLearningObjectivesDialog({
  contentMLearningO,
  setContentMLearningO,
  courseAssessmentId,
  index,
}: any) {
  const [open, setOpen] = React.useState(false);
  const handleCloseAssessQue = () => {
    setOpen(false);
  };

  const initialValues = {
    contentModules: contentMLearningO.contentModules.map((d: any) => {
      if (!Object.hasOwn(d, 'bool')) {
        d.bool = false;
        return d;
      } else {
        return d;
      }
    }),
    learningObjectives: contentMLearningO.learningObjectives.map((d: any) => {
      if (!Object.hasOwn(d, 'bool')) {
        d.bool = false;
        return d;
      } else {
        return d;
      }
    }),
    id: '',
    administration_no: '',
    contentMLearningOValue: {},
  };

  const helpObj = {
    afterSuccessfullSubmit: (values: any) => {
      setContentMLearningO((e: any) => {
        console.log(e, 63);
        const newState = [...e];

        newState[index] = values.contentMLearningOValue;
        return newState;
      });
      handleCloseAssessQue();
      Alert.success({ title: 'Success', text: 'Saved successfully' });
    },

    afterUnSuccessfullSubmit: (err: any) => {
      Alert.error({
        title: 'Error',
        text: 'Unable to save the content modules & learning objectives',
      });
      // .then(() => {
      //   handleCloseAssessQue();
      // });
    },
  };
  return (
    <>
      <Button
        size='medium'
        variant='contained'
        onClick={() => {
          setOpen(true);
        }}
        style={{ textTransform: 'capitalize', minWidth: '150px' }}
      >
        SELECT CONTENT MODULES & LEARNING OBJECTIVES
      </Button>
      <Dialog open={open} scroll={'paper'}>
        <DialogTitle sx={{ backgroundColor: '#1663ab', color: 'white' }}>
          <div className='flex justify-between items-center'>
            <div>Select Content Modules & Learning Objectives</div>
            <Fab size='small'>
              <CloseIcon
                className='cursor-pointer'
                onClick={handleCloseAssessQue}
              />
            </Fab>
          </div>
        </DialogTitle>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
            // console.log(values);
            values.id = courseAssessmentId;
            values.administration_no = index + 1;
            values.contentMLearningOValue = {
              contentModules: values.contentModules,
              learningObjectives: values.learningObjectives,
            };
            delete values.contentModules;
            delete values.learningObjectives;

            saveContentMLearningOValue(
              helpObj.afterSuccessfullSubmit,
              helpObj.afterUnSuccessfullSubmit,
              values,
              setSubmitting
            );
          }}
        >
          {({ handleSubmit, values, setFieldValue, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <DialogContent
                dividers
                sx={{ maxHeight: '60vh', overflowY: 'auto' }}
              >
                <div>
                  <Typography variant='h6' gutterBottom>
                    Content Modules
                  </Typography>

                  <div className='flex flex-col gap-y-4 pl-10'>
                    <RenderCheckBoxList fieldName='contentModules' />
                  </div>
                </div>
                <div className='mt-10'>
                  <Typography variant='h6' gutterBottom>
                    Learning Objectives
                  </Typography>
                  <div className='flex flex-col gap-y-4 pl-10'>
                    <RenderCheckBoxList fieldName='learningObjectives' />
                  </div>
                </div>

                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
              </DialogContent>
              <DialogActions>
                <div className='flex gap-5 justify-around mt-5'>
                  <Button
                    size='large'
                    variant='contained'
                    style={{ textTransform: 'capitalize', width: '110px' }}
                    onClick={handleCloseAssessQue}
                  >
                    Cancel
                  </Button>
                  <Button
                    size='large'
                    variant='contained'
                    color='primary'
                    type='submit'
                    disabled={isSubmitting}
                    style={{
                      textTransform: 'capitalize',
                      width: '110px',
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

export default ContentModulesLearningObjectivesDialog;
