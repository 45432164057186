import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  TextField,
} from '@mui/material';
import * as React from 'react';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import Alert from '../../utils/Alert/Alert';
import { updateReminderSettings } from '../../reduxStore/reducer/administrationReducer';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

export function ReminderSettings(props: any) {
  const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');
  const defaultValue = [
    props?.reminderSettings?.instructors
      ? props?.reminderSettings?.instructors
      : props.instructorsCoordinators
      ? props.instructorsCoordinators
      : [], //0
    props?.reminderSettings?.studentReminder
      ? props?.reminderSettings?.studentReminder
      : 'Every other day', //1
    props?.reminderSettings?.participationReminder
      ? props?.reminderSettings?.participationReminder
      : 'Every other day', //2
    props?.courseAssessmentId, //3
    props?.reminderSettings?.studentReminderTime
      ? props?.reminderSettings?.studentReminderTime
      : '12:01', //4
    props?.reminderSettings?.participationReminderTime
      ? props?.reminderSettings?.participationReminderTime
      : '12:01', //5
  ];
  const [formControls, setFormControls] = React.useState<any>({
    open: [false, false, false, false],
    options: [
      props.instructorsCoordinators ? props.instructorsCoordinators : [],
      ['Everyday', 'Every other day', 'Every third day', 'No thanks'],
      ['Everyday', 'Every other day', 'Every third day', 'Deadline passed'],
    ],
    field: [
      defaultValue[0], //0
      defaultValue[1], //1
      defaultValue[2], //2
      defaultValue[3], //3
      defaultValue[4], //4
      defaultValue[5], //5
    ],
    error: false,
    loading: false,
  });

  React.useEffect(() => {
    if (formControls.options[0].length !== 0) return;
    // getDownloadOptionValue(helpObj.afterGetDownloadOptionValue);
  }, [props.reminderDialog]);

  const helpObj = {
    loading: [0, 1, 2].map(
      (index) =>
        formControls.open[index] && formControls.options[index].length === 0
    ),

    updateOpenValue: (index: number, newValue: boolean) => {
      setFormControls((prevState: any) => ({
        ...prevState,
        open: prevState.open.map((value: any, i: number) =>
          i === index ? newValue : value
        ),
      }));
    },

    updateOptionValue: (index: number, newValue: any[]) => {
      setFormControls((prevState: any) => ({
        ...prevState,
        options: prevState.options.map((value: any, i: number) =>
          i === index ? newValue : value
        ),
      }));
    },

    updateFieldValue: (index: number, newValue: any[] | string) => {
      //
      setFormControls((prevState: any) => ({
        ...prevState,
        field: prevState.field.map((preValue: any, i: number) =>
          i === index ? newValue : preValue
        ),
      }));
    },

    updateErrorValue: (boolean: boolean) => {
      setFormControls((prevState: any) => ({
        ...prevState,
        error: boolean,
      }));
    },

    updateLoadingValue: (boolean: boolean) => {
      setFormControls((prevState: any) => ({
        ...prevState,
        loading: boolean,
      }));
    },

    clearForm: () => {
      setFormControls({
        open: [false, false, false],
        options: [[], [], []],
        field: defaultValue,
        error: false,
      });
    },

    afterSuccessfullSubmit: (response: any) => {
      Alert.success({
        title: 'Email reminder settings updated successfully',
        text: '',
      });
      helpObj.updateLoadingValue(false);
      props.setReminderDialog(false);
    },

    afterUnSuccessfullSubmit: (err: any) => {
      helpObj.updateLoadingValue(false);
      //
    },
  };

  const submitForm = () => {
    updateReminderSettings(
      helpObj.afterSuccessfullSubmit,
      helpObj.afterUnSuccessfullSubmit,
      formControls.field
    );
  };

  //
  return (
    <Dialog open={props?.reminderDialog} scroll={scroll} fullWidth>
      <DialogTitle sx={{ backgroundColor: '#1663ab', color: 'white' }}>
        Manage Email Reminders
      </DialogTitle>
      <DialogContent dividers={scroll === 'paper'}>
        <div className='flex gap-3 justify-start	items-center p-2 '>
          <label className='w-72'>Instructor(s)</label>
          <Autocomplete
            sx={{ width: 550 }}
            // fullWidth
            open={formControls.open[0]}
            size='small'
            onOpen={() => {
              helpObj.updateOpenValue(0, true);
            }}
            onClose={() => {
              helpObj.updateOpenValue(0, false);
            }}
            disableCloseOnSelect
            onChange={(e: any, v: any, r: any, { option }: any) => {
              if (
                formControls.field[0].some((data: any) => data.id === option.id)
              ) {
                //remove option because already in the value
                helpObj.updateFieldValue(
                  0,
                  formControls.field[0].filter(
                    (item: any) => item.id !== option.id
                  )
                );
              } else {
                //add option because not in the value
                formControls.field[0].push(option);
                helpObj.updateFieldValue(0, formControls.field[0]);
              }
            }}
            disableClearable
            getOptionLabel={(option: any) => option?.name}
            options={formControls.options[0]}
            loading={helpObj.loading[0]}
            multiple
            value={formControls.field[0]}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {helpObj.loading[0] ? (
                        <CircularProgress color='inherit' size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={(() =>
                    formControls.field[0].some(
                      (data: any) => data.id === option.id
                    ))()}
                />
                {option.name}
              </li>
            )}
          />
        </div>

        <div className='flex gap-3 justify-start items-center p-2 '>
          <label className='w-80'>Student Reminder</label>
          <Autocomplete
            sx={{ width: 350 }}
            open={formControls.open[1]}
            size='small'
            onOpen={() => {
              helpObj.updateOpenValue(1, true);
            }}
            onClose={() => {
              helpObj.updateOpenValue(1, false);
            }}
            getOptionLabel={(option: any) => option}
            options={formControls.options[1]}
            loading={helpObj.loading[1]}
            onChange={(e, v, r, d) => {
              helpObj.updateFieldValue(1, v);
              if (v === 'No thanks') {
                helpObj.updateFieldValue(4, ''); //clear student reminder time & also that field is disabled
                helpObj.updateFieldValue(2, defaultValue[2]); //set the default value of this field
                helpObj.updateFieldValue(5, defaultValue[5]); //set the same value as in student reminder time or default value
              } else {
                helpObj.updateFieldValue(2, v); //correct this "set the default value of this field"
                helpObj.updateFieldValue(5, formControls.field[4]); //set the participation reminder same as student reminder time

                //check the previous value of student reminder time is empty
                if (formControls.field[4] === '') {
                  helpObj.updateFieldValue(4, defaultValue[4]); //correct this "clear student reminder time & also that field is disabled"
                  helpObj.updateFieldValue(5, defaultValue[4]); //correct this "set the same value as in student reminder time or default value"
                }
              }
            }}
            value={formControls.field[1]}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {helpObj.loading[1] ? (
                        <CircularProgress color='inherit' size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                {option}
                {option !== 'No thanks' ? (
                  <NotificationsActiveOutlinedIcon
                    fontSize='small'
                    color='primary'
                    className='ml-2'
                  />
                ) : (
                  <NotificationsOffIcon
                    fontSize='small'
                    color='primary'
                    className='ml-2'
                  />
                )}
              </li>
            )}
            disableClearable
          />
          <TextField
            sx={{ width: 240 }}
            disabled={formControls.field[1] === 'No thanks' ? true : false}
            type='time'
            value={formControls.field[4]}
            size='small'
            onChange={(e) => {
              helpObj.updateFieldValue(4, e.target.value);
              if (formControls.field[1] !== 'No thanks') {
                helpObj.updateFieldValue(5, e.target.value);
              }
            }}
          />
        </div>

        {formControls.field[1] === 'No thanks' ? (
          <div className='flex gap-3 justify-start items-center p-2 '>
            <label className='w-80'>Participation Reminder</label>
            <Autocomplete
              sx={{ width: 350 }}
              open={formControls.open[2]}
              size='small'
              onOpen={() => {
                helpObj.updateOpenValue(2, true);
              }}
              onClose={() => {
                helpObj.updateOpenValue(2, false);
              }}
              onChange={(e, v, r, d) => {
                helpObj.updateFieldValue(2, v);
                if (v === 'Deadline passed') {
                  helpObj.updateFieldValue(5, '');
                } else {
                  helpObj.updateFieldValue(5, defaultValue[5]);
                }
              }}
              value={formControls.field[2]}
              getOptionLabel={(option: any) => option}
              options={formControls.options[2]}
              loading={helpObj.loading[2]}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {helpObj.loading[2] ? (
                          <CircularProgress color='inherit' size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  {option}

                  <NotificationsActiveOutlinedIcon
                    fontSize='small'
                    color='primary'
                    className='ml-2'
                  />
                </li>
              )}
              disableClearable
            />
            <TextField
              sx={{ width: 240 }}
              disabled={
                formControls.field[2] === 'Deadline passed' ? true : false
              }
              type='time'
              value={formControls.field[5]}
              size='small'
              onChange={(e) => {
                helpObj.updateFieldValue(5, e.target.value);
              }}
            />
          </div>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          sx={{ width: 120 }}
          onClick={() => {
            Alert.confirm(() => {
              props?.setReminderDialog(!props?.reminderDialog);
              helpObj.updateErrorValue(false);
              helpObj.clearForm();
            });
          }}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          onClick={() => {
            helpObj.updateLoadingValue(true);
            submitForm();
          }}
          disabled={
            formControls.field.every((array: any) => array.length === 0) ||
            formControls.loading
          }
          sx={{ width: 120 }}
          children={
            formControls.loading ? (
              <CircularProgress color='inherit' size={25} />
            ) : (
              'Update'
            )
          }
        />
      </DialogActions>
    </Dialog>
  );
}
