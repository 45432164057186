import React, { useState } from 'react';
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Popover,
  Typography,
} from '@mui/material';
import { useFormikContext } from 'formik';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import HelpIcon from '@mui/icons-material/Help';
import Alert from '../../utils/Alert/Alert';

export function ContentMLearningOList({
  fieldName,
  handleEdit,
  handleDelete,
  contentData, // Your data containing all content information
}: any) {
  const { setFieldTouched, values, setFieldValue, setFieldError }: any =
    useFormikContext();

  // State to manage popover open state
  const [popoverOpen, setPopoverOpen] = useState(false);
  // State to manage popover anchor element
  const [anchorEl, setAnchorEl] = useState(null);
  // State to manage the content to display in the popover
  const [popoverContent, setPopoverContent] = useState<any>(null);

  // Function to handle popover open
  const handlePopoverOpen = (event: any, contentValue: any) => {
    setPopoverOpen(true);
    setAnchorEl(event.currentTarget);

    // Find the content data based on the content value
    const selectedContent = values?.[fieldName]?.find(
      (content: any) => content.value === contentValue
    );
    if (selectedContent) {
      console.log(selectedContent);
      setPopoverContent(selectedContent);
    }
  };

  // Function to handle popover close
  const handlePopoverClose = () => {
    setPopoverOpen(false);
  };

  return (
    <>
      <List>
        {values?.[fieldName]
          ?.sort((a: any, b: any) => a.value.localeCompare(b.value))
          .map((content: any, index: any) => (
            <ListItem
              key={content.value}
              secondaryAction={
                <>
                  <IconButton
                    color='primary'
                    onClick={() => {
                      setFieldError(`${fieldName}Value`, ''); // Clear validation error for 'value' field
                      setFieldError(`${fieldName}Time`, ''); // Clear validation error for 'time' field
                      handleEdit(
                        fieldName,
                        index,
                        values,
                        setFieldValue,
                        setFieldError,
                        setFieldTouched
                      );
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color='error'
                    onClick={() => {
                      Alert.confirm(() => {
                        handleDelete(fieldName, index, values, setFieldValue);
                      });
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </>
              }
            >
              <ListItemButton>
                <ListItemText
                  primary={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {content.value}
                      {content.descript && (
                        <IconButton
                          size='small'
                          onMouseEnter={(event) =>
                            handlePopoverOpen(event, content.value)
                          }
                          onMouseLeave={handlePopoverClose}
                          aria-describedby={
                            popoverOpen ? 'mouse-over-popover' : undefined
                          }
                        >
                          <HelpIcon color='success' />
                        </IconButton>
                      )}
                      {content.time && ` - ${content.time}`}
                    </div>
                  }
                />
              </ListItemButton>
            </ListItem>
          ))}
      </List>
      {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
      <Popover
        id='mouse-over-popover'
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableRestoreFocus
        sx={{
          pointerEvents: 'none',
        }}
        PaperProps={{
          style: { maxWidth: '450px' }, // Adjust the width as needed
        }}
      >
        {popoverContent && (
          <Typography sx={{ p: 2 }}>{popoverContent.descript}</Typography>
        )}
      </Popover>
    </>
  );
}
