import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { AppDispatch, RootState } from '../../reduxStore/Store';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAdaptiveTestItems,
  takeTestItems,
} from '../../reduxStore/reducer/assessmentReducer';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import {
  MenuItem,
  Box,
  Tabs,
  Tab,
  Typography,
  Radio,
  Grid,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Select,
  OutlinedInput,
  DialogTitle,
  Checkbox,
  TextField,
  Button,
  Paper,
  Stack,
  Skeleton,
  useTheme,
} from '@mui/material';
import Swal from 'sweetalert2';

const TestItems = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();
  const [val, setVal] = React.useState<any>({});

  // indicates the currently viewing question
  const [activeStep, setActiveStep] = React.useState(0);

  const [questions, setQuestions] = useState<any>([]);

  const [currentQuestion, setcurrentQuestion] = useState<any>(null);

  const [isSubmitted, setSubmitted] = React.useState(false);

  // indicates whether the student submitted or saved
  const [testStatus, setTestStatus] = React.useState<any>(false);

  const [button, setButton] = useState(false);

  useEffect(() => {
    dispatch(
      getAdaptiveTestItems(state.answer.id, setQuestions, setcurrentQuestion)
    );

    const interval = setInterval(() => {
      setcurrentQuestion((prev: any) => {
        if (!prev) return;
        const updatedQuestion = {
          ...prev,
          duration: isNaN(prev.duration) ? 0 : +prev.duration + 1,
        };

        // Update the questions state as well
        setQuestions((prevQuestions: any) => {
          return prevQuestions.map((question: any) =>
            question.id === updatedQuestion.id ? updatedQuestion : question
          );
        });

        return updatedQuestion;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const updateDuration = (currentQuestionIndex: any) => {
    const updatedQuestions = questions?.map((dataItem: any, i: any) => {
      if (i === activeStep) {
        const dataItemCopy = {
          ...dataItem,
          duration: currentQuestion.duration,
        };
        return dataItemCopy;
      } else {
        return dataItem;
      }
    });

    setQuestions(updatedQuestions);
    setcurrentQuestion(updatedQuestions[currentQuestionIndex]);
  };

  const handleBack = () => {
    updateDuration(activeStep - 1);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleNext = () => {
    updateDuration(activeStep + 1);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleSubmit = (testStatus: any) => {
    if (testStatus) {
      setTestStatus(testStatus);
      updateDuration(activeStep);
      setSubmitted(true);
    }
    state.testItem = { answer: questions };
    dispatch(takeTestItems(state, navigate));
  };

  const handleRadioChange = (e: any, i: any) => {
    //change the answer for particular question
    setQuestions((questions: any) => {
      return questions.map((question: any, index: any) => {
        if (index === i) {
          setcurrentQuestion({
            ...question,
            answer: e.target.value,
          });
          return {
            ...question,
            answer: e.target.value,
          };
        }

        return question;
      });
    });
  };

  const onCheckboxChange = (e: any, i: any) => {
    const { name, checked } = e.target;

    setQuestions((questions: any) =>
      questions.map((question: any, index: any) => {
        const res =
          index === i
            ? {
                ...question,
                answer: {
                  ...question.answer,
                  [name]: checked,
                },
              }
            : question;
        setcurrentQuestion(res);
        return res;
      })
    );
  };

  const onInputChange = (e: any, i: any) => {
    const { name, value: inputData } = e.target;

    setQuestions((questions: any) =>
      questions.map((question: any, index: any) => {
        const res = index === i ? { ...question, answer: inputData } : question;
        setcurrentQuestion(res);
        return res;
      })
    );
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {(questions?.length > 0 ? questions.length : 0) > 0 ? (
          <Box
            sx={{
              maxWidth: 900,
              flexGrow: 1,
            }}
          >
            <h2
              style={{
                fontSize: '15px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              This item is under development. Please answer it to the best of
              your ability.
            </h2>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                margin: '15px',
              }}
            >
              <Button
                size='small'
                variant='contained'
                onClick={handleBack}
                disabled={
                  //   location?.state?.assessment_type === 'adaptive'
                  //     ? true
                  //     :
                  activeStep === 0
                }
              >
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
                Back
              </Button>

              <Select
                size='small'
                value={activeStep}
                onChange={(e: any) => {
                  updateDuration(e.target.value);
                  setActiveStep(e.target.value);
                }}
                // className={classes.select}
              >
                {[
                  ...Array(questions?.length > 0 ? questions.length : 0).keys(),
                ].map((num) => (
                  <MenuItem key={num} value={num}>
                    {num + 1}
                  </MenuItem>
                ))}
              </Select>

              {/* {currentQuestion?.duration} */}

              <Button
                sx={{ marginRight: 2 }}
                size='small'
                variant='contained'
                onClick={handleNext}
                style={
                  activeStep ===
                  (questions?.length > 0 ? questions.length : 0) - 1
                    ? { display: 'none' }
                    : {}
                }
                disabled={
                  activeStep ===
                  (questions?.length > 0 ? questions.length : 0) - 1
                }
              >
                Next
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
              <Button
                style={
                  questions?.length - 1 === activeStep
                    ? {}
                    : { display: 'none' }
                }
                variant='contained'
                disabled={button}
                onClick={() => handleSubmit(true)}
              >
                Complete Section
              </Button>
            </div>
            <Paper
              variant='outlined'
              // sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
            >
              {questions[activeStep] ? (
                <Box
                  sx={{
                    minHeight: 400,
                    height: 'auto',
                    maxWidth: 900,
                    width: '100%',
                    p: 1,
                  }}
                >
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid
                      container
                      // spacing={1}
                      // style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        style={activeStep + 1 === 1 ? {} : { display: 'none' }}
                      >
                        {/* <div
                          dangerouslySetInnerHTML={{
                            __html: val?.question_data?.instruction?.replace(
                              /\\/g,
                              ''
                            )
                              ? `<div class="sun-editor-editable" style="padding-top: 0px;
                          padding-bottom: 0px; max-width: 918px;">${val?.question_data?.instruction?.replace(
                            /\\/g,
                            ''
                          )}</div>`
                              : '',
                          }}
                        /> */}
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <div>
                          {/* <div
                            dangerouslySetInnerHTML={{
                              __html: `<div class="sun-editor-editable" style="padding-top: 0px;
                      padding-bottom: 0px; max-width: 918px;">${framingCondition(
                        questions[activeStep]?.framing
                      )}</div>`,
                            }}
                          /> */}
                          {/* framingCondition(questions[activeStep]?.framing) &&
                  (framingCondition(questions[activeStep]?.framing).match(
                    regex
                  ) !== null
                    ? framingCondition(
                        questions[activeStep]?.framing
                      )?.match(regex)[0]
                    : false) ? (
                    <IconButton
                      onClick={() => {
                        const reg: any = /alt="(.*?)"/;
                        const altText = reg.exec(
                          questions[activeStep]?.questionContent
                        )[1];
                        speak(altText);
                      }}
                    >
                      <VolumeUpIcon />
                    </IconButton>
                  ) : (
                    ''
                  )} */}
                        </div>
                      </Grid>
                      <div className='flex'>
                        <Grid style={{ flexBasis: '2%' }} item xs={2} sm={2}>
                          {/* <b>{questions[activeStep]?.questionLabel}</b> */}
                          <b>{activeStep + 1} </b>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          style={{ display: 'flex', flexFlow: 'wrap' }}
                        >
                          <div>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: `<div class="sun-editor-editable" style="padding-top: 0px;
                      padding-bottom: 0px; max-width: 918px;">${questions[
                        activeStep
                      ]?.questionContent?.replace(/\\/g, '')}</div>`,
                              }}
                            />
                            {/* {questions[activeStep]?.questionContent &&
                  (questions[activeStep]?.questionContent?.match(
                    regex
                  ) !== null
                    ? questions[activeStep]?.questionContent?.match(
                        regex
                      )[0]
                    : false) ? (
                    <IconButton
                      onClick={() => {
                        const reg: any = /alt="(.*?)"/;
                        const altText = reg.exec(
                          questions[activeStep]?.questionContent
                        )[1];
                        speak(altText);
                      }}
                    >
                      <VolumeUpIcon />
                    </IconButton>
                  ) : (
                    ''
                  )} */}
                          </div>
                        </Grid>
                      </div>
                      {questions[activeStep]?.responseType === 'Single' ? (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          style={
                            questions[activeStep].optionOrientation ===
                            'Vertical'
                              ? { display: 'flex', flexDirection: `row` }
                              : questions[activeStep].optionOrientation ===
                                'Horizontal'
                              ? { display: 'flex', flexDirection: `row` }
                              : {}
                          }
                        >
                          <FormControl
                            style={{
                              paddingLeft: '21px',
                              display: 'flex',
                              flexDirection: 'row',
                            }}
                          >
                            <RadioGroup
                              // defaultValue={
                              //   questions[activeStep]?.answer
                              //     ? questions[activeStep]?.answer
                              //     : ''
                              // }
                              // defaultChecked={gender.value}
                              name='answer'
                              value={
                                questions[activeStep]?.answer
                                  ? Number(questions[activeStep]?.answer)
                                  : ''
                              }
                              onChange={(e) => handleRadioChange(e, activeStep)}
                              style={
                                questions[activeStep].optionOrientation ===
                                'Vertical'
                                  ? {
                                      display: 'flex',
                                      paddingLeft: '13px',
                                      // marginTop: '10px',
                                      // marginBottom: '10px',
                                    }
                                  : questions[activeStep].optionOrientation ===
                                    'Horizontal'
                                  ? {
                                      display: 'flex',
                                      flexDirection: 'row',
                                      flexWrap: 'wrap',
                                    }
                                  : { display: 'flex', flexDirection: 'row' }
                              }
                            >
                              {questions[activeStep]?.responseOptions.map(
                                (item: any, index: any) => (
                                  <FormControlLabel
                                    style={
                                      {
                                        // marginTop: '10px',
                                        // marginBottom: '10px',
                                      }
                                    }
                                    value={index}
                                    control={<Radio size='small' />}
                                    label={
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: `<div class="sun-editor-editable" style="padding-top: 9px;
                                  padding-bottom: 1px; max-width:918px">${item?.optionContent?.replace(
                                    /\\/g,
                                    ''
                                  )}</div>`,
                                        }}
                                      />
                                      // <div></div>
                                    }
                                  />
                                )
                              )}
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      ) : (
                        ''
                      )}

                      {questions[activeStep]?.responseType === 'Multiple' ? (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          style={
                            questions[activeStep]?.optionOrientation ===
                            'Horizontal'
                              ? {
                                  display: 'flex',
                                }
                              : {}
                          }
                        >
                          {questions[activeStep]?.responseOptions.map(
                            (item: any, index: any) => (
                              <label
                                key={index}
                                style={
                                  questions[activeStep]?.optionOrientation ===
                                  'Vertical'
                                    ? {
                                        display: 'flex',
                                        // marginTop: '10px',
                                        // marginBottom: '10px',
                                      }
                                    : questions[activeStep]
                                        ?.optionOrientation === 'Horizontal'
                                    ? {
                                        marginTop: '10px',
                                        marginBottom: '10px',
                                      }
                                    : {
                                        display: 'flex',
                                        // marginTop: '10px',
                                        // marginBottom: '10px',
                                      }
                                }
                              >
                                <Checkbox
                                  // value={item.value}
                                  id={index}
                                  size='small'
                                  name={index}
                                  checked={
                                    questions[activeStep]?.answer[index] ||
                                    false
                                  }
                                  onChange={(e) =>
                                    onCheckboxChange(e, activeStep)
                                  }
                                />
                                <div
                                  style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex',
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: `<div class="sun-editor-editable" style="padding-top: 9px;
                            padding-bottom: 1px; max-width:918px">${questions[
                              activeStep
                            ]?.responseOptions[index].optionContent?.replace(
                              /\\/g,
                              ''
                            )}</div>`,
                                  }}
                                ></div>
                              </label>
                            )
                          )}
                        </Grid>
                      ) : (
                        ''
                      )}

                      {questions[activeStep]?.responseType === 'Open' ? (
                        <Grid item xs={12} sm={12}>
                          {questions[activeStep]?.responseOptions.map(
                            (item: any, index: any) => (
                              <div key={questions[activeStep].index}>
                                <TextField
                                  fullWidth
                                  multiline
                                  name='answer'
                                  label='Response Input:'
                                  defaultValue={
                                    questions[activeStep]?.answer || ''
                                  }
                                  // value={data?.answer || ''}
                                  ref={(input: any) => {
                                    if (input) {
                                      input.focus();
                                    }
                                  }}
                                  onChange={(e: any) =>
                                    onInputChange(e, activeStep)
                                  }
                                />
                              </div>
                            )
                          )}
                        </Grid>
                      ) : (
                        ''
                      )}

                      {questions[activeStep]?.responseType === 'Text' ? (
                        <Grid item xs={12} sm={12}>
                          {questions[activeStep]?.responseOptions.map(
                            (item: any, index: any) => (
                              <div key={questions[activeStep].index}>
                                <TextField
                                  //  fullWidth
                                  className='w-full'
                                  name='answer'
                                  label='Response Input:'
                                  defaultValue={
                                    questions[activeStep]?.answer || ''
                                  }
                                  // value={data?.answer || ''}
                                  onChange={(e: any) =>
                                    onInputChange(e, activeStep)
                                  }
                                />
                              </div>
                            )
                          )}
                        </Grid>
                      ) : (
                        ''
                      )}

                      {questions[activeStep]?.responseType === 'Number' ? (
                        <Grid item xs={12} sm={12}>
                          {questions[activeStep]?.responseOptions.map(
                            (item: any, index: any) => (
                              <div key={questions[activeStep].index}>
                                <TextField
                                  type='number'
                                  name='answer'
                                  label='Response Input:'
                                  defaultValue={
                                    Number(questions[activeStep]?.answer) || ''
                                  }
                                  // value={data?.answer || ''}
                                  onChange={(e: any) =>
                                    onInputChange(e, activeStep)
                                  }
                                />
                              </div>
                            )
                          )}
                        </Grid>
                      ) : (
                        ''
                      )}
                    </Grid>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'end',
                        marginTop: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      {/* <Button
                        sx={{ marginRight: 2 }}
                        // variant="outlined"
                        onClick={() => {
                          handleSubmit(false);
                        }}
                      >
                        Save And Complete Later
                      </Button> */}
                      <Button
                        sx={{ marginRight: 2 }}
                        size='small'
                        variant='contained'
                        onClick={handleNext}
                        style={
                          activeStep ===
                          (questions?.length > 0 ? questions.length : 0) - 1
                            ? { display: 'none' }
                            : {}
                        }
                        disabled={
                          activeStep ===
                          (questions?.length > 0 ? questions.length : 0) - 1
                        }
                      >
                        Next
                        {theme.direction === 'rtl' ? (
                          <KeyboardArrowLeft />
                        ) : (
                          <KeyboardArrowRight />
                        )}
                      </Button>
                      <Button
                        style={
                          questions?.length - 1 === activeStep
                            ? {}
                            : { display: 'none' }
                        }
                        variant='contained'
                        disabled={button}
                        onClick={() => handleSubmit(true)}
                      >
                        Complete Section
                      </Button>
                      {/* <div>
                  {JSON.stringify(
                    studentAssessmentData?.answer?.answer[activeStep]
                  )}
                </div> */}
                    </div>
                  </Box>
                </Box>
              ) : (
                <Stack spacing={1}>
                  {/* <Skeleton variant="rectangular" width={900} height={500} /> */}
                  <Skeleton variant='rectangular' width={900} height={900} />
                </Stack>
              )}
            </Paper>
          </Box>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default TestItems;
