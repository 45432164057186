function createMergeTag(name, data) {
  const mergeTag = {
    name: 'Attach Question',
    display: 'submenu',

    add: function (core, targetElement) {
      if (data.length === 0) {
        return;
      }

      let listDiv = this.setSubmenu.call(core, data);

      var self = this;
      listDiv.querySelectorAll('.se-btn-list').forEach(function (btn) {
        btn.addEventListener('click', self.onClick.bind(core));
      });

      core.initMenuTarget(this.name, targetElement, listDiv);
    },

    setSubmenu: function () {
      const listDiv = this.util.createElement('DIV');
      listDiv.className = 'se-submenu se-list-layer';

      let innerHTML =
        '<div class="se-list-inner se-list-font-size" style="max-height: 100px; overflow-y: auto;"><ul class="se-list-basic">';
      data.forEach((data, index) => {
        if (name === 'Question') {
          innerHTML += `<li><button type="button" class="se-btn-list" value="[${name} ${
            index + 1
          }]">${name} ${index + 1}</button></li>`;
        } else if (name === 'Item') {
          innerHTML += `<li><button type="button" class="se-btn-list" value="[Item-${data.item_id}]">Item-${data.item_id}</button></li>`;
        }
      });
      innerHTML += '</ul></div>';

      listDiv.innerHTML = innerHTML;

      return listDiv;
    },

    onClick: function (e) {
      const value = e.target.value;
      const node = this.util.createElement('span');
      this.util.addClass(node, 'se-custom-tag');
      node.textContent = value;

      this.insertNode(node);
      const zeroWidthSpace = this.util.createTextNode(this.util.zeroWidthSpace);
      node.parentNode.insertBefore(zeroWidthSpace, node.nextSibling);

      this.submenuOff();
    },
  };

  return mergeTag;
}

export default createMergeTag;
