// import { Input, Button, IconButton, Skeleton } from '@mui/material';
// import React, {
//   useState,
//   useEffect,
//   useMemo,
//   useCallback,
//   useRef,
// } from 'react';
// import { MdModeEdit, MdDelete } from 'react-icons/md';
// import { ErrorMessage, Field, Form, Formik, useField } from 'formik';
// import { initialValues } from '../register/activatedStepper/InitialValues';
// import * as Yup from 'yup';
// import { useDispatch, useSelector } from 'react-redux';
// import { AppDispatch, RootState } from '../../reduxStore/Store';
// import {
//   update,
//   getUserDetailsById,
//   updateUserDetails,
// } from '../../reduxStore/reducer/registerReducer';

// function Profile({ userData }: any) {
//   const dispatch = useDispatch<AppDispatch>();

//   // const [initialValues, setInitialValues] = useState<any>(
//   //   userData?.id != null ? { ...userData } : {}
//   // );
//   const validation = Yup.object({
//     first_name: Yup.string()
//       .matches(/^[a-zA-Z\s]+$/, 'Only alphabets and spaces are allowed.')
//       .matches(/^[^ ]/, 'The starting letter should not be a space.')
//       .matches(/[^\s]$/, 'Ending letter should not be a space.')
//       .required('Required Field'),
//     last_name: Yup.string()
//       .matches(/^[a-zA-Z\s]+$/, 'Only alphabets and spaces are allowed.')
//       .matches(/^[^ ]/, 'The starting letter should not be a space.')
//       .matches(/[^\s]$/, 'Ending letter should not be a space.')
//       .required('Required Field'),
//     middle_name: Yup.string()
//       .matches(/^[a-zA-Z\s]+$/, 'Only alphabets and spaces are allowed.')
//       .matches(/^[^ ]/, 'The starting letter should not be a space.')
//       .matches(/[^\s]$/, 'Ending letter should not be a space.'),
//     other_name: Yup.string()
//       .matches(/^[a-zA-Z\s]+$/, 'Only alphabets and spaces are allowed.')
//       .matches(/^[^ ]/, 'The starting letter should not be a space.')
//       .matches(/[^\s]$/, 'Ending letter should not be a space.'),
//     email: Yup.string().email().required('Required Field'),
//   });
//   // const handleCancel = () => {
//   //
//   //   setInitialValue(userData.data);
//   //   return;
//   // };

//
//   //

//   return (
//     <div>
//       <h1 className="text-3xl font-large p-2">Profile</h1>
//       {userData?.id != null ? (
//         <Formik
//           enableReinitialize
//           initialValues={userData?.id != null ? { ...userData } : {}} //initialValues
//           validationSchema={validation}
//           onSubmit={(values) => {
//             dispatch(updateUserDetails(values));
//           }}
//         >
//           {({ values, errors, touched, isValid }) => (
//             <Form>
//               <div className="grid gap-y-2 max-w-lg sm:max-w-3xl mt-4 ml-2 sm:grid-cols-2">
//                 <label htmlFor="institution" className="">
//                   Email
//                 </label>
//                 <div>
//                   <Field
//                     id="email"
//                     name="email"
//                     disabled={true}
//                     className="input-field h-9"
//                   />
//                   <ErrorMessage
//                     name="email"
//                     component="span"
//                     className="text-red-600 ml-2 text-sm"
//                   />
//                 </div>
//                 <label htmlFor="institution" className="">
//                   First Name
//                   <span className="text-red-500 text-lg"> *</span>
//                 </label>
//                 <div>
//                   <Field
//                     id="first_name"
//                     name="first_name"
//                     className="input-field h-9"
//                   />
//                   <ErrorMessage
//                     name="first_name"
//                     component="span"
//                     className="text-red-600 ml-2 text-sm"
//                   />
//                 </div>

//                 <label htmlFor="city" className="mt-2">
//                   Last Name<span className="text-red-500 text-lg"> *</span>
//                 </label>
//                 <div>
//                   <Field
//                     id="last_name"
//                     name="last_name"
//                     className="input-field h-9"
//                   />
//                   <ErrorMessage
//                     name="last_name"
//                     component="span"
//                     className="text-red-600 ml-2 text-sm"
//                   />
//                 </div>
//                 <label htmlFor="city" className="mt-2">
//                   Middle Name/Initial
//                 </label>
//                 <div>
//                   <Field
//                     id="middle_name"
//                     name="middle_name"
//                     className="input-field h-9"
//                   />
//                   <ErrorMessage
//                     name="middle_name"
//                     component="span"
//                     className="text-red-600 ml-2 text-sm"
//                   />
//                 </div>
//                 <label htmlFor="city" className="mt-2">
//                   Preferred First Name
//                 </label>
//                 <div>
//                   <Field
//                     id="other_name"
//                     name="other_name"
//                     className="input-field h-9"
//                   />
//                   <ErrorMessage
//                     name="other_name"
//                     component="span"
//                     className="text-red-600 ml-2 text-sm"
//                   />
//                 </div>
//                 <label
//                   htmlFor="city"
//                   className="mt-2"
//                   style={{ visibility: 'hidden' }}
//                 ></label>
//                 <div className="mt-2">
//                   <Button type="submit" variant="contained" disabled={!isValid}>
//                     Save
//                   </Button>
//                 </div>
//               </div>
//               <div className="flex justify-center gap-10 mt-7 mb-5">
//                 {/* <Button
//                   variant="contained"
//                   type="reset"
//                   color="error"
//                   onClick={(e) => formik.resetForm()}
//                   // style={{
//                   //   visibility: 'hidden',
//                   // }}
//                 >
//                   Cancel
//                 </Button> */}
//               </div>
//             </Form>
//           )}
//         </Formik>
//       ) : (
//         ''
//       )}
//     </div>
//   );
// }
// export default Profile;

import { Input, Button, IconButton, Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../reduxStore/Store';
import {
  getStudentDetailsById,
  getUserDetailsById,
  updateUserDetails,
} from '../../reduxStore/reducer/registerReducer';
import jwtDecode from 'jwt-decode';
import { forgotAction } from '../../reduxStore/reducer/authenticateReducer';
import Password from '../login/Password';

function Profile({ userData, studentData }: any) {
  const dispatch = useDispatch<AppDispatch>();

  const [type, setType] = useState('');
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState({
    email: '',
  });

  const handleEmail = (emailValue: any) => {
    dispatch(forgotAction({ email: emailValue }));
    setEmail({ email: '' });
  };

  useEffect(() => {
    // dispatch(
    //   getUserById({
    //     id: `${JSON.parse(localStorage.getItem('token') || '{}')}`,
    //   })
    // );
    const user: any = JSON.parse(localStorage.getItem('token') || '{}');

    const { type }: any = jwtDecode(user);
    setType(type);

    if (type === 'student') {
      //disp
      dispatch(getStudentDetailsById({ id: user }, setLoading));
    } else {
      // dispatch(getUserDetailsByIdAffiliate({ id: user }));
      dispatch(getUserDetailsById({ id: user }, setLoading));
    }
  }, [dispatch]);

  const validation = Yup.object({
    first_name: Yup.string()
      // .matches(/^[a-zA-Z\s]+$/, 'Only alphabets and spaces are allowed.')
      .matches(/^[^ ]/, 'The starting letter should not be a space.')
      .matches(/[^\s]$/, 'Ending letter should not be a space.')
      .required('Required Field'),
    last_name: Yup.string()
      // .matches(/^[a-zA-Z\s]+$/, 'Only alphabets and spaces are allowed.')
      .matches(/^[^ ]/, 'The starting letter should not be a space.')
      .matches(/[^\s]$/, 'Ending letter should not be a space.')
      .required('Required Field'),
    middle_name: Yup.string()
      // .matches(/^[a-zA-Z\s]+$/, 'Only alphabets and spaces are allowed.')
      .matches(/^[^ ]/, 'The starting letter should not be a space.')
      .matches(/[^\s]$/, 'Ending letter should not be a space.'),
    other_name: Yup.string()
      // .matches(/^[a-zA-Z\s]+$/, 'Only alphabets and spaces are allowed.')
      .matches(/^[^ ]/, 'The starting letter should not be a space.')
      .matches(/[^\s]$/, 'Ending letter should not be a space.'),
    email: Yup.string().email().required('Required Field'),
  });

  const dataToUse = userData || studentData;

  return (
    <div>
      <h1 className='text-3xl font-large p-2'>Profile</h1>
      {dataToUse?.id != null ? (
        <Formik
          enableReinitialize
          initialValues={dataToUse?.id != null ? { ...dataToUse } : {}}
          validationSchema={validation}
          onSubmit={(values) => {
            dispatch(updateUserDetails(values));
          }}
        >
          {({ values, errors, touched, isValid }) => (
            <Form>
              <div className='grid gap-y-2 max-w-lg sm:max-w-3xl mt-4 ml-2 sm:grid-cols-2'>
                <label htmlFor='institution' className=''>
                  Email
                </label>
                <div>
                  <Field
                    id='email'
                    name='email'
                    // disabled={true}
                    className='input-field h-9'
                  />
                  <ErrorMessage
                    name='email'
                    component='span'
                    className='text-red-600 ml-2 text-sm'
                  />
                </div>
                <label htmlFor='institution' className=''>
                  First Name
                  <span className='text-red-500 text-lg'> *</span>
                </label>
                <div>
                  <Field
                    id='first_name'
                    name='first_name'
                    className='input-field h-9'
                    disabled={type === 'student'}
                  />
                  <ErrorMessage
                    name='first_name'
                    component='span'
                    className='text-red-600 ml-2 text-sm'
                  />
                </div>

                <label htmlFor='city' className='mt-2'>
                  Last Name<span className='text-red-500 text-lg'> *</span>
                </label>
                <div>
                  <Field
                    id='last_name'
                    name='last_name'
                    className='input-field h-9'
                    disabled={type === 'student'}
                  />
                  <ErrorMessage
                    name='last_name'
                    component='span'
                    className='text-red-600 ml-2 text-sm'
                  />
                </div>
                {type !== 'student' && (
                  <>
                    <label htmlFor='city' className='mt-2'>
                      Middle Name/Initial
                    </label>
                    <div>
                      <Field
                        id='middle_name'
                        name='middle_name'
                        className='input-field h-9'
                      />
                      <ErrorMessage
                        name='middle_name'
                        component='span'
                        className='text-red-600 ml-2 text-sm'
                      />
                    </div>
                    <label htmlFor='city' className='mt-2'>
                      Preferred First Name
                    </label>
                    <div>
                      <Field
                        id='other_name'
                        name='other_name'
                        className='input-field h-9'
                      />
                      <ErrorMessage
                        name='other_name'
                        component='span'
                        className='text-red-600 ml-2 text-sm'
                      />
                    </div>

                    {/* <a
                      onClick={() => handleEmail(values.email)}
                      className="no-underline hover:underline text-link cursor-pointer mt-2"
                    >
                      I need to reset my password
                    </a> */}

                    <label
                      htmlFor='city'
                      className='mt-2'
                      style={{ visibility: 'hidden' }}
                    ></label>
                    <div className='mt-2'>
                      <Button
                        type='submit'
                        variant='contained'
                        disabled={!isValid}
                      >
                        Save
                      </Button>
                      <Button
                        sx={{ marginLeft: '10px' }}
                        variant='contained'
                        onClick={() => handleEmail(values.email)}
                      >
                        Reset Password
                      </Button>
                    </div>
                  </>
                )}
              </div>
              <div className='flex justify-center gap-10 mt-7 mb-5'>
                {/* Button for cancel */}
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        ''
      )}
    </div>
  );
}

export default Profile;
