import React, { useEffect, useState } from 'react';
import StepperIndex from '../pages/register/stepper/Index';
import SetepperActivatedIndex from '../pages/register/activatedStepper/Index';
import SetepperActivatedAffiliateIndex from '../pages/MyAccount/activatedStepper/Index';
import Activate from '../pages/register/activate/Activate';
import ActivateAffiliate from '../pages/MyAccount/activate/Activate';
import Users from '../pages/users';
import Discipline from '../pages/discipline/Discipline';
import { Routes, Route } from 'react-router-dom';
import PrivateRoutes from './PrivateRoutes';
import PublicRoute from './PublicRoute';
import LoginComponent from '../pages/login/Login';
import Reset from '../pages/login/Reset';
import Home from '../pages/home/Home';
import Organisation from '../pages/organisation/Organisation';
import College from '../pages/college/College';
import Department from '../pages/department/Department';
import SubDiscipline from '../pages/discipline/SubDiscipline';
import Administrative from '../pages/administrative/Administrative';
import Program from '../pages/program/Program';
import Unitroles from '../pages/unitroles/Unitroles';
import MyAccount from '../pages/MyAccount/MyAccount';
import Center from '../pages/Center/Center';
import AddStudent from '../pages/AddStudent/AddStudent';
import Course from '../pages/course/Course';
import Password from '../pages/login/Password';
import PasswordActivate from '../pages/register/activate/PasswordActivate';
import CourseAddAssestment from '../pages/Assessment/Index';
import CoursePrefix from '../pages/coursePrefix/CoursePrefix';
import CourseDetails from '../pages/courseDetails/CourseDetails';
import AddEditCourse from '../pages/course/AddEditCourse';
import Administration from '../pages/Administration/Administration';
import EmailingUsers from '../pages/emailingUsers/EmailingUsers';
import Likert from '../pages/Assessment/Likert';
import TakeAssessment from '../pages/Assessment/TakeAssessment';
import DemographicIndex from '../pages/Assessment/activatedStepper/Index';
import TakeAssessmentQuestion from '../pages/Assessment/TakeAssessmentQuestion';
import Assessment from '../pages/Assessment/Assessment';
import CourseRegisterIndex from '../pages/course/Register/Index';
import OpenRequest from '../pages/MyAccount/Stepper/OpenRequest';
import HelpCenter from '../pages/MyAccount/Stepper/HelpCenter';
import FeatureRequest from '../pages/MyAccount/Stepper/FeatureRequest';
import NewReports from '../pages/MyAccount/Stepper/NewReports';
import PrivateWrapper from '../pages/layout/PrivateWrapper';
import PublicWrapper from '../pages/layout/PublicWrapper';
import SystemSettings from '../pages/systemSettings/SystemSettings';
import StudentAccountCreation from '../pages/StudentSignUp/StudentAccountCreation';
import StudentAssessment from '../pages/StudentSignUp/StudentAssessment';
import AddEditStaticAssessment from '../pages/Assessment/AddEditStaticAssessment';
import StudentTesting1 from '../pages/StudentSignUp/StudentTesting1';
import StudentDashboard from '../pages/StudentSignUp/StudentDashboard';
import AddEditAdaptiveAssessment from '../pages/Assessment/AddEditAdaptiveAssessment';
import PageNotFound from '../pages/errorPages/PageNotFound';
import Unauthorized from '../pages/errorPages/Unauthorized';
import Forbidden from '../pages/errorPages/forbidden';
import ConsentForm from '../pages/Assessment/ConsentForm';
import ViewAssessmentPage from '../pages/Assessment/ViewAssessmentPage';
import TestItems from '../pages/Assessment/TestItems';
import Confirmation from '../pages/register/stepper/Confirmation';
import StudentSuccess from '../pages/register/stepper/studentSuccess';
import StudentAccountCreationAndConfirmation from '../pages/StudentSignUp/StudentAccountCreationAndConfirmation';
import Success from '../pages/Assessment/Success';
import Maintenance from '../pages/errorPages/Maintenance';
import CourseAssessmentGridPage from '../pages/course/CourseAssessmentGridPage';
import Role from '../pages/Assessment/Role';
import api from '../reduxStore/route';
import { useLocation } from 'react-router-dom';

const routeConfig = {
  faculty: [
    '/home',
    '/course',
    '/users',
    // "/courseprefix",
    // "/coursedetails",
    '/editcourse',
    '/administration',
    '/helpcenter',
    '/myaccount',
    '/openrequest',
    '/featurecenter',
    '/addcourse',
    '/activated/userOrganization',
    '/userOrganization/activation',
    '/administration/:courseassessmentid/:courseid',
    '/dashboard',
    '/assessment',
    '/viewassessment',
    '/question',
    'assessment/adaptive',
    '/likert',
    '/course/assessment/:id',
  ],
  admin: [
    '/home',
    '/discipline',
    // '/subdiscipline',
    // "subdiscipline",
    '/addstudent',
    '/openrequest',
    '/helpcenter',
    '/featurecenter',
    // "/administration",
    // '/administration/:courseassessmentid',
    '/administration/:courseassessmentid/:courseid',
    '/course/assessment/:id',
    // '/sendmail',
    // '/centers',
    '/organization',
    // '/college',
    // '/department',
    '/users',
    // '/administrative',
    // '/program',
    '/unitroles',
    '/myaccount',
    '/addcourse',
    '/course',
    // '/activated/register',
    '/userOrganization/activation',
    '/activated/userOrganization',
    '/addcourse',
    '/editcourse',
    // '/courseprefix',
    // '/coursedetails',
    '/assessment',
    '/test',
    '/addassessment',
    '/question',
    '/newreports',
    '/likert',
    '/assessment/adaptive',
    '/assessment/adaptive/question',
    '/systemsettings',
    '/darkmode',
    '/studentsignup',
    '/reports',
    // '/dashboard',
    '/devq',
    '/viewassessment',
  ],
  student: [
    // '/',
    '/home',
    '/studentassessment',
    '/studentstatus',
    '/studentAssessment/addStudent/:course_student_id/:course_assessment_id/:administration_no',
    '/student/demographicsquestionnaire',
    '/student/testquestion',
    '/myaccount',
    '/studentdashboard',
    '/student/consent-form',
    '/student/test-items',
    '/assessment/success',
    '/student/role',
  ],
};

interface MainRoutesI {
  toggleTheme: () => void;
}
const MainRoutes = ({ toggleTheme }: MainRoutesI) => {
  const location = useLocation();

  const maintenance = localStorage.getItem('maintenance') || 'false';

  useEffect(() => {
    api.get(`/maintenance-status`);
  }, [location, localStorage]);

  const isMaintenance = maintenance === 'true';
  return (
    <Routes>
      {isMaintenance ? (
        <Route path='*' element={<Maintenance />} />
      ) : (
        <>
          <Route path='/' element={<PrivateRoutes config={routeConfig} />}>
            <Route path='/' element={<PrivateWrapper />}>
              {/* <Route path="/" element={<Navigate replace to="/home" />} /> */}
              <Route path='/home' element={<Home />} />
              <Route path='/discipline' element={<Discipline />} />
              <Route path='/subdiscipline' element={<SubDiscipline />} />
              <Route path='/addstudent' element={<AddStudent />} />
              <Route path='/openrequest' element={<OpenRequest />} />
              <Route path='/helpcenter' element={<HelpCenter />} />
              <Route path='/featurecenter' element={<FeatureRequest />} />
              {/* <Route path="/administration" element={<Administration />} /> */}

              <Route
                path='/administration/:courseassessmentid/:courseid'
                element={<Administration />}
              />
              {/* <Route path="/sendmail" element={<Sendmail />} /> */}
              <Route path='/discipline' element={<Discipline />} />
              <Route
                path='/course/assessment/:id'
                element={<CourseAssessmentGridPage />}
              />

              <Route path='/subdiscipline' element={<SubDiscipline />} />
              <Route path='/centers' element={<Center />} />
              <Route path='/organization' element={<Organisation />} />
              {/* <Route path="/addorganization" element={<AddOrganisation />} /> */}
              {/* <Route path="/editorganization" element={<AddOrganisation />} />  */}
              <Route path='/college' element={<College />} />
              <Route path='/department' element={<Department />} />
              <Route path='/users' element={<Users />} />
              <Route path='/administrative' element={<Administrative />} />
              <Route path='/program' element={<Program />} />
              <Route path='/unitroles' element={<Unitroles />} />
              <Route path='/myaccount' element={<MyAccount />} />
              <Route path='/addcourse' element={<AddEditCourse />} />
              <Route path='/course' element={<Course />} />
              <Route path='/newreports' element={<NewReports />} />
              <Route
                path='/studentassessment'
                element={<StudentAssessment />}
              />
              <Route path='/studentstatus' element={<StudentTesting1 />} />
              <Route path='/viewassessment' element={<ViewAssessmentPage />} />
              <Route
                path='/userOrganization/activation'
                element={<ActivateAffiliate />}
              />
              <Route
                path='/activated/userOrganization'
                element={<SetepperActivatedAffiliateIndex />}
              />
              <Route path='/addcourse' element={<AddEditCourse />} />
              <Route path='/editcourse' element={<AddEditCourse />} />
              <Route path='/courseprefix' element={<CoursePrefix />} />
              <Route path='/coursedetails' element={<CourseDetails />} />

              <Route path='/assessment' element={<Assessment />} />
              <Route path='/addassessment' element={<CourseAddAssestment />} />
              <Route path='/question' element={<AddEditStaticAssessment />} />

              <Route
                path='/assessment/adaptive'
                element={<AddEditAdaptiveAssessment />}
              />

              <Route path='/likert' element={<Likert />} />
              <Route
                path='/systemsettings'
                element={<SystemSettings toggleTheme={toggleTheme} />}
              />
              <Route path='/studentdashboard' element={<StudentDashboard />} />

              <Route
                path='/studentAssessment/addStudent/:course_student_id/:course_assessment_id/:administration_no'
                element={<TakeAssessment />}
              />
              <Route
                path='/student/demographicsquestionnaire'
                element={<DemographicIndex />}
              />
              <Route
                path='/student/testquestion'
                element={<TakeAssessmentQuestion />}
              />
              <Route path='/student/role' element={<Role />} />
              <Route path='/reports' element={<EmailingUsers />} />
              <Route path='/student/consent-form' element={<ConsentForm />} />

              <Route path='/student/test-items' element={<TestItems />} />
            </Route>
          </Route>
          <Route path='/' element={<PublicRoute />}>
            <Route path='/' element={<PublicWrapper />}>
              <Route path='/login' element={<LoginComponent />} />

              <Route path='/user/activation' element={<Activate />} />
              <Route path='/resetpassword' element={<Reset />} />
              <Route path='/admin/activation' element={<PasswordActivate />} />
              <Route path='/admin/password' element={<Password />} />

              <Route
                path='/invite/activation'
                element={<CourseRegisterIndex />}
              />
              <Route
                path='/assessment/student/:course_student_id/:course_assessment_id/:administration_no'
                element={<TakeAssessment />}
              />
              <Route
                path='/assessment/student/:course_student_id/:course_assessment_id/:administration_no/signup'
                element={<StudentAccountCreation />}
              />
              <Route
                path='student/:course_student_id/signup'
                element={<StudentAccountCreationAndConfirmation />}
              />
              <Route path='/confirmation' element={<Confirmation />} />
              <Route path='/studentSuccess' element={<StudentSuccess />} />

              <Route path='/register' element={<StepperIndex />} />
              <Route
                path='/activated/register'
                element={<SetepperActivatedIndex />}
              />
              <Route
                path='/demographicsquestionnaire'
                element={<DemographicIndex />}
              />
              <Route
                path='/testquestion'
                element={<TakeAssessmentQuestion />}
              />
              <Route path='/role' element={<Role />} />
              <Route path='/consent-form' element={<ConsentForm />} />
              <Route path='/test-items' element={<TestItems />} />
              <Route path='/assessment/success' element={<Success />} />
            </Route>
          </Route>
          <Route path='/error/unauthorized' element={<Unauthorized />}></Route>
          <Route path='/error/forbidden' element={<Forbidden />}></Route>
          <Route path='/*' element={<PageNotFound />} />
        </>
      )}
    </Routes>
  );
};

export default MainRoutes;
