import React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Dashboard from '../Dashboard/Dashboard';
import MailSettings from './Settings/MailSettings';
import DisplaySettings from './Settings/DisplaySettings';
import SecuritySettings from './Settings/SecuritySettings';
interface SystemSettings {
  toggleTheme: () => void;
}
const SystemSettings = ({ toggleTheme }: SystemSettings) => {
  const [value, setValue] = React.useState('mailsettings');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <main style={{ height: '81vh' }}>
      <header className="header">System Settings</header>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab
                style={{ textTransform: 'capitalize' }}
                label="Mail Settings"
                value="mailsettings"
              />
              <Tab
                style={{ textTransform: 'capitalize' }}
                label="Display Settings"
                value="2"
              />
              <Tab
                style={{ textTransform: 'capitalize' }}
                label="Security Settings"
                value="3"
              />
            </TabList>
          </Box>
          <TabPanel value="mailsettings">
            <MailSettings />
          </TabPanel>
          <TabPanel value="2">
            <DisplaySettings toggleTheme={toggleTheme} />
          </TabPanel>
          <TabPanel value="3">
            <SecuritySettings />
          </TabPanel>
        </TabContext>
      </Box>
    </main>
  );
};

export default SystemSettings;
