import React, { useCallback, useContext, useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { AppContext } from './Context';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
  getSystemSettingsList,
  getSystemSettingsPasswordAndComplexity,
} from '../../../reduxStore/reducer/systemSettingsReducer';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../reduxStore/Store';

export default function FourthStep() {
  const dispatch = useDispatch<AppDispatch>();
  const { formValues, handleChange, handleBack, handleNext, variant, margin } =
    useContext(AppContext);
  const { password, reEnterPassword } = formValues;

  // Check if all values are not empty and if there are some errors
  const isError = useCallback(
    () =>
      Object.keys({ password, reEnterPassword }).some(
        (name) =>
          (formValues[name].required && !formValues[name].value) ||
          formValues[name].error
      ),
    [formValues, password, reEnterPassword]
  );

  const errorMessage =
    password.value !== reEnterPassword.value
      ? 'Password and Confirm Password Mismatch'
      : '';

  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);

  const [passwords, setPasswords] = useState<any>([]);
  const [passwordsComplexity, setPasswordsComplexity] = useState<any>({});

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowRePassword = () => setShowRePassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const handleMouseDownRePassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  useEffect(() => {
    dispatch(
      getSystemSettingsPasswordAndComplexity(
        setPasswords,
        setPasswordsComplexity
      )
    );
  }, []);

  const validatePassword = (password: string) => {
    const regex = new RegExp(passwords.join(''));
    return regex.test(password);
  };

  const doPasswordsMatch = (password: string, confirmPassword: string) => {
    return password === confirmPassword;
  };

  const initialValues = {
    password: password.value || '',
    reEnterPassword: reEnterPassword.value || '',
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required('Password is required')
      .matches(new RegExp(passwords.join('')), () => (
        <div className='mr-5'>
          {passwordsComplexity.map((requirement: any, index: any) => (
            <li key={index}>{requirement}</li>
          ))}
        </div>
      ))
      .typeError('A number is required'),
    reEnterPassword: Yup.string()
      .required('Re-Enter Password is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  const onSubmit = (values: any) => {
    password.value = values.password;
    reEnterPassword.value = values.reEnterPassword;
    handleNext();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values }) => (
        <Form>
          <Grid container spacing={2}>
            {/* Password Field */}
            <Grid item xs={12} sm={12}>
              <h2>
                <b>Set a password</b>
              </h2>

              <FormControl
                fullWidth
                sx={{ m: 1 }}
                variant={variant}
                margin={margin}
              >
                <InputLabel htmlFor='standard-adornment-password'>
                  Password
                </InputLabel>
                <Field
                  as={Input}
                  name='password'
                  id='standard-adornment-password'
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                      </IconButton>
                      {values.password && (
                        <span
                          style={{
                            color: validatePassword(values.password)
                              ? 'green'
                              : 'red',
                          }}
                        >
                          {validatePassword(values.password) ? '✔' : '✖'}
                        </span>
                      )}
                    </InputAdornment>
                  }
                />
                <FormHelperText error={!!password.error}>
                  {password.error}
                </FormHelperText>
              </FormControl>
              <ErrorMessage
                name='password'
                component='span'
                className='text-red-600 ml-2 text-sm'
              />
            </Grid>

            {/* Re-Enter Password Field */}
            <Grid item xs={12} sm={12}>
              <FormControl
                fullWidth
                sx={{ m: 1 }}
                variant={variant}
                margin={margin}
              >
                <InputLabel htmlFor='standard-adornment-reEnterPassword'>
                  Confirm Password
                </InputLabel>
                <Field
                  as={Input}
                  name='reEnterPassword'
                  id='standard-adornment-password'
                  type={showRePassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowRePassword}
                        onMouseDown={handleMouseDownRePassword}
                      >
                        {showRePassword ? (
                          <AiFillEyeInvisible />
                        ) : (
                          <AiFillEye />
                        )}
                      </IconButton>
                      {values.reEnterPassword && (
                        <span
                          style={{
                            color: doPasswordsMatch(
                              values.password,
                              values.reEnterPassword
                            )
                              ? 'green'
                              : 'red',
                          }}
                        >
                          {doPasswordsMatch(
                            values.password,
                            values.reEnterPassword
                          )
                            ? '✔'
                            : '✖'}
                        </span>
                      )}
                    </InputAdornment>
                  }
                />
                <FormHelperText error={!!reEnterPassword.error}>
                  {reEnterPassword.error}
                </FormHelperText>
              </FormControl>
              <ErrorMessage
                name='reEnterPassword'
                component='span'
                className='text-red-600 ml-2 text-sm'
              />
            </Grid>
          </Grid>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
            <Button onClick={handleBack} sx={{ mr: 1 }}>
              Back
            </Button>
            <Button variant='contained' type='submit' color='primary'>
              Next
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
}
