import api from './index';
import { PaginationProp } from '../../utils/globalInterface/GlobalInterfaces';

const addSystemSettings = (data: any) => api.post('/system_settings', data);
const getSystemSettingsIdAndName = () => api.get('/system_settings');
const getSystemSettingsList = () => api.get('/system_settings/list');
const getSystemSettingsPasswordAndComplexity = () =>
  api.get('/system_settings/password');

const updateSystemSettings = (data: any, id: string) =>
  api.put(`/system_settings/${id}`, data);

export {
  addSystemSettings,
  getSystemSettingsIdAndName,
  getSystemSettingsList,
  updateSystemSettings,
  getSystemSettingsPasswordAndComplexity,
};
