import React, { useEffect, useCallback, useState } from 'react';
import { IconButton, MenuItem, Tooltip, useTheme } from '@mui/material';
import {
  Paper,
  Box,
  Tabs,
  Tab,
  Typography,
  Radio,
  Grid,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Select,
  OutlinedInput,
  DialogTitle,
  Checkbox,
} from '@mui/material';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import { RiStethoscopeLine } from 'react-icons/ri';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import TakeAssessmentQuestion from './TakeAssessmentQuestion';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import 'suneditor/src/assets/css/suneditor.css'; // Import Sun Editor's CSS File'
import 'suneditor/src/assets/css/suneditor-contents.css'; // Import Sun Editor's CSS File
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import { useLocation } from 'react-router-dom';
import { viewAssessment } from '../../reduxStore/reducer/assessmentReducer';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../reduxStore/Store';
import api from '../../reduxStore/route';
import PhotoIcon from '@mui/icons-material/Photo';
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ViewAssessmentPage = () => {
  //
  const dispatch = useDispatch<AppDispatch>();
  const [val, setVal] = React.useState<any>({});
  const [refImage, setRefImage] = React.useState<any>({});
  console.log(refImage, 'refImage');
  const [imageDescription, SetImageDescription] = useState([]);
  //const [value, setValue] = React.useState(0);
  const [viewData, setViewData] = useState();
  const [loading, setLoading] = useState(true);
  const search = useLocation().search;
  const id = new URLSearchParams(search).get('id');
  const type = new URLSearchParams(search).get('type');

  useEffect(() => {
    // if (state?.data?.questions?.data?.length !== 0) {
    //   setVal(state?.data);
    // }

    if (type === 'course') {
      api
        .get(`/assessment/${id}`)
        .then((response: any) => {
          response.data.data.questions = response.data.data.question_data;

          dispatch(
            viewAssessment(
              { question_id: response.data.data.assessment_question_id },
              setVal,
              setLoading,
              setRefImage,
              SetImageDescription
            )
          );

          // setViewData(response.data.data);
          // setOpenView(true);
          // setVal(response.data.data);
          // setLoading(false);
        })
        .catch((err: any) => {});
    } else {
      dispatch(
        viewAssessment(
          { question_id: id },
          setVal,
          setLoading,
          setRefImage,
          SetImageDescription
        )
      );
    }
  }, [id, type]);

  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = val?.questions?.data?.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const questions = val?.questions?.data.filter(
    (e: any) => e.type === 'question'
  );

  const framing = val?.questions?.data.filter((e: any) => e.type === 'framing');

  const framingCondition = (item: any) => {
    const res = framing.find((e: any) => e.framingLabel === item);

    if (res) return res.framingContent?.replace(/\\/g, '');
    else return '';
  };

  const openImageInNewTab = () => {
    // Create an HTML document string with the image embedded
    const htmlContent = `
      <html>
        <head>
          <title>Reference Image</title>
          <style>
            body { 
              display: flex; 
              justify-content: center; 
              align-items: center; 
              height: 100vh; 
              margin: 0; 
              background-color: #f0f0f0;
            }
            img {
              max-width: 100%;
              max-height: 100%;
              object-fit: contain;
            }
          </style>
        </head>
        <body>
          <img src="${refImage}" alt="Reference Image" />
        </body>
      </html>
    `;

    // Create a new Blob object using the HTML content
    const blob = new Blob([htmlContent], { type: 'text/html' });

    // Create a URL for the Blob object
    const url = URL.createObjectURL(blob);

    // Open the new tab with the Blob URL
    const newTab = window.open(url, '_blank');

    // Check if the new tab opened successfully
    if (!newTab) {
      console.error('Failed to open new tab.');
    }
  };
  return (
    <div>
      <div style={{ minHeight: '81vh' }}>
        {questions?.length > 0 && (
          <div
            style={{
              backgroundColor: 'white',
              display: 'flex',
              justifyContent: 'center',
              marginTop: '10px',
            }}
          >
            <Box
              sx={{
                maxWidth: 900,
                flexGrow: 1,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  margin: '15px',
                  // position: 'fixed',
                  // minWidth: '470px',
                  backgroundColor: 'white',
                }}
              >
                <Button
                  size='small'
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  {theme.direction === 'rtl' ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                  Back
                </Button>
                <div style={{ display: 'flex', gap: '10px' }}>
                  <Select
                    size='small'
                    value={activeStep}
                    onChange={(e: any) => {
                      setActiveStep(e.target.value);
                    }}
                    // className={classes.select}
                  >
                    {[
                      ...Array(
                        questions?.length > 0 ? questions.length : 0
                      ).keys(),
                    ].map((num) => (
                      <MenuItem key={num} value={num}>
                        {num + 1}
                      </MenuItem>
                    ))}
                  </Select>
                </div>

                <Button
                  size='small'
                  onClick={handleNext}
                  disabled={
                    activeStep ===
                    (questions?.length > 0 ? questions.length : 0) - 1
                  }
                >
                  Next
                  {theme.direction === 'rtl' ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              </div>
            </Box>
          </div>
        )}

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Box
            sx={{
              maxWidth: 900,
              flexGrow: 1,
            }}
          >
            <Paper
              variant='outlined'
              sx={{ marginTop: questions?.length > 0 ? '' : '80px' }}
            >
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                {' '}
                {refImage && (
                  <>
                    <div>
                      <span>{imageDescription}</span>

                      <Tooltip title='View image'>
                        <IconButton onClick={openImageInNewTab}>
                          <PhotoIcon className='text-edit-icon' />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </>
                )}
              </div>
              {questions?.length > 0 ? (
                <Box
                  sx={{
                    minHeight: 400,
                    height: 'auto',
                    maxWidth: 900,
                    width: '100%',
                    p: 1,
                  }}
                >
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid
                      container
                      //spacing={2}
                      // sx={{ padding: '10px' }}
                    >
                      {val?.questions?.instruction && (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          style={
                            activeStep + 1 === 1 ? {} : { display: 'none' }
                          }
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: `<div class="sun-editor-editable" style="padding-top: 0px;
                                padding-bottom: 0px; max-width: 918px;">${val?.questions?.instruction?.replace(
                                  /\\/g,
                                  ''
                                )}</div>`,
                            }}
                            style={{ whiteSpace: 'pre-wrap' }}
                          />
                        </Grid>
                      )}

                      {framingCondition(questions[activeStep]?.framing) && (
                        <Grid item xs={12} sm={12}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: `<div class="sun-editor-editable" style="padding-top: 0px;
                                padding-bottom: 0px; max-width: 918px;">${framingCondition(
                                  questions[activeStep]?.framing
                                )}</div>`,
                            }}
                            style={{ whiteSpace: 'pre-wrap' }}
                          />
                        </Grid>
                      )}
                      <Grid style={{ marginBottom: '5px' }} item xs={2} sm={2}>
                        <b style={{ whiteSpace: 'nowrap' }}>
                          {questions[activeStep]?.questionLabel}
                        </b>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        style={{ display: 'flex', flexFlow: 'wrap' }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: `<div class="sun-editor-editable" style="padding-top: 0px;
                              padding-bottom: 0px; max-width: 918px;">${questions[
                                activeStep
                              ]?.questionContent?.replace(/\\/g, '')}</div>`,
                          }}
                          style={{ whiteSpace: 'pre-wrap' }}
                        />
                      </Grid>

                      {questions[activeStep]?.responseType === 'Single' ? (
                        <Grid item xs={12} sm={12}>
                          <FormControl
                            style={{
                              // paddingLeft: '21px',
                              display: 'flex',
                              flexDirection: 'row',
                            }}
                          >
                            <RadioGroup
                              defaultValue={
                                questions[activeStep]?.answer
                                  ? questions[activeStep]?.answer
                                  : ''
                              }
                              // defaultChecked={gender.value}
                              name='answer'
                              value={
                                questions[activeStep]?.answer
                                  ? questions[activeStep]?.answer
                                  : ''
                              }
                              style={
                                questions[activeStep].optionOrientation ===
                                'Vertical'
                                  ? {
                                      display: 'flex',
                                      paddingLeft: '13px',
                                      // marginTop: '10px',
                                      // marginBottom: '10px',
                                    }
                                  : questions[activeStep].optionOrientation ===
                                    'Horizontal'
                                  ? {
                                      display: 'flex',
                                      flexDirection: 'row',
                                      flexWrap: 'wrap',
                                    }
                                  : { display: 'flex', flexDirection: 'row' }
                              }
                            >
                              {questions[activeStep]?.responseOptions.map(
                                (item: any, index: any) => (
                                  <FormControlLabel
                                    style={
                                      {
                                        // marginTop: '10px',
                                        // marginBottom: '10px',
                                      }
                                    }
                                    value={index}
                                    control={<Radio size='small' />}
                                    label={
                                      index + 1 ===
                                      Number(
                                        questions?.[activeStep]?.defaultAnswer
                                      ) ? (
                                        <div
                                          style={{
                                            color: 'black',
                                            whiteSpace: 'pre-wrap',
                                          }}
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              `<div class="sun-editor-editable" style="background-color:yellow; padding-top: 9px;
                                                padding-bottom: 1px; max-width:918px">${item?.optionContent}</div>`.replace(
                                                /\\/g,
                                                ''
                                              ),
                                          }}
                                        />
                                      ) : (
                                        <div
                                          style={{
                                            color: 'black',
                                            whiteSpace: 'pre-wrap',
                                          }}
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              `<div class="sun-editor-editable" style="padding-top: 9px;
                                                padding-bottom: 1px; max-width:918px">${item?.optionContent}</div>`.replace(
                                                /\\/g,
                                                ''
                                              ),
                                          }}
                                        />
                                      )
                                    }
                                  />
                                )
                              )}
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      ) : (
                        ''
                      )}

                      {questions[activeStep]?.responseType === 'Multiple' ? (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          style={
                            questions[activeStep]?.optionOrientation ===
                            'Horizontal'
                              ? {
                                  display: 'flex',
                                }
                              : {}
                          }
                        >
                          {questions[activeStep]?.responseOptions.map(
                            (item: any, index: any) => (
                              <label
                                key={index}
                                style={
                                  questions[activeStep]?.optionOrientation ===
                                  'Vertical'
                                    ? {
                                        display: 'flex',
                                        // marginTop: '10px',
                                        // marginBottom: '10px',
                                      }
                                    : questions[activeStep]
                                        ?.optionOrientation === 'Horizontal'
                                    ? {
                                        marginTop: '10px',
                                        marginBottom: '10px',
                                      }
                                    : {
                                        display: 'flex',
                                        // marginTop: '10px',
                                        // marginBottom: '10px',
                                      }
                                }
                              >
                                <Checkbox
                                  disabled={true}
                                  // value={item.value}
                                  id={index}
                                  size='small'
                                  name={index}
                                  checked={
                                    questions[activeStep]?.answer[index] ||
                                    false
                                  }
                                />
                                <div
                                  style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex',
                                    whiteSpace: 'pre-wrap',
                                  }}
                                  dangerouslySetInnerHTML={
                                    questions[activeStep]?.defaultAnswer?.[
                                      index + 1
                                    ] === true
                                      ? {
                                          __html:
                                            `<div class="sun-editor-editable" style="background-color:yellow; padding-top: 9px;
                                              padding-bottom: 1px;  max-width:918px">${item.optionContent}</div>`?.replace(
                                              /\\/g,
                                              ''
                                            ),
                                        }
                                      : {
                                          __html:
                                            `<div class="sun-editor-editable" style="padding-top: 9px;
                                              padding-bottom: 1px;  max-width:918px">${item?.optionContent}</div>`.replace(
                                              /\\/g,
                                              ''
                                            ),
                                        }
                                  }
                                ></div>
                              </label>
                            )
                          )}
                        </Grid>
                      ) : (
                        ''
                      )}

                      {questions[activeStep]?.responseType === 'Open' ? (
                        <Grid item xs={12} sm={12}>
                          {questions[activeStep]?.responseOptions.map(
                            (item: any, index: any) => (
                              <div key={questions[activeStep].index}>
                                <TextField
                                  disabled={true}
                                  className='w-full'
                                  multiline
                                  name='answer'
                                  label='Response Input:'
                                  defaultValue={
                                    questions[activeStep]?.defaultAnswer || ''
                                  }
                                  // value={data?.answer || ''}
                                  ref={(input: any) => {
                                    if (input) {
                                      input.focus();
                                    }
                                  }}
                                />
                              </div>
                            )
                          )}
                        </Grid>
                      ) : (
                        ''
                      )}

                      {questions[activeStep]?.responseType === 'Text' ? (
                        <Grid item xs={12} sm={12}>
                          {questions[activeStep]?.responseOptions.map(
                            (item: any, index: any) => (
                              <div key={questions[activeStep].index}>
                                <TextField
                                  disabled={true}
                                  fullWidth
                                  name='answer'
                                  label='Response Input:'
                                  defaultValue={
                                    questions[activeStep]?.defaultAnswer || ''
                                  }
                                  // value={data?.answer || ''}
                                />
                              </div>
                            )
                          )}
                        </Grid>
                      ) : (
                        ''
                      )}

                      {questions[activeStep]?.responseType === 'Number' ? (
                        <Grid item xs={12} sm={12}>
                          {questions[activeStep]?.responseOptions.map(
                            (item: any, index: any) => (
                              <div key={questions[activeStep].index}>
                                <TextField
                                  disabled={true}
                                  type='number'
                                  name='answer'
                                  label='Response Input:'
                                  defaultValue={(() => {
                                    const b1 =
                                      typeof questions[activeStep]
                                        ?.defaultAnswer === 'number' &&
                                      questions[activeStep]?.defaultAnswer == 0;
                                    const b2 = Boolean(
                                      questions[activeStep]?.defaultAnswer
                                    );
                                    return b1 || b2
                                      ? Number(
                                          questions[activeStep]?.defaultAnswer
                                        )
                                      : '';
                                  })()}

                                  // defaultValue={
                                  //   questions?.[activeStep]?.isScored === 'Yes'
                                  //     ? item?.optionContent
                                  //       ? item?.optionContent
                                  //       : ''
                                  //     : ''
                                  // }
                                  // value={data?.answer || ''}
                                />
                              </div>
                            )
                          )}
                        </Grid>
                      ) : (
                        ''
                      )}
                    </Grid>
                  </Box>
                </Box>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '10px',
                  }}
                >
                  {loading ? 'Loading...' : ' No Questions To Display'}
                </div>
              )}
            </Paper>
          </Box>
        </div>

        <div
          style={{
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '10px',
            marginBottom: '10px',
          }}
        >
          <Box
            sx={{
              maxWidth: 900,
              flexGrow: 1,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'end',
                margin: '15px',

                backgroundColor: 'white',
              }}
            >
              <Button
                style={{ textTransform: 'capitalize' }}
                onClick={() => {
                  window.close();
                }}
                variant='contained'
              >
                Cancel
              </Button>
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default ViewAssessmentPage;
