import api from './index';

const getDashboards = (selectedYear: any) =>
  api.get(`/dashboard/count/${selectedYear}`);

const getUsersCountBasedOnYear = (selectedYear: any) =>
  api.get(`/dashboard/user/count/${selectedYear}`);

const getUsersStudentCountBasedOnYear = (selectedYear: any) =>
  api.get(`/dashboard/user-student/count/${selectedYear}`);
const getAssessmentCountBasedOnStatus = () =>
  api.get(`/dashboard/assessment/count`);
const getOrganizationCountBasedOnStatus = (selectedYear: any) =>
  api.get(`/dashboard/organization/count/${selectedYear}`);
//faculty

const getAdministrationCountBasedOnStatus = () =>
  api.get(`/dashboard/administration/count`);

const getCourseAdministrationCountBasedOnStatus = () =>
  api.get(`/dashboard/course-administration/count`);
const getFacultyDashboardList = (selectedYear: any) =>
  api.get(`/dashboard/faculty-count/count/${selectedYear}`);

const getFacultyCourseCardCountList = () =>
  api.get(`/dashboard/faculty-course-card/count`);

export {
  getDashboards,
  getUsersCountBasedOnYear,
  getUsersStudentCountBasedOnYear,
  getAssessmentCountBasedOnStatus,
  getOrganizationCountBasedOnStatus,
  getAdministrationCountBasedOnStatus,
  getCourseAdministrationCountBasedOnStatus,
  getFacultyDashboardList,
  getFacultyCourseCardCountList,
};
