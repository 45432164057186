import {
  Dialog,
  DialogTitle,
  Hidden,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableBody,
} from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

interface ImageAlternateTextDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  imageAlternateData: any;
}

const ImageAlternateTextDialog: React.FC<ImageAlternateTextDialogProps> = (
  props
) => {
  const { open, setOpen, imageAlternateData } = props;

  return (
    <div>
      <Dialog
        open={open}
        style={{ zIndex: +10 }}
        sx={{
          '& .MuiDialog-paper': {
            width: '600px',
            height: '500px',
          },
        }}
      >
        <DialogTitle sx={{ backgroundColor: '#1663ab', color: 'white' }}>
          <div className='flex justify-between items-center'>
            <div>View Image Alternative Text</div>
            <div>
              <CloseIcon onClick={() => setOpen(false)} />
            </div>
          </div>
        </DialogTitle>
        <div style={{ margin: '20px' }}>
          <p style={{ marginBottom: '10px', fontSize: '12px' }}>
            The table below shows the alternate text associated to each image in
            this assessment. Alternate text should not be empty for
            accessibility purposes.
          </p>
          <TableContainer component={Paper}>
            <Table aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <Hidden xsDown>
                    <TableCell>Image</TableCell>
                  </Hidden>
                  <TableCell>Alternate Text</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {imageAlternateData.map((row: any) => (
                  <TableRow key={row.alt}>
                    <Hidden xsDown>
                      <TableCell
                        component='th'
                        scope='row'
                        style={{ width: '50%' }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: row?.image?.replace(/\\/g, ''),
                          }}
                        />
                      </TableCell>
                    </Hidden>
                    <TableCell>{row.alt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Dialog>
    </div>
  );
};

export default ImageAlternateTextDialog;
