import {
  Box,
  TextField,
  Typography,
  Radio,
  Button,
  CircularProgress,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../reduxStore/Store';
import {
  checkAssessmentStatus,
  getAssessmentStudent,
  updateSession,
} from '../../reduxStore/reducer/assessmentReducer';
import Alert from '../../utils/Alert/Alert';
import { date } from 'yup';
import moment from 'moment';
import { AppContext } from '../MyAccount/Context';

function addQuotesIfNeeded(str: any) {
  if (str) {
    return str.replace(
      /"([^"]*)"|'([^']*)'|\b([^'",\s]+)\b/g,
      (match: any, doubleQuoted: any, singleQuoted: any, unquoted: any) => {
        if (doubleQuoted || singleQuoted) {
          // Already quoted, return as is
          return match;
        } else {
          // Add double quotes around unquoted word
          return `"${unquoted}"`;
        }
      }
    );
  } else {
    return {};
  }
}

const TakeAssessment = () => {
  const { handleNext } = React.useContext(AppContext);
  const [loading, setLoading] = React.useState(true);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { course_student_id, course_assessment_id, administration_no } =
    useParams();

  const assessmentStatusData: any = useSelector(
    (state: RootState) => state.assessment.assessmentStatus
  );

  const alterFunction = (data: any) => {
    if (data) {
      return JSON.parse(
        data.replace(/\\/g, '').replace(/[{}]/g, (match: any) => {
          return match === '{' ? '[' : ']';
        })
      );
    } else {
      return {};
    }
  };

  const studentData: any = useSelector((state: RootState) => {
    let data: any = { ...state.assessment.assessmentStudent };

    if (data?.id) {
    }

    if (data?.id) {
      data.ethnicity = data?.ethnicity
        ? alterFunction(addQuotesIfNeeded(data?.ethnicity))
        : {};
      data.gender = data?.gender
        ? alterFunction(addQuotesIfNeeded(data?.gender))
        : {};
      data.race = data?.race
        ? alterFunction(addQuotesIfNeeded(data?.race))
        : {};
    }

    return data;
  });

  useEffect(() => {
    const afterGettingAssessmentStatus = (assessmentStatus: any) => {
      if (!assessmentStatus || !Object.keys(assessmentStatus)?.length) return;

      const { test_status, end_date_time, start_date_time, option } =
        assessmentStatus;

      if (test_status) {
        Alert.info({ title: 'Assessment already taken', text: '' });
        navigate('/login');
        return;
      }

      if (end_date_time && moment(end_date_time) < moment()) {
        Alert.info({ title: 'Assessment Deadline crossed', text: '' });
        navigate('/login');
        return;
      }

      if (!start_date_time || !end_date_time) {
        Alert.info({ title: 'Assessment not started', text: '' });
        navigate('/login');
        return;
      }

      if (!option || test_status === false || test_status === null) {
        if (moment(start_date_time) > moment()) {
          Alert.info({ title: 'Assessment not started', text: '' });
          navigate('/login');
          return;
        }

        dispatch(
          getAssessmentStudent(
            { id: course_student_id, type: 'take' },
            navigate,
            afterGettingStudentStatus,
            assessmentStatus
          )
        );
      }
    };

    const afterGettingStudentStatus = (
      assessmentStatus: any,
      studentStatus: any
    ) => {
      const data = { ...studentStatus };
      if (data?.id) {
        data.ethnicity = data?.ethnicity
          ? alterFunction(addQuotesIfNeeded(data?.ethnicity))
          : {};
        data.gender = data?.gender
          ? alterFunction(addQuotesIfNeeded(data?.gender))
          : {};
        data.race = data?.race
          ? alterFunction(addQuotesIfNeeded(data?.race))
          : {};
      }
      onSubmit(assessmentStatus, data);
      // if (assessmentStatus?.option?.length && data.status_in_school?.length) {
      //   // Student has already filled the consent and demographics
      //   onSubmit(assessmentStatus, data);
      // } else {
      //   setLoading(false);
      // }
    };

    dispatch(
      checkAssessmentStatus(
        {
          course_assessment_id,
          course_student_id,
          administration_no,
        },
        navigate,
        afterGettingAssessmentStatus
      )
    );
  }, []);

  const onSubmit = async (assessmentStatus: any, studentStatus: any) => {
    if (state?.signUp) {
      navigate(
        Boolean(studentStatus?.role_in_class)
          ? '/student/testquestion'
          : '/student/role',
        {
          state: {
            course_student_id: course_student_id,
            course_assessment_id: course_assessment_id,
            administration_no: administration_no,
            participation: assessmentStatus?.option,
            data: studentStatus,
            role_in_class: studentStatus?.role_in_class,
            assessment_type: assessmentStatus?.assessment_type,
            signUp: false,
            login: true,
          },
        }
      );
    } else {
      navigate(
        Boolean(studentStatus?.role_in_class) ? '/testquestion' : '/role',
        {
          state: {
            course_student_id: course_student_id,
            course_assessment_id: course_assessment_id,
            administration_no: administration_no,
            participation: assessmentStatus?.option,
            data: studentStatus,
            role_in_class: studentStatus?.role_in_class,
            assessment_type: assessmentStatus?.assessment_type,
            signUp: false,
            login: false,
          },
        }
      );
    }

    setLoading(false);
  };

  return (
    <div>
      {!loading ? (
        <></>
      ) : (
        <div className='text-center flex justify-center items-center h-screen'>
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default TakeAssessment;
