// import axios from 'axios';
// import TokenService from '../../api/token.service';
// import { refreshToken } from '../reducer/authenticateReducer';
// import CryptoJS from 'crypto-js';

// const api = axios.create({
//   baseURL:
//     `${process.env.REACT_APP_BASE_URL}/api` || 'http://localhost:8081/api',
//   headers: {
//     'Content-Type': 'application/json',
//   },
// });
// api.interceptors.request.use(
//   (config: any) => {
//     config.headers = {
//       ...config.headers,
//       authorization: localStorage.getItem('token')
//         ? `Bearer ${JSON.parse(localStorage.getItem('token') || '{}')}`
//         : '',
//       'Content-Type': 'application/json',
//     };

//     if(config?.data){
//       const encryptionKey = 'your-encryption-key';
//       var ciphertext = CryptoJS.AES.encrypt(
//         JSON.stringify(config.data),
//         encryptionKey
//       );

//       config.data = { data: ciphertext };
//     }

//     return config;
//   },
//   (error: any) => {
//     return Promise.reject(error);
//   }
// );
// api.interceptors.response.use(
//   (next: any) => {
//     return Promise.resolve(next);
//   },
//   (error: any) => {
//
//     // if (
//     //   error.response.status === 402 &&
//     //   error.response.data.message === 'jwt expired'
//     // ) {
//     //   const token = {
//     //     token: TokenService.GetRefreshToken(),
//     //   };
//     //   refreshToken(token);
//     // } else
//     if (error.response.status === 401) {
//       Logout();
//     }
//     // else{
//     //   Logout()
//     // }
//     return Promise.reject(error);
//   }
// );

// function Logout() {
//   TokenService.RemoveAccessToken();
//   TokenService.RemoveRefreshToken();
//   // TokenService.RemoveRole();
//   localStorage.removeItem('role');
//   document.cookie = `refresh_token =; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
//   // navigate('/login');
//   window.location.reload();
// }

// export default api;

import axios from 'axios';
import TokenService from '../../api/token.service';
import { refreshToken } from '../reducer/authenticateReducer';
import CryptoJS from 'crypto-js';

const api = axios.create({
  baseURL:
    `${process.env.REACT_APP_BASE_URL}/api` || 'http://localhost:8081/api',
  headers: {
    'Content-Type': 'application/json',
  },
});
const key = (token?: string) => {
  let encryptionKey =
    process.env.REACT_APP_ENCRYPTION_KEY || 'ASZdXSKwFQjq80o4f04c';
  // let token = JSON.parse(localStorage.getItem("token") || "{}");
  if (token && typeof token === 'string') {
    encryptionKey = String(token?.slice(-20));
  }
  return encryptionKey;
};
api.interceptors.request.use(
  async (config: any) => {
    config.headers = {
      ...config.headers,
      authorization: config.headers.authorization
        ? config.headers.authorization
        : localStorage.getItem('token')
        ? `Bearer ${JSON.parse(localStorage.getItem('token') || '{}')}`
        : '',
      'Content-Type': config.headers['content-type']
        ? config.headers['content-type']
        : 'application/json',
    };
    let token = config.headers.authorization;

    if (config?.data) {
      const encryptionKey = key(token);

      // Encrypt the payload
      const ciphertext = CryptoJS.AES.encrypt(
        JSON.stringify(config.data),
        encryptionKey
      ).toString();

      config.data = { data: ciphertext };
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (next: any) => {
    // Decrypt the response

    const encryptionKey = key(next.config.headers.authorization);

    if (next?.data) {
      // try {
      var bytes = CryptoJS.AES.decrypt(next?.data, encryptionKey);

      var originalText = bytes.toString(CryptoJS.enc.Utf8);

      next.data = JSON.parse(originalText);
      // } catch (error) {
      //
      // }
    }
    localStorage.removeItem('maintenance');

    return Promise.resolve(next);
  },
  (error: any) => {
    if (error.response.status === 403) {
      window.location.href = '/error/forbidden';
      //Logout();
    } else if (error.response.status === 401) {
      // if (
      //   error.response?.data?.message === 'jwt expired' ||
      //   String(error?.response?.data?.message)
      //     .toLowerCase()
      //     .includes('token') ||
      //   String(error?.response?.data?.message).toLowerCase().includes('jwt')
      // ) {
      //   Logout();
      // } else {
      //   // window.location.href = '/error/unauthorized';
      // }
      Logout();
    } else if (error.response.status === 503) {
      localStorage.setItem('maintenance', 'true');
      // window.location.reload();
    }
    return Promise.reject(error);
  }
);

function Logout() {
  TokenService.RemoveAccessToken();
  TokenService.RemoveRefreshToken();
  localStorage.removeItem('role');
  localStorage.removeItem('hasRenderedBefore');
  localStorage.removeItem('hasRenderedBeforeInvite');
  localStorage.removeItem('maintenance');
  // window.location.reload();
  window.location.href = '/';
}

export default api;
