import React from 'react';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { useLocation, useNavigate } from 'react-router';
import { Link } from '@mui/material';
import Swal from 'sweetalert2';

export default function Success() {
  const theme = createTheme();

  const location = useLocation();
  const navigate = useNavigate(); // Use the useNavigate hook to get the navigate function

  const { state } = location;
  const handleCreateAccount = () => {
    const { password } = state.studentAccountData.data;
    if (password === null || password === '') {
      const { course_student_id, course_assessment_id, administration_no } =
        state.studentAccountData;
      navigate(
        `/assessment/student/${course_student_id}/${course_assessment_id}/${administration_no}/signup`
      );
    } else {
      Swal.fire({
        text: 'Student already has an account',
        icon: 'info',
        confirmButtonText: 'OK',
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/login');
        }
      });
    }
  };

  return (
    <>
      {location?.state ? (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
            <Paper
              variant="outlined"
              sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
            >
              <Typography variant="h5" sx={{ py: 4 }}>
                <div>
                  Congratulations, you have completed the survey. Your
                  instructor will be notified. If you would like to manage your
                  surveys on LASSO, you can create an optional student account.
                </div>
                <Link
                  component="button"
                  variant="body1"
                  onClick={handleCreateAccount}
                >
                  Click here to create a student account
                </Link>
              </Typography>
              {/* <Typography component="p" align="center">
                                Please check your Email for a Account activation Link
                            </Typography> */}
            </Paper>
          </Container>
        </ThemeProvider>
      ) : null}
    </>
  );
}
