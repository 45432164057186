import * as React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Button,
  Link,
  TextField,
  Tooltip,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  FormHelperText,
  CircularProgress,
  Radio,
  Fab,
  Box,
  Paper,
} from '@mui/material';
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
  FormikErrors,
  FieldArray,
} from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SunEditor from 'suneditor-react';
import katex from 'katex';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import * as yup from 'yup';
import Alert from '../../utils/Alert/Alert';

const AssessSpecificQuestion = ({ question, handleSaveAssessSpecQue }: any) => {
  const [openAssessSpecQue, setOpenAssessSpecQue] = React.useState(false);
  const handleCloseAssessQue = () => {
    // Alert.confirm(() => {
      setOpenAssessSpecQue(false);
    // });
  };
  const formikRef: any = React.useRef(null);
  const initialValues = {
    assessSpecQuestion:
      question?.length > 0
        ? question
        : [
            {
              question_label: 1,
              question_content: '',
              response_type: 'Single',
              response_options: [
                {
                  label: 1,
                  content: '',
                },
              ],
            },
          ],
  };

  // Define schema for assessment specific question
  const assessSpecQuestionSchema = yup.object({
    assessSpecQuestion: yup.array().of(
      yup.object().shape({
        question_label: yup.number().required('Required Field'),
        question_content: yup.string().required('Required Field'),
        response_type: yup.string().required('Required Field'),
        response_options: yup.array().of(
          yup.object({
            label: yup.number().required('Required Field'),
            content: yup.string().required('Required Field'),
          })
        ),
      })
    ),
  });
  const sunEditorOptions: any = {
    options: {
      katex: katex,
      fontSize: [
        8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 22, 24, 26, 28, 36,
        48, 72,
      ],
      mathFontSize: [
        { text: '0.5', value: '0.5em' },
        { text: '0.6', value: '0.6em' },
        { text: '0.7', value: '0.7em' },
        { text: '0.8', value: '0.8em' },
        { text: '0.9', value: '0.9em' },
        { text: '1', value: '1em', default: true },
        { text: '1.1', value: '1.1em' },
        { text: '1.2', value: '1.2em' },
        { text: '1.3', value: '1.3em' },
        { text: '1.4', value: '1.4em' },
        { text: '1.5', value: '1.5em' },
        { text: '1.6', value: '1.6em' },
        { text: '1.7', value: '1.7em' },
        { text: '1.8', value: '1.8em' },
        { text: '1.9', value: '1.9em' },
        { text: '2', value: '2em' },
        { text: '2.1', value: '2.1em' },
        { text: '2.2', value: '2.2em' },
        { text: '2.3', value: '2.3em' },
        { text: '2.4', value: '2.4em' },
        { text: '2.5', value: '2.5em' },
        { text: '2.6', value: '2.6em' },
        { text: '2.7', value: '2.7em' },
        { text: '2.8', value: '2.8em' },
        { text: '2.9', value: '2.9em' },
        { text: '3', value: '3em' },
        { text: '3.1', value: '3.2em' },
        { text: '3.2', value: '3.2em' },
        { text: '3.3', value: '3.3em' },
        { text: '3.4', value: '3.4em' },
        { text: '3.5', value: '3.5em' },
      ],

      mode: 'classic',
      buttonList: [
        [
          'undo',
          'redo',
          'font',
          'fontSize',
          'formatBlock',
          'blockquote',
          'bold',
          'underline',
          'italic',
          'strike',
          'subscript',
          'superscript',
          'fontColor',
          'hiliteColor',
          'textStyle',
          'removeFormat',
          'outdent',
          'indent',
          'align',
          'horizontalRule',
          'list',
          'lineHeight',
          'fullScreen',
          'showBlocks',
          'codeView',
          'preview',
          'print',
          'link',
          'horizontalRule',
          'math',
          'table',
          'image',
        ],
      ],
    },
    defaultStyle: 'height: auto; white-space: pre-wrap;',
  };

  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction='up' ref={ref} {...props} />;
  });

  return (
    <>
      <Button
        size='medium'
        variant='contained'
        onClick={() => {
          setOpenAssessSpecQue(true);
        }}
        style={{ textTransform: 'capitalize', minWidth: '150px' }}
      >
        Add/Edit questions for instructor
      </Button>
      <Dialog
        open={openAssessSpecQue}
        fullScreen
        scroll={'paper'}
        //   TransitionComponent={Transition}
      >
        <DialogTitle sx={{ backgroundColor: '#1663ab', color: 'white' }}>
          <div className='flex justify-between items-center'>
            <div>Add/Edit Assessment Specific Question</div>
            <Fab size='small'>
              <CloseIcon
                className='cursor-pointer'
                onClick={handleCloseAssessQue}
              />
            </Fab>
          </div>
        </DialogTitle>

        <DialogContent dividers={true}>
          <Formik
            innerRef={formikRef}
            initialValues={initialValues}
            validationSchema={assessSpecQuestionSchema}
            onSubmit={(values) => {
              handleSaveAssessSpecQue(values.assessSpecQuestion);
              handleCloseAssessQue();
            }}
          >
            {({
              values,
              errors,
              setFieldValue,
              setFieldTouched,
              touched,
              isValid,
            }) => (
              <Form>
                <Box className='border border-[#CBD5E1] rounded-md mt-5 p-3 pl-2'>
                  <FieldArray name='assessSpecQuestion'>
                    {({ remove, insert, push }) => (
                      <>
                        {values.assessSpecQuestion.map(
                          (
                            {
                              question_label,
                              question_content,
                              response_type,
                              response_options,
                            }: any,
                            questionIndex: number
                          ) => {
                            return (
                              <div className='flex  max-w-7xl'>
                                {/* question box divided into four */}
                                <Accordion
                                  key={question_label}
                                  sx={{
                                    borderRadius: '5px',
                                    marginBottom: '10px',
                                  }}
                                  className='p-3 w-full border border-[#D5D5D5]'
                                  defaultExpanded={true}
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                  >
                                    <Typography variant='h6' color='primary'>
                                      Question {questionIndex + 1}
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <div className='grid grid-cols-12 gap-x-3 gap-y-8'>
                                      {/* 1 */}
                                      <Field
                                        as={TextField}
                                        disabled={true}
                                        size='small'
                                        className='col-span-1'
                                        sx={{
                                          marginTop: '21px',
                                          width: '96px',
                                        }}
                                        variant='outlined'
                                        label='Label'
                                        value={question_label}
                                        name={`assessSpecQuestion[${questionIndex}].question_label`}
                                        type='text'
                                        inputProps={{ maxLength: 3 }}
                                        //   error={Boolean(
                                        //     touched.question_label &&
                                        //       errors.question_label
                                        //   )}
                                      />

                                      {/* 2 */}
                                      <div className='col-span-11 max-h-max pl-10'>
                                        <label style={{ fontSize: '12px' }}>
                                          Question Content:
                                        </label>
                                        <Field
                                          component={SunEditor}
                                          setContents={question_content}
                                          name={`assessSpecQuestion[${questionIndex}].question_content`}
                                          onChange={(content: string) => {
                                            setFieldValue(
                                              `assessSpecQuestion[${questionIndex}].question_content`,
                                              content
                                            );
                                          }}
                                          onBlur={() => {
                                            // Use setFieldTouched to mark the field as touched
                                            setFieldTouched(
                                              `assessSpecQuestion[${questionIndex}].question_content`,
                                              true
                                            );
                                          }}
                                          //@ts-ignore
                                          setDefaultStyle={
                                            sunEditorOptions.defaultStyle
                                          }
                                          setOptions={sunEditorOptions.options}
                                        />

                                        <ErrorMessage
                                          name={`assessSpecQuestion.${questionIndex}.question_content`}
                                          component='span'
                                          className='text-red-400 mt-8 text-md'
                                        />
                                      </div>

                                      {/* 3 */}
                                      <div className=' col-span-9'>
                                        {/* option parts */}
                                        <div className='grid gap-y-8'>
                                          {(response_type === 'Single' ||
                                            response_type === 'Multiple') && (
                                            <FieldArray
                                              name={`assessSpecQuestion[${questionIndex}].response_options`}
                                            >
                                              {({ push, remove }: any) => (
                                                <>
                                                  {response_options.map(
                                                    (
                                                      option: any,
                                                      optionIndex: any
                                                    ) => (
                                                      <>
                                                        {/* single option divide into four part (2 + 10) */}
                                                        <div
                                                          className='grid grid-cols-12 gap-1'
                                                          key={option.label}
                                                        >
                                                          {/* checkbox or radio button into 12 (2 + 10) */}
                                                          <div className='mt-6 col-span-2 grid grid-cols-4 items-center'>
                                                            {/* text field displays option label */}
                                                            <Tooltip
                                                              title='Option Label: '
                                                              placement='top'
                                                            >
                                                              <Field
                                                                as={TextField}
                                                                disabled={true}
                                                                style={{
                                                                  width: '60px',
                                                                }}
                                                                size='small'
                                                                name={`assessSpecQuestion[${questionIndex}].response_options[${optionIndex}].label`}
                                                                value={
                                                                  option.label
                                                                }
                                                                type='text'
                                                              />
                                                            </Tooltip>
                                                          </div>

                                                          <div className='col-span-10 mr-6'>
                                                            <label
                                                              style={{
                                                                fontSize:
                                                                  '12px',
                                                              }}
                                                            >
                                                              Option Content:
                                                            </label>
                                                            <Field
                                                              component={
                                                                SunEditor
                                                              }
                                                              setContents={
                                                                option.content
                                                              }
                                                              name={`assessSpecQuestion[${questionIndex}].response_options[${optionIndex}].content`}
                                                              autoFocus={false}
                                                              onChange={(
                                                                content: any
                                                              ) => {
                                                                setFieldValue(
                                                                  `assessSpecQuestion[${questionIndex}].response_options[${optionIndex}].content`,
                                                                  content
                                                                );
                                                              }}
                                                              onBlur={() => {
                                                                // Use setFieldTouched to mark the field as touched
                                                                setFieldTouched(
                                                                  `assessSpecQuestion[${questionIndex}].response_options[${optionIndex}].content`,
                                                                  true
                                                                );
                                                              }}
                                                              //@ts-ignore
                                                              setDefaultStyle={`${sunEditorOptions.defaultStyle}`}
                                                              //@ts-ignore
                                                              setOptions={
                                                                sunEditorOptions.options
                                                              }
                                                            />
                                                            <ErrorMessage
                                                              name={`assessSpecQuestion[${questionIndex}].response_options[${optionIndex}].content`}
                                                              component='span'
                                                              className='text-red-400 mt-8 text-md'
                                                            />
                                                          </div>
                                                        </div>

                                                        {/* button parts */}
                                                        <div
                                                          className={
                                                            response_options.length -
                                                              1 ===
                                                            optionIndex
                                                              ? 'mt-5 grid grid-cols-4 gap-x-5'
                                                              : 'hidden'
                                                          }
                                                        >
                                                          <Button
                                                            size='small'
                                                            onClick={() =>
                                                              push({
                                                                label:
                                                                  option.label +
                                                                  1,
                                                                content: '',
                                                              })
                                                            }
                                                          >
                                                            Add Option
                                                          </Button>
                                                          <Button
                                                            size='small'
                                                            disabled={
                                                              response_options.length ===
                                                              1
                                                            }
                                                            onClick={() =>
                                                              remove(
                                                                optionIndex
                                                              )
                                                            }
                                                          >
                                                            Remove Option
                                                          </Button>
                                                        </div>
                                                      </>
                                                    )
                                                  )}
                                                </>
                                              )}
                                            </FieldArray>
                                          )}
                                          {response_type === 'Open' && (
                                            <div className='grid grid-cols-12 mr-2'>
                                              <TextField
                                                className='col-start-3 col-span-full'
                                                fullWidth
                                                multiline
                                                name='optionContent'
                                                label='Correct Response'
                                                disabled={true}
                                              />
                                            </div>
                                          )}
                                          {response_type === 'Number' && (
                                            <div className='grid grid-cols-12 mr-2'>
                                              <TextField
                                                className='col-start-3 col-span-full'
                                                fullWidth
                                                label='Correct Response'
                                                name='optionLabel'
                                                type='number'
                                                inputMode='decimal'
                                                disabled={true}
                                              />
                                            </div>
                                          )}
                                          {response_type === 'Text' && (
                                            <div className='grid grid-cols-12 mr-2'>
                                              <TextField
                                                className='col-start-3 col-span-full'
                                                fullWidth
                                                label='Correct Response'
                                                name='optionContent'
                                                type='text'
                                                disabled={true}
                                              />
                                            </div>
                                          )}
                                        </div>
                                      </div>

                                      {/* 4 */}
                                      <div
                                        className={`bg-slate-50 col-span-3 flex flex-col flex-start gap-y-6 ${
                                          response_type === 'Single' ||
                                          response_type === 'Multiple'
                                            ? 'py-10'
                                            : ''
                                        }`}
                                      >
                                        <FormControl variant='outlined'>
                                          <InputLabel>Response Type</InputLabel>
                                          <Field
                                            as={Select}
                                            value={response_type}
                                            name={`assessSpecQuestion[${questionIndex}].response_type`}
                                            label='Response Type:'
                                            onChange={(e: any) => {
                                              let response_options: any = '';
                                              if (
                                                e.target.value === 'Single' ||
                                                e.target.value === 'Multiple'
                                              ) {
                                                response_options = [
                                                  {
                                                    label: 1,
                                                    content: '',
                                                  },
                                                ];
                                              }
                                              setFieldValue(
                                                `assessSpecQuestion[${questionIndex}].response_options`,
                                                response_options
                                              );
                                              setFieldValue(
                                                `assessSpecQuestion[${questionIndex}].response_type`,
                                                e.target.value
                                              );
                                            }}
                                            //   disabled={Boolean(
                                            //     pathname == '/editcourse'
                                            //   )}
                                            defaultValue='Single'
                                          >
                                            <MenuItem value='Single'>
                                              Single
                                            </MenuItem>
                                            <MenuItem value='Multiple'>
                                              Multiple
                                            </MenuItem>
                                            <MenuItem value='Number'>
                                              Number
                                            </MenuItem>
                                            <MenuItem value='Text'>
                                              Text
                                            </MenuItem>
                                            <MenuItem value='Open'>
                                              Open
                                            </MenuItem>
                                          </Field>
                                        </FormControl>
                                      </div>
                                    </div>
                                  </AccordionDetails>
                                </Accordion>
                                {/* pathname == '/addcourse' && (*/}
                                <Paper
                                  elevation={3}
                                  className={` ${
                                    values.assessSpecQuestion.length <= 0
                                      ? 'hidden'
                                      : 'bg-[#F3F3F3] p-3 rounded-md mt-10 ml-2 h-fit flex flex-col gap-y-3'
                                  }`}
                                >
                                  <Tooltip
                                    title='Add question'
                                    placement='right'
                                    arrow
                                  >
                                    <Fab
                                      onClick={() => {
                                        values.assessSpecQuestion.splice(
                                          questionIndex + 1,
                                          0,
                                          {
                                            question_label: question_label + 1,
                                            question_content: '',
                                            response_type: 'Single',
                                            response_options: [
                                              {
                                                label: 1,
                                                content: '',
                                              },
                                            ],
                                          }
                                        );
                                        setFieldValue(
                                          'assessSpecQuestion',
                                          values.assessSpecQuestion.map(
                                            (data: any, index: number) => {
                                              if (questionIndex + 1 < index) {
                                                return {
                                                  ...data,
                                                  question_label:
                                                    data.question_label + 1,
                                                };
                                              } else {
                                                return { ...data };
                                              }
                                            }
                                          )
                                        );
                                      }}
                                      size='small'
                                      color='primary'
                                      aria-label='add'
                                    >
                                      <AddCircleOutlineIcon />
                                    </Fab>
                                  </Tooltip>

                                  <Tooltip
                                    title='Remove'
                                    placement='right'
                                    arrow
                                  >
                                    <Fab
                                      onClick={() => remove(questionIndex)}
                                      size='small'
                                      color='primary'
                                      aria-label='remove'
                                    >
                                      <RemoveCircleOutlineIcon />
                                    </Fab>
                                  </Tooltip>
                                </Paper>
                                {/* )} */}
                              </div>
                            );
                          }
                        )}
                      </>
                    )}
                  </FieldArray>
                </Box>
                {/* values
                <pre>{JSON.stringify(values, null, 2)}</pre>
                touched
                <pre>{JSON.stringify(touched, null, 2)}</pre>
                errors
                <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                {/* <div className="mb-[20%]"></div> */}
              </Form>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <div className='flex gap-5 justify-around mt-5'>
            <Button
              size='large'
              variant='contained'
              style={{ textTransform: 'capitalize', width: '110px' }}
              onClick={handleCloseAssessQue}
            >
              Cancel
            </Button>
            <Button
              size='large'
              variant='contained'
              color='primary'
              type='submit'
              // disabled={
              //   action === 'View'
              //     ? true
              //     : formikRef?.current?.isValid
              //     ? !formikRef?.current?.isValid
              //     : true
              // }
              onClick={() => {
                if (formikRef.current) {
                  formikRef.current.submitForm();
                }
              }}
              style={{
                textTransform: 'capitalize',
                width: '110px',
              }}
            >
              Save
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default AssessSpecificQuestion;
