import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from '../Store';
import { AxiosResponse, AxiosRequestConfig } from 'axios';
import * as systemSettingsRoute from '../route/systemSettingsRoute';
import { NavigateFunction } from 'react-router-dom';
import { Unitroles } from '../../pages/unitroles/Unitroles';
import { PaginationProp } from '../../utils/globalInterface/GlobalInterfaces';
import {
  handleAddTransaction,
  handleDeleteTransaction,
  handleUpdateTransaction,
} from '../../utils/gridMethod/GridTransaction';
import { GridApi } from 'ag-grid-community';
import Alert from '../../utils/Alert/Alert';
import Swal from 'sweetalert2';

export interface AxiosError<T = any> extends Error {
  config: AxiosRequestConfig;
  code?: string;
  request?: object;
  data: object;
  response?: AxiosResponse<T>;
  isAxiosError: boolean;
  toJSON: () => object;
}

const initialState = {
  getSystemSettingsList: [],
  systemSettingsName: [],
  loading: false,
};

const addSystemSettings = (data: any, pageName: any, setSubmitting?: any) => {
  return systemSettingsRoute
    .addSystemSettings(data)
    .then((res: AxiosResponse) => {
      Swal.fire({
        title: `${pageName} updated successfully`,
        // text: 'Data inserted successfully',
        icon: 'success',
        confirmButtonText: 'OK',
      }).then((userResponse) => {
        //
        if (userResponse.isConfirmed && setSubmitting) {
          setSubmitting(false);
        }
      });
    })
    .catch((err: AxiosError) => {
      if (setSubmitting) {
        setSubmitting(false);
      }
    });
};

const getSystemSettingsIdAndName = () => async (dispatch: AppDispatch) => {
  return systemSettingsRoute
    .getSystemSettingsIdAndName()
    .then((res: AxiosResponse) => {})
    .catch((err: AxiosError) => err);
};
// const getSystemSettingsList = () => async (dispatch: any) => {
//   return systemSettingsRoute
//     .getSystemSettingsList()
//     .then((res: AxiosResponse) => {
//       if (res?.data?.data && res?.data?.data?.length) {
//         dispatch(handleGetSystemSettings(res?.data?.data));
//       } else {
//       }
//     })
//     .catch((err: AxiosError) => {});
// };
const getSystemSettingsList = (setApiKey: any) => async (dispatch: any) => {
  try {
    const response = await systemSettingsRoute.getSystemSettingsList();
    const data = response.data;

    if (data || data.length) {
      dispatch(handleGetSystemSettings(data));
      setApiKey(data);
    } else {
      // Handle case when no data is returned
    }
  } catch (error) {
    // Handle error
  }
};
const getSystemSettingsPasswordAndComplexity =
  (setPasswords: any, setPasswordsComplexity: any) => async (dispatch: any) => {
    try {
      const response =
        await systemSettingsRoute.getSystemSettingsPasswordAndComplexity();
      const data = response.data.data;

      if (data || data.password_complexity.length > 0) {
        const datja = data.password_complexity.map((d: any) => {
          return d.value;
        });
        const datjsa = data.password_complexity.map((d: any) => {
          return d.label;
        });

        dispatch(handleGetSystemSettings(data));
        setPasswords(datja);
        setPasswordsComplexity(datjsa);
      } else {
        // Handle case when no data is returned
      }
    } catch (error) {
      // Handle error
    }
  };

const updateSystemSettings = (data: any, id: any) => {
  return systemSettingsRoute
    .updateSystemSettings(data, data?.id)
    .then((res: AxiosResponse) => {})
    .catch((err: AxiosError) => {});
};

const SystemSettingsSlice = createSlice({
  name: 'SystemSettingsSlice',
  initialState,
  reducers: {
    handleGetSystemSettings: (state, action) => {
      state.getSystemSettingsList = action.payload;
    },
    handleGetSystemSettingsName: (state, action) => {
      state.systemSettingsName = action.payload;
    },
    handleLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

//reducers export  to pass action state to reducer type
export const {
  handleGetSystemSettings,
  handleLoading,
  handleGetSystemSettingsName,
} = SystemSettingsSlice.actions;

//action to calls in ui with dispatch methods
export {
  addSystemSettings,
  getSystemSettingsIdAndName,
  getSystemSettingsList,
  updateSystemSettings,
  getSystemSettingsPasswordAndComplexity,
};

export default SystemSettingsSlice.reducer;
