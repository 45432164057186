import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  Select,
  MenuItem,
  Paper,
  Grid,
  FormControl,
  RadioGroup,
  Radio,
  DialogActions,
  FormControlLabel,
  Checkbox,
  TextField,
} from '@mui/material';
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Close,
} from '@mui/icons-material';
import React from 'react';

const ViewAdaptiveAssessment = (props: any) => {
  const { open, setOpen, question } = props;

  return (
    <Dialog
      open={open}
      maxWidth='lg'
      fullWidth={true}
      sx={{ minWidth: '900px' }}
    >
      <DialogTitle sx={{ backgroundColor: '#1663ab', color: 'white' }}>
        <div className='flex justify-between items-center'>
          <div>View Adaptive Assessment</div>
          <Fab size='small'>
            <Close className='cursor-pointer' onClick={() => setOpen(false)} />
          </Fab>
        </div>
      </DialogTitle>
      <DialogContent dividers={true}>
        <div
          style={{
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '10px',
          }}
        >
          <Box
            sx={{
              maxWidth: 900,
              flexGrow: 1,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                margin: '15px',
                backgroundColor: 'white',
              }}
            >
              <Button size='small' disabled={true}>
                <KeyboardArrowLeft />
                Back
              </Button>
              <div style={{ display: 'flex', gap: '10px' }}>
                <Select size='small' value={'1'}>
                  <MenuItem key={1} value={1}>
                    1
                  </MenuItem>
                </Select>
              </div>

              <Button size='small' disabled={true}>
                Next
                <KeyboardArrowRight />
              </Button>
            </div>
          </Box>
        </div>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Box
            sx={{
              maxWidth: 900,
              flexGrow: 1,
            }}
          >
            <Paper
              variant='outlined'
              sx={{ marginTop: question?.questionLabel ? '' : '80px' }}
            >
              <Box
                sx={{
                  minHeight: 400,
                  height: 'auto',
                  maxWidth: 900,
                  width: '100%',
                  p: 1,
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container>
                    <Grid style={{ marginBottom: '5px' }} item xs={2} sm={2}>
                      <b style={{ whiteSpace: 'nowrap' }}>
                        {question?.questionLabel}
                      </b>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      style={{ display: 'flex', flexFlow: 'wrap' }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `<div class="sun-editor-editable" style="padding-top: 0px; padding-bottom: 0px; max-width: 918px;">${question?.questionContent?.replace(
                            /\\/g,
                            ''
                          )}</div>`,
                        }}
                        style={{ whiteSpace: 'pre-wrap' }}
                      />
                    </Grid>

                    {question?.responseType === 'Single' && (
                      <Grid item xs={12} sm={12}>
                        <FormControl
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          <RadioGroup
                            defaultValue={question?.answer || ''}
                            name='answer'
                            value={question?.answer || ''}
                            style={
                              question?.optionOrientation === 'Vertical'
                                ? {
                                    display: 'flex',
                                    paddingLeft: '13px',
                                  }
                                : {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    flexWrap: 'wrap',
                                  }
                            }
                          >
                            {question?.responseOptions.map(
                              (item: any, index: any) => (
                                <FormControlLabel
                                  key={index}
                                  value={index}
                                  control={<Radio size='small' />}
                                  label={
                                    index + 1 ===
                                    Number(question?.defaultAnswer) ? (
                                      <div
                                        style={{
                                          color: 'black',
                                          whiteSpace: 'pre-wrap',
                                        }}
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            `<div class="sun-editor-editable" style="background-color:yellow; padding-top: 9px; padding-bottom: 1px; max-width:918px">${item?.optionContent}</div>`.replace(
                                              /\\/g,
                                              ''
                                            ),
                                        }}
                                      />
                                    ) : (
                                      <div
                                        style={{
                                          color: 'black',
                                          whiteSpace: 'pre-wrap',
                                        }}
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            `<div class="sun-editor-editable" style="padding-top: 9px; padding-bottom: 1px; max-width:918px">${item?.optionContent}</div>`.replace(
                                              /\\/g,
                                              ''
                                            ),
                                        }}
                                      />
                                    )
                                  }
                                />
                              )
                            )}
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    )}

                    {question?.responseType === 'Multiple' && (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        style={
                          question?.optionOrientation === 'Horizontal'
                            ? {
                                display: 'flex',
                              }
                            : {}
                        }
                      >
                        {question?.responseOptions.map(
                          (item: any, index: any) => (
                            <label
                              key={index}
                              style={
                                question?.optionOrientation === 'Vertical'
                                  ? {
                                      display: 'flex',
                                    }
                                  : {
                                      marginTop: '10px',
                                      marginBottom: '10px',
                                    }
                              }
                            >
                              <Checkbox
                                disabled={true}
                                id={index.toString()}
                                size='small'
                                name={index.toString()}
                                checked={question?.answer[index] || false}
                              />
                              <div
                                style={{
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  display: 'flex',
                                  whiteSpace: 'pre-wrap',
                                }}
                                dangerouslySetInnerHTML={
                                  question?.defaultAnswer?.[index + 1] === true
                                    ? {
                                        __html:
                                          `<div class="sun-editor-editable" style="background-color:yellow; padding-top: 9px; padding-bottom: 1px; max-width:918px">${item.optionContent}</div>`.replace(
                                            /\\/g,
                                            ''
                                          ),
                                      }
                                    : {
                                        __html:
                                          `<div class="sun-editor-editable" style="padding-top: 9px; padding-bottom: 1px; max-width:918px">${item?.optionContent}</div>`.replace(
                                            /\\/g,
                                            ''
                                          ),
                                      }
                                }
                              ></div>
                            </label>
                          )
                        )}
                      </Grid>
                    )}

                    {question?.responseType === 'Open' && (
                      <Grid item xs={12} sm={12}>
                        {question?.responseOptions.map(
                          (item: any, index: any) => (
                            <div key={index}>
                              <TextField
                                disabled={true}
                                className='w-full'
                                multiline
                                name='answer'
                                label='Response Input:'
                                defaultValue={question?.defaultAnswer || ''}
                              />
                            </div>
                          )
                        )}
                      </Grid>
                    )}

                    {question?.responseType === 'Text' && (
                      <Grid item xs={12} sm={12}>
                        {question?.responseOptions.map(
                          (item: any, index: any) => (
                            <div key={index}>
                              <TextField
                                disabled={true}
                                fullWidth
                                name='answer'
                                label='Response Input:'
                                defaultValue={question?.defaultAnswer || ''}
                              />
                            </div>
                          )
                        )}
                      </Grid>
                    )}

                    {question?.responseType === 'Number' && (
                      <Grid item xs={12} sm={12}>
                        {question?.responseOptions.map(
                          (item: any, index: any) => (
                            <div key={index}>
                              <TextField
                                disabled={true}
                                type='number'
                                name='answer'
                                label='Response Input:'
                                defaultValue={(() => {
                                  const b1 =
                                    typeof question?.defaultAnswer ===
                                      'number' && question?.defaultAnswer === 0;
                                  const b2 = Boolean(question?.defaultAnswer);
                                  return b1 || b2
                                    ? Number(question?.defaultAnswer)
                                    : '';
                                })()}
                              />
                            </div>
                          )
                        )}
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </Box>
            </Paper>
          </Box>
        </div>
      </DialogContent>
      <DialogActions>
        <div className='flex gap-5 justify-around mt-5'>
          <Button
            size='large'
            variant='contained'
            style={{ textTransform: 'capitalize', width: '110px' }}
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default ViewAdaptiveAssessment;
