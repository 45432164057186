import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { Field, useFormikContext } from 'formik';

export function RenderCheckBoxList({ fieldName }: any) {
  const {
    values, setFieldValue,
  }: any = useFormikContext();

  return <>
    {values[`${fieldName}`]?.map((d: any, i: any) => {
      return (
        <FormControlLabel
          control={<Field
            as={Checkbox}
            name={fieldName}
            checked={values[fieldName][i].bool}
            onChange={(e: any) => {
              values[fieldName][i].bool =
                e.target.checked;
              setFieldValue(
                fieldName,
                values[fieldName]
              );
            }} />}
          label={values[fieldName][i].descript? values[fieldName][i].descript:values[fieldName][i].value} />
      );
    })}
    
  </>;

}
