import React from 'react';
import { Field, useFormikContext } from 'formik';
import {
  TextField,
  Button,
  IconButton,
  Popover,
  Grid,
  Box,
} from '@mui/material';
import * as Yup from 'yup';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';

const AddCustomForm = ({ fieldName }: any) => {
  const {
    setFieldTouched,
    values,
    setFieldValue,
    errors,
    touched,
    setFieldError,
  }: any = useFormikContext();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddCustom = () => {
    // Mark all fields as touched
    setFieldTouched(`${fieldName}Value`, true);
    setFieldTouched(`${fieldName}Time`, true);
    setFieldTouched(`${fieldName}Descript`, true);

    // Validate the form
    validationSchema
      .validate(
        {
          [`${fieldName}Value`]: values[`${fieldName}Value`],
          [`${fieldName}Time`]: values[`${fieldName}Time`],
        },
        { abortEarly: false }
      )
      .then(() => {
        // If form is valid, create a new custom object
        const newCustom = {
          value: values[`${fieldName}Value`],
          time: values[`${fieldName}Time`],
          descript: values[`${fieldName}Descript`],
        };

        // Add the new custom object to the existing array
        const updatedArray = [...values[fieldName], newCustom];

        // Update the form field with the updated array
        setFieldValue(fieldName, updatedArray);

        // Clear form fields
        setFieldValue(`${fieldName}Value`, '');
        setFieldValue(`${fieldName}Time`, '');
        setFieldValue(`${fieldName}Descript`, '');
      })
      .catch((validationErrors) => {
        // Handle validation errors
        validationErrors.inner.forEach((error: any) => {
          setFieldError(error.path, error.message);
        });
      });
  };

  // Define validation schema for content module and learning objective fields
  const validationSchema = Yup.object().shape({
    [`${fieldName}Value`]: Yup.string()
      .trim()
      .required('Value is required')
      .matches(/^\S*$/, 'Value cannot contain spaces')
      .test('unique-value', 'Value must be unique', function (value) {
        const fieldArray = values[fieldName] || [];
        return !fieldArray.some((module: any) => module.value === value);
      }),

    // [`${fieldName}Descript`]: Yup.string(),
    [`${fieldName}Time`]: Yup.mixed()
      .nullable()
      .test(
        'is-valid-number',
        'Must be a valid number or empty value',
        function (value) {
          // Allow either an empty string or a valid number
          return value === '' || (!isNaN(parseFloat(value)) && isFinite(value));
        }
      )
      .typeError('Must be a valid number or empty value')
      .test(
        'is-positive-number',
        'Time must be greater than or equal to 0',
        function (value) {
          // Check if the value is a positive number
          return value === '' || parseFloat(value) >= 0;
        }
      ),
  });

  return (
    <>
      <Grid item container wrap='nowrap' xs={12} gap={1}>
        <Grid xs={8}>
          <Field
            focused
            name={`${fieldName}Value`}
            as={TextField}
            label={fieldName
              .replace(/contentModule/g, 'Content module')
              .replace(/learningObjective/g, 'Learning objective')}
            variant='standard'
            error={touched[`${fieldName}Value`] && errors[`${fieldName}Value`]}
            helperText={
              touched[`${fieldName}Value`] && errors[`${fieldName}Value`]
            }
            fullWidth
          />{' '}
        </Grid>
        <Grid xs={2}>
          <Box display='flex' alignItems='center'>
            <Field
              focused
              name={`${fieldName}Time`}
              type='number'
              as={TextField}
              label='Time'
              variant='standard'
              error={touched[`${fieldName}Time`] && errors[`${fieldName}Time`]}
              helperText={
                touched[`${fieldName}Time`] && errors[`${fieldName}Time`]
              }
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === '-' || e.key === 'e' || e.key === 'E') {
                  e.preventDefault();
                }
              }}
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, '');
              }}
            />
            <Box ml={1} alignSelf='flex-end'>
              min.
            </Box>
          </Box>
        </Grid>
        <IconButton
          sx={{ marginLeft: '10px' }}
          onClick={handleAddCustom}
          size='large'
        >
          <AddCircleOutlineRoundedIcon color='action' fontSize='large' />
        </IconButton>
      </Grid>

      <Field
        focused
        name={`${fieldName}Descript`}
        as={TextField}
        label='Description'
        fullWidth
        error={
          touched[`${fieldName}Descript`] && errors[`${fieldName}Descript`]
        }
        helperText={
          touched[`${fieldName}Descript`] && errors[`${fieldName}Descript`]
        }
        multiline
        rows={3}
        // sx={{ width: 320 }}
      />
      {/* <pre>{JSON.stringify(values, null, 2)}</pre>
      <pre>{JSON.stringify(touched, null, 2)}</pre>
      <pre>{JSON.stringify(errors, null, 2)}</pre> */}
    </>
  );
};

export default AddCustomForm;
