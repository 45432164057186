import React from 'react';

function Maintenance() {
  return (
    <div className="flex flex-col h-screen justify-center items-center ">
      <div>
        <h3 className="text-9xl text-slate-800">Maintenance</h3>
      </div>
      <div>
        <p className="text-xl text-center">
          The page you are looking for was under maintenance.
        </p>
      </div>
    </div>
  );
}

export default Maintenance;
