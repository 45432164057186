import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Grid,
  Link,
} from '@mui/material';
import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../reduxStore/Store';
import { useNavigate, useParams } from 'react-router-dom';
import Demographics from '../MyAccount/Demographics';
import Index from '../Assessment/activatedStepper/Index';
import { getAssessmentStudent } from '../../reduxStore/reducer/studentSignupReducer';
import TermsOfService from '../register/stepper/Dialog/TermsOfService';
import ReCAPTCHA from 'react-google-recaptcha';
const YOUR_SITE_KEY = process.env
  .REACT_APP_YOUR_SITE_RECAPTCHA_KEY_v2 as string;

function addQuotesIfNeeded(str: any) {
  if (str) {
    return str.replace(
      /"([^"]*)"|'([^']*)'|\b([^'",\s]+)\b/g,
      (match: any, doubleQuoted: any, singleQuoted: any, unquoted: any) => {
        if (doubleQuoted || singleQuoted) {
          return match;
        } else {
          return `"${unquoted}"`;
        }
      }
    );
  } else {
    return {};
  }
}
const StudentAccountCreationAndConfirmation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { course_student_id } = useParams();
  useEffect(() => {
    dispatch(
      getAssessmentStudent({ id: course_student_id, type: 'signup' }, navigate)
    );
  }, []);

  const studentData: any = useSelector((state: RootState) => {
    let data: any = { ...state.studentsignup.studentConfirm };

    const alterFunction = (data: any) => {
      if (data) {
        return JSON.parse(
          data.replace(/\\/g, '').replace(/[{}]/g, (match: any) => {
            return match === '{' ? '[' : ']';
          })
        );
      } else {
        return {};
      }
    };

    if (data?.id) {
    }

    if (data?.id) {
      data.ethnicity = data?.ethnicity
        ? alterFunction(addQuotesIfNeeded(data?.ethnicity))
        : {};
      data.gender = data?.gender
        ? alterFunction(addQuotesIfNeeded(data?.gender))
        : {};
      data.race = data?.race
        ? alterFunction(addQuotesIfNeeded(data?.race))
        : {};
    }

    return data;
  });
  //

  const [termsOfService, setTermsOfService] = React.useState(false);

  const [termsOpen, setTermsOpen] = React.useState(false);

  const handleClickTermsOpen = () => {
    setTermsOpen(true);
  };

  const handleTermsClose = () => {
    setTermsOpen(false);
  };
  const [recaptchaToken, setRecaptchaToken] = React.useState(false);

  function onChange(value: any) {
    //console.log('Captcha value:', value);
    if (value) {
      setRecaptchaToken(true);
    } else {
      setRecaptchaToken(false);
    }
  }

  return (
    <div>
      <h1 className='text-2xl font-large p-2'> Student Account Creation</h1>

      <div className='flex justify-center'>
        <div className='w-3/5'>
          <Accordion expanded={true}>
            <AccordionSummary sx={{ backgroundColor: '#085985' }}>
              <h1 className='text-xl text-white font-large'>Student Details</h1>
            </AccordionSummary>
            <AccordionDetails>
              <div className='grid gap-y-2 max-w-lg sm:max-w-3xl mt-4 ml-2 sm:grid-cols-2'>
                <label htmlFor='institution' className=''>
                  Email
                </label>
                <div>
                  <input
                    id='email'
                    name='email'
                    className='input-field h-9'
                    value={studentData.email_id}
                  />
                </div>
                <label htmlFor='institution' className=''>
                  First Name
                </label>
                <div>
                  <input
                    id='first_name'
                    name='first_name'
                    className='input-field h-9'
                    value={studentData.first_name}
                  />
                </div>

                <label htmlFor='city' className='mt-2'>
                  Last Name
                </label>
                <div>
                  <input
                    id='last_name'
                    name='last_name'
                    className='input-field h-9'
                    value={studentData.last_name}
                  />
                  <div
                    className='recaptcha-wrapper'
                    style={{ marginTop: '10px' }}
                  >
                    <ReCAPTCHA sitekey={YOUR_SITE_KEY} onChange={onChange} />
                  </div>
                  <Grid item xs={12} sm={12}>
                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                      <div>
                        <Checkbox
                          size='small'
                          checked={termsOfService}
                          onChange={(e: any) => {
                            setTermsOfService(e.target.checked);
                          }}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </div>
                      <div style={{ fontSize: '13px' }}>
                        {' '}
                        I agree to{' '}
                        <Link
                          component='button'
                          variant='body2'
                          onClick={handleClickTermsOpen}
                        >
                          LASSO's Terms of Service.
                        </Link>
                      </div>
                    </div>
                  </Grid>
                </div>
              </div>
              <div className='flex justify-center gap-10 mt-7 mb-5'></div>
            </AccordionDetails>
          </Accordion>

          <div className='mt-2 float-right'>
            <Button
              disabled={!termsOfService || !recaptchaToken}
              variant='contained'
              onClick={() => {
                navigate('/admin/password?id=studentSignUp', {
                  state: {
                    course_student_id: course_student_id,
                    participation: '0',
                    data: studentData,
                    signUp: true,
                  },
                });
              }}
            >
              Next{' '}
            </Button>
          </div>

          <TermsOfService
            termsOpen={termsOpen}
            handleTermsClose={handleTermsClose}
          />
        </div>
      </div>
    </div>
  );
};

export default StudentAccountCreationAndConfirmation;
