import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { AppContext } from '../register/activatedStepper/Context';
import { Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { resetAction } from '../../reduxStore/reducer/authenticateReducer';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../reduxStore/Store';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';
import { activatePassword } from '../../reduxStore/reducer/userReducer';
import {
  getSystemSettingsList,
  getSystemSettingsPasswordAndComplexity,
} from '../../reduxStore/reducer/systemSettingsReducer';
import { activateStudentPassword } from '../../reduxStore/reducer/studentSignupReducer';

const theme = createTheme();

function Password() {
  const initialValues = {
    newPassword: '',
    confirmPassword: '',
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id: any = queryParams.get('id');
  const dispatch = useDispatch<AppDispatch>();
  const navigate: any = useNavigate();
  const { formValues, handleChange, handleNext, variant, margin } =
    useContext(AppContext);
  const { password, reEnterPassword } = formValues;

  // Check if all values are not empty and if there are some errors
  const isError = useCallback(
    () =>
      Object.keys({ password, reEnterPassword }).some(
        (name) =>
          (formValues[name].required && !formValues[name].value) ||
          formValues[name].error
      ),
    [formValues, password, reEnterPassword]
  );

  const errorMessage =
    password.value !== reEnterPassword.value
      ? 'Password and Confirm Password Mismatch'
      : '';

  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowRePassword = () => setShowRePassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const handleMouseDownRePassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const [passwords, setPasswords] = useState<any>([]);
  const [passwordsComplexity, setPasswordsComplexity] = useState<any>({});
  console.log(passwordsComplexity, 'passwordsComplexity');

  useEffect(() => {
    dispatch(
      getSystemSettingsPasswordAndComplexity(
        setPasswords,
        setPasswordsComplexity
      )
    );
  }, []);

  // Function to validate the password
  const validatePassword = (password: string) => {
    const regex = new RegExp(passwords.join(''));
    return regex.test(password);
  };

  // Function to check if passwords match
  const doPasswordsMatch = (password: string, confirmPassword: string) => {
    return password === confirmPassword;
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container component='main' maxWidth='sm' sx={{ mb: 1 }}>
        <Paper
          variant='outlined'
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <h2 className='mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl '>
            Create Password
          </h2>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object({
              newPassword: Yup.string()
                .required('Password is required')
                .matches(new RegExp(passwords.join('')), () => (
                  <div className='mr-5'>
                    {passwordsComplexity.map((requirement: any, index: any) => (
                      <li key={index}>{requirement}</li>
                    ))}
                  </div>
                )),
              confirmPassword: Yup.string()
                .required('Confirm Password is required')
                .oneOf(
                  [Yup.ref('newPassword'), null],
                  'Password and Confirm Password must match'
                ),
            })}
            onSubmit={(values) => {
              if (id.includes('studentSignUp')) {
                dispatch(
                  activateStudentPassword(
                    { data: location.state, password: values.confirmPassword },
                    navigate
                  )
                );
              } else {
                dispatch(
                  activatePassword(
                    { token: id, password: values.confirmPassword },
                    navigate
                  )
                );
              }
            }}
          >
            {({ values }) => (
              <Form className='mt-4 space-y-4 lg:mt-5 md:space-y-5' action='#'>
                <div>
                  <FormControl
                    fullWidth
                    sx={{ m: 1 }}
                    variant={variant}
                    margin={margin}
                  >
                    <InputLabel htmlFor='standard-adornment-password'>
                      Password
                    </InputLabel>
                    <Field
                      as={Input}
                      name='newPassword'
                      placeholder='password'
                      id='standard-adornment-password'
                      type={showPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? (
                              <AiFillEyeInvisible />
                            ) : (
                              <AiFillEye />
                            )}
                          </IconButton>
                          {values.newPassword && (
                            <span
                              style={{
                                color: validatePassword(values.newPassword)
                                  ? 'green'
                                  : 'red',
                              }}
                            >
                              {validatePassword(values.newPassword) ? '✔' : '✖'}
                            </span>
                          )}
                        </InputAdornment>
                      }
                    />
                    <FormHelperText error={!!password.error}>
                      {password.error}
                    </FormHelperText>
                  </FormControl>
                  <ErrorMessage
                    name='newPassword'
                    component='span'
                    className='text-red-600 ml-2 text-sm'
                  />
                </div>
                <div>
                  <FormControl
                    fullWidth
                    sx={{ m: 1 }}
                    variant={variant}
                    margin={margin}
                  >
                    <InputLabel htmlFor='standard-adornment-reEnterPassword'>
                      Confirm Password
                    </InputLabel>
                    <Field
                      as={Input}
                      name='confirmPassword'
                      placeholder='Re-Enter Password'
                      id='standard-adornment-password'
                      type={showRePassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleClickShowRePassword}
                            onMouseDown={handleMouseDownRePassword}
                          >
                            {showRePassword ? (
                              <AiFillEyeInvisible />
                            ) : (
                              <AiFillEye />
                            )}
                          </IconButton>
                          {values.confirmPassword && (
                            <span
                              style={{
                                color: doPasswordsMatch(
                                  values.newPassword,
                                  values.confirmPassword
                                )
                                  ? 'green'
                                  : 'red',
                              }}
                            >
                              {doPasswordsMatch(
                                values.newPassword,
                                values.confirmPassword
                              )
                                ? '✔'
                                : '✖'}
                            </span>
                          )}
                        </InputAdornment>
                      }
                    />
                    <FormHelperText error={!!reEnterPassword.error}>
                      {reEnterPassword.error}
                    </FormHelperText>
                  </FormControl>
                  <ErrorMessage
                    name='confirmPassword'
                    component='span'
                    className='text-red-600 ml-2 text-sm'
                  />
                </div>
                <Button
                  style={{ marginTop: '25px', marginBottom: '25px' }}
                  fullWidth
                  type='submit'
                  variant='contained'
                >
                  Activate password
                </Button>
              </Form>
            )}
          </Formik>
        </Paper>
      </Container>
    </ThemeProvider>
  );
}

export default Password;
