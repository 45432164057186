import * as React from 'react';
import Box from '@mui/material/Box';
import { AgGridReact } from 'ag-grid-react';
import { useState, useEffect, useCallback } from 'react';
import { ColDef } from 'ag-grid-community';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import ContentModulesLearningObjectivesDialog from './ContentModulesLearningObjectivesDialog';

export default function ContentMLearOTable({
  contentMLearningO,
  setContentMLearningO,
  courseAssessmentId,
  index,
}: any) {
  const generateRowData = useCallback(() => {
    const objData = contentMLearningO;
    const transformedData = [];

    // Determine the maximum length between cm and lo arrays
    const maxLength = Math.max(
      objData.contentModules?.length,
      objData.learningObjectives?.length
    );

    for (let i = 0; i < maxLength; i++) {
      transformedData.push({
        content_module: objData.contentModules[i] || {
          value: '',
          time: '',
          descript: '',
        },
        learning_objective: objData.learningObjectives[i] || {
          value: '',
          time: '',
          descript: '',
        },
      });
    }
    return transformedData;
  }, [contentMLearningO]);

  const rowData: any = generateRowData();

  // Column Definitions: Defines & controls grid columns.
  const colDefs: any = [
    {
      headerName: 'Content module',
      field: 'content_module',
      cellStyle: { pointerEvents: 'none', backgroundColor: 'transparent' },

      cellRenderer: (row: any) => {
        if (row.data.content_module.value) {
          return (
            <FormControlLabel
              control={
                <Checkbox
                  size='small'
                  checked={row.data.content_module.bool}
                  sx={{
                    visibility: row.data.content_module.bool
                      ? 'none'
                      : 'hidden',
                    '&:hover': {
                      backgroundColor: 'transparent', // Remove hover effect
                    },
                    '& .MuiSvgIcon-root': {
                      '&:hover': {
                        backgroundColor: 'transparent', // Remove hover effect on icon
                      },
                    },
                    pointerEvents: 'none', // Prevent interaction and cursor change
                  }}
                />
              }
              label={`${row.data.content_module.value}`}
              sx={{
                pointerEvents: 'none', // Prevent interaction and cursor change
              }}
            />
          );
        }
      },
    },
    {
      headerName: 'Learning objective',
      field: 'learning_objective',
      cellStyle: { pointerEvents: 'none', backgroundColor: 'transparent' },
      cellRenderer: (row: any) => {
        return (
          row.data.learning_objective.value && (
            <FormControlLabel
              control={
                <Checkbox
                  size='small'
                  checked={row.data.learning_objective.bool}
                  sx={{
                    visibility: row.data.learning_objective.bool
                      ? 'none'
                      : 'hidden',
                    '&:hover': {
                      backgroundColor: 'transparent', // Remove hover effect
                    },
                    '& .MuiSvgIcon-root': {
                      '&:hover': {
                        backgroundColor: 'transparent', // Remove hover effect on icon
                      },
                    },
                    pointerEvents: 'none', // Prevent interaction and cursor change
                  }}
                />
              }
              label={`${row.data.learning_objective.value}`}
              sx={{
                pointerEvents: 'none', // Prevent interaction and cursor change
              }}
            />
          )
        );
      },
    },
  ];

  const defaultColDef = {
    flex: 1,
    // suppressCellHighlighting: true,
  };

  return (
    <>
      <ContentModulesLearningObjectivesDialog
        contentMLearningO={contentMLearningO}
        setContentMLearningO={setContentMLearningO}
        courseAssessmentId={courseAssessmentId}
        index={index}
      />

      <div
        className={'ag-theme-quartz'}
        style={{ width: '100%', height: '25vh', marginTop: '5px' }}
      >
        <AgGridReact
          rowData={rowData}
          columnDefs={colDefs}
          defaultColDef={defaultColDef}
          rowHeight={40}
          headerHeight={30}
        />
      </div>
    </>
  );
}
