import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import AGGrid from '../../utils/MasterGrid/MasterGrid';
import { ColDef, ColGroupDef, GridOptions } from 'ag-grid-community';
import StudentMail from '../AddStudent/StudentMail';
import MasterGrid from '../../utils/MasterGrid/MasterGrid';
import { ICellRendererParams } from 'ag-grid-community';
import { createServerSideDatasource } from '../../utils/gridMethod/createServerSideDatasource';
import { GridReadyEvent } from 'ag-grid-community';
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { AnyIfEmpty, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../reduxStore/Store';
import {
  getCourseAdministrationByCourse,
  getReportsData,
  getResultAnalysis,
  getStudentAndAdministrationByAssessmentId,
  getStudentsByCourse,
} from '../../reduxStore/reducer/administrationReducer';
import axios from 'axios';
import { AgGridReact } from 'ag-grid-react';
import IconButton from '@mui/material/IconButton/IconButton';
import { AiOutlineMail } from 'react-icons/ai';
// import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import { BsSearch } from 'react-icons/bs';
import InputAdornment from '@mui/material/InputAdornment';
import { CheckBox } from '@mui/icons-material';
import { Input } from 'postcss';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import FileImport from '../courseDetails/FileImport';
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import moment from 'moment';
import debounce from 'lodash/debounce';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FormikProps } from 'formik';

import ContactMailIcon from '@mui/icons-material/ContactMail';
import { getUserById } from '../../reduxStore/reducer/registerReducer';
import * as readxlsx from 'xlsx';
import Alert from '../../utils/Alert/Alert';
import { ReminderSettings } from './ReminderSettings';
import EditNotificationsSharpIcon from '@mui/icons-material/EditNotificationsSharp';
import { getCourseDetail } from '../../reduxStore/reducer/courseReducer';
import api from '../../reduxStore/route';
import { AdministrationBoxes } from './AdministrationBoxes';
import Reports from './Dialog/Reports';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import { display } from '@mui/system';
import Center from '../Center/Center';
import { editCourseAssessmentData } from '../../reduxStore/reducer/assessmentReducer';
let cancelGetStudentsByCourse: any;
export let studentData: any = [];
let reminderSettings: any = [];

const defaultAdministrationDefinition: any = [
  {
    headerName: 'First Name',
    field: 'first_name',
    minWidth: 180,
  },
  {
    headerName: 'Middle Name',
    field: 'middle_name',
    minWidth: 180,
  },
  { headerName: 'Last Name', field: 'last_name', minWidth: 220 },
  { headerName: 'Email Id', field: 'email_id', minWidth: 220 },
];
export let administrationBox: any = [];
export let contentMLearningObj: any = {
  contentModules: [],
  learningObjectives: [],
};

const Administration = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [refresh, setRefresh] = useState<any>(null);
  const navigate = useNavigate();

  const { courseassessmentid: courseAssessmentId, courseid: id } = useParams();
  //
  const gridRef = useRef<any>(null);
  const [rosterCount, setRosterCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [reminderDialog, setReminderDialog] = useState(false);
  const [cronValue, setCronValue] = useState<any>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [renderAdministrationBoxes, setRenderAdministrationBoxes] =
    useState<boolean>(false);
  const [instructorsCoordinators, setInstructorsCoordinators] = useState<any>();
  const [reportsOpen, setReportsOpen] = useState(false);
  const [migrated, setMigrated] = useState(false);
  // const [contentMLearningO, setContentMLearningO] = useState<any>({
  //   contentModules: [],
  //   learningObjectives: [],
  // });
  console.log(renderAdministrationBoxes, 'renderAdministrationBoxes');

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const addAssessmentRow = (values: any) => {
    const transaction: any = {
      add: [values],
      addIndex: 0,
    };
    gridRef.current.api?.applyTransaction(transaction);
  };

  useEffect(() => {
    if (localStorage.getItem('token')) {
      dispatch(
        getUserById({
          id: `${JSON.parse(localStorage.getItem('token') || '{}')}`,
        })
      );

      getCourseDetail({ id }).then((data: any) => {
        //
        let map = new Map(data.instructors.map((obj: any) => [obj.id, obj]));
        if (data.assessment_coordinators)
          data.assessment_coordinators.forEach((obj: any) =>
            map.set(obj.id, obj)
          );
        let final = [...map.values()];
        //
        setInstructorsCoordinators(final);
      });
    }
  }, [dispatch]);

  const getUserDataAndType = useSelector(
    (state: any) => state.register.getUserById
  );

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    // textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: 'agTextColumnFilter',
    pagination: true,
    flex: 1,
    minWidth: 150,
  };

  const singleMemberMailSend = (
    id: any,
    courseAdministrationId: any,
    email: any,
    first_name?: any,
    last_name?: any,
    isManual?: boolean
  ) => {
    if (id && email && courseAdministrationId) {
      sendMail(
        [
          {
            id: id,
            courseAssessmentId: courseAssessmentId,
            courseAdministrationId: courseAdministrationId,
            email: email,
            first_name,
            last_name,
          },
        ],
        isManual
      );
    }
  };

  const sendMail = (
    data: any,
    isManual?: boolean,
    setLastReminder?: any,
    index?: any,
    pickerValues?: any,
    pickerValuesEnd?: any
  ) => {
    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/api/course_details/mail/${
        isManual || false
      }`,
      data: { mails: data, cronValue: cronValue },
    };

    if (pickerValues) {
      const timestamp1 = Date.parse(pickerValues[index]); // convert to UTC timestamp

      const date1 = new Date(timestamp1); // create a Date object in UTC time
      const utcString1 = date1.toISOString(); // convert to UTC string

      const timestamp2 = Date.parse(pickerValuesEnd[index]); // convert to UTC timestamp
      const date2 = new Date(timestamp2); // create a Date object in UTC time
      const utcString2 = date2.toISOString(); // convert to UTC string
      data[0].start_date_time = utcString1;
      data[0].end_date_time = utcString2;
    }

    let body = {
      mails: data,
      cronValue: cronValue,
    };

    api
      .post(`/course_details/mail/${isManual || false}`, body)
      .then(function (response: any) {
        setLoading(true);
        setRefresh(true);
        if (isManual) {
          const mailObj = response?.data?.data?.length
            ? response?.data?.data[0]
            : null;
          if (!mailObj) return;

          //Old Template
          // const body = `%0D%0ADear ${data[0].first_name} ${data[0].last_name},
          // %0D%0A%0D%0A${getUserDataAndType?.data?.first_name} ${getUserDataAndType?.data?.last_name} has requested you participate in a short online survey. Your responses will be shared with your instructor to improve the instruction of this course. We ask that you complete the survey to the best of your ability and on your own without any assistance (e.g. from friends, books, or the internet).

          //  %0D%0A%0D%0AThe link provided below will take you to your personal copy of the survey. The survey will take approximately (5-30 min) to complete. Your participation is appreciated.
          //  %0D%0A%0D%0Abrowser: ${mailObj?.mailObj?.url}
          //   %0D%0A%0D%0AAny questions should be addressed to ${getUserDataAndType?.data?.first_name} ${getUserDataAndType?.data?.last_name} at ${getUserDataAndType?.data?.email}.
          //   %0D%0A%0D%0AThe deadline for completing the assessment is ${mailObj?.mailObj?.end_date_time}
          //   %0D%0A%0D%0AThe LASSO platform ,`;

          //New Template with signup changes
          const body = `
          %0D%0ADear ${data[0].first_name} ${data[0].last_name},
          %0D%0A%0D%0A${getUserDataAndType?.data?.first_name} ${getUserDataAndType?.data?.last_name} has requested you participate in a short online survey (${mailObj?.mailObj?.student?.assessment_name}). Your responses will be shared with your instructor to improve the instruction of this course. We ask that you complete the survey to the best of your ability and independently without assistance (e.g., from friends, books, or the internet). 
          
          %0D%0A%0D%0AThe link provided below will take you to your personal copy of the survey. The survey will take approximately (5-30 min) to complete. The assessment is due by ${mailObj?.mailObj?.end_date_time}. Your participation is appreciated. 
          %0D%0A%0D%0Abrowser: ${mailObj?.mailObj?.url}  
          %0D%0A%0D%0AAny questions should be addressed to ${getUserDataAndType?.data?.first_name} ${getUserDataAndType?.data?.last_name} at ${getUserDataAndType?.data?.email}.  
          %0D%0A%0D%0AThe deadline for completing the assessment is ${mailObj?.mailObj?.end_date_time}
          %0D%0A%0D%0AThe LASSO platform,
          %0D%0A%0D%0AAccount Creation
          %0D%0A%0D%0AThe link provided below will create an account for you.
          %0D%0A%0D%0A${mailObj?.mailObj?.studentUrl}
          %0D%0A%0D%0ALASSO - Learning About STEM Student Outcomes
          %0D%0A%0D%0AIowa State University,`;

          openMailApp({
            to: data[0]?.email,
            subject: `Assessment Reminder for LASSO (${mailObj?.mailObj?.student?.assessment_name})`,
            body,
          });
        } else {
          Alert.success({ title: 'Saved and email reminder Sent', text: '' });

          if (setLastReminder && index != null && index != undefined) {
            setLastReminder((e: any) => {
              const newDateValues = [...e];
              if (response?.data?.last_reminder)
                newDateValues[index] = response?.data?.last_reminder;
              return newDateValues;
            });
          }
        }
        if (response?.data?.message === 'no mails') {
          Alert.success({ title: 'Dates saved successfully', text: '' });
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  // const [studentData, setStudentData] = useState([]);
  const openMailApp = ({ to, subject, body }: any) => {
    const mailtoUrl = `mailto:${to}?subject=${subject}&body=${body}`;
    const anchor = document.createElement('a');
    anchor.href = mailtoUrl;
    anchor.target = '_blank'; // Opens link in new tab
    anchor.style.display = 'none'; // Hide the anchor element
    document.body.appendChild(anchor);
    anchor.click(); // Simulate a click on the anchor element
    document.body.removeChild(anchor); // Clean up after opening the link
  };

  const callback = (result: any) => {
    const administrationData =
      result.data &&
      result.data?.administration &&
      result.data?.administration?.length
        ? result.data?.administration
        : [];

    setMigrated(result.data?.migrated);

    studentData =
      result.data && result.data?.student && result.data?.student?.length
        ? result.data.student
        : [];

    if (!Array.isArray(studentData)) {
      studentData = [];
    }

    reminderSettings =
      result.data && result.data?.reminder_setting
        ? result.data?.reminder_setting
        : [];

    administrationBox = administrationData;
    console.log(administrationData, 'administrationData');

    interface CustomColumnDef {
      field: string;
      headerName?: string;
      hide?: string;
      sort?: string;
      cellRenderer?: (params: any) => JSX.Element; // Add this line to include cellRenderer
    }

    const columnDef: CustomColumnDef[] = [
      { field: 'id', hide: 'true' },
      { field: 'first_name', headerName: 'First Name' },

      { field: 'middle_name', hide: 'true' },
      { field: 'last_name', headerName: 'Last Name', sort: 'asc' },
      { field: 'email_id', headerName: 'Email' },
    ];

    if (studentData.length > 0) {
      columnDef.push(
        ...studentData?.[0]?.test_statuses?.map((data: any, index: number) => ({
          // field: `${data.csadm_id}`,
          headerName: `Administration ${++index}`,
          minWidth: 160,
          cellRenderer: (params: any) => {
            const student = params.data.test_statuses[index - 1];

            if (!params?.data?.is_active) {
              return (
                <div className='flex items-center'>
                  <Tooltip title='Participation indicator'>
                    {student?.test_status ? (
                      <EventAvailableIcon style={{ color: 'green' }} />
                    ) : (
                      <EventBusyIcon className='text-edit-icon' />
                    )}
                  </Tooltip>

                  <Tooltip title='System mail'>
                    <IconButton disabled>
                      <MailOutlineIcon className='text-edit-icon' />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Default App'>
                    <IconButton disabled>
                      <ContactMailIcon className='text-edit-icon' />
                    </IconButton>
                  </Tooltip>
                </div>
              );
            } else {
              return (
                <div className='flex items-center'>
                  <Tooltip title='Participation indicator'>
                    {student?.test_status ? (
                      <EventAvailableIcon style={{ color: 'green' }} />
                    ) : (
                      <EventBusyIcon className='text-edit-icon' />
                    )}
                  </Tooltip>
                  <Tooltip title='System mail'>
                    <IconButton
                      disabled={(() => {
                        let condition = false;

                        if (!student?.is_active) {
                          condition = true;
                        }

                        if (
                          data.start_date_time == null ||
                          data.end_date_time == null
                        ) {
                          condition = true;
                        }

                        if (
                          data.start_date_time !== null ||
                          data.end_date_time !== null
                        ) {
                          if (
                            !moment().isBetween(
                              data?.start_date_time,
                              data?.end_date_time
                            )
                          ) {
                            condition = true;
                          }
                        }

                        if (student?.test_status == true) {
                          condition = true;
                        }

                        // Extra check for studentData.test_statuses.is_active
                        if (student?.is_active === false) {
                          condition = true;
                        }

                        return condition;
                      })()}
                      onClick={() => {
                        singleMemberMailSend(
                          params.data.course_student_id,
                          index,
                          params.data.email_id,
                          params.data.first_name,
                          params.data.last_name,
                          false
                        );
                      }}
                    >
                      <MailOutlineIcon className='text-edit-icon' />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title='Default App'>
                    <IconButton
                      disabled={(() => {
                        let condition = false;

                        if (!student?.is_active) {
                          condition = true;
                        }

                        if (
                          data.start_date_time == null ||
                          data.end_date_time == null
                        ) {
                          condition = true;
                        }

                        if (
                          data.start_date_time !== null ||
                          data.end_date_time !== null
                        ) {
                          if (
                            !moment().isBetween(
                              data?.start_date_time,
                              data?.end_date_time
                            )
                          ) {
                            condition = true;
                          }
                        }

                        if (student?.test_status == true) {
                          condition = true;
                        }

                        // Extra check for studentData.test_statuses.is_active
                        if (student?.is_active === false) {
                          condition = true;
                        }

                        return condition;
                      })()}
                      onClick={() => {
                        singleMemberMailSend(
                          params.data.course_student_id,
                          index,
                          params.data.email_id,
                          params.data.first_name,
                          params.data.last_name,
                          true
                        );
                      }}
                    >
                      <ContactMailIcon className='text-edit-icon' />
                    </IconButton>
                  </Tooltip>
                </div>
              );
            }
          },
        }))
      );
    }

    columnDef.push({
      headerName: 'Dropped',
      cellRenderer: (params: any) => (
        <div>{params?.data?.is_active === true ? '' : 'Yes'}</div>
      ),
      field: 'is_active',
    });

    // --------------------
    try {
      if (result?.data?.contentMLearningO) {
        const cMod = result.data.contentMLearningO?.content_modules.map(
          (e: any, i: any) => {
            return {
              value: e,
              time:
                result.data.contentMLearningO?.content_modules_time &&
                result.data.contentMLearningO?.content_modules_time[i]
                  ? result.data.contentMLearningO?.content_modules_time[i]
                  : '',
              descript:
                result.data.contentMLearningO?.content_modules_descript &&
                result.data.contentMLearningO?.content_modules_descript[i]
                  ? result.data.contentMLearningO?.content_modules_descript[i]
                  : '',
            };
          }
        );
        const learObj = result.data.contentMLearningO?.learning_objectives.map(
          (e: any, i: any) => {
            return {
              value: e,
              time:
                result.data.contentMLearningO?.learning_objectives_time &&
                result.data.contentMLearningO?.learning_objectives_time[i]
                  ? result.data.contentMLearningO?.learning_objectives_time[i]
                  : '',
              descript:
                result.data.contentMLearningO?.learning_objectives_descript &&
                result.data.contentMLearningO?.learning_objectives_descript[i]
                  ? result.data.contentMLearningO?.learning_objectives_descript[
                      i
                    ]
                  : '',
            };
          }
        );

        const ContentMLearningO = {
          contentModules: cMod,
          learningObjectives: learObj,
        };
        // setContentMLearningO(ContentMLearningO);
        contentMLearningObj = ContentMLearningO;
      }
    } catch (err: any) {
      console.log(err);
    }
    //---------------------------------------------
    gridRef.current.api.setColumnDefs(columnDef);
    // console.log(columnDef, 'columnDef');

    // gridRef.current.api.setColumnDefs([
    //   { field: 'id', hide: 'true' },
    //   { field: 'first_name' },
    //   { field: 'last_name' },
    //   { field: 'email_id' },
    //   ...studentData?.[0]?.test_statuses?.map((data: any, index: number) => ({
    //     // field: `${data.csadm_id}`,
    //     headerName: `Administration ${++index}`,
    //     minWidth: 160,
    //     cellRenderer: (params: any) => {
    //       const student = params.data.test_statuses[index - 1];
    //       return (
    //         <div className="flex items-center">
    //           <input
    //             type="checkbox"
    //             checked={student?.test_status}
    //             readOnly
    //             className="w-3.5 h-4 mr-2"
    //           />
    //           <Tooltip title="System mail">
    //             <IconButton
    //               disabled={(() => {
    //                 let condition: boolean = false;

    //                 if (
    //                   data.start_date_time == null ||
    //                   data.end_date_time == null
    //                 ) {
    //                   condition = true;
    //                 }

    //                 if (
    //                   data.start_date_time !== null ||
    //                   data.end_date_time !== null
    //                 ) {
    //                   if (
    //                     !moment().isBetween(
    //                       data?.start_date_time,
    //                       data?.end_date_time
    //                     )
    //                   ) {
    //                     condition = true;
    //                   }
    //                 }

    //                 if (student?.test_status == true) {
    //                   condition = true;
    //                 }
    //                 return condition;
    //               })()}
    //               onClick={() => {
    //                 singleMemberMailSend(
    //                   params.data.course_student_id, //cs_id
    //                   params.data.test_statuses[index - 1].administration_no, //cadm_no
    //                   params.data.email_id,
    //                   params.data.first_name,
    //                   params.data.last_name,
    //                   false
    //                 );
    //               }}
    //             >
    //               <MailOutlineIcon className="text-edit-icon" />
    //             </IconButton>
    //           </Tooltip>
    //           <Tooltip title="Default App">
    //             <IconButton
    //               disabled={(() => {
    //                 let condition: boolean = false;

    //                 if (
    //                   data.start_date_time == null ||
    //                   data.end_date_time == null
    //                 ) {
    //                   condition = true;
    //                 }

    //                 if (
    //                   data.start_date_time !== null ||
    //                   data.end_date_time !== null
    //                 ) {
    //                   if (
    //                     !moment().isBetween(
    //                       data?.start_date_time,
    //                       data?.end_date_time
    //                     )
    //                   ) {
    //                     condition = true;
    //                   }
    //                 }

    //                 if (student?.test_status == true) {
    //                   condition = true;
    //                 }
    //                 return condition;
    //               })()}
    //               onClick={() => {
    //                 singleMemberMailSend(
    //                   params.data.course_student_id, //cs_id
    //                   params.data.test_statuses[index - 1].administration_no, //cadm_no
    //                   params.data.email_id,
    //                   params.data.first_name,
    //                   params.data.last_name,
    //                   true
    //                 );
    //               }}
    //             >
    //               <ContactMailIcon className="text-edit-icon" />
    //             </IconButton>
    //           </Tooltip>
    //         </div>
    //       );
    //     },
    //   })),
    // ]);

    gridRef.current.api.setRowData(studentData);
    // }, 3000);

    setRosterCount(studentData?.length);
    setRenderAdministrationBoxes(administrationBox.length ? true : false);
  };
  React.useEffect(() => {
    //if (!gridRef.current) return;
    // setLoading(true);
    if (localStorage.getItem('token')) {
      getStudentAndAdministrationByAssessmentId(
        courseAssessmentId,
        // { cancelToken: cancelGetStudentsByCourse.token },
        gridRef,
        callback,
        setLoading
      );

      // if (refresh) {
      //   setOpen(true);
      // }
    }
  }, [gridRef.current, refresh]);

  const onGridReady = (params: any) => {
    gridRef.current = params;
    gridRef.current.api.showLoadingOverlay();
  };

  const [studentResultsData, setStudentResultsData] = useState();
  const [courseInfoData, setCourseInfoData] = useState();

  function handleGetResultAnalysis(courseAssessmentId: any) {
    dispatch(getResultAnalysis(courseAssessmentId, downloadReportAnalysis));
  }

  function downloadReportAnalysis(
    courseInfoData: any,
    studentResultsData: any
  ) {
    const fileNameProperties = courseInfoData.pop();
    const workbook = readxlsx.utils.book_new();

    // create first sheet with course info data
    //@ts-ignore
    const courseInfoSheet = readxlsx.utils.json_to_sheet(courseInfoData);
    readxlsx.utils.book_append_sheet(workbook, courseInfoSheet, 'Course Info');

    // create second sheet with student results data
    //@ts-ignore
    const studentResultsSheet = readxlsx.utils.aoa_to_sheet(studentResultsData);
    readxlsx.utils.book_append_sheet(
      workbook,
      studentResultsSheet,
      'Student Results'
    );

    // write workbook data to file and download
    const excelBuffer = readxlsx.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    const now = new Date();
    const UTCTime = `${(now.getUTCMonth() + 1)
      .toString()
      .padStart(2, '0')}-${now
      .getUTCDate()
      .toString()
      .padStart(2, '0')}-${now.getUTCFullYear()} ${now
      .getUTCHours()
      .toString()
      .padStart(2, '0')}:${now.getUTCMinutes().toString().padStart(2, '0')} ${
      now.getUTCHours() >= 12 ? 'PM' : 'AM'
    }`;

    //@ts-ignore
    const fileName = `LASSO_${fileNameProperties.code}_${
      fileNameProperties.term
    }_${fileNameProperties.year}_${
      fileNameProperties.instructor &&
      !fileNameProperties.instructor.includes('undefined')
        ? `${fileNameProperties.instructor}_`
        : ''
    }${fileNameProperties.admNo}_${
      fileNameProperties.courseName
    }_${UTCTime}.xlsx`;
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    downloadLink.style.display = 'none';
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = fileName;
    downloadLink.click();
    URL.revokeObjectURL(url);
    document.body.removeChild(downloadLink);
  }

  const onFilterChanged = (e: any) => {
    //
    if (e.api.rowModel.rowsToDisplay.length == 0) {
      e.api.showNoRowsOverlay();
    } else {
      e.api.hideOverlay();
    }
  };

  const onFilterTextBoxChanged = () => {
    const inputElement = document.getElementById(
      'filter-text-box'
    ) as HTMLInputElement;
    if (inputElement && gridRef.current) {
      gridRef.current.api.setQuickFilter(inputElement.value);
    }
  };
  const [open, setOpen] = React.useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  // useEffect(() => {
  //   if (instructorsCoordinators?.length && reminderSettings) {
  //     setIsDataLoaded(true);
  //   }
  // }, [instructorsCoordinators, reminderSettings]);
  const location = useLocation();
  const courseName = location.state?.courseName;
  const assessmentId = location.state?.assessmentId; // Retrieve assessmentId
  const assessmentType = location?.state.type;

  const [expandedAccordions, setExpandedAccordions] = useState<{
    [key: string]: boolean;
  }>({});

  const handleAccordionChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpandedAccordions((prevState) => ({
        ...prevState,
        [panel]: isExpanded,
      }));
    };

  const administrationData = {
    id: location.state?.id,
    assessment_id: {
      official_name: location.state?.courseName,
      assessment_id: location.state?.assessmentId,
      code: location.state?.code,
      type: location.state?.type,
    },
    course_id: location.state?.courseId,
  };

  // Retrieve from localStorage if available, but prioritize the value from location.state
  const initialNoOfAdministrations = location.state?.noOfAdministrations
    ? location.state.noOfAdministrations
    : localStorage.getItem('noOfAdministrations')
    ? Number(localStorage.getItem('noOfAdministrations'))
    : 0;

  // State to manage the number of administrations dynamically
  const [textValue, setTextValue] = useState(initialNoOfAdministrations);

  useEffect(() => {
    // Reset the state when page loads or navigation changes
    if (location.state?.noOfAdministrations) {
      setTextValue(location.state.noOfAdministrations);
    } else {
      setTextValue(0); // Or any default value if needed
    }

    // Optionally clear localStorage to avoid reuse on other pages
    return () => {
      localStorage.removeItem('noOfAdministrations');
    };
  }, [location.state?.noOfAdministrations]);
  const [error, setError] = useState(''); // State to handle error messages

  const handleChange = (event: any) => {
    setTextValue(event.target.value); // Allow editing freely
  };

  const handleUpdateAdministrations = () => {
    if (textValue == '') {
      setError('Required field');
      return; // Prevent update
    }
    const enteredValue = Number(textValue); // Convert input to a number

    const currentStoredValue =
      Number(localStorage.getItem('noOfAdministrations')) ||
      initialNoOfAdministrations; // Get current value

    // Validation logic
    if (enteredValue > 16 || enteredValue <= currentStoredValue) {
      setError('value must be greater than current value upto 16');
      return; // Prevent update
    } else {
      setError(''); // Clear error if input is valid

      // Store the updated value in localStorage as a string
      localStorage.setItem('noOfAdministrations', String(enteredValue));

      // Prepare the full data with static values and dynamic number of administrations
      const data = {
        ...administrationData, // Include all the static data
        no_of_administrations: enteredValue, // Add the dynamic value
      };

      // Dispatch the action with the updated data
      dispatch(
        editCourseAssessmentData(
          data,
          () => {}, // Handle closing or further actions (optional)
          () => {}, // Any additional callbacks (optional)
          {}, // Params (optional)
          () => {}, // setInitialValue (optional)
          () => {}, // setIsEdit (optional)
          () => {}, // setIsDispatching (optional)
          null, // gridApi if needed
          administrationData, // Initial values for handling updates if needed
          setLoading,
          setRefresh
        )
      );
    }
  };

  return (
    <main>
      <h1 className='header'>Assessment Administration </h1>
      <Box
        sx={{ flexGrow: 1 }}
        className='p-2'
        style={loading ? { display: 'none' } : {}}
      >
        <Grid container rowGap={{ xs: 3 }}>
          <Grid item xs={12}>
            <Item>
              <Accordion
                expanded={expandedAccordions['panel1'] || false}
                onChange={handleAccordionChange('panel1')}
                className='border-gray-500 border-2'
              >
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls='panel2-content'
                  id='panel2-header'
                >
                  <Typography
                    sx={{
                      marginLeft: '5px',
                      fontSize: '17px',
                      fontWeight: 500,
                    }}
                  >
                    Assessment Roster
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className='flex flex-row items-center justify-between mr-4'>
                    <div className='flex items-center justify-start gap-8'>
                      <div className='pl-1 font-semibold'>
                        <label className='pr-2'>Roster Count :</label>{' '}
                        {rosterCount}
                      </div>
                    </div>

                    {/* <Fab size="small" onClick={() => setReminderDialog(true)}>
                  <EditNotificationsSharpIcon />
                </Fab> */}
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <div>
                        <Button
                          onClick={() => setOpen(true)}
                          variant='contained'
                          size='small'
                          style={{ marginLeft: '13px' }}
                        >
                          <span>Manage Students</span>
                        </Button>

                        <FileImport
                          props={{
                            open: open,
                            setOpen: setOpen,
                            data: { id },
                            setRefresh: setRefresh,
                            name: 'Manage Students',
                          }}
                        />
                      </div>
                      <div>
                        <Button
                          onClick={() => setReminderDialog(true)}
                          variant='contained'
                          size='small'
                        >
                          <span>Manage email reminders</span>
                        </Button>

                        <ReminderSettings
                          reminderDialog={reminderDialog}
                          setReminderDialog={setReminderDialog}
                          userType={getUserDataAndType.data?.type}
                          courseAssessmentId={courseAssessmentId}
                          instructorsCoordinators={instructorsCoordinators}
                          reminderSettings={reminderSettings}
                        />
                      </div>
                      <div>
                        <TextField
                          size='small'
                          id='filter-text-box'
                          placeholder='Search'
                          onChange={onFilterTextBoxChanged}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position='start'>
                                <BsSearch />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    style={
                      loading
                        ? { display: 'none' }
                        : { width: '100%', height: '600px' }
                    }
                    className='ag-theme-alpine  p-1 mb-14'
                  >
                    <AgGridReact
                      // ref={gridRef}
                      columnDefs={defaultAdministrationDefinition}
                      defaultColDef={defaultColDef}
                      onGridReady={onGridReady}
                      pagination={true}
                      suppressServerSideInfiniteScroll={false}
                      paginationPageSize={25}
                      animateRows={true}
                      onFilterChanged={onFilterChanged}
                      overlayLoadingTemplate={
                        '<span class="ag-overlay-loading-center">Please wait while loading</span>'
                      }
                    />
                  </div>
                </AccordionDetails>
              </Accordion>
            </Item>
          </Grid>
          <Item>
            <Accordion
              expanded={expandedAccordions['panel2'] || false}
              onChange={handleAccordionChange('panel2')}
              className='border-gray-500 border-2'
            >
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                aria-controls='panel2-content'
                id='panel2-header'
              >
                <Typography
                  sx={{ marginLeft: '5px', fontSize: '17px', fontWeight: 500 }}
                >
                  Administrations: {courseName}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack direction={'row'} alignItems={'center'} gap={'20px'}>
                  <label className='text-sm'>Number of Administrations:</label>
                  <TextField
                    type='number'
                    id='standard-basic'
                    variant='standard'
                    value={textValue}
                    onChange={handleChange}
                    error={!!error} // Show error style
                    helperText={error} // Display error message
                  />
                  <Button
                    sx={{ textTransform: 'initial' }}
                    onClick={handleUpdateAdministrations}
                  >
                    Set number of administrations
                  </Button>
                  {assessmentType === 'static' && ( // Replace 'desiredType' with the specific type you want to check
                    <Button
                      onClick={() => {
                        window.open(
                          `/viewassessment?id=${assessmentId}&type=course`
                        );
                      }}
                      sx={{ textTransform: 'initial' }}
                    >
                      Preview Assessment
                    </Button>
                  )}
                </Stack>
                <Grid
                  container
                  rowGap={{ xs: 3 }}
                  columnGap={{ md: 2.5 }}
                  className='border border-slate-400 rounded-md p-1.5'
                >
                  {/* {renderAdministrationBoxes()} */}
                  {renderAdministrationBoxes && (
                    <AdministrationBoxes
                      rosterCount={rosterCount}
                      sendMail={sendMail}
                      courseAssessmentId={courseAssessmentId}
                      contentMLearningO={contentMLearningObj}
                      adminId={location?.state?.id}
                      setRefresh={setRefresh}
                      setLoading={setLoading}
                    />
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Item>

          <Grid
            component={Paper}
            elevation={2}
            item
            xs={12}
            lg
            className='rounded-md'
            // style={{ marginBottom: '116px' }}
          >
            <Item>
              <Accordion
                expanded={expandedAccordions['panel3'] || false}
                onChange={handleAccordionChange('panel3')}
                className='border-gray-500 border-2'
              >
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls='panel2-content'
                  id='panel2-header'
                >
                  <Typography
                    sx={{
                      marginLeft: '5px',
                      fontSize: '17px',
                      fontWeight: 500,
                    }}
                  >
                    Download Data and Reports{' '}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container gap={2} className='my-2 pl-2'>
                    <Grid
                      component='h2'
                      className='font-semibold  p-1 mb-2 rounded-sm'
                    >
                      Download the summary of all administrations{' '}
                    </Grid>
                  </Grid>
                  <Grid container gap={2} className='my-2 pl-2'>
                    <Button
                      variant='outlined'
                      onClick={() =>
                        handleGetResultAnalysis(courseAssessmentId)
                      }
                      disabled={(() => {
                        const arr = administrationBox
                          ?.filter((e: any) => e?.is_active)
                          ?.map((e: any) => e?.end_date_time);
                        if (
                          arr?.includes(null) ||
                          arr == undefined ||
                          !arr?.length
                        )
                          return true;
                        const bool = arr?.every((e: any) =>
                          moment().utc().isAfter(moment(e))
                        );
                        return !bool;
                      })()}
                    >
                      DOWNLOAD ALL DATA
                    </Button>
                    {/* <Button
                      variant='outlined'
                      onClick={() => getReportsData(courseAssessmentId)}
                      disabled={(() => {
                        const arr = administrationBox?.map(
                          (e: any) => e?.end_date_time
                        );
                        if (
                          arr?.includes(null) ||
                          arr == undefined ||
                          !arr?.length
                        )
                          return true;
                        const bool = arr?.every((e: any) =>
                          moment().utc().isAfter(moment(e))
                        );
                        return !bool;
                      })()}
                    >
                      Get Report
                    </Button>
                    <Button
                      variant='outlined'
                      disabled={(() => {
                        const arr = administrationBox?.map(
                          (e: any) => e?.end_date_time
                        );
                        if (
                          arr?.includes(null) ||
                          arr == undefined ||
                          !arr?.length
                        )
                          return true;
                        const bool = arr?.every((e: any) =>
                          moment().utc().isAfter(moment(e))
                        );
                        return !bool;
                      })()}
                    >
                      Additional Reports
                    </Button> */}
                    <Button
                      variant='outlined'
                      disabled={(() => {
                        const arr = administrationBox
                          ?.filter((e: any) => e?.is_active)
                          ?.map((e: any) => e?.end_date_time);
                        if (
                          arr?.includes(null) ||
                          arr == undefined ||
                          !arr?.length
                        )
                          return true;
                        const bool = arr?.every((e: any) =>
                          moment().utc().isAfter(moment(e))
                        );
                        return !bool;
                      })()}
                      onClick={() => setReportsOpen(true)}
                    >
                      Reports Menu
                    </Button>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Item>
          </Grid>
        </Grid>
      </Box>

      <Reports
        open={reportsOpen}
        setOpen={setReportsOpen}
        courseAssessmentId={courseAssessmentId}
      />

      <div
        className='flex justify-center items-center h-[90vh]'
        style={!loading ? { display: 'none' } : {}}
      >
        <CircularProgress />
      </div>
    </main>
  );
};

export default Administration;
