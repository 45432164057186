import { Input, Button, IconButton, Select } from '@mui/material';
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { MdModeEdit, MdDelete } from 'react-icons/md';
import { ErrorMessage, Field, Form, Formik, useField } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';

import './style.css';
import { AppDispatch } from '../../../reduxStore/Store';
import { updateUserDetails } from '../../../reduxStore/reducer/registerReducer';
import {
  addSystemSettings,
  getSystemSettingsList,
  getSystemSettingsPasswordAndComplexity,
} from '../../../reduxStore/reducer/systemSettingsReducer';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { Scrollbar } from 'react-scrollbars-custom';

// Define the options for password complexity
const passwordComplexityOptions = [
  { value: '(?=.*?[A-Z])', label: 'At least one upper case English letter' },
  { value: '(?=.*?[a-z])', label: 'At least one lower case English letter' },
  { value: '(?=.*?[0-9])', label: 'At least one digit' },
  { value: '(?=.*?[#?!@$%^&*-])', label: 'At least one special character' },
  { value: '.{8,}', label: 'Minimum eight in length' },
];

const SecuritySettings = ({ userData }: any) => {
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(getSystemSettingsList(setApiKey));
  }, [dispatch]);
  const [apiKey, setApiKey] = useState<any>({});
  const validation = Yup.object({
    password_retry_count: Yup.number()
      .integer('Please enter an integer value')
      .required('Required Field'),

    password_complexity: Yup.mixed().required('Required Field'),
  });
  //
  const [initialValues, setInitialValue] = useState<any>({
    password_retry_count: '',
    password_complexity: [],
  });
  useEffect(() => {
    //
    if (
      apiKey &&
      apiKey.data &&
      apiKey.data.admin_email &&
      apiKey.data.send_grid_api_key
    ) {
      setInitialValue({
        password_retry_count: apiKey.data.password_retry_count || '',
        password_complexity: apiKey.data.password_complexity || [],
      });
      const savedOptions = apiKey.data.password_complexity || [];
      setSelectedOptions(savedOptions);
    }
  }, [apiKey]);

  interface PasswordComplexityOption {
    value: string;
    label: string;
  }

  const [selectedOptions, setSelectedOptions] = useState<
    PasswordComplexityOption[]
  >([]);

  // Filter the options to exclude the selected options
  const filteredOptions = useMemo(() => {
    return passwordComplexityOptions.filter(
      (option) =>
        !selectedOptions.some(
          (selectedOption) => selectedOption.value === option.value
        )
    );
  }, [selectedOptions]);
  const [password, setPassword] = useState<any>({});
  //

  return (
    <div>
      <div className="bg-sky-800 text-white h-10 p-2 font-bold">
        <p>Security Settings</p>
      </div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validation}
        onSubmit={(values, { setSubmitting }) => {
          // Find the index of the "Minimum eight in length" option
          const minLengthIndex = values.password_complexity.findIndex(
            (option: any) => option.label === 'Minimum eight in length'
          );

          // Remove the "Minimum eight in length" option from the array
          const updatedPasswordComplexity = values.password_complexity.filter(
            (option: any) => option.label !== 'Minimum eight in length'
          );

          // Add the updated "Minimum eight in length" option as the last item in the new array
          updatedPasswordComplexity.push({
            value: '.{8,}',
            label: 'Minimum eight in length',
          });

          // Update the values with the new password complexity array
          values.password_complexity = updatedPasswordComplexity;
          // Convert the selected options to JSON string
          const passwordComplexity = JSON.stringify(values.password_complexity);

          // Save the JSON string or use it as needed
          dispatch(
            addSystemSettings(
              { ...values, passwordComplexity },
              'Security settings',
              setSubmitting
            )
          );
        }}
      >
        {({ values, setFieldValue, isValid, isSubmitting }) => (
          <Form>
            <Scrollbar style={{ height: 'calc(100vh - 18rem)' }}>
              <div className="grid gap-y-2 max-w-lg sm:max-w-3xl mt-4 ml-2 sm:grid-cols-2">
                <label htmlFor="institution" className="">
                  Password Retrieval Count
                  <span className="text-red-500 text-lg"> *</span>
                </label>
                <div>
                  <Field
                    id="password_retry_count"
                    name="password_retry_count"
                    className="input-field h-9"
                    type="number"
                    step="1"
                    onChange={(e: any) => {
                      setFieldValue('password_retry_count', e.target.value);
                    }}
                  />
                  <ErrorMessage
                    name="password_retry_count"
                    component="span"
                    className="text-red-600 ml-2 text-sm"
                  />
                </div>

                <label htmlFor="city" className="mt-2">
                  Password Complexity
                  <span className="text-red-500 text-lg"> *</span>
                </label>
                <div>
                  <Autocomplete
                    multiple
                    id="password_complexity"
                    options={filteredOptions}
                    getOptionLabel={(option) => option.label}
                    value={selectedOptions}
                    limitTags={1}
                    getOptionDisabled={(option: any) =>
                      option.label === 'Minimum eight in length'
                    }
                    onChange={(event, value) => {
                      setSelectedOptions(value);
                      setFieldValue('password_complexity', value);
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          label={option.label}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Password Complexity"
                        placeholder="Select complexity options"
                      />
                    )}
                  />
                  <ErrorMessage
                    name="password_complexity"
                    component="span"
                    className="text-red-600 ml-2 text-sm"
                  />
                </div>

                <label
                  htmlFor="city"
                  className="mt-2"
                  style={{ visibility: 'hidden' }}
                ></label>
                <div className="mt-2">
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={
                      !isValid || selectedOptions.length === 0 || isSubmitting
                    }
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Scrollbar>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SecuritySettings;
