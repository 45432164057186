import { Container, Typography } from '@mui/material';
import React from 'react';
import Paper from '@mui/material/Paper';

const StudentSuccess = () => {
  return (
    <>
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Typography variant="h2" align="center" sx={{ py: 4 }}>
            Email Sent
          </Typography>
          <Typography component="p" align="center">
            Please check your Email for a Account activation Link
          </Typography>
        </Paper>
      </Container>
    </>
  );
};

export default StudentSuccess;
