import { TextField, Button, InputAdornment, Stack } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { GridHeader2Props } from '../interfaces/GridHeader2Props';
import { organizationIcon, searchIcon } from '../icons';

const GridHeader3 = ({ textFieldProps, buttonProps }: GridHeader2Props) => {
  let { sm1, md1, lg1, children1, ...rest1 } = buttonProps.button1;
  let { sm2, md2, lg2, children2, ...rest2 } = buttonProps.button2;
  let { sm3, md3, lg3, children3, ...rest3 } = buttonProps.button3;
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const isMediumScreen = useMediaQuery(
    '(min-width:601px) and (max-width:960px)'
  );
  const isLargeScreen = useMediaQuery('(min-width:961px)');

  const renderChildren = (sm: any, md: any, lg: any, children: any) => {
    if (isSmallScreen && sm) {
      return sm;
    } else if (isMediumScreen && md) {
      return md;
    } else if (isLargeScreen && lg) {
      return lg;
    } else {
      return children;
    }
  };

  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      px={1}
    >
      <div className='flex gap-3'>
        <Button
          {...rest1}
          children={renderChildren(sm1, md1, lg1, children1)}
          size='medium'
          variant='contained'
          color='primary'
          // startIcon={organizationIcon}
        />
        <Button
          {...rest3}
          children={renderChildren(sm3, md3, lg3, children3)}
          size='medium'
          variant='contained'
          color='primary'
          // startIcon={organizationIcon}
        />
      </div>
      <Stack
        direction='row'
        justifyContent='flex-end'
        alignItems='center'
        spacing={2}
        p={1}
      >
        <TextField
          {...textFieldProps}
          size='small'
          variant='outlined'
          placeholder='Search...'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>{searchIcon}</InputAdornment>
            ),
          }}
        />

        <Button
          {...rest2}
          children={renderChildren(sm2, md2, lg2, children2)}
          size='medium'
          variant='contained'
          color='primary'
          // startIcon={organizationIcon}
        />
      </Stack>
    </Stack>
  );
};

export default GridHeader3;
