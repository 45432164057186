import { Input, Button, IconButton } from '@mui/material';
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { MdModeEdit, MdDelete } from 'react-icons/md';
import { ErrorMessage, Field, Form, Formik, useField } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';

import './style.css';
import { AppDispatch } from '../../../reduxStore/Store';
import { updateUserDetails } from '../../../reduxStore/reducer/registerReducer';
import {
  addSystemSettings,
  getSystemSettingsList,
} from '../../../reduxStore/reducer/systemSettingsReducer';

const DisplaySettings = ({ userData }: any) => {
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(getSystemSettingsList(setApiKey));
  }, [dispatch]);
  const [apiKey, setApiKey] = useState<any>({});

  //
  const [initialValues, setInitialValue] = useState<any>({
    popup_timing: '',
    popup_count: '',
  });

  const validation = Yup.object({
    popup_timing: Yup.number()
      .integer('Please enter an integer value')
      .required('Required Field'),
    popup_count: Yup.number()
      .integer('Please enter an integer value')
      .required('Required Field'),
  });

  useEffect(() => {
    //
    if (
      apiKey &&
      apiKey.data &&
      apiKey.data.admin_email &&
      apiKey.data.send_grid_api_key
    ) {
      setInitialValue({
        popup_timing: apiKey.data.popup_timing || '',
        popup_count: apiKey.data.popup_count || '',
      });
    }
  }, [apiKey]);

  return (
    <div>
      <div className="bg-sky-800 text-white h-10 p-2 font-bold">
        <p>Display Settings</p>
      </div>
      <Formik
        enableReinitialize
        initialValues={initialValues} //initialValues
        validationSchema={validation}
        onSubmit={(values, { setSubmitting }) => {
          // dispatch(updateUserDetails(values));
          dispatch(
            addSystemSettings(values, 'Display settings', setSubmitting)
          );
        }}
      >
        {({ values, errors, touched, isValid, isSubmitting }) => (
          <Form>
            <div className="grid gap-y-2 max-w-lg sm:max-w-3xl mt-4 ml-2 sm:grid-cols-2">
              <label htmlFor="institution" className="">
                Popup Timing
                <span className="text-red-500 text-lg"> *</span>
              </label>
              <div>
                <Field
                  type={'number'}
                  id="popup_timing"
                  name="popup_timing"
                  className="input-field h-9"
                />
                <ErrorMessage
                  name="popup_timing"
                  component="span"
                  className="text-red-600 ml-2 text-sm"
                />
              </div>

              <label htmlFor="city" className="mt-2">
                Popup Count
                <span className="text-red-500 text-lg"> *</span>
              </label>
              <div>
                <Field
                  type={'number'}
                  id="popup_count"
                  name="popup_count"
                  className="input-field h-9"
                />
                <ErrorMessage
                  name="popup_count"
                  component="span"
                  className="text-red-600 ml-2 text-sm"
                />
              </div>

              <label
                htmlFor="city"
                className="mt-2"
                style={{ visibility: 'hidden' }}
              ></label>
              <div className="mt-2">
                <Button
                  type="submit"
                  variant="contained"
                  disabled={isSubmitting || !isValid}
                >
                  Save
                </Button>
              </div>
            </div>
            {/* <div className="flex justify-center gap-10 mt-7 mb-5">
              <pre>{JSON.stringify(values, null, 2)}</pre>
            </div> */}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default DisplaySettings;
