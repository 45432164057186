import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Snackbar,
  TextField,
} from '@mui/material';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import {
  getDownloadOptionValue,
  getSheetData,
} from '../../reduxStore/reducer/courseReducer';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import * as readxlsx from 'xlsx';
import { createFilterOptions } from '@mui/material/Autocomplete';

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: (option: any) => option.name,
});

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

export function DownloadDialog(props: any) {
  const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');
  const [formControls, setFormControls] = React.useState<any>({
    open: [false, false, false],
    options: [[], [], []],
    field: [[], [], []],
    error: false,
    loading: false,
  });

  React.useEffect(() => {
    if (formControls.options[0].length !== 0) return;
    getDownloadOptionValue(helpObj.afterGetDownloadOptionValue);
  }, [props.downloadDialog]);

  const helpObj = {
    loading: [0, 1, 2].map(
      (index) =>
        formControls.open[index] && formControls.options[index].length === 0
    ),

    updateOpenValue: (index: number, newValue: boolean) => {
      setFormControls((prevState: any) => ({
        ...prevState,
        open: prevState.open.map((value: any, i: number) =>
          i === index ? newValue : value
        ),
      }));
    },

    updateOptionValue: (index: number, newValue: any[]) => {
      setFormControls((prevState: any) => ({
        ...prevState,
        options: prevState.options.map((value: any, i: number) =>
          i === index ? newValue : value
        ),
      }));
    },

    updateFieldValue: (index: number, newValue: any[]) => {
      setFormControls((prevState: any) => ({
        ...prevState,
        field: prevState.field.map((value: any, i: number) =>
          i === index ? newValue : value
        ),
      }));
    },

    updateErrorValue: (boolean: boolean) => {
      setFormControls((prevState: any) => ({
        ...prevState,
        error: boolean,
      }));
    },

    updateLoadingValue: (boolean: boolean) => {
      setFormControls((prevState: any) => ({
        ...prevState,
        loading: boolean,
      }));
    },

    clearForm: () => {
      setFormControls({
        open: [false, false, false],
        options: [[], [], []],
        field: [[], [], []],
        error: false,
      });
    },

    afterGetDownloadOptionValue: (response: any) => {
      Object.keys(response).forEach((key, index) => {
        helpObj.updateOptionValue(index, response[key]);
      });

      //generates
      // helpObj.updateOptionValue(0, response?.term);
      // helpObj.updateOptionValue(1, response?.year);
      // helpObj.updateOptionValue(, response?.assessment);
    },

    afterGetSheetData: (response: any) => {
      const workbook = readxlsx.utils.book_new();

      const appendSheets = (sheets: any) => {
        const names = [
          'Course(s)',
          'Assessment(s)',
          'Student(s)',
          'Administration',
        ];

        Object.keys(sheets).forEach((sheetName: any, i: any) => {
          let sheet;
          //
          if (sheetName === 'studentData') {
            sheet = readxlsx.utils.aoa_to_sheet(sheets[sheetName]);
          } else {
            sheet = readxlsx.utils.json_to_sheet(sheets[sheetName]);
          }
          if (i < 3)
            readxlsx.utils.book_append_sheet(workbook, sheet, names[i]);
          else
            readxlsx.utils.book_append_sheet(
              workbook,
              sheet,
              `${sheetName}_Response(s)`
            );
        });
      };

      appendSheets(response);

      // write workbook data to file and download
      const excelBuffer = readxlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      const now = new Date();
      const formattedDate = `${(now.getUTCMonth() + 1)
        .toString()
        .padStart(2, '0')}-${now
        .getUTCDate()
        .toString()
        .padStart(2, '0')}-${now.getUTCFullYear()} ${now
        .getUTCHours()
        .toString()
        .padStart(2, '0')}:${now.getUTCMinutes().toString().padStart(2, '0')} ${
        now.getUTCHours() >= 12 ? 'PM' : 'AM'
      }`;

      const fileName = `LASSO_Research_${formattedDate}.xlsx`;
      const downloadLink = document.createElement('a');
      document.body.appendChild(downloadLink);
      downloadLink.style.display = 'none';
      const blob = new Blob([excelBuffer], {
        type: 'application/octet-stream',
      });
      const url = URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = fileName;
      downloadLink.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(downloadLink);
      helpObj.updateLoadingValue(false);
    },
  };

  const submitForm = () => {
    if (props?.type == 'DOWNLOAD DATA') {
      formControls.field.push('DOWNLOAD DATA');
    }
    getSheetData(helpObj.afterGetSheetData, formControls.field);
  };

  return (
    <Dialog open={props?.downloadDialog} scroll={scroll} fullWidth>
      <DialogTitle sx={{ backgroundColor: '#1663ab', color: 'white' }}>
        Download Filter
      </DialogTitle>
      <DialogContent dividers={scroll === 'paper'}>
        <div className='flex gap-2 justify-start	items-center p-2 '>
          <label className='w-72'>Term</label>
          <Autocomplete
            sx={{ width: 400 }}
            open={formControls.open[0]}
            size='small'
            onOpen={() => {
              helpObj.updateOpenValue(0, true);
            }}
            onClose={() => {
              helpObj.updateOpenValue(0, false);
            }}
            disableCloseOnSelect
            getOptionLabel={(option: any) => option}
            options={formControls.options[0]}
            loading={helpObj.loading[0]}
            multiple
            onChange={(e, v, r, d) => {
              helpObj.updateFieldValue(0, v);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {helpObj.loading[0] ? (
                        <CircularProgress color='inherit' size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
          />
        </div>
        <div className='flex gap-3 justify-start	items-center p-2 '>
          <label className='w-72'>Year</label>
          <Autocomplete
            sx={{ width: 400 }}
            open={formControls.open[1]}
            size='small'
            onOpen={() => {
              helpObj.updateOpenValue(1, true);
            }}
            onClose={() => {
              helpObj.updateOpenValue(1, false);
            }}
            disableCloseOnSelect
            onChange={(e, v, r, d) => {
              helpObj.updateFieldValue(1, v);
            }}
            getOptionLabel={(option: any) => option}
            options={formControls.options[1]}
            loading={helpObj.loading[1]}
            multiple
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {helpObj.loading[1] ? (
                        <CircularProgress color='inherit' size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
          />
        </div>

        <div className='flex gap-3 justify-start items-center p-2 '>
          <label className='w-72'>Assessment</label>
          <Autocomplete
            sx={{ width: 400 }}
            filterOptions={filterOptions}
            open={formControls.open[2]}
            size='small'
            onOpen={() => {
              helpObj.updateOpenValue(2, true);
            }}
            onClose={() => {
              helpObj.updateOpenValue(2, false);
            }}
            disableCloseOnSelect
            onChange={(e, v, r, d) => {
              helpObj.updateFieldValue(2, v);
            }}
            getOptionLabel={(option: any) => {
              return option?.name;
            }}
            options={formControls.options[2]}
            loading={helpObj.loading[2]}
            multiple
            ChipProps={{ style: { maxWidth: 80 } }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {helpObj.loading[2] ? (
                        <CircularProgress color='inherit' size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </li>
            )}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          onClick={() => {
            helpObj.updateLoadingValue(true);
            submitForm();
          }}
          disabled={
            formControls.field.every((array: any) => array.length === 0) ||
            formControls.loading
          }
          sx={{ width: 120 }}
          children={
            formControls.loading ? (
              <CircularProgress color='inherit' size={25} />
            ) : (
              'Download'
            )
          }
        />
        <Button
          variant='contained'
          onClick={() => {
            props?.setDownloadDialog(!props?.downloadDialog);
            helpObj.updateErrorValue(false);
            helpObj.clearForm();
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
