import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  getAdaptiveAssessmentListWithTestItems,
  getSelectedAdaptiveAssessmentListWithTestItems,
} from '../../../reduxStore/reducer/assessmentReducer';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../reduxStore/Store';

interface TestItemsDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  state: any;
  name: string[];
  setName: (name: string[]) => void;
}

const TestItemsDialog: React.FC<TestItemsDialogProps> = (props) => {
  const { open, setOpen, state, name, setName } = props;
  const dispatch = useDispatch<AppDispatch>();

  const [itemData, setItemData] = useState([]);

  useEffect(() => {
    dispatch(getAdaptiveAssessmentListWithTestItems(setItemData));
  }, [dispatch, state]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <div>
      <Dialog
        open={open}
        style={{ zIndex: +10 }}
        sx={{
          '& .MuiDialog-paper': {
            width: '600px',
            height: '250px',
          },
        }}
      >
        <DialogTitle sx={{ backgroundColor: '#1663ab', color: 'white' }}>
          <div className='flex justify-between items-center'>
            <div>Adaptive Test Item</div>
            <div>
              <CloseIcon
                onClick={() => {
                  setOpen(false);
                }}
              />
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid xs={12} md={12} style={{ marginTop: '20px' }}>
            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id='demo-multiple-checkbox-label'>Tag</InputLabel>
              <Select
                labelId='demo-multiple-checkbox-label'
                id='demo-multiple-checkbox'
                multiple
                value={name}
                onChange={handleChange}
                input={<OutlinedInput label='Assessment' />}
                renderValue={(selected: any) =>
                  (selected as string[]).join(', ')
                }
                // Assuming 'MenuProps' is defined elsewhere
                MenuProps={MenuProps}
              >
                {itemData.map((data: any) => (
                  <MenuItem key={data.name} value={data.name}>
                    <Checkbox checked={name.indexOf(data.name) > -1} />
                    <ListItemText primary={data.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid
            xs={12}
            md={12}
            style={{
              marginTop: '20px',
              display: 'flex',
              justifyContent: 'end',
            }}
          >
            <Button
              variant='contained'
              onClick={() => {
                setOpen(false);
              }}
            >
              Close
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TestItemsDialog;
