import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Tooltip } from '@mui/material';

interface State extends SnackbarOrigin {
  open: boolean;
}

export default function CopyLink() {
  const [state, setState] = React.useState<State>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const { vertical, horizontal, open } = state;

  const handleClick = (newState: SnackbarOrigin) => () => {
    setState({ ...newState, open: true });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  return (
    <Box sx={{ width: 145 }}>
      <Tooltip title='This is a link instructors can add to their LMS or assignments that students can use to log in to LASSO once their email is added to a course'>
        <Link
          component='button'
          variant='body2'
          onClick={() => {
            const url = `${window.location.origin}/confirmation`;
            navigator.clipboard
              .writeText(url)
              .then(() => {
                setState({
                  vertical: 'bottom',
                  horizontal: 'right',
                  open: true,
                });
              })
              .catch((err) => {
                console.error('Error copying to clipboard', err);
              });
          }}
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            textDecoration: 'none',
            color: 'primary.main',
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'underline',
            },
            textWrap: 'wrap',
            // textAlign: 'left',
          }}
          fontSize={12}
        >
          Optional Assessment Link
        </Link>
      </Tooltip>
      <Snackbar
        autoHideDuration={500}
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message='Link copied to clipboard'
        key={vertical + horizontal}
      />
    </Box>
  );
}
