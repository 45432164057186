// reducer.js

export const initialState = {
  dark: '',
};

const themeReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'SET_THEME':
      return { ...state, dark: action.payload };
    default:
      return state;
  }
};

export const setTheme = (theme: any) => {
  return {
    type: 'SET_THEME',
    payload: theme,
  };
};

// export const clearSearch = () => ({
//   type: 'CLEAR_SEARCH',
// });

export default themeReducer;
