import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ColGroupDef, ColumnApi, GridApi } from 'ag-grid-community';
import GridHeader1 from '../../utils/gridHeader/GridHeader1';
import MasterGrid from '../../utils/MasterGrid/MasterGrid';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Typography,
  Grid,
  Button,
  Checkbox,
  InputAdornment,
  Menu,
  Switch,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
  InputLabel,
  IconButton,
  Paper,
  DialogProps,
  Input,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import {
  addAdaptiveAssessment,
  addAssessment,
  deleteAssessment,
  getAssessmentData,
  getAssessmentDiscipline,
  getAssessmentQuestion,
  searchAssessment,
  updateAdaptivePublishStatus,
  viewAssessment,
} from '../../reduxStore/reducer/assessmentReducer';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../reduxStore/Store';
import Editor from '../../utils/Editor/Editor';
import { createServerSideDatasource } from '../../utils/gridMethod/createServerSideDatasource';
import { GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { useNavigate } from 'react-router';
import ViewAssessment from './ViewAssessment';
import { getDisciplineDetails } from '../../reduxStore/reducer/disciplineReducer';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { MdEdit } from 'react-icons/md';
import { AssessmentDiscipline } from './Dialogs/AssessmentDiscipline';
import GridHeader2 from '../../utils/gridHeader/GridHeader2';
import debounce from 'lodash/debounce';
import { ServerSideGrid } from '../../utils/MasterGrid/ServerSideGrid';
import { updatePublishStatus } from '../../reduxStore/reducer/assessmentReducer';
import DeleteIcon from '@mui/icons-material/Delete';
import Alert from '../../utils/Alert/Alert';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { getUserById } from '../../reduxStore/reducer/registerReducer';
import EditIcon from '@mui/icons-material/Edit';

const param: string[] = [];
const adaptiveValuesInitial = {
  discipline: {
    name: '',
    id: '',
  },
  disciplineError: '',
  officialAssessmentName: '',
  officialAssessmentNameError: '',
  publicAssessmentName: '',
  publicAssessmentNameError: '',
  assessmentCode: '',
  assessmentCodeError: '',
  publicCode: '',
  publicCodeError: '',
  key: '',
  keyError: '',
  difficulty: false,
  discrimination: false,
  slip: false,
  guess: false,
  under_development: false,
  guessing: false,
  irtError: '',
  contentModules: param,
  contentModulesError: '',
  learningObjectives: param,
  learningObjectivesError: '',
};
const Assessment = () => {
  const [isTyping, setIsTyping] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  //for changing the value on text field immediately
  const [searchQuery, setSearchQuery] = useState('');
  const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');
  //Delays capturing, changing text on text field
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery);

  const [gridApi, setGridApi] = useState<GridApi>();
  const [columnApi, setColumnApi] = useState<ColumnApi>();
  const formikRef = React.useRef(null);

  const [staticOpen, setStaticOpen] = useState(false);
  const [adaptiveOpen, setAdaptiveOpen] = useState(false);
  const [hybridOpen, setHybridOpen] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [viewData, setViewData] = useState();
  const [editingContentLearning, setEditingContentLearning] = useState({
    contentModule: '',
    learningObject: '',
  });

  const getUserDataAndType: any = useSelector(
    (state: any) => state.register.getUserById
  );
  function PublishedVersionActions(row: any) {
    const publishedData = row?.data?.test_statuses?.find(
      (d: any) => d?.publish == true
    );

    if (publishedData?.publish) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          {(getUserDataAndType.data?.type === 'admin' ||
            (getUserDataAndType.data?.type === 'faculty' &&
              getUserDataAndType.data?.taspauserassessments !== null &&
              getUserDataAndType.data?.taspauserassessments.includes(
                row.data.id
              ))) && (
            <div>
              <b>{publishedData?.version}</b>
              <Button
                size='small'
                variant='contained'
                sx={{
                  fontSize: '12px',
                  height: '29px',
                  alignSelf: 'center',
                  marginLeft: '13px',
                }}
                onClick={() => {
                  dispatch(
                    updatePublishStatus(publishedData, row.api, row.data)
                  );
                }}
              >
                Unpublish
              </Button>
            </div>
          )}

          <IconButton
            onClick={() => {
              const data: any = { ...row.data, question_id: publishedData.id };
              // dispatch(viewAssessment(data, setViewData, setOpenView));
              window.open(`/viewassessment?id=${data.question_id}`);
            }}
          >
            <RemoveRedEyeIcon className='text-edit-icon' />
          </IconButton>
        </div>
      );
    } else if (row?.data?.type === 'adaptive') {
      return (
        <div>
          {getUserDataAndType.data?.type === 'admin' ||
          (getUserDataAndType.data?.type === 'faculty' &&
            getUserDataAndType.data?.taspauserassessments !== null &&
            getUserDataAndType.data?.taspauserassessments.includes(
              row.data.id
            )) ? (
            <Button
              size='small'
              variant='contained'
              sx={{
                fontSize: '12px',
                height: '29px',
                alignSelf: 'center',
                marginLeft: '13px',
              }}
              onClick={() => {
                let data = {
                  id: row.data.id,
                  adaptive_published: !row?.data?.adaptive_published,
                };
                dispatch(updateAdaptivePublishStatus(data, row.api, row.data));
              }}
            >
              {row?.data?.adaptive_published ? 'Unpublish' : 'Publish'}
            </Button>
          ) : null}
        </div>
      );
    } else {
      return <div>None</div>;
    }
  }

  function LatestVersionActions(row: any) {
    const unPublishedData = row?.data?.test_statuses?.findIndex(
      (d: any) => d?.publish == true
    );

    const isAdmin = getUserDataAndType.data?.type === 'admin';
    const isFaculty = getUserDataAndType.data?.type === 'faculty';
    const type = row.data.type;
    const hasTaspaUserAssessments =
      getUserDataAndType.data?.taspauserassessments !== null;
    const includesRowDataId =
      getUserDataAndType.data?.taspauserassessments?.includes(row.data.id);

    const canEditOrView =
      isAdmin || (isFaculty && hasTaspaUserAssessments && includesRowDataId);

    const latestTestStatus =
      row?.data?.test_statuses[row.data.test_statuses.length - 1];
    const latestTestStatusId = latestTestStatus?.id;

    const isPublished = unPublishedData + 1 < row?.data?.test_statuses?.length;

    const assessmentData = {
      state: {
        state: {
          value: {
            id: row.data.id,
            discipline: {
              id: row.data.discipline,
              name: row.data.discipline_name,
            },
            officialAssessmentName: row.data.official_name,
            publicAssessmentName: row.data.public_name,
            assessmentCode: row.data.code,
            publicCode: row.data.public_code,
            minimum_duration: row.data.minimum_duration,
            questionType: row.data.question_label_type,
            optionType: row.data.option_label_type,
            filter: row.data?.filter_question,
            key: row.data.use_key,
            likert: row.data.is_likert,
            reference_image: row.data.reference_image,
            image_description: row.data.image_description,
            under_development: row.data.under_development,
            question: latestTestStatus?.questions || [],
            question_id: latestTestStatusId,
          },
          action: 'Update',
        },
      },
      url: '/question',
    };

    const adaptiveData = {
      id: row.data.id,
      discipline: {
        id: row.data.discipline || '',
        name: row.data.discipline_name || '',
      },
      officialAssessmentName: row.data.official_name || '',
      publicAssessmentName: row.data.public_name || '',
      assessmentCode: row.data.code || '',
      publicCode: row.data.public_code || '',
      instruction: row.data.instruction || '',
      learningObjectives: row.data.learning_objectives || '',
      contentModules: row.data.content_modules || '',
      difficulty: row.data.irt_difficulty,
      discrimination: row.data.irt_discrimination,
      slip: row.data.slip,
      guess: row.data.guess,
      under_development: row.data.under_development,
      guessing: row.data.irt_guessing,
      slipping: row.data.irt_slipping,
      rFile: row.data.r_file,
      reference_image: row.data.reference_image,
      image_description: row.data.image_description,
      assessSpecQuestion:
        row?.data?.specific_questions[0]?.question_label != null
          ? row?.data?.specific_questions
          : [],
      contentModulesTime: row.data.content_modules_time || '',
      contentModulesDescript: row.data.content_modules_descript || '',
      learningObjectivesTime: row.data.learning_objectives_time || '',
      learningObjectivesDescript: row.data.learning_objectives_descript || '',
    };

    return (
      <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        {canEditOrView && type !== 'adaptive' && isPublished && (
          <div>
            {(!getUserDataAndType.data?.taspauserassessments ||
              includesRowDataId) && (
              <b style={{ marginRight: '5px', marginLeft: '5px' }}>
                {latestTestStatus?.version}
              </b>
            )}
          </div>
        )}
        {canEditOrView && type !== 'adaptive' && isPublished && (
          <>
            <IconButton
              onClick={() => {
                row.setIsEdit(true);
                dispatch(getAssessmentQuestion(assessmentData, navigate));
              }}
            >
              <MdEdit className='text-edit-icon' />
            </IconButton>
            <IconButton
              onClick={() => {
                const data: any = {
                  ...row.data,
                  question_id: latestTestStatusId,
                };
                window.open(`/viewassessment?id=${data.question_id}`);
              }}
            >
              <RemoveRedEyeIcon className='text-edit-icon' />
            </IconButton>
          </>
        )}
        {row.data?.is_likert && canEditOrView && isPublished && (
          <Button
            size='small'
            variant='contained'
            sx={{
              fontSize: '12px',
              height: '29px',
              alignSelf: 'center',
              marginLeft: '13px',
            }}
            onClick={() => {
              const data: any = row.data;

              const assessmentData = {
                state: {
                  state: {
                    value: {
                      id: data.id,
                      category: data.category,
                      is_published:
                        row.data.test_statuses[
                          row.data.test_statuses.length - 1
                        ].publish,
                      discipline: {
                        id: data.discipline,
                        name: data.discipline_name,
                      },
                      officialAssessmentName: data.official_name,
                      publicAssessmentName: data.public_name,
                      assessmentCode: data.code,
                      publicCode: data.public_code,
                      minimum_duration: data.minimum_duration,
                      questionType: data.question_label_type,
                      optionType: data.option_label_type,
                      key: data.use_key,
                      filter: data?.filter_question,
                      likert: data.is_likert,
                      question_id:
                        data?.test_statuses[row.data.test_statuses.length - 1]
                          ?.id,
                    },
                  },
                },
                url: '/likert',
              };

              dispatch(getAssessmentQuestion(assessmentData, navigate));
            }}
          >
            Key
          </Button>
        )}
        {type !== 'adaptive' &&
          unPublishedData !== -1 &&
          unPublishedData + 1 === row?.data?.test_statuses?.length &&
          canEditOrView && (
            <Button
              size='small'
              variant='contained'
              sx={{
                marginBottom: '6px',
                alignSelf: 'center',
                fontSize: '12px',
                height: '29px',
              }}
              onClick={() => {
                assessmentData.state.state.action = 'Version';
                dispatch(getAssessmentQuestion(assessmentData, navigate));
              }}
            >
              Create New Version
            </Button>
          )}

        {canEditOrView && (
          <IconButton
            onClick={() => {
              Alert.confirm(() => {
                dispatch(deleteAssessment(row.data, row.api));
              });
            }}
          >
            <DeleteIcon color='error' />
          </IconButton>
        )}

        {row?.data?.type === 'adaptive' && canEditOrView && (
          <IconButton
            style={row?.data?.type === 'adaptive' ? {} : { display: 'none' }}
            onClick={() => {
              navigate('/assessment/adaptive', {
                state: {
                  value: adaptiveData,
                  action: 'Edit',
                },
              });
            }}
          >
            <MdEdit className='text-edit-icon' />
          </IconButton>
        )}
      </div>
    );
  }

  const [transactionDefs, setTransactionDefs] = useState<any>([]);
  useEffect(() => {
    const newTransactionDefs = [
      {
        headerName: 'Type',
        field: 'type',
        minWidth: 150,
        maxWidth: 150,
        cellRenderer: (params: any) => (
          <div>{params?.data?.type === 'adaptive' ? 'Adaptive' : 'Static'}</div>
        ),
      },
      {
        headerName: 'Discipline',
        field: 'discipline_name',
        // resizable: false,
      },
      {
        headerName: 'Official Name',
        field: 'official_name',
        // resizable: false,
      },
      {
        headerName: 'Public Name',
        field: 'public_name',
        // resizable: false,
      },
      {
        headerName: 'Code',
        field: 'code',
        // resizable: false,
      },
      {
        headerName: 'Public Code',
        field: 'public_code',
        // resizable: false,
      },
      {
        headerName: 'Minimum Duration',
        field: 'minimum_duration',
        cellRenderer: (params: any) => (
          <div>
            {params?.data?.minimum_duration
              ? params?.data?.minimum_duration
              : 'None'}
          </div>
        ),
        // resizable: false,
      },
      {
        headerName: 'Preview Assessment',
        field: 'version',
        // resizable: false,

        sortable: false,
        filter: false,
        minWidth: 200,
        maxWidth: 250,
        cellRenderer: PublishedVersionActions,
        cellRendererParams: (params: ICellRendererParams) => ({
          ...params,
          setIsEdit,
        }),
      },
      {
        headerName: 'Action',
        sortable: false,
        // resizable: false,

        filter: false,
        minWidth: 250,
        maxWidth: 300,
        cellRenderer: LatestVersionActions,
        cellRendererParams: (params: ICellRendererParams) => ({
          ...params,
          setIsEdit,
        }),
      },
      {
        headerName: 'Created By',
        field: 'created_by',
        // resizable: false,
      },
      {
        headerName: 'Updated By',
        field: 'updated_by',
        // resizable: false,
      },
      {
        headerName: 'Created At',
        field: 'created_at',
        sort: 'desc',

        // resizable: false,

        filter: 'agDateColumnFilter',
        cellRenderer: (row: ICellRendererParams) => {
          return moment(row.data.created_at).format('MM/DD/YYYY h:mm:ss A');
        },
      },
    ];

    setTransactionDefs(newTransactionDefs);
  }, [getUserDataAndType.data?.type]);

  const [initialValues, setInitialValue] = useState<any>({
    discipline: null,
    officialAssessmentName: '',
    publicAssessmentName: '',
    assessmentCode: '',
    publicCode: '',
    under_development: false,
    questionType: '',
    optionType: '',
    key: '',
    likert: '',
    minimum_duration: '',
    filter: '',
  });

  const [adaptiveInitialValues, setAdaptiveInitialValue] = useState<any>({
    discipline: null,
    officialAssessmentName: '',
    publicAssessmentName: '',
    assessmentCode: '',
    publicCode: '',
    key: '',
    difficulty: false,
    discrimination: false,
    slip: false,
    guess: false,
    under_development: false,
    guessing: false,
    contentModules: [],
    learningObjectives: [],
    // slip: '',
    // guess: '',
  });

  const [adaptiveValues, setAdaptiveValue] = useState({
    discipline: {
      name: '',
      id: '',
    },
    disciplineError: '',
    officialAssessmentName: '',
    officialAssessmentNameError: '',
    publicAssessmentName: '',
    publicAssessmentNameError: '',
    assessmentCode: '',
    assessmentCodeError: '',
    publicCode: '',
    publicCodeError: '',
    key: '',
    keyError: '',
    difficulty: false,
    discrimination: false,
    slip: false,
    guess: false,
    under_development: false,
    guessing: false,
    irtError: '',
    contentModules: param,
    contentModulesError: '',
    learningObjectives: param,
    learningObjectivesError: '',
  });

  const openDialog = (scrollType: DialogProps['scroll']) => {
    setOpen(true);
    setInitialValue({
      discipline: null,
      officialAssessmentName: '',
      publicAssessmentName: '',
      assessmentCode: '',
      publicCode: '',
      under_development: false,
      questionType: '',
      optionType: '',
      key: '',
      likert: '',
      minimum_duration: '',
    });
    setScroll(scrollType);
  };

  const [open, setOpen] = useState(false);
  const handleClickClose = () => {
    setOpen(false);
  };
  const validation = Yup.object({
    discipline: Yup.object({
      id: Yup.string().required('Required Field'),
      name: Yup.string().required('Required Field'),
    }).required('Required Field'),
    officialAssessmentName: Yup.string()
      .matches(/^[^\s].*$/, 'The starting letter should not be a space.')
      .matches(/[^\s]$/, 'Ending letter should not be a space.')
      .required('Required Field'),
    publicAssessmentName: Yup.string()
      .matches(/^[^\s].*$/, 'The starting letter should not be a space.')
      .matches(/[^\s]$/, 'Ending letter should not be a space.')
      .required('Required Field'),
    assessmentCode: Yup.string()
      .matches(/^[^\s].*$/, 'The starting letter should not be a space.')
      .matches(/[^\s]$/, 'Ending letter should not be a space.')
      .required('Required Field'),
    publicCode: Yup.string()
      .matches(/^[^\s].*$/, 'The starting letter should not be a space.')
      .matches(/[^\s]$/, 'Ending letter should not be a space.')
      .required('Required Field'),
    questionType: Yup.string().required('Required Field'),
    optionType: Yup.string().required('Required Field'),
    key: Yup.string().required('Required Field'),
    likert: Yup.string().required('Required Field'),
    minimum_duration: Yup.number().required('Required Field'),
    filter: Yup.string().required('Required Field'),
  });

  const adaptive_validation = Yup.object({
    discipline: Yup.object({
      id: Yup.string().required('Required Field'),
      name: Yup.string().required('Required Field'),
    }).required('Required Field'),
    officialAssessmentName: Yup.string()
      .matches(/^[^\s].*$/, 'The starting letter should not be a space.')
      .matches(/[^\s]$/, 'Ending letter should not be a space.')
      .required('Required Field'),
    publicAssessmentName: Yup.string()
      .matches(/^[^\s].*$/, 'The starting letter should not be a space.')
      .matches(/[^\s]$/, 'Ending letter should not be a space.')
      .required('Required Field'),
    assessmentCode: Yup.string()
      .matches(/^[^\s].*$/, 'The starting letter should not be a space.')
      .matches(/[^\s]$/, 'Ending letter should not be a space.')
      .required('Required Field'),
    publicCode: Yup.string()
      .matches(/^[^\s].*$/, 'The starting letter should not be a space.')
      .matches(/[^\s]$/, 'Ending letter should not be a space.')
      .required('Required Field'),
    // contentModules: Yup.array()
    //   .min(1, 'At least one content module is required')
    //   .required('Required Field'),
    // learningObjectives: Yup.array()
    //   .min(1, 'At least one learning objective is required')
    //   .required('Required Field'),
    // difficulty: Yup.boolean(),
    // discrimination: Yup.boolean(),
    // guessing: Yup.boolean(),
    // Slipping: Yup.boolean(),

    // difficulty: Yup.bool().when(['discrimination', 'guessing'], {
    //   is: (discrimination: boolean, guessing: boolean) => {
    //     if (!discrimination && !guessing) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   },
    //   then: Yup.bool().required('IRT model options Atleast one Required'),
    // }),
    // discrimination: Yup.bool().when(['difficulty', 'guessing'], {
    //   is: (difficulty: boolean, guessing: boolean) => {
    //     if (!difficulty && !guessing) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   },
    //   then: Yup.bool().required('IRT model options Atleast one Required'),
    // }),
    // guessing: Yup.bool().when(['discrimination', 'difficulty'], {
    //   is: (discrimination: boolean, difficulty: boolean) => {
    //     if (!discrimination && !difficulty) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   },
    //   then: Yup.bool().required('IRT model options Atleast one Required'),
    // }),
    // irt: Yup.object().shape(
    //   {
    //     difficulty: Yup.bool().when(['discrimination', 'guessing'], {
    //       is: (discrimination: boolean, guessing: boolean) => {
    //         if (!discrimination && !guessing) {
    //           return true;
    //         } else {
    //           return false;
    //         }
    //       },
    //       then: Yup.bool().required('IRT model options Atleast one Required'),
    //     }),
    //     discrimination: Yup.bool().when(['difficulty', 'guessing'], {
    //       is: (difficulty: boolean, guessing: boolean) => {
    //         if (!difficulty && !guessing) {
    //           return true;
    //         } else {
    //           return false;
    //         }
    //       },
    //       then: Yup.bool().required('IRT model options Atleast one Required'),
    //     }),
    //     guessing: Yup.bool().when(['discrimination', 'difficulty'], {
    //       is: (discrimination: boolean, difficulty: boolean) => {
    //         if (!discrimination && !difficulty) {
    //           return true;
    //         } else {
    //           return false;
    //         }
    //       },
    //       then: Yup.bool().required('IRT model options Atleast one Required'),
    //     }),
    //   },
    //   [
    //     ['discrimination', 'guessing'],
    //     ['difficulty', 'guessing'],
    //     ['discrimination', 'difficulty'],
    //   ]
    // ),

    // irt: Yup.object()
    //   .shape({
    //     // slipping: Yup.boolean(),
    //     difficulty: Yup.boolean(),
    //     discrimination: Yup.boolean(),
    //     guessing: Yup.boolean(),
    //   })
    //   .test(
    //     'at-least-one-true',
    //     'At least one option must be selected for IRT model',
    //     (obj: any) => {
    //
    //       const { difficulty, discrimination, guessing } = obj;
    //       //return  difficulty || discrimination || guessing;
    //       if (difficulty || discrimination || guessing) {
    //         return true;
    //       }

    //       return new Yup.ValidationError(
    //         'Must agree to something', // your custom error message
    //         null,
    //         'myCustomFieldName'
    //       );
    //     }
    //   ),
  });

  useEffect(() => {
    dispatch(
      getUserById({
        id: `${JSON.parse(localStorage.getItem('token') || '{}')}`,
      })
    );

    dispatch(getDisciplineDetails());
    dispatch(getAssessmentDiscipline());
  }, [dispatch]);

  useEffect(() => {
    if (getUserDataAndType?.data?.type === 'faculty')
      setTransactionDefs([
        {
          headerName: 'Discipline',
          field: 'discipline_name',
          // resizable: false,
        },
        {
          headerName: 'Official Name',
          field: 'official_name',
          // resizable: false,
        },
        {
          headerName: 'Code',
          field: 'code',
          // resizable: false,
        },
        {
          headerName: 'Preview Version',
          field: 'version',
          // resizable: false,

          sortable: false,
          filter: false,
          minWidth: 200,
          maxWidth: 250,
          cellRenderer: PublishedVersionActions,
          cellRendererParams: (params: ICellRendererParams) => ({
            ...params,
            setIsEdit,
          }),
        },
        {
          headerName: 'Action',
          sortable: false,
          filter: false,
          minWidth: 200,
          maxWidth: 250,
          cellRenderer: LatestVersionActions,
          cellRendererParams: (params: ICellRendererParams) => ({
            ...params,
            setIsEdit,
          }),
        },
      ]);
  }, [getUserDataAndType.data?.type]);

  const disciplineData: { id: string; name: string }[] = useSelector(
    (state: RootState) => {
      const data = state.assessment.assessmentDiscipline;

      const alterData: any = [...data];
      alterData.unshift({
        id: '',
        name: 'ADD NEW DISCIPLINE',
      });
      return alterData;
    }
  );

  const [openForm, setOpenForm] = useState<any>({
    isOpen: false,
    callback: null,
  });
  useEffect(() => {
    if (adaptiveOpen) {
      //

      if (formikRef?.current) {
        //
        let disciplineAdaptive: any = formikRef?.current;
        let disciplineAdaptiveValues = disciplineAdaptive?.values?.discipline;
        //
        setAdaptiveValue({
          ...adaptiveValues,
          discipline: disciplineAdaptiveValues,
          disciplineError: '',
        });
      }
    }
    //
  }, [openForm]);
  const debounceSearch = debounce((value: string) => {
    setDebouncedSearchQuery(value);
  }, 500);

  const onFilterTextBoxChanged = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchQuery(e.target.value);
    const value = e.target.value;
    debounceSearch(value);
  };

  const textFieldProps2 = {
    value: searchQuery,
    onChange: onFilterTextBoxChanged,
  };
  const buttonProps2 = {
    children: 'Add Assessment',
    onClick: () => openDialog('paper'),
    sm: 'Add',
    sx: getUserDataAndType.data?.type === 'faculty' ? { display: 'none' } : {},
  };
  const handleSubmit = () => {
    if (formikRef.current) {
      //@ts-ignore
      formikRef.current.submitForm();
    }
  };

  const assessments = [
    { label: 'Static Assessment', value: 'static_assessment' },
    { label: 'Adaptive Assessment', value: 'adaptive_assessment' },
  ];

  return (
    <div>
      <header className='header'>Assessments</header>
      <GridHeader2
        textFieldProps={textFieldProps2}
        buttonProps={buttonProps2}
      />

      <ServerSideGrid
        rowDataUrl='/assessment/'
        debouncedSearchQuery={searchQuery}
        columnDefs={transactionDefs}
        gridApi={gridApi}
        columnApi={columnApi}
        setGridApi={setGridApi}
        setColumnApi={setColumnApi}
      />
      {/* Assessment Choose Form */}
      <Dialog open={open} style={{ zIndex: +10 }}>
        <DialogTitle sx={{ backgroundColor: '#1663ab', color: 'white' }}>
          <div className='flex justify-between items-center'>
            <div>Assessment</div>
            <div>
              <CloseIcon onClick={handleClickClose} />
            </div>
          </div>
        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <div>
            <Grid container justifyContent='center' spacing={6}>
              {assessments.map((value, i) => (
                <Grid key={value.value} item>
                  <Paper
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: 200,
                      width: 180,
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      //
                      switch (assessments[i].value) {
                        case 'static_assessment':
                          setStaticOpen(true);
                          break;
                        case 'adaptive_assessment':
                          setAdaptiveValue(adaptiveValuesInitial);
                          setAdaptiveOpen(true);
                          break;
                        case 'hybrid_assessment':
                          setHybridOpen(true);
                          break;
                        default:
                          break;
                      }
                    }}
                  >
                    <b>{value.label}</b>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <div className='flex gap-3 justify-end'>
            <Button
              variant='contained'
              // fullWidth
              onClick={handleClickClose}
              style={{ textTransform: 'capitalize' }}
            >
              Cancel
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      {/* Static Assessment */}
      <Dialog open={staticOpen} style={{ zIndex: +10 }}>
        <DialogTitle sx={{ backgroundColor: '#1663ab', color: 'white' }}>
          <div className='flex justify-between items-center'>
            <div>Creates New Static Assessment</div>
            <div>
              <CloseIcon
                onClick={() => {
                  Alert.confirm(() => {
                    setStaticOpen(false);
                  });
                }}
              />
            </div>
          </div>
        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <div>
            <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              validationSchema={validation}
              onSubmit={(values, { setSubmitting }) => {
                navigate('/question', {
                  state: {
                    value: values,
                    action: 'Create',
                  },
                });

                setInitialValue(values);
                setSubmitting(false);
                setIsTyping(false);
              }}
            >
              {({
                values,
                errors,
                touched,
                isValid,
                setFieldValue,
                isSubmitting,
                handleBlur,
                handleChange,
              }) => (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <Field
                        name='discipline'
                        as={Autocomplete}
                        value={values?.discipline}
                        size='small'
                        options={disciplineData}
                        getOptionLabel={(option: any) => option?.name || ''}
                        isOptionEqualToValue={(option: any, current: any) =>
                          option.id === current.id
                        }
                        fullWidth
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        onChange={(
                          event: React.SyntheticEvent,
                          discipline: any
                        ) => {
                          if (discipline?.name == 'ADD NEW DISCIPLINE') {
                            setOpenForm({
                              ...openForm,
                              ...{ isOpen: true, callback: setFieldValue },
                            });
                            setFieldValue('discipline', {
                              name: '',
                              id: '',
                            });
                          } else {
                            setFieldValue('discipline', {
                              name: discipline?.name,
                              id: discipline?.id,
                            });
                          }
                        }}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            label='Discipline'
                            variant='standard'
                            placeholder='Select'
                            error={
                              errors.discipline && touched.discipline
                                ? true
                                : false
                            }
                            helperText={
                              errors.discipline && touched.discipline
                                ? 'Required Field'
                                : ''
                            }
                            required
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Field
                        as={TextField}
                        label='Official Assessment Name:'
                        variant='standard'
                        required
                        name='officialAssessmentName'
                        value={values.officialAssessmentName}
                        helperText={
                          errors.officialAssessmentName &&
                          touched.officialAssessmentName
                            ? errors.officialAssessmentName
                            : ''
                        }
                        fullWidth
                        error={
                          errors.officialAssessmentName &&
                          touched.officialAssessmentName
                        }
                        sx={{ marginBottom: '15px' }}
                        onChange={(e: any) => {
                          handleChange(e);
                          setFieldValue(
                            'officialAssessmentName',
                            e.target.value,
                            true
                          ); // force validation
                          setIsTyping(true);
                        }}
                        onBlur={(e: any) => {
                          handleBlur(e);
                          setIsTyping(false);
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              {isTyping &&
                                (errors.officialAssessmentName ? (
                                  <span style={{ color: 'red' }}>✖</span>
                                ) : (
                                  values.officialAssessmentName && (
                                    <span style={{ color: 'green' }}>✔</span>
                                  )
                                ))}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Field
                        variant='standard'
                        as={TextField}
                        label='Public View Assessment Name:'
                        required
                        name='publicAssessmentName'
                        value={values.publicAssessmentName}
                        helperText={
                          errors.publicAssessmentName &&
                          touched.publicAssessmentName
                            ? errors.publicAssessmentName
                            : ''
                        }
                        fullWidth
                        error={
                          errors.publicAssessmentName &&
                          touched.publicAssessmentName
                        }
                        sx={{ marginBottom: '15px' }}
                        onChange={(e: any) => {
                          handleChange(e);
                          setFieldValue(
                            'publicAssessmentName',
                            e.target.value,
                            true
                          ); // force validation
                          setIsTyping(true);
                        }}
                        onBlur={(e: any) => {
                          handleBlur(e);
                          setIsTyping(false);
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              {isTyping &&
                                (errors.publicAssessmentName ? (
                                  <span style={{ color: 'red' }}>✖</span>
                                ) : (
                                  values.publicAssessmentName && (
                                    <span style={{ color: 'green' }}>✔</span>
                                  )
                                ))}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Field
                        variant='standard'
                        as={TextField}
                        label='Assessment Code:'
                        required
                        name='assessmentCode'
                        value={values.assessmentCode}
                        helperText={
                          errors.assessmentCode && touched.assessmentCode
                            ? errors.assessmentCode
                            : ''
                        }
                        fullWidth
                        error={errors.assessmentCode && touched.assessmentCode}
                        sx={{ marginBottom: '15px' }}
                        onChange={(e: any) => {
                          handleChange(e);
                          setFieldValue('assessmentCode', e.target.value, true); // force validation
                          setIsTyping(true);
                        }}
                        onBlur={(e: any) => {
                          handleBlur(e);
                          setIsTyping(false);
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              {isTyping &&
                                (errors.assessmentCode ? (
                                  <span style={{ color: 'red' }}>✖</span>
                                ) : (
                                  values.assessmentCode && (
                                    <span style={{ color: 'green' }}>✔</span>
                                  )
                                ))}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Field
                        variant='standard'
                        as={TextField}
                        label='Public Code:'
                        required
                        name='publicCode'
                        value={values.publicCode}
                        helperText={
                          errors.publicCode && touched.publicCode
                            ? errors.publicCode
                            : ''
                        }
                        fullWidth
                        error={errors.publicCode && touched.publicCode}
                        sx={{ marginBottom: '15px' }}
                        onChange={(e: any) => {
                          handleChange(e);
                          setFieldValue('publicCode', e.target.value, true); // force validation
                          setIsTyping(true);
                        }}
                        onBlur={(e: any) => {
                          handleBlur(e);
                          setIsTyping(false);
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              {isTyping &&
                                (errors.publicCode ? (
                                  <span style={{ color: 'red' }}>✖</span>
                                ) : (
                                  values.publicCode && (
                                    <span style={{ color: 'green' }}>✔</span>
                                  )
                                ))}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Field
                        variant='standard'
                        as={TextField}
                        label='Minimum Duration(In Minutes)'
                        required
                        name='minimum_duration'
                        value={values.minimum_duration}
                        helperText={
                          errors.minimum_duration && touched.minimum_duration
                            ? errors.minimum_duration
                            : ''
                        }
                        fullWidth
                        error={
                          errors.minimum_duration && touched.minimum_duration
                        }
                        sx={{ marginBottom: '15px' }}
                        onChange={(e: any) => {
                          handleChange(e);
                          setFieldValue(
                            'minimum_duration',
                            e.target.value,
                            true
                          ); // force validation
                          setIsTyping(true);
                        }}
                        onBlur={(e: any) => {
                          handleBlur(e);
                          setIsTyping(false);
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              {isTyping &&
                                (errors.minimum_duration ? (
                                  <span style={{ color: 'red' }}>✖</span>
                                ) : (
                                  values.minimum_duration && (
                                    <span style={{ color: 'green' }}>✔</span>
                                  )
                                ))}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <FormControl
                        fullWidth
                        variant='standard'
                        sx={{ my: 1 }}
                        error={
                          errors?.questionType && touched?.questionType
                            ? true
                            : false
                        }
                      >
                        <InputLabel>Question Label Type:</InputLabel>
                        <Field as={Select} name='questionType'>
                          <MenuItem value='Custom'>Custom</MenuItem>
                          <MenuItem value='1,2,3'>1,2,3</MenuItem>
                        </Field>
                        <ErrorMessage
                          name='questionType'
                          component='span'
                          className='text-red-600 ml-2 text-sm'
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <FormControl
                        fullWidth
                        variant='standard'
                        sx={{ my: 1 }}
                        error={
                          errors?.optionType && touched?.optionType
                            ? true
                            : false
                        }
                      >
                        <InputLabel>Option Label Type:</InputLabel>
                        <Field as={Select} name='optionType'>
                          <MenuItem value='Custom'>Custom</MenuItem>
                          <MenuItem value='a,b,c'>a,b,c</MenuItem>
                          <MenuItem value='1,2,3'>1,2,3</MenuItem>
                        </Field>
                        <ErrorMessage
                          name='optionType'
                          component='span'
                          className='text-red-600 ml-2 text-sm'
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <FormControl
                        fullWidth
                        variant='standard'
                        sx={{ my: 1 }}
                        error={errors?.key && touched?.key ? true : false}
                      >
                        <InputLabel>Assessment uses key?:</InputLabel>
                        <Field as={Select} name='key'>
                          <MenuItem value='true'>Yes</MenuItem>
                          <MenuItem value='false'>No</MenuItem>
                        </Field>
                        <ErrorMessage
                          name='key'
                          component='span'
                          className='text-red-600 ml-2 text-sm'
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <FormControl
                        fullWidth
                        variant='standard'
                        sx={{ my: 1 }}
                        error={errors?.likert && touched?.likert ? true : false}
                      >
                        <InputLabel>Is Likert?:</InputLabel>
                        <Field as={Select} name='likert'>
                          <MenuItem value='true'>Yes</MenuItem>
                          <MenuItem value='false'>No</MenuItem>
                        </Field>
                        <ErrorMessage
                          name='likert'
                          component='span'
                          className='text-red-600 ml-2 text-sm'
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <FormControl
                        fullWidth
                        variant='standard'
                        sx={{ my: 1 }}
                        error={errors?.filter && touched?.filter ? true : false}
                      >
                        <InputLabel>Uses filter question ?:</InputLabel>
                        <Field as={Select} name='filter'>
                          <MenuItem value='true'>Yes</MenuItem>
                          <MenuItem value='false'>No</MenuItem>
                        </Field>
                        <ErrorMessage
                          name='likert'
                          component='span'
                          className='text-red-600 ml-2 text-sm'
                        />
                      </FormControl>
                    </Grid>

                    <Grid item style={{ marginBottom: '10px' }}>
                      <label className='cursor-pointer'>
                        <Field
                          as={Checkbox}
                          sx={{ marginLeft: '-10px' }}
                          name='under_development'
                          checked={values?.under_development}
                          onChange={(event: any) => {
                            const isChecked = event.target.checked;

                            setFieldValue('under_development', isChecked);
                          }}
                          error={errors.checkboxes && touched.checkboxes}
                        />
                        <span className='ml-1'>Under Development</span>
                      </label>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </div>
        </DialogContent>
        <DialogActions>
          <div className='flex gap-3 justify-end'>
            <Button
              variant='contained'
              // fullWidth
              onClick={() => {
                Alert.confirm(() => {
                  setStaticOpen(false);
                });
              }}
              style={{ textTransform: 'capitalize' }}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              type='submit'
              // fullWidth
              onClick={handleSubmit}
              style={{ textTransform: 'capitalize' }}
            >
              Save
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      {/* Adaptive Assessment */}
      <Dialog open={adaptiveOpen} style={{ zIndex: +10 }}>
        <DialogTitle sx={{ backgroundColor: '#1663ab', color: 'white' }}>
          <div className='flex justify-between items-center'>
            <div>Creates New Adaptive Assessment</div>
            <div>
              <CloseIcon
                onClick={() => {
                  Alert.confirm(() => {
                    setAdaptiveValue(adaptiveValuesInitial);
                    setAdaptiveOpen(false);
                  });
                }}
              />
            </div>
          </div>
        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <div>
            <Formik
              innerRef={formikRef}
              initialValues={adaptiveInitialValues}
              validationSchema={adaptive_validation}
              onSubmit={(values, { setSubmitting }) => {
                //
                setAdaptiveValue((e: any) => ({
                  ...e,
                  ['officialAssessmentName']: values.officialAssessmentName,
                  ['publicAssessmentName']: values.publicAssessmentName,
                  ['assessmentCode']: values.assessmentCode,
                  ['publicCode']: values.publicCode,
                  ['discipline']: values.discipline,
                }));

                dispatch(
                  addAdaptiveAssessment(
                    {
                      ...adaptiveValues,
                      ['officialAssessmentName']: values.officialAssessmentName,
                      ['publicAssessmentName']: values.publicAssessmentName,
                      ['assessmentCode']: values.assessmentCode,
                      ['publicCode']: values.publicCode,
                      ['discipline']: values.discipline,
                    },
                    navigate
                  )
                );
                //   if (
                //     // adaptiveValues.disciplineError === '' &&
                //     // adaptiveValues.discipline.name !== '' &&
                //     // adaptiveValues.officialAssessmentNameError === '' &&
                //     // adaptiveValues.officialAssessmentName !== '' &&
                //     // adaptiveValues.publicAssessmentNameError === '' &&
                //     // adaptiveValues.publicAssessmentName !== '' &&
                //     // adaptiveValues.assessmentCodeError === '' &&
                //     // adaptiveValues.assessmentCode !== '' &&
                //     // adaptiveValues.publicCodeError === '' &&
                //     // adaptiveValues.publicCode !== '' &&
                //     adaptiveValues.contentModulesError === '' &&
                //     adaptiveValues.contentModules.length > 0 &&
                //     adaptiveValues.learningObjectivesError === '' &&
                //     adaptiveValues.learningObjectives.length > 0 &&
                //     (adaptiveValues.guessing ||
                //       adaptiveValues.difficulty ||
                //       adaptiveValues.discrimination)
                //   ) {
                //     setAdaptiveValue((e: any) => ({
                //       ...e,
                //       ['officialAssessmentName']: values.officialAssessmentName,
                //       ['publicAssessmentName']: values.publicAssessmentName,
                //       ['assessmentCode']: values.assessmentCode,
                //       ['publicCode']: values.publicCode,
                //       ['discipline']: values.discipline,
                //     }));

                //     dispatch(
                //       addAdaptiveAssessment(
                //         {
                //           ...adaptiveValues,
                //           ['officialAssessmentName']:
                //             values.officialAssessmentName,
                //           ['publicAssessmentName']: values.publicAssessmentName,
                //           ['assessmentCode']: values.assessmentCode,
                //           ['publicCode']: values.publicCode,
                //           ['discipline']: values.discipline,
                //         },
                //         navigate
                //       )
                //     );
                //   } else {
                //
                //     setAdaptiveValue({
                //       ...adaptiveValues,
                //       // disciplineError: !adaptiveValues.discipline.id
                //       //   ? 'Required Field'
                //       //   : '',
                //       // publicAssessmentNameError:
                //       //   !adaptiveValues.publicAssessmentName
                //       //     ? 'Required Field'
                //       //     : '',
                //       // officialAssessmentNameError:
                //       //   !adaptiveValues.officialAssessmentName
                //       //     ? 'Required Field'
                //       //     : '',
                //       // assessmentCodeError: !adaptiveValues.assessmentCode
                //       //   ? 'Required Field'
                //       //   : '',
                //       // publicCodeError: !adaptiveValues.publicCode
                //       //   ? 'Required Field'
                //       //   : '',

                //       contentModulesError:
                //         adaptiveValues.contentModules.length > 0
                //           ? ''
                //           : 'At least one content modules is required',
                //       learningObjectivesError:
                //         adaptiveValues.learningObjectives.length > 0
                //           ? ''
                //           : 'At least one learning objectives is required',
                //     });
                //   }
                setSubmitting(false);
                setIsTyping(false);
              }}
            >
              {({
                values,
                errors,
                touched,
                isValid,
                setFieldValue,
                setFieldTouched,
                handleBlur,
                handleChange,
              }) => (
                <Form noValidate>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <Field
                        name='discipline'
                        as={Autocomplete}
                        value={values?.discipline}
                        size='small'
                        options={disciplineData}
                        getOptionLabel={(option: any) => option?.name || ''}
                        isOptionEqualToValue={(option: any, current: any) =>
                          option.id === current.id
                        }
                        fullWidth
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        onChange={(
                          event: React.SyntheticEvent,
                          discipline: any
                        ) => {
                          if (discipline?.name == 'ADD NEW DISCIPLINE') {
                            setOpenForm({
                              ...openForm,
                              ...{ isOpen: true, callback: setFieldValue },
                            });
                            setFieldValue('discipline', {
                              name: '',
                              id: '',
                            });
                          } else {
                            setFieldValue('discipline', {
                              name: discipline?.name,
                              id: discipline?.id,
                            });
                          }
                        }}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            label='Discipline'
                            variant='standard'
                            placeholder='Select'
                            error={
                              errors.discipline && touched.discipline
                                ? true
                                : false
                            }
                            helperText={
                              errors.discipline && touched.discipline
                                ? 'Required Field'
                                : ''
                            }
                            required
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Field
                        as={TextField}
                        label='Official Assessment Name:'
                        variant='standard'
                        required
                        name='officialAssessmentName'
                        value={values.officialAssessmentName}
                        helperText={
                          errors.officialAssessmentName &&
                          touched.officialAssessmentName
                            ? errors.officialAssessmentName
                            : ''
                        }
                        fullWidth
                        error={
                          errors.officialAssessmentName &&
                          touched.officialAssessmentName
                        }
                        sx={{ marginBottom: '15px' }}
                        onChange={(e: any) => {
                          handleChange(e);
                          setFieldValue(
                            'officialAssessmentName',
                            e.target.value,
                            true
                          ); // force validation
                          setIsTyping(true);
                        }}
                        onBlur={(e: any) => {
                          handleBlur(e);
                          setIsTyping(false);
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              {isTyping &&
                                (errors.officialAssessmentName ? (
                                  <span style={{ color: 'red' }}>✖</span>
                                ) : (
                                  values.officialAssessmentName && (
                                    <span style={{ color: 'green' }}>✔</span>
                                  )
                                ))}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Field
                        variant='standard'
                        as={TextField}
                        label='Public View Assessment Name:'
                        required
                        name='publicAssessmentName'
                        value={values.publicAssessmentName}
                        helperText={
                          errors.publicAssessmentName &&
                          touched.publicAssessmentName
                            ? errors.publicAssessmentName
                            : ''
                        }
                        fullWidth
                        error={
                          errors.publicAssessmentName &&
                          touched.publicAssessmentName
                        }
                        sx={{ marginBottom: '15px' }}
                        onChange={(e: any) => {
                          handleChange(e);
                          setFieldValue(
                            'publicAssessmentName',
                            e.target.value,
                            true
                          ); // force validation
                          setIsTyping(true);
                        }}
                        onBlur={(e: any) => {
                          handleBlur(e);
                          setIsTyping(false);
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              {isTyping &&
                                (errors.publicAssessmentName ? (
                                  <span style={{ color: 'red' }}>✖</span>
                                ) : (
                                  values.publicAssessmentName && (
                                    <span style={{ color: 'green' }}>✔</span>
                                  )
                                ))}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Field
                        variant='standard'
                        as={TextField}
                        label='Assessment Code:'
                        required
                        name='assessmentCode'
                        value={values.assessmentCode}
                        helperText={
                          errors.assessmentCode && touched.assessmentCode
                            ? errors.assessmentCode
                            : ''
                        }
                        fullWidth
                        error={errors.assessmentCode && touched.assessmentCode}
                        sx={{ marginBottom: '15px' }}
                        onChange={(e: any) => {
                          handleChange(e);
                          setFieldValue('assessmentCode', e.target.value, true); // force validation
                          setIsTyping(true);
                        }}
                        onBlur={(e: any) => {
                          handleBlur(e);
                          setIsTyping(false);
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              {isTyping &&
                                (errors.assessmentCode ? (
                                  <span style={{ color: 'red' }}>✖</span>
                                ) : (
                                  values.assessmentCode && (
                                    <span style={{ color: 'green' }}>✔</span>
                                  )
                                ))}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Field
                        as={TextField}
                        label='Public Code :'
                        variant='standard'
                        required
                        name='publicCode'
                        value={values.publicCode}
                        helperText={
                          errors.publicCode && touched.publicCode
                            ? errors.publicCode
                            : ''
                        }
                        fullWidth
                        error={errors.publicCode && touched.publicCode}
                        sx={{ marginBottom: '15px' }}
                        onChange={(e: any) => {
                          handleChange(e);
                          setFieldValue('publicCode', e.target.value, true); // force validation
                          setIsTyping(true);
                        }}
                        onBlur={(e: any) => {
                          handleBlur(e);
                          setIsTyping(false);
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              {isTyping &&
                                (errors.publicCode ? (
                                  <span style={{ color: 'red' }}>✖</span>
                                ) : (
                                  values.publicCode && (
                                    <span style={{ color: 'green' }}>✔</span>
                                  )
                                ))}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      IRT model options
                      <Grid item>
                        <label className='cursor-pointer'>
                          <Field
                            as={Checkbox}
                            sx={{ marginLeft: '-10px' }}
                            name='difficulty'
                            checked={adaptiveValues?.difficulty}
                            onChange={(e: any) => {
                              setAdaptiveValue({
                                ...adaptiveValues,
                                difficulty: e.target.checked,
                              });
                            }}
                          />
                          <span className='ml-1'>Difficulty</span>
                        </label>
                      </Grid>
                      <Grid item>
                        <label className='cursor-pointer'>
                          <Field
                            as={Checkbox}
                            sx={{ marginLeft: '-10px' }}
                            name='discrimination'
                            checked={adaptiveValues?.discrimination}
                            onChange={(e: any) => {
                              setAdaptiveValue({
                                ...adaptiveValues,
                                discrimination: e.target.checked,
                              });
                            }}
                          />
                          <span className='ml-1'>Discrimination</span>
                        </label>
                      </Grid>
                      <Grid item>
                        <label className='cursor-pointer'>
                          <Field
                            as={Checkbox}
                            sx={{ marginLeft: '-10px' }}
                            name='guessing'
                            checked={adaptiveValues?.guessing}
                            onChange={(e: any) => {
                              setAdaptiveValue({
                                ...adaptiveValues,
                                guessing: e.target.checked,
                              });
                            }}
                          />
                          <span className='ml-1'>Guessing</span>
                        </label>
                      </Grid>
                      DINA
                      <Grid item>
                        <label className='cursor-pointer'>
                          <Field
                            as={Checkbox}
                            sx={{ marginLeft: '-10px' }}
                            name='slip'
                            checked={adaptiveValues?.slip}
                            onChange={(e: any) => {
                              setAdaptiveValue({
                                ...adaptiveValues,
                                slip: e.target.checked,
                              });
                            }}
                          />
                          <span className='ml-1'>Slip</span>
                        </label>
                      </Grid>
                      <Grid item>
                        <label className='cursor-pointer'>
                          <Field
                            as={Checkbox}
                            sx={{ marginLeft: '-10px' }}
                            name='guess'
                            checked={adaptiveValues?.guess}
                            onChange={(e: any) => {
                              setAdaptiveValue({
                                ...adaptiveValues,
                                guess: e.target.checked,
                              });
                            }}
                          />
                          <span className='ml-1'>Guess</span>
                        </label>
                      </Grid>
                      {/* <Grid item>
                        {!adaptiveValues.guessing &&
                          !adaptiveValues.difficulty &&
                          !adaptiveValues.discrimination && (
                            <FormHelperText
                              error={
                                !adaptiveValues.guessing &&
                                !adaptiveValues.difficulty &&
                                !adaptiveValues.discrimination
                                  ? true
                                  : false
                              }
                            >
                              <>At least select one IRT</>
                            </FormHelperText>
                          )}
                      </Grid> */}
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <FormControl fullWidth sx={{ m: 1 }} variant='standard'>
                        <InputLabel htmlFor='standard-adornment-password'>
                          {!values.contentModule && 'Content Modules'}
                        </InputLabel>

                        <Field
                          as={Input}
                          name='contentModule'
                          placeholder='Content Modules'
                          id='standard-adornment-password'
                          type='text'
                          // onCopy={(e: any) => e.preventDefault()}
                          // onCopy={(e: any) => {
                          //   e.preventDefault();
                          //   alert('Copying the password is not allowed.');
                          // }}

                          helperText={
                            adaptiveValues.contentModules.length <= 0 &&
                            adaptiveValues.contentModulesError
                              ? adaptiveValues.contentModulesError
                              : ''
                          }
                          fullWidth
                          error={
                            adaptiveValues.contentModules.length <= 0 &&
                            adaptiveValues.contentModulesError
                          }
                          endAdornment={
                            <InputAdornment position='end'>
                              <IconButton
                                aria-label='toggle password visibility'
                                onClick={() => {
                                  var inValid = /\s/;
                                  var k = inValid.test(values.contentModule);
                                  if (k) {
                                    return Alert.info({
                                      title:
                                        'Content Module should not be allowed space.',
                                      text: '',
                                    });
                                  }

                                  if (
                                    !values.contentModule ||
                                    !String(values.contentModule).trim()
                                  ) {
                                    return Alert.info({
                                      title:
                                        'Content Module should not be empty',
                                      text: '',
                                    });
                                  }
                                  //

                                  if (
                                    adaptiveValues?.contentModules?.length > 0
                                  ) {
                                    let data = adaptiveValues?.contentModules;
                                    if (
                                      data.find(
                                        (e: any) => e === values.contentModule
                                      )
                                    ) {
                                      return Alert.info({
                                        title: 'Content Module already exists',
                                        text: '',
                                      });
                                    }
                                    // setFieldValue('contentModules', [
                                    //   ...values.contentModules,
                                    //   values.contentModule,
                                    // ]);

                                    setAdaptiveValue({
                                      ...adaptiveValues,
                                      contentModules: [
                                        ...data,
                                        values.contentModule,
                                      ],
                                      contentModulesError: '',
                                    });
                                  } else {
                                    values.contentModules = [];
                                    // setFieldValue('contentModules', [
                                    //   values.contentModule,
                                    // ]);
                                    setAdaptiveValue({
                                      ...adaptiveValues,
                                      contentModules: [values.contentModule],
                                      contentModulesError: '',
                                    });
                                  }
                                  setFieldValue('contentModule', '');
                                  setEditingContentLearning({
                                    ...editingContentLearning,
                                    contentModule: '',
                                  });
                                }}
                              >
                                <AddCircleOutlineRoundedIcon />
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        <FormHelperText
                          error={
                            adaptiveValues.contentModules.length <= 0 &&
                            adaptiveValues.contentModulesError
                              ? true
                              : false
                          }
                        >
                          <>{adaptiveValues.contentModulesError}</>
                        </FormHelperText>
                      </FormControl>
                      <List>
                        {adaptiveValues?.contentModules
                          ? adaptiveValues.contentModules.map(
                              (option: any, index: any) => (
                                <ListItem key={index}>
                                  <ListItemText primary={option} />
                                  <div>
                                    <IconButton>
                                      <EditIcon
                                        fontSize='small'
                                        color='primary'
                                        onClick={() => {
                                          let editContentModule =
                                            adaptiveValues.contentModules.find(
                                              (item: string) =>
                                                item ===
                                                adaptiveValues?.contentModules[
                                                  index
                                                ]
                                            );

                                          setFieldValue(
                                            'contentModule',
                                            editContentModule
                                          );
                                          let removeContentModules =
                                            adaptiveValues.contentModules.filter(
                                              (item: string) =>
                                                item !==
                                                adaptiveValues.contentModules[
                                                  index
                                                ]
                                            );
                                          if (
                                            editingContentLearning.contentModule
                                          ) {
                                            removeContentModules.push(
                                              editingContentLearning.contentModule
                                            );
                                          }
                                          setEditingContentLearning({
                                            ...editingContentLearning,
                                            contentModule:
                                              editContentModule || '',
                                          });
                                          setAdaptiveValue({
                                            ...adaptiveValues,
                                            contentModules:
                                              removeContentModules,
                                            contentModulesError: '',
                                          });
                                        }}
                                      />
                                    </IconButton>
                                    <IconButton>
                                      <DeleteIcon
                                        fontSize='small'
                                        color='error'
                                        onClick={() => {
                                          let removeContentModules =
                                            adaptiveValues.contentModules.filter(
                                              (item: string) =>
                                                item !==
                                                adaptiveValues.contentModules[
                                                  index
                                                ]
                                            );

                                          // setFieldValue(
                                          //   'learningObjectives',
                                          //   removeContentModules
                                          // );
                                          setAdaptiveValue({
                                            ...adaptiveValues,
                                            contentModules:
                                              removeContentModules,
                                            contentModulesError: '',
                                          });
                                        }}
                                      />
                                    </IconButton>
                                  </div>
                                </ListItem>
                              )
                            )
                          : ''}
                      </List>
                      <ErrorMessage
                        name='newPassword'
                        component='span'
                        className='text-red-600 ml-2 text-sm'
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <FormControl fullWidth sx={{ m: 1 }} variant='standard'>
                        <InputLabel htmlFor='standard-adornment-password'>
                          {!values.learningObjective && 'Learning Objectives'}
                        </InputLabel>
                        <Field
                          as={Input}
                          name='learningObjective'
                          placeholder='Learning Objective'
                          id='standard-adornment-password'
                          type='text'
                          helperText={
                            adaptiveValues.learningObjectives.length <= 0 &&
                            adaptiveValues.learningObjectivesError
                              ? adaptiveValues.learningObjectivesError
                              : ''
                          }
                          fullWidth
                          error={
                            adaptiveValues.learningObjectives.length <= 0 &&
                            adaptiveValues.learningObjectivesError
                          }
                          endAdornment={
                            <InputAdornment position='end'>
                              <IconButton
                                aria-label='toggle password visibility'
                                onClick={() => {
                                  var inValid = /\s/;
                                  var k = inValid.test(
                                    values.learningObjective
                                  );
                                  if (k) {
                                    return Alert.info({
                                      title:
                                        'Learning Objective should not be allowed space.',
                                      text: '',
                                    });
                                  }
                                  if (
                                    !values.learningObjective ||
                                    !String(values.learningObjective).trim()
                                  ) {
                                    return Alert.info({
                                      title:
                                        'Learning Objective should not be empty',
                                      text: '',
                                    });
                                  }
                                  let data = adaptiveValues?.learningObjectives;
                                  if (data.length > 0) {
                                    if (
                                      data.find(
                                        (e: any) =>
                                          e === values.learningObjective
                                      )
                                    ) {
                                      return Alert.info({
                                        title:
                                          'Learning Objective already exists',
                                        text: '',
                                      });
                                    }

                                    setAdaptiveValue({
                                      ...adaptiveValues,
                                      learningObjectives: [
                                        ...data,
                                        values.learningObjective,
                                      ],
                                      learningObjectivesError: '',
                                    });
                                  } else {
                                    values.learningObjectives = [];
                                    // setFieldValue('learningObjectives', [
                                    //   values.learningObjective,
                                    // ]);
                                    setAdaptiveValue({
                                      ...adaptiveValues,
                                      learningObjectives: [
                                        values.learningObjective,
                                      ],
                                      learningObjectivesError: '',
                                    });
                                  }
                                  setFieldValue('learningObjective', '');
                                  setEditingContentLearning({
                                    ...editingContentLearning,
                                    learningObject: '',
                                  });
                                }}
                              >
                                <AddCircleOutlineRoundedIcon />
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        <FormHelperText
                          error={
                            adaptiveValues.learningObjectives.length <= 0 &&
                            adaptiveValues.learningObjectivesError
                              ? true
                              : false
                          }
                        >
                          <>{adaptiveValues.learningObjectivesError}</>
                        </FormHelperText>
                      </FormControl>
                      <List>
                        {adaptiveValues?.learningObjectives
                          ? adaptiveValues.learningObjectives.map(
                              (option: any, index: any) => (
                                <ListItem key={index}>
                                  <ListItemText primary={option} />
                                  <div>
                                    <IconButton>
                                      <EditIcon
                                        fontSize='small'
                                        color='primary'
                                        onClick={() => {
                                          let editLearningObjective =
                                            adaptiveValues.learningObjectives.find(
                                              (item: string) =>
                                                item ===
                                                adaptiveValues
                                                  ?.learningObjectives[index]
                                            );

                                          setFieldValue(
                                            'learningObjective',
                                            editLearningObjective
                                          );
                                          let removeLearningObjectives =
                                            adaptiveValues.learningObjectives.filter(
                                              (item: string) =>
                                                item !==
                                                adaptiveValues
                                                  .learningObjectives[index]
                                            );
                                          if (
                                            editingContentLearning.learningObject
                                          ) {
                                            removeLearningObjectives.push(
                                              editingContentLearning.learningObject
                                            );
                                          }
                                          setEditingContentLearning({
                                            ...editingContentLearning,
                                            learningObject:
                                              editLearningObjective || '',
                                          });
                                          setAdaptiveValue({
                                            ...adaptiveValues,
                                            learningObjectives:
                                              removeLearningObjectives,
                                            learningObjectivesError: '',
                                          });
                                        }}
                                      />
                                    </IconButton>
                                    <IconButton>
                                      <DeleteIcon
                                        fontSize='small'
                                        color='error'
                                        onClick={() => {
                                          let removeLearningObjectives =
                                            adaptiveValues.learningObjectives.filter(
                                              (item: string) =>
                                                item !==
                                                adaptiveValues
                                                  .learningObjectives[index]
                                            );

                                          // setFieldValue(
                                          //   'learningObjectives',
                                          //   removeLearningObjectives
                                          // );
                                          setAdaptiveValue({
                                            ...adaptiveValues,
                                            learningObjectives:
                                              removeLearningObjectives,
                                            learningObjectivesError: '',
                                          });
                                        }}
                                      />
                                    </IconButton>
                                  </div>
                                </ListItem>
                              )
                            )
                          : ''}
                      </List>
                      <ErrorMessage
                        name='newPassword'
                        component='span'
                        className='text-red-600 ml-2 text-sm'
                      />
                    </Grid>
                    <Grid item>
                      <label className='cursor-pointer'>
                        <Field
                          as={Checkbox}
                          sx={{ marginLeft: '-10px' }}
                          name='under_development'
                          checked={adaptiveValues?.under_development}
                          onChange={(e: any) => {
                            setAdaptiveValue({
                              ...adaptiveValues,
                              under_development: e.target.checked,
                            });
                          }}
                        />
                        <span className='ml-1'>Under Development</span>
                      </label>
                    </Grid>
                  </Grid>
                  {/* <br></br>
                              <br></br>
                              values
                              <pre>{JSON.stringify(values, null, 2)}</pre>
                              errors
                              <pre>{JSON.stringify(errors, null, 2)}</pre>
                              touched
                              <pre>{JSON.stringify(touched, null, 2)}</pre> */}
                </Form>
              )}
            </Formik>
          </div>
        </DialogContent>
        <DialogActions>
          <div className='flex gap-3 justify-end'>
            <Button
              variant='contained'
              // fullWidth
              onClick={() => {
                Alert.confirm(() => {
                  setAdaptiveValue(adaptiveValuesInitial);
                  setAdaptiveOpen(false);
                });
              }}
              style={{ textTransform: 'capitalize' }}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              type='submit'
              // fullWidth
              onClick={handleSubmit}
              style={{ textTransform: 'capitalize' }}
            >
              Save
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      {/* <ViewAssessment open={openView} setOpen={setOpenView} data={viewData} /> */}
      <AssessmentDiscipline open={openForm} setOpenForm={setOpenForm} />
    </div>
  );
};

export default Assessment;
