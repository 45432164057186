import React, { useEffect, useState, useMemo } from 'react';
import { AgChartsReact } from 'ag-charts-react';
import {
  AgChartOptions,
  AgHistogramSeriesTooltipRendererParams,
} from 'ag-charts-community';
import { Box, Button, Chip, Grid, Stack, Typography } from '@mui/material';
// import ChartExample from './Chart';
import { Organization } from '../organisation/OrganizationForm';
import { AppDispatch } from '../../reduxStore/Store';
import { useDispatch, useSelector } from 'react-redux';

import { Link, useNavigate } from 'react-router-dom';
import Administration from '../Administration/Administration';
import ChartExample from './index';
import {
  getStudentDashboardAssessmentList,
  getStudentDashboardList,
} from '../../reduxStore/reducer/studentDashboardReducer';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { Scrollbar } from 'react-scrollbars-custom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import AssignmentIcon from '@mui/icons-material/Assignment';
import moment from 'moment';
import { green } from '@mui/material/colors';

const StudentDashboard = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [showAllCards, setShowAllCards] = React.useState(false);

  const [cardsData, setCardsData] = React.useState([]);
  const visibleCards = showAllCards
    ? cardsData.filter((card: any) => card.is_active) // Filter out inactive cards
    : cardsData.filter((card: any) => card.is_active).slice(0, 4);
  const handleMoreClick = () => {
    setShowAllCards((prevShowAllCards) => !prevShowAllCards);
    //
    //
  };

  const [course, setCourse] = useState(null);
  const [options, setOptions] = useState(null);
  const navigate = useNavigate();

  const handleNavigate = (data: any) => {
    //
    const { administration_number, course_assessment, course_student_id } =
      data;

    navigate(
      `/studentAssessment/addStudent/${data.student_id}/${data.course_assessment_id}/${data.adm_no}`,
      {
        state: {
          signUp: true,
          // Add any additional state data you want to pass here
        },
      }
    );
  };

  const [assessment, setAssessment] = useState(null);
  // const [options, setOptions] = useState(null);
  //

  useEffect(() => {
    dispatch(
      getStudentDashboardAssessmentList(options, setOptions, setCardsData)
    );

    dispatch(getStudentDashboardList(setCourse, setAssessment));
    // dispatch(getStudentDashboardAssessmentList(options, setOptions));
  }, [dispatch]);
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  const StyledPaper = styled(Paper)(({ theme }) => ({
    width: '100%',
    maxWidth: 560,
    bgcolor: 'background.paper',
    border: `1px solid ${theme.palette.divider}`,
    boxShadow: theme.shadows[3], // Adjust the shadow intensity by changing the value (0 to 24)
  }));

  return (
    <div>
      <Box sx={{ width: '100%' }}>
        <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <link
                  rel='stylesheet'
                  href='https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css'
                />
                <div
                  style={{ minHeight: '100px', minWidth: '100px' }}
                  className='mt-4 block relative h-32 rounded overflow-hidden bg-gradient-to-r from-blue-600 to-blue-300 text-white animate__animated animate__bounce shadow-xl'
                >
                  <h2 className='text-gray-100 title-font text-base font-bold p-4'>
                    Course Count
                  </h2>
                  <p className='text-black text-2xl font-bold flex items-center justify-center'>
                    {course}
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Link to='/studentassessment'>
                  <div
                    style={{ minHeight: '100px', minWidth: '100px' }}
                    className='mt-4 block relative h-32 rounded overflow-hidden bg-gradient-to-r from-[#4caf50] to-green-300 text-white animate__animated animate__bounce shadow-xl'
                  >
                    <h2 className='text-gray-100 title-font text-base font-bold p-4'>
                      Assessment Count
                    </h2>
                    <p className='text-black text-2xl font-bold flex items-center justify-center'>
                      {assessment}
                    </p>
                  </div>
                </Link>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={12} xl={12}>
                {' '}
                <div
                // className="lg:w-1/2 md:w-1/2 p-4 w-full"
                // style={{ height: '600px' }}
                >
                  <ChartExample />
                </div>{' '}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Grid item xs={12} sm={4} md={4} lg={12} xl={12}>
              <div style={{ padding: '16px 0px' }}>
                <StyledPaper
                  elevation={0}
                  sx={{
                    width: '100%',
                    // maxWidth: 560,
                    bgcolor: 'background.paper',
                  }}
                >
                  <Typography
                    variant='h6'
                    component='div'
                    sx={{ px: 2, py: 1, textAlign: 'center' }}
                  >
                    Assessment Administration Dates
                  </Typography>
                  <div>
                    <Scrollbar style={{ width: '100%', height: 410 }}>
                      {visibleCards?.map((d: any) => (
                        <List sx={{ paddingTop: '0px' }} key={d.id}>
                          <ListItem
                            alignItems='flex-start'
                            sx={{ cursor: 'pointer' }}
                            onClick={() => handleNavigate(d)}
                          >
                            <ListItemAvatar>
                              <Avatar
                                sx={{ bgcolor: green[500] }}
                                variant='rounded'
                              >
                                <AssignmentIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              // primary={d.official_name}
                              children={
                                <>
                                  {d.assessment_name}{' '}
                                  {/* <Chip label={d.adm_id} size="small" /> */}
                                  <div style={{ display: 'flex', gap: '20px' }}>
                                    <div>
                                      <Chip
                                        label={d.course_name}
                                        size='small'
                                      />
                                    </div>
                                    <div
                                      style={{ display: 'flex', gap: '10px' }}
                                    >
                                      <div>
                                        {d.ass_code} {'-'}
                                      </div>
                                      <div>{d.adm_id}</div>
                                    </div>
                                  </div>
                                </>
                              }
                              secondary={
                                <div
                                  style={{
                                    display: 'flex',
                                    marginTop: '3px',
                                    gap: '5px',
                                  }}
                                >
                                  <Typography
                                    variant='body2'
                                    color='text.primary'
                                  >
                                    Start Date:
                                  </Typography>
                                  <Typography
                                    variant='body2'
                                    color='text.primary'
                                    style={{ marginRight: '20px' }}
                                  >
                                    {moment(d.start_date_time).format(
                                      'MM-DD-YYYY hh:mm A'
                                    )}
                                  </Typography>
                                  <Typography
                                    variant='body2'
                                    color='text.primary'
                                  >
                                    End Date:
                                  </Typography>
                                  {moment(d.end_date_time).format(
                                    'MM-DD-YYYY hh:mm A'
                                  )}
                                </div>
                              }
                            />
                          </ListItem>
                          <Divider variant='inset' component='li' />
                        </List>
                      ))}
                      {!showAllCards && cardsData.length > 4 && (
                        <Stack spacing={2} sx={{ p: 2 }}>
                          <Button
                            variant='outlined'
                            onClick={handleMoreClick}
                            sx={{ width: '100%' }}
                          >
                            More
                          </Button>
                        </Stack>
                      )}
                    </Scrollbar>
                  </div>
                </StyledPaper>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default StudentDashboard;
