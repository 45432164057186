import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { getPreferenceFileData } from '../../reduxStore/reducer/emailingUsersReducer';
import * as XLSX from 'xlsx';
import { AppDispatch } from '../../reduxStore/Store';
import { IconButton } from '@mui/material';
import { MdDelete, MdDownload } from 'react-icons/md';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
interface FileRecord {
  email: string | null;
  first_name: string;
  last_name: string;
  email_messages: string | null;
  type: string;
}

const EmailingUsers = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [fileRecords, setFileRecords] = useState<FileRecord[] | null>(null);
  //

  useEffect(() => {
    dispatch(getPreferenceFileData(setFileRecords));
  }, [dispatch]);

  const downloadReportAnalysis = () => {
    if (fileRecords !== null) {
      const recordsForSheet = fileRecords.map((record) => ({
        'First Name': record.first_name,
        'Last Name': record.last_name,
        Email: record.email,

        // Type: record.type,
        'All communications': record.email_messages?.includes('communications')
          ? 'Yes'
          : 'No',
        'LASSO related workshops': record.email_messages?.includes('workshops')
          ? 'Yes'
          : 'No',
        'LASSO updates': record.email_messages?.includes('lasso')
          ? 'Yes'
          : 'No',
        'LASSO related research': record.email_messages?.includes('research')
          ? 'Yes'
          : 'No',
        'None/Unsubscribed': record.email_messages?.includes('none')
          ? 'Yes'
          : 'No',
      }));

      const workbook = XLSX.utils.book_new();
      const fileRecordsSheet = XLSX.utils.json_to_sheet(recordsForSheet);

      // Auto-size columns based on content
      const header = Object.keys(recordsForSheet[0]);
      const colWidths = header.map((col) => ({
        wch: Math.max(20, col.length + 2), // Minimum width of 20 characters
      }));
      fileRecordsSheet['!cols'] = colWidths;

      XLSX.utils.book_append_sheet(workbook, fileRecordsSheet, 'File Records');

      const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });

      const fileName = 'Report_For_User_Preferences.xlsx';
      const blob = new Blob([excelBuffer], {
        type: 'application/octet-stream',
      });
      const url = URL.createObjectURL(blob);

      const downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = fileName;

      document.body.appendChild(downloadLink);
      downloadLink.click();

      URL.revokeObjectURL(url);
      document.body.removeChild(downloadLink);
    }
  };

  const gridOptions = {
    defaultColDef: {
      sortable: true,
      resizable: true,
      filter: true,
      flex: 1,
    },
  };

  const columnDefs: any = [
    { headerName: 'Report Name', field: 'reportName', width: 200 },
    {
      headerName: 'Download',
      cellRenderer: (params: any) => (
        <IconButton onClick={() => downloadReportAnalysis()}>
          <MdDownload className="text-edit-icon" />
        </IconButton>
      ),
      width: 150,
    },
  ];

  const rowData = [
    {
      reportName: 'User preferences',
    },
  ];

  return (
    <main style={{ height: '81vh' }}>
      <header className="header">Reports</header>
      <div className="ag-theme-alpine" style={{ height: '200px' }}>
        <AgGridReact
          columnDefs={columnDefs}
          rowData={rowData}
          gridOptions={gridOptions}
        />
      </div>
    </main>
  );
};

export default EmailingUsers;
