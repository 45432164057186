import * as React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Checkbox,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Radio,
  Fab,
  Box,
} from '@mui/material';
import { Formik, Form, Field, FieldArray } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { addCourseAssessSpecificQuestionData } from '../../reduxStore/reducer/assessmentReducer';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../reduxStore/Store';

const CourseAssessSpecificQuestion = ({
  isOpenClose,
  handleCloseAssessQue,
  handleSaveAssessSpecQue,
  question,
}: any) => {
  const formikRef: any = React.useRef(null);
  const dispatch = useDispatch<AppDispatch>();
  const initialValues = {
    assessSpecQuestion: question?.length > 0 ? question : [],
  };

  return (
    <Dialog
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            // width: "100%",
            maxWidth: '1000px',
            height: '100%', // Set your width here
          },
        },
      }}
      open={isOpenClose}
      scroll={'paper'}
    >
      <DialogTitle sx={{ backgroundColor: '#1663ab', color: 'white' }}>
        <div className="flex justify-between items-center">
          <div>Assessment Specific Question</div>
          <Fab size="small">
            <CloseIcon
              className="cursor-pointer"
              onClick={handleCloseAssessQue}
            />
          </Fab>
        </div>
      </DialogTitle>

      <DialogContent sx={{ width: '800px' }}>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          //   validationSchema={validation}
          onSubmit={(values: any) => {
            handleSaveAssessSpecQue(values);
            dispatch(
              addCourseAssessSpecificQuestionData(
                values.assessSpecQuestion,
                handleCloseAssessQue
              )
            );
          }}
        >
          {({
            values,
            isValid,
            handleChange,
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
          }) => (
            <Form className="min-h-max">
              <Box className="border border-[#CBD5E1] rounded-md mt-5 p-3 pl-2">
                <FieldArray name="assessSpecQuestion">
                  {({ remove, insert, push }) => (
                    <>
                      {values?.assessSpecQuestion.map(
                        (
                          {
                            question_label,
                            question_content,
                            response_type,
                            response_options,
                            answer,
                          }: any,
                          questionIndex: any
                        ) => {
                          return (
                            // question box divided into four
                            <Accordion
                              key={question_label}
                              sx={{
                                borderRadius: '5px',
                                marginBottom: '10px',
                              }}
                              className="p-3 w-full border border-[#D5D5D5]"
                              defaultExpanded={true}
                            >
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h6" color="primary">
                                  Question {questionIndex + 1}
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="grid grid-cols-12 gap-x-3 gap-y-8">
                                  {/* 1 */}
                                  <div className="col-span-1">
                                    {question_label}
                                  </div>

                                  {/* 2 */}
                                  <div
                                    className="col-span-11 max-h-max pl-10"
                                    dangerouslySetInnerHTML={{
                                      __html: `<div class="sun-editor-editable" style="padding-top: 0px;
                          padding-bottom: 0px; max-width: 518px;">${question_content.replace(
                            /\\/g,
                            ''
                          )}</div>`,
                                    }}
                                  />

                                  {/* 3 */}
                                  <div className=" col-span-9">
                                    {/* option parts */}
                                    <div className="grid gap-y-8">
                                      {(response_type === 'Single' ||
                                        response_type === 'Multiple') && (
                                        <FieldArray
                                          name={`assessSpecQuestion[${questionIndex}].response_options`}
                                        >
                                          {({ push, remove }: any) => (
                                            <>
                                              {response_options.map(
                                                (
                                                  option: any,
                                                  optionIndex: any
                                                ) => (
                                                  <>
                                                    {/* single option divide into four part (2 + 10) */}
                                                    <div
                                                      className="grid grid-cols-12 gap-1"
                                                      key={option.label}
                                                    >
                                                      {response_type ==
                                                      'Single' ? (
                                                        <Field
                                                          //   disabled={Boolean(
                                                          //     isEdit
                                                          //   )}
                                                          as={Radio}
                                                          name={`assessSpecQuestion[${questionIndex}].answer[${optionIndex}]`}
                                                          onChange={() => {
                                                            setFieldValue(
                                                              `assessSpecQuestion[${questionIndex}].answer`,
                                                              [optionIndex]
                                                            );
                                                          }}
                                                          checked={Boolean(
                                                            answer[0] ==
                                                              optionIndex
                                                          )}
                                                        />
                                                      ) : (
                                                        <Field
                                                          as={Checkbox}
                                                          //   disabled={Boolean(
                                                          //     isEdit
                                                          //   )}
                                                          name={`assessSpecQuestion[${questionIndex}].answer[${optionIndex}]`}
                                                          onChange={(
                                                            e: any
                                                          ) => {
                                                            if (
                                                              e.target.checked
                                                            ) {
                                                              answer.push(
                                                                optionIndex
                                                              );
                                                            } else {
                                                              const indexToRemove =
                                                                answer.indexOf(
                                                                  optionIndex
                                                                );
                                                              if (
                                                                indexToRemove !==
                                                                -1
                                                              ) {
                                                                answer.splice(
                                                                  indexToRemove,
                                                                  1
                                                                );
                                                              }
                                                            }
                                                            setFieldValue(
                                                              `assessSpecQuestion[${questionIndex}].answer`,
                                                              answer
                                                            );
                                                          }}
                                                          checked={answer.includes(
                                                            optionIndex
                                                          )}
                                                        />
                                                      )}
                                                      <div
                                                        className="col-span-10 mr-6"
                                                        style={{
                                                          color: 'black',
                                                        }}
                                                        dangerouslySetInnerHTML={{
                                                          __html:
                                                            `<div class="sun-editor-editable" style="padding-top: 9px;
                                                  padding-bottom: 1px; max-width:376px">${option.content}</div>`.replace(
                                                              /\\/g,
                                                              ''
                                                            ),
                                                        }}
                                                      />{' '}
                                                    </div>
                                                  </>
                                                )
                                              )}
                                            </>
                                          )}
                                        </FieldArray>
                                      )}
                                      {response_type === 'Open' && (
                                        <div className="grid grid-cols-12 mr-2">
                                          <Field
                                            as={TextField}
                                            // disabled={Boolean(isEdit)}
                                            className="col-start-3 col-span-full"
                                            fullWidth
                                            multiline
                                            name={`assessSpecQuestion[${questionIndex}].answer[0]`}
                                            label="Response"
                                            size="small"
                                          />
                                        </div>
                                      )}
                                      {response_type === 'Number' && (
                                        <div className="grid grid-cols-12 mr-2">
                                          <Field
                                            as={TextField}
                                            // disabled={Boolean(isEdit)}
                                            name={`assessSpecQuestion[${questionIndex}].answer[0]`}
                                            size="small"
                                            label="Response"
                                            inputMode="decimal"
                                            type="number"
                                            className="col-start-3 col-span-full"
                                          />
                                        </div>
                                      )}
                                      {response_type === 'Text' && (
                                        <div className="grid grid-cols-12 mr-2">
                                          <Field
                                            as={TextField}
                                            // disabled={Boolean(isEdit)}
                                            name={`assessSpecQuestion[${questionIndex}].answer[0]`}
                                            className="col-start-3 col-span-full"
                                            fullWidth
                                            label="Response"
                                            type="text"
                                            size="small"
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          );
                        }
                      )}
                    </>
                  )}
                </FieldArray>
              </Box>

              {/* values
          <pre>{JSON.stringify(values, null, 2)}</pre>
          touched
          <pre>{JSON.stringify(touched, null, 2)}</pre>
          errors
          <pre>{JSON.stringify(errors, null, 2)}</pre> */}
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <div className="flex gap-5 justify-around mt-5">
          <Button
            size="large"
            variant="contained"
            style={{ textTransform: 'capitalize', width: '110px' }}
            onClick={handleCloseAssessQue}
          >
            Cancel
          </Button>
          <Button
            size="large"
            variant="contained"
            color="primary"
            type="submit"
            // disabled={
            //   action === 'View'
            //     ? true
            //     : formikRef?.current?.isValid
            //     ? !formikRef?.current?.isValid
            //     : true
            // }
            onClick={() => {
              if (formikRef.current) {
                formikRef.current.submitForm();
              }
            }}
            style={{
              textTransform: 'capitalize',
              width: '110px',
            }}
          >
            Save
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default CourseAssessSpecificQuestion;
