// 1 normal with collapse
import plugins from 'suneditor/src/plugins';
import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import GridHeader1 from '../../utils/gridHeader/GridHeader1';
import MasterGrid from '../../utils/MasterGrid/MasterGrid';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  makeStyles,
  AutocompleteRenderInputParams,
  Autocomplete,
  RadioGroup,
  Box,
  Grid,
  Button,
  Checkbox,
  InputAdornment,
  Menu,
  Switch,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
  InputLabel,
  IconButton,
  Paper,
  Divider,
  Radio,
  FormLabel,
  FormControlLabel,
  TableHead,
  TableContainer,
  Hidden,
  DialogProps,
} from '@mui/material';
import { CancelRounded, Delete, Edit } from '@mui/icons-material';
import { FaRegCopy } from 'react-icons/fa';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import {
  addAssessment,
  addAssessmentVersion,
  editAssessmentData,
  getAdaptiveAssessmentListWithTestItems,
  getAssessmentDiscipline,
  getAssessmentQuestion,
  getSelectedAdaptiveAssessmentListWithTestItems,
  saveAdaptiveAssessmentListWithTestItems,
  updatePublishStatus,
} from '../../reduxStore/reducer/assessmentReducer';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../reduxStore/Store';
import Editor from '../../utils/Editor/Editor';
import { createServerSideDatasource } from '../../utils/gridMethod/createServerSideDatasource';
import { GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useLocation, useNavigate } from 'react-router';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import 'suneditor/src/assets/css/suneditor-contents.css';
// import 'katex/dist/katex.min.css';
import { getDisciplineDetails } from '../../reduxStore/reducer/disciplineReducer';
import { MdOutlineRadio } from 'react-icons/md';
import {
  editCoursePrefixData,
  getCoursePrefixName,
} from '../../reduxStore/reducer/coursePrefixReducer';
import { AssessmentDiscipline } from './Dialogs/AssessmentDiscipline';
import katex from 'katex';
import 'katex/dist/katex.min.css';
import Alert from '../../utils/Alert/Alert';
import ViewAssessment from './ViewAssessment';
import Fab from '@mui/material/Fab';
import { activateStudentPassword } from '../../reduxStore/route/studentSignupRoute';
import QueueOutlinedIcon from '@mui/icons-material/QueueOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Swal from 'sweetalert2';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import AssessSpecificQuestion from './AssessSpecificQuestion';
import createMergeTag from './Plugin/merge_tag_plugin.js'; // Path to your merge_tag_plugin.js file
import { set } from 'lodash';
import TestItemsDialog from './Dialogs/TestItemsDialog';
import FilterQuestionDialog from './Dialogs/FilterQuestionDialog';
import LikertDialog from './Dialogs/LikertDialog';
import ImageAlternateTextDialog from './Dialogs/ImageAlternateTextDialog';
import AssessmentHelper from '../../utils/Helpers/Assessment';
import AttachReports from './Dialogs/AttachReports';

const { getAlphabetFromNumber, fontSizeOptions, mathFontSizeOptions } =
  AssessmentHelper;

const AddEditStaticAssessment = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [stateData, setStateData] = useState<any>(
    state?.value ? state?.value : {}
  );
  const [activeBlock, setActiveBlock] = useState(0);
  const [isVersionFirstTime, setIsVersionFirstTime] = useState(true);
  const [assessSpecQuestion, setAssessSpecQuestion] = useState(
    state?.value?.assessSpecQuestion ? state?.value?.assessSpecQuestion : []
  );

  const [isTyping, setIsTyping] = useState(false);

  // Add a state variable to keep track of the active editor
  const [activeEditor, setActiveEditor] = useState<any>(null);

  const [val, setVal] = useState<any>(
    state?.value?.question
      ? state?.value?.question
      : {
          instruction: '',
          likert: {
            type: 'question',
            questionLabel:
              stateData?.questionType == '1,2,3'
                ? state?.value?.question?.length + 1
                : '',
            questionContent: '',
            responseType: 'Single',
            isScored:
              stateData?.key == true || stateData?.key == 'true' ? 'Yes' : 'No',
            optionOrientation: 'Vertical',
            framing: '',
            answer: '',
            defaultAnswer: '',
            responseOptions: [],
            index: '',
          },
          data: [],
          filterQuestion: {
            filterQuestion: state?.value?.question?.filterQuestion
              ?.filterQuestion
              ? state?.value?.question?.filterQuestion?.filterQuestion
              : '',
            filterResponse: state?.value?.question?.filterQuestion
              ?.filterResponse
              ? state?.value?.question?.filterQuestion?.filterResponse
              : '',
          },
        }
  );

  //client side pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [contentBlocksRef, setContentBlocksRef]: any = useState([]);
  const currentPageRef: any = useRef(1);
  const itemsPerPage = 5;

  const startIndex = (currentPageRef.current - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Get the items for the current page
  let currentItems =
    val?.data?.length > 0 && val?.data.slice(startIndex, endIndex);

  let numOfContentBlock = currentItems?.length > 0 ? currentItems?.length : 0;

  //  const contentBlocksRef: any = useMemo(() => Array(numOfContentBlock).fill(0).map(i=> React.createRef()), [numOfContentBlock]);

  useEffect(() => {
    if (currentItems?.length) {
      setContentBlocksRef(
        Array(currentItems?.length)
          .fill(0)
          .map((i) => React.createRef())
      );
    } else {
      setContentBlocksRef([]);
    }
  }, [window.scrollY]);

  const totalPages = Math.ceil(val.data.length / itemsPerPage);

  const [filterQuestion, setFilterQuestion] = useState({
    filterQuestion: state?.value?.question?.filterQuestion?.filterQuestion
      ? state?.value?.question?.filterQuestion?.filterQuestion
      : '',
    filterResponse: state?.value?.question?.filterQuestion?.filterResponse
      ? state?.value?.question?.filterQuestion?.filterResponse
      : '',
  });

  const [filterState, setFilterState] = useState<any>({
    filterQuestion: '',
    filterResponse: '',
  });

  const [likert, setLikert] = useState(
    stateData?.question
      ? stateData?.question.likert
      : {
          type: 'question',
          questionLabel:
            stateData?.questionType == '1,2,3'
              ? stateData?.question?.length + 1
              : '',
          questionContent: '',
          responseType: 'Single',
          isScored:
            stateData?.key == true || stateData?.key == 'true' ? 'Yes' : 'No',
          optionOrientation: 'Vertical',
          framing: '',
          answer: '',
          defaultAnswer: '',
          responseOptions: [],
          index: '',
        }
  );

  const handleadd = () => {
    const value: any = { ...val };
    if (stateData?.likert == 'true' || stateData?.likert == true) {
      value.data.push({
        ...val?.likert,
        questionLabel:
          stateData?.questionType == '1,2,3' ? value?.data?.length + 1 : '',
        index: questionIndex,
      });
    } else {
      value.data.push({
        type: 'question',
        questionLabel:
          stateData?.questionType == '1,2,3' ? value?.data?.length + 1 : '',
        questionContent: '',
        responseType: 'Single',
        isScored:
          stateData?.key == 'true' || stateData?.key == true ? 'Yes' : 'No',
        optionOrientation: 'Vertical',
        framing: '',
        answer: '',
        defaultAnswer: '',
        responseOptions: [],
        index: questionIndex,
      });
    }
    setVal(value);
  };

  const handleAddAfter = (index: any) => {
    const i = (currentPageRef.current - 1) * itemsPerPage + index;
    const value: any = { ...val };

    if (stateData?.likert == 'true' || stateData?.likert == true) {
      value.data.splice(i + 1, 0, {
        ...val?.likert,
        questionLabel:
          stateData?.questionType == '1,2,3' ? questionIndex + 1 : '',
        index: questionIndex,
      });
    } else {
      value.data.splice(i + 1, 0, {
        type: 'question',
        questionLabel:
          stateData?.questionType == '1,2,3' ? questionIndex + 1 : '',
        questionContent: '',
        responseType: 'Single',
        isScored: stateData?.key == 'true' || stateData?.key ? 'Yes' : 'No',
        optionOrientation: 'Vertical',
        responseOptions: [],
        answer: '',
        defaultAnswer: '',
        index: questionIndex ? questionIndex : 1,
      });
    }
    if (stateData?.questionType == '1,2,3') {
      let questionIndex = 0;
      const mappedData = value.data.map((item: any, index: any) => {
        if (item.type == 'question') {
          questionIndex += 1;
          return { ...item, questionLabel: questionIndex };
        } else {
          return item;
        }
      });
      value.data = mappedData;
      setVal(value);
    } else {
      setVal(value);
    }
  };

  const handleCopy = (index: any) => {
    const i = (currentPageRef.current - 1) * itemsPerPage + index;
    const value: any = structuredClone(val);

    if (
      (stateData?.likert == 'true' || stateData?.likert == true) &&
      value?.data[i].type === 'question'
    ) {
      value.data.splice(i + 1, 0, {
        ...structuredClone(value?.data[i]),
        questionLabel:
          stateData?.questionType == '1,2,3' ? questionIndex + 1 : '',
        index: questionIndex,
      });
    } else if (value?.data[i].type === 'question') {
      value.data.splice(i + 1, 0, {
        ...structuredClone(value?.data[i]),
        questionLabel:
          stateData?.questionType == '1,2,3' ? questionIndex + 1 : '',
        isScored: stateData?.key == 'true' || stateData?.key ? 'Yes' : 'No',
        index: questionIndex ? questionIndex : 1,
      });
    }

    if (stateData?.questionType == '1,2,3') {
      let questionIndex = 0;
      const mappedData = value.data.map((item: any, index: any) => {
        if (item.type == 'question') {
          questionIndex += 1;
          return { ...item, questionLabel: questionIndex };
        } else {
          return item;
        }
      });
      value.data = mappedData;
    }
    if (value?.data[i].type === 'framing') {
      value.data.splice(i + 1, 0, {
        ...value?.data[i],
        framingLabel: `Framing ${framingIndex + 1}`,
      });

      let framingInd = 0;
      const mappedData = value.data.map((item: any, index: any) => {
        if (item.type == 'framing') {
          framingInd += 1;
          return {
            ...item,
            framingLabel: `Framing ${framingInd}`,
          };
        } else {
          return item;
        }
      });
      value.data = mappedData;
    }

    setVal(value);
  };

  const handleOptionAdd = async (index: any, type?: any) => {
    const i = (currentPageRef.current - 1) * itemsPerPage + index;
    const value: any = { ...val };

    if (type && value.data[i].responseType === 'Multiple' && type === 'none') {
      value.data[i].responseOptions.push({
        optionLabel:
          stateData?.optionType == '1,2,3'
            ? value.data[i].responseOptions?.length + 1
            : stateData?.optionType == 'a,b,c'
            ? `${await getAlphabetFromNumber(
                value.data[i].responseOptions?.length + 1
              )}`
            : '',
        optionContent: 'None of the above',
        optionType: 'none',
      });
    } else if (type === 'other') {
      value.data[i].responseOptions.push({
        optionLabel:
          stateData?.optionType == '1,2,3'
            ? value.data[i].responseOptions?.length + 1
            : stateData?.optionType == 'a,b,c'
            ? `${await getAlphabetFromNumber(
                value.data[i].responseOptions?.length + 1
              )}`
            : '',
        optionContent: 'Other',
        optionType: 'other',
      });
    } else {
      value.data[i].responseOptions.push({
        optionLabel:
          stateData?.optionType == '1,2,3'
            ? value.data[i].responseOptions?.length + 1
            : stateData?.optionType == 'a,b,c'
            ? `${await getAlphabetFromNumber(
                value.data[i].responseOptions?.length + 1
              )}`
            : '',
        optionContent: '',
      });
    }
    setVal(value);
  };

  const handledelete = (index: any) => {
    const i = (currentPageRef.current - 1) * itemsPerPage + index;
    if (currentItems.length === 1 && currentPageRef.current > 1) {
      setCurrentPage((prev) => prev - 1);
      currentPageRef.current -= 1;
    }
    setVal((prevValue: any) => {
      const updatedValue = { ...prevValue };
      const removedData = updatedValue.data.splice(i, 1);

      updatedValue.data.forEach((item: any) => {
        const questionIndex = `[Question ${i + 1}]`;
        if (
          item.type == 'question' &&
          item.questionContent.includes(
            `<span class="se-custom-tag">${questionIndex}`
          )
        ) {
          item.questionContent = item.questionContent.replace(
            questionIndex,
            ''
          );
        }
      });

      if (removedData[0].type === 'framing') {
        updatedValue.data.map((d: any) => {
          if (
            d.type === 'question' &&
            d.framing === removedData[0].framingLabel
          ) {
            d.framing = '';
          }
          return d;
        });
      }

      if (stateData?.questionType === '1,2,3') {
        let questionIndex = 0;
        let framingIndex = 0;
        const mappedData = updatedValue.data.map((item: any) => {
          if (item.type === 'question') {
            questionIndex += 1;
            return { ...item, questionLabel: questionIndex };
          } else {
            framingIndex += 1;
            return { ...item, framingLabel: `Framing ${framingIndex}` };
          }
        });
        updatedValue.data = mappedData;
      }

      if (
        val?.filterQuestion?.filterQuestion >=
        updatedValue?.data?.filter((d: any) => d.type == 'question')?.length
      ) {
        updatedValue.filterQuestion = {
          filterQuestion: '',
          filterResponse: '',
        };
        setFilterQuestion({
          filterQuestion: '',
          filterResponse: '',
        });
      }

      // Update the state inside the callback function
      return { ...updatedValue };
    });
  };

  const handleAddFrame = (data: any, index: any) => {
    const i = (currentPageRef.current - 1) * itemsPerPage + index;

    setVal((prevValue: any) => {
      const value = { ...prevValue };

      const framingLength = dataFraming.length;

      value.data.splice(i + 1, 0, {
        type: 'framing',
        framingLabel: `Framing ${framingIndex + 1}`,
        framingContent: '',
      });

      let framingInd = 0;
      const mappedData = value.data.map((item: any, index: any) => {
        if (item.type == 'framing') {
          framingInd += 1;
          return {
            ...item,
            framingLabel: `Framing ${framingInd}`,
          };
        } else {
          return item;
        }
      });
      value.data = mappedData;

      return value;
    });

    // setVal(() => value);
  };

  const columnchange = (e: any, index: any) => {
    const i = (currentPageRef.current - 1) * itemsPerPage + index;
    const { name, value } = e.target;

    setVal((prevVal: any) => {
      const inputdata = { ...prevVal };

      if (name === 'isScored' && value === 'No') {
        inputdata.data[i].defaultAnswer = '';
      }

      if (
        (name === 'responseType' && value === 'Open') ||
        value === 'Number' ||
        value === 'Text'
      ) {
        if (value === 'Number') {
          inputdata.data[i].defaultAnswer = '0';
          inputdata.data[i].responseOptions = [
            { optionLabel: '0', optionContent: '0' },
          ];
        }
        if (value === 'Single') {
          inputdata.data[i].defaultAnswer = '';
          inputdata.data[i].responseOptions = [
            { optionLabel: '', optionContent: '' },
          ];
        }
        if (value === 'Multiple') {
          inputdata.data[i].defaultAnswer = '';
          inputdata.data[i].responseOptions = [
            { optionLabel: '', optionContent: '' },
          ];
        } else {
          inputdata.data[i].defaultAnswer = '';
          inputdata.data[i].responseOptions = [
            { optionLabel: '', optionContent: '' },
          ];
        }
      }

      if (
        (name === 'responseType' && value === 'Single') ||
        (name === 'responseType' && value === 'Multiple')
      ) {
        // Clear the default answer for single/multiple choice questions
        inputdata.data[i].defaultAnswer = '';

        const arr1 = inputdata.data[i].responseOptions.map(
          (option: any, index: number) => ({
            optionLabel:
              stateData?.optionType === '1,2,3'
                ? (index + 1).toString()
                : stateData?.optionType === 'a,b,c'
                ? getAlphabetFromNumber(index + 1) // Call the function to get the alphabet
                : stateData?.optionType === 'Custom'
                ? option.optionLabel
                : '',
            optionContent: option.optionContent,
            optionType:
              name === 'responseType' &&
              value === 'Single' &&
              option.optionType === 'none'
                ? undefined
                : option.optionType,
          })
        );

        const arr2 = [
          {
            optionLabel:
              stateData?.optionType == '1,2,3'
                ? '1'
                : stateData?.optionType == 'a,b,c'
                ? 'A'
                : '',
            optionContent: '',
          },
        ];

        // Renumber the options for single/multiple choice questions
        inputdata.data[i].responseOptions = arr1.length == 0 ? arr2 : arr1;
      }

      inputdata.data[i][name] = value;
      return inputdata;
    });
  };

  const columnOptionchange = (e: any, ind: any, index: any) => {
    const i = (currentPageRef.current - 1) * itemsPerPage + ind;
    const { name, value } = e.target;

    setVal((prevVal: any) => {
      const inputdata = { ...prevVal };
      inputdata.data[i].responseOptions[index][name] = value;

      if (
        inputdata.data[i].responseType == 'Open' ||
        inputdata.data[i].responseType == 'Number' ||
        inputdata.data[i].responseType == 'Text'
      ) {
        if (inputdata.data[i].responseType == 'Number') {
          inputdata.data[i].defaultAnswer =
            inputdata.data[i].responseOptions[index].optionLabel;
        } else {
          inputdata.data[i].defaultAnswer =
            inputdata.data[i].responseOptions[index].optionContent;
        }
      }
      return inputdata;
    });

    // const inputdata: any = { ...val };
    // inputdata.data[i].responseOptions[index][name] = value;

    // if (
    //   inputdata.data[i].responseType == 'Open' ||
    //   inputdata.data[i].responseType == 'Number' ||
    //   inputdata.data[i].responseType == 'Text'
    // ) {
    //   if (inputdata.data[i].responseType == 'Number') {
    //     inputdata.data[i].defaultAnswer =
    //       inputdata.data[i].responseOptions[index].optionLabel;
    //   } else {
    //     inputdata.data[i].defaultAnswer =
    //       inputdata.data[i].responseOptions[index].optionContent;
    //   }
    // }

    // setVal(inputdata);
  };

  const handleDeleteOption = (index: any, ind: any) => {
    const i = (currentPageRef.current - 1) * itemsPerPage + ind;

    setVal((prevVal: any) => {
      let deleteval: any = { ...prevVal };

      const { responseOptions, defaultAnswer, responseType } =
        deleteval.data[i];

      if (responseType == 'Single') {
        if (defaultAnswer == responseOptions.length) {
          deleteval.data[i].defaultAnswer = '';
        }
      }

      if (deleteval.data[i].responseType == 'Multiple') {
        let indexStr = deleteval.data[i].responseOptions.length;
        if (defaultAnswer.hasOwnProperty(indexStr)) {
          delete deleteval.data[i].defaultAnswer[indexStr];
        }
      }

      deleteval.data[i].responseOptions.pop();

      if (
        val?.filterQuestion?.filterResponse >
        deleteval?.data[val?.filterQuestion?.filterQuestion - 1]
          ?.responseOptions?.length
      ) {
        deleteval.filterQuestion = {
          filterQuestion: '',
          filterResponse: '',
        };
        setFilterQuestion({
          filterQuestion: '',
          filterResponse: '',
        });
      }
      return deleteval;
    });
  };

  const moveUp = (index: any) => {
    const i = (currentPageRef.current - 1) * itemsPerPage + index;

    setVal((prevVal: any) => {
      const arr: any = { ...prevVal };

      if (arr?.data[i].type === 'question') {
        const previousElement = arr.data[i - 1].questionLabel;
        const currentElement = arr.data[i].questionLabel;

        if (previousElement && currentElement) {
          if (previousElement < currentElement) {
            const currentValue = arr.data[i];
            currentValue.questionLabel = previousElement;

            const nextValue = arr.data[i - 1];
            nextValue.questionLabel = currentElement;

            const temp = currentValue;
            arr.data[i] = nextValue;
            arr.data[i - 1] = temp;
          }

          return arr;
        }
      }

      if (arr?.data[i].type === 'framing') {
        const previousElement = arr.data[i - 1].framingLabel;
        const currentElement = arr.data[i].framingLabel;

        if (previousElement && currentElement) {
          const num1 = parseInt(previousElement.match(/\d+$/)[0]);
          const num2 = parseInt(currentElement.match(/\d+$/)[0]);
          if (num1 < num2) {
            const temp = arr.data[i].framingContent;
            arr.data[i].framingContent = arr.data[i - 1].framingContent;
            arr.data[i - 1].framingContent = temp;
          }

          return arr;
        }
      }

      const element = arr.data[i];
      arr.data.splice(i, 1);
      arr.data.splice(i - 1, 0, element);

      return arr;
    });
  };

  const moveDown = (index: any) => {
    const i = (currentPageRef.current - 1) * itemsPerPage + index;

    setVal((prevVal: any) => {
      let arr: any = { ...prevVal };

      if (arr?.data[i].type === 'question') {
        const previousElement = arr.data[i + 1].questionLabel;
        const currentElement = arr.data[i].questionLabel;

        if (previousElement && currentElement) {
          if (previousElement > currentElement) {
            const currentValue = arr.data[i];
            currentValue.questionLabel = previousElement;

            const nextValue = arr.data[i + 1];
            nextValue.questionLabel = currentElement;

            const temp = currentValue;
            arr.data[i] = nextValue;
            arr.data[i + 1] = temp;
          }

          return arr;
        }
      }

      if (arr?.data[i].type === 'framing') {
        const previousElement = arr.data[i + 1].framingLabel;
        const currentElement = arr.data[i].framingLabel;

        if (previousElement && currentElement) {
          const num1 = parseInt(previousElement.match(/\d+$/)[0]);
          const num2 = parseInt(currentElement.match(/\d+$/)[0]);
          if (num1 > num2) {
            const temp = arr.data[i].framingContent;
            arr.data[i].framingContent = arr.data[i + 1].framingContent;
            arr.data[i + 1].framingContent = temp;
          }

          return arr;
        }
      }

      const element = arr.data[i];
      arr.data.splice(i, 1);
      arr.data.splice(i + 1, 0, element);

      return arr;
    });
  };

  const [Instruction, setInstruction] = useState(
    val?.instruction.replace(/<\/?p>/g, '').replace(/<br\s?\/?>/g, '') == ''
      ? false
      : true
  );

  const validation = Yup.object({
    discipline: Yup.object({
      id: Yup.string().required('Required Field'),
      name: Yup.string().required('Required Field'),
    }).required('Required Field'),
    officialAssessmentName: Yup.string()
      .matches(
        /^[a-zA-z]/,
        'starting letter should not be a space, numbers or special characters'
      )
      .required('Required Field'),
    publicAssessmentName: Yup.string()
      .matches(
        /^[a-zA-z]/,
        'starting letter should not be a space, numbers or special characters'
      )
      .required('Required Field'),
    assessmentCode: Yup.string()
      .matches(
        /^[a-zA-z]/,
        'starting letter should not be a space, numbers or special characters'
      )
      .required('Required Field'),
    publicCode: Yup.string()
      .matches(
        /^[a-zA-z]/,
        'starting letter should not be a space, numbers or special characters'
      )
      .required('Required Field'),
    questionType: Yup.string().required('Required Field'),
    optionType: Yup.string().required('Required Field'),
    key: Yup.string().required('Required Field'),
    likert: Yup.string().required('Required Field'),
    minimum_duration: Yup.number().required('Required Field'),
  });

  useEffect(() => {
    dispatch(getAssessmentDiscipline());
    // dispatch(getAdaptiveAssessmentListWithTestItems(setItemData));
    if (stateData?.question_id) {
      dispatch(
        getSelectedAdaptiveAssessmentListWithTestItems(
          state?.value?.question_id,
          setName
        )
      );
    }
  }, [dispatch, state]);

  useEffect(() => {
    const assessmentData = {
      state: {
        state: {
          value: {
            id: stateData.id,
            discipline: {
              id: stateData.discipline.id,
              name: stateData.discipline.name,
            },
            officialAssessmentName: stateData.officialAssessmentName,
            publicAssessmentName: stateData.publicAssessmentName,
            assessmentCode: stateData.assessmentCode,
            publicCode: stateData.publicCode,
            under_development: stateData.under_development,
            reference_image: stateData.reference_image,
            image_description: stateData.image_description,
            minimum_duration: stateData.minimum_duration,
            questionType: stateData.questionType,
            optionType: stateData.optionType,
            key: stateData.key,
            filter: stateData?.filter,
            likert: stateData.likert,
            question: stateData.question,
            question_id: stateData.question_id,
            reports: stateData.reports,
          },
          action: state.action === 'Update' ? 'Update' : 'Version',
          version: state.action === 'Update' ? false : true,
        },
      },
      url: '/question',
    };

    if (stateData?.question_id) {
      dispatch(
        getAssessmentQuestion(assessmentData, navigate, setInitialValue)
      );
    }
  }, []);

  const formikRef: any = React.useRef(null);

  const disciplineData: { id: string; name: string }[] = useSelector(
    (state: RootState) => {
      const data = state.assessment.assessmentDiscipline;

      const alterData: any = [...data];
      alterData.unshift({
        id: '',
        name: 'ADD NEW DISCIPLINE',
      });
      return alterData;
    }
  );

  const [initialValues, setInitialValue] = useState<any>(state?.value);

  useEffect(() => {
    if (formikRef.current) {
      const updatedValues = {
        ...formikRef.current.values,
        ...initialValues,
      };

      formikRef.current.resetForm({
        values: updatedValues,
      });
    }
  }, [initialValues]);

  useEffect(() => {
    if (!contentBlocksRef?.length) return;

    const handleScroll = () => {
      contentBlocksRef.forEach((blockRef: any, index: any) => {
        const block = blockRef.current;
        if (block) {
          const blockTop = block.offsetTop - 200;
          const blockHeight = block.clientHeight;

          if (
            window.scrollY >= blockTop &&
            window.scrollY < blockTop + blockHeight
          ) {
            setActiveBlock(index);
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [contentBlocksRef]);

  const handleRadioChange = (e: any, index: any) => {
    const i = (currentPageRef.current - 1) * itemsPerPage + index;

    setVal((prevVal: any) => {
      const value: any = { ...prevVal };
      value.data[i].defaultAnswer = e.target.value;
      return value;
    });
  };

  const onCheckboxChange = (e: any, index: any, options?: any) => {
    const i = (currentPageRef.current - 1) * itemsPerPage + index;
    let notaObj: any; /*none of the above (nota) object*/

    console.log(e.target);
    setVal((prevVal: any) => {
      const value: any = { ...prevVal };
      const { name, checked } = e.target;

      //      sample value of d = [
      //     {
      //       "optionLabel": "1",
      //       "optionContent": "<p>sdsd</p>"
      //   },
      //   {
      //       "optionLabel": "2",
      //       "optionContent": "<p>dsfsfd</p>"
      //   },
      //   {
      //       "optionLabel": "3",
      //       "optionContent": ""
      //   },
      //   {
      //       "optionLabel": 4,
      //       "optionContent": "<p>None of the above</p>"
      //   }
      // ]
      console.log(options);

      // sample value of value?.data[i]?.defaultAnswer = {
      //   "1"(optionLabel of element in array of object d): false(checkbox value),
      //   "2": false,
      //   "3": false,
      //   "4": true
      // }

      if (options) {
        const currentCheckbox = name;
        notaObj = options?.filter(
          (option: any) => option?.optionType === 'none'
        )?.[0];
        let notaObjOptionLabel = options?.findIndex(
          (option: any) => option?.optionType == 'none'
        );

        if (notaObjOptionLabel >= 0) {
          notaObjOptionLabel++;
        }

        if (currentCheckbox == notaObjOptionLabel) {
          const isOtherCheckboxFalse = options.every(
            (option: any, optionLabel: any) => {
              if (
                optionLabel + 1 !== notaObjOptionLabel &&
                Object.hasOwn(value?.data[i]?.defaultAnswer, optionLabel + 1)
              ) {
                return value?.data[i]?.defaultAnswer[optionLabel + 1] === false;
              } else {
                return true;
              }
            }
          );
          if (isOtherCheckboxFalse) {
            const data = {
              ...value?.data[i]?.defaultAnswer,
              [name]: checked,
            };
            value.data[i].defaultAnswer = data;
            return value;
          } else {
            /* current checkbox is "none of the above" & some of others are checked */
            const data = {
              ...value?.data[i]?.defaultAnswer,
            };

            //so make other checkbox as false
            options.forEach((option: any, optionLabel: any) => {
              if (
                optionLabel + 1 !== notaObjOptionLabel &&
                Object.hasOwn(value?.data[i]?.defaultAnswer, optionLabel + 1)
              ) {
                if (value?.data[i]?.defaultAnswer[optionLabel + 1] === true) {
                  data[optionLabel + 1] = false;
                }
              } else {
                /* option is false so logic never came here */
                //return true;
              }
            });

            //make current(none) as true
            data[name] = checked;
            value.data[i].defaultAnswer = data;
            return value;

            // Alert.info({
            //   title:
            //     'Either None of the above or other checkboxes will be selected',
            //   text: '',
            // });
            // return prevVal;
          }
        } else {
          if (notaObj) {
            if (
              Object.hasOwn(
                value?.data[i]?.defaultAnswer,
                notaObjOptionLabel
              ) &&
              value.data[i].defaultAnswer[notaObjOptionLabel] === true
            ) {
              /* current checkbox is other than "none of the above" and "none of the above" is checked */

              //so make "none of the above" checkbox as false & make current(some of other) as true
              const data = {
                ...value?.data[i]?.defaultAnswer,
                [notaObjOptionLabel]: false,
                [name]: checked,
              };
              value.data[i].defaultAnswer = data;
              return value;

              // Alert.info({
              //   title:
              //     'Either None of the above or other checkboxes will be selected',
              //   text: '',
              // });
              // return prevVal;
            } else {
              const data = {
                ...value?.data[i]?.defaultAnswer,
                [name]: checked,
              };
              value.data[i].defaultAnswer = data;
              return value;
            }
          } else {
            const data = {
              ...value?.data[i]?.defaultAnswer,
              [name]: checked,
            };
            value.data[i].defaultAnswer = data;
            return value;
          }
        }
      } else {
        const data = {
          ...value?.data[i]?.defaultAnswer,
          [name]: checked,
        };
        value.data[i].defaultAnswer = data;
        return value;
      }
    });
  };

  const [openForm, setOpenForm] = useState<any>({
    isOpen: false,
    callback: null,
  });
  const [filterOpen, setFilterOpen] = useState<any>(false);

  const [itemOpen, setItemOpen] = useState<any>(false);

  const [reportsOpen, setReportsOpen] = useState<any>(false);

  const [publish, setPublish] = useState(false);

  const [open, setOpen] = useState(false);

  const [view, setView] = useState(false);

  const handleSaveAssessSpecQue = (question: any) => {
    setAssessSpecQuestion(question);
  };

  const dataFraming = val.data.filter((d: any, i: any) => d.type === 'framing');

  let questionIndex = 0;
  let framingIndex = 0;

  const regex = /<img.*?\>/g; // Add the 'g' flag for global search
  const arr: any = [];

  const imageData = val?.data?.map((d: any) => {
    const questionContentMatches = d?.questionContent?.match(regex);
    const framingContentMatches = d?.framingContent?.match(regex);

    if (questionContentMatches) {
      arr.push(...questionContentMatches);
    }

    if (framingContentMatches) {
      arr.push(...framingContentMatches);
    }

    d?.responseOptions?.map((data: any) => {
      if (data?.optionContent) {
        const optionContentMatches = data?.optionContent?.match(regex);
        if (optionContentMatches) {
          arr.push(...optionContentMatches);
        }
      }
    });
  });

  const imageAlternateData = arr?.map((d: any) => {
    const reg: any = /alt="(.*?)"/;
    const altText = reg.exec(d)[1];
    return { image: d, alt: altText ? altText : '' };
  });
  const filterData = val?.data?.filter((d: any) => d.type === 'question');

  const [button, setButton] = useState(false);

  //likert
  const [likertOpen, setLikertOpen] = useState(false);

  const [error, setError] = useState<any>([]);

  const [name, setName] = React.useState<any>(
    state?.value?.question?.test_assessment_ids?.length > 0
      ? state.value.question.test_assessment_ids
      : []
  );

  const [reports, setReports] = React.useState<any>(
    state?.value?.reports?.length > 0 ? state.value.reports : []
  );

  const handleImageChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any) => void,
    clearImage: (setFieldValue: any) => void
  ) => {
    const files = e.target.files;
    if (!files || files.length === 0) {
      clearImage(setFieldValue);
      return;
    }

    const file = files[0];
    if (file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setFieldValue('reference_image', base64String);
      };
      reader.readAsDataURL(file);
    } else {
      clearImage(setFieldValue);
      Swal.fire('Invalid file type', 'Please choose an image file.', 'error');
    }
  };

  const clearImage = (setFieldValue: any) => {
    setFieldValue('reference_image', ''); // Clear reference_image
  };
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <main className='p-10'>
      <div className=' p-5 mb-12 max-w-7xl border border-[#D5D5D5] rounded-md'>
        <Formik
          initialValues={initialValues}
          // enableReinitialize
          validationSchema={validation}
          innerRef={formikRef}
          onSubmit={async (values, { setSubmitting }) => {
            if (!val.filterQuestion) {
              val.filterQuestion = {};
            }

            val.filterQuestion.filterQuestion =
              filterQuestion?.filterQuestion || '';
            val.filterQuestion.filterResponse =
              filterQuestion?.filterResponse || '';

            values.assessSpecQuestion = assessSpecQuestion;
            values.question = val;
            values.question_id = state?.value?.question_id;
            values.test_assessment_ids = name.length > 0 ? name : [];
            values.reports = reports.length > 0 ? reports : [];

            let isAllFalse = false;

            if (state.version) {
              isAllFalse = true;
            }

            if (filterData.length > 0 == false) {
              Alert.info({
                title:
                  'Assessment questions is required to create a assessment',
                text: '',
              });
              return;
            }

            if (
              (stateData?.key === true || stateData?.key === 'true'
                ? val?.filterQuestion?.filterQuestion === ''
                : val?.filterQuestion?.filterResponse === '' &&
                  val?.filterQuestion?.filterResponse === '') &&
              (values?.filter == 'true' || values?.filter == true) &&
              state.version === true
            ) {
              Alert.info({
                title: 'Please add filter question',
                text: '',
              }).then(() => {
                setFilterOpen(true);
              });
              return;
            }

            if (filterData.length > 0) {
              if (state.version) {
                values.is_published = true;

                const errorMap = val?.data.map((d: any, i: any) => {
                  let structure: any = {
                    questionContent: false,
                    questionLabel: false,
                    responseOptions: [],
                    responseOption: false,
                    answer: false,
                  };

                  if (d.type == 'question') {
                    const sanitizedQuestionContent = d.questionContent.replace(
                      /\u200B/g,
                      ''
                    );
                    const vd = sanitizedQuestionContent
                      .replace(/<p><br><\/p>/g, '') // remove empty <p> tags and <br> tags
                      .trim();
                    if (vd === '') {
                      structure.questionContent = true;
                    }
                    // else if(){

                    // }

                    if (d.questionLabel == '') {
                      structure.questionLabel = true;
                    }

                    // if (
                    //   d.responseType == 'Open' || d.responseType == 'Text'
                    // ) {
                    //   if (d.defaultAnswer == '') {
                    //     structure.answer = true;
                    //   }
                    // }

                    if (
                      d.defaultAnswer == '' &&
                      d.responseType !== 'Multiple'
                    ) {
                      if (stateData?.key == 'true' || stateData?.key == true) {
                        if (
                          d?.isScored !== 'No' &&
                          d.responseType !== 'Open' &&
                          d.responseType !== 'Number' &&
                          d.responseType !== 'Text'
                        ) {
                          structure.answer = true;
                        } else if (
                          d?.isScored !== 'No' &&
                          d.responseType == 'Number' &&
                          d.questionLabel == ''
                        ) {
                          structure.answer = true;
                        } else if (
                          d?.isScored !== 'No' &&
                          d.responseType == 'Text' &&
                          d.questionLabel == ''
                        ) {
                          structure.answer = true;
                        }
                      }
                    }

                    if (d.responseType == 'Multiple') {
                      if (stateData?.key == 'true' || stateData?.key == true) {
                        if (
                          Object.entries(d?.defaultAnswer)
                            .map(([key, value]) => {
                              if (value) return key || '';
                            })
                            .filter((i) => i).length == 0
                        ) {
                          if (d?.isScored !== 'No') {
                            structure.answer = true;
                          }
                        } else {
                          structure.answer = false;
                        }
                      }
                    }

                    if (d.responseOptions.length > 0) {
                      d.responseOptions.map((data: any) => {
                        if (
                          (stateData?.key == 'true' ||
                            stateData?.key == true) &&
                          d?.isScored == 'No'
                        ) {
                          structure.responseOptions.push({
                            optionLabel: false,
                            optionContent: false,
                          });
                        } else if (d.responseType === 'Open') {
                          structure.responseOptions.push({
                            optionLabel: false,
                            optionContent: false,
                          });
                        } else if (d.responseType === 'Text') {
                          const sanitizedOptionContent =
                            data.optionContent.replace(/\u200B/g, '');

                          const vd = {
                            optionLabel: false,
                            optionContent:
                              sanitizedOptionContent
                                .replace(/<p><br><\/p>/g, '') // remove empty <p> tags and <br> tags
                                .trim() === '',
                          };
                          structure.responseOptions.push(vd);
                        } else {
                          const sanitizedOptionContent =
                            data.optionContent.replace(/\u200B/g, '');

                          const vd = {
                            optionLabel: data.optionLabel === '' ? true : false,
                            optionContent:
                              sanitizedOptionContent
                                .replace(/<p><br><\/p>/g, '') // remove empty <p> tags and <br> tags
                                .trim() === '',
                          };
                          structure.responseOptions.push(vd);
                        }
                      });
                    } else {
                      structure.responseOption = true;
                    }
                  } else {
                    structure = {};
                  }
                  return structure;
                });
                const error = await Promise.all(errorMap);

                for (let i = 0; i < error.length; i++) {
                  const obj = error[i];

                  if (Object.keys(obj).length === 0) {
                    continue; // skip empty object
                  }

                  if (obj.questionContent !== false) {
                    isAllFalse = false;
                    break;
                  }

                  if (obj.questionLabel !== false) {
                    isAllFalse = false;
                    break;
                  }

                  if (obj.answer !== false) {
                    isAllFalse = false;
                    break;
                  }

                  if (obj.responseOption !== false) {
                    isAllFalse = false;
                    break;
                  }

                  // if (obj.responseOptions.length === ) {
                  for (let j = 0; j < obj.responseOptions.length; j++) {
                    const option = obj.responseOptions[j];

                    if (option.optionContent !== false) {
                      isAllFalse = false;
                      break;
                    }

                    if (option.optionLabel !== false) {
                      isAllFalse = false;
                      break;
                    }
                  }
                  // } else {
                  //   Alert.info({
                  //     title: 'Fleez',
                  //     text: '',
                  //   });
                  //   isAllFalse = false;
                  //   break;
                  // }
                }

                setError(() => {
                  return error;
                });

                if (
                  (isAllFalse && values?.filter === 'true') ||
                  values?.filter === true
                ) {
                  if (stateData?.key == true || stateData?.key == 'true') {
                    if (val?.filterQuestion?.filterQuestion == '') {
                      isAllFalse = false;
                      Alert.info({
                        title: 'Please add filter question',
                        text: '',
                      }).then(() => {
                        setFilterOpen(true);
                      });
                      return;
                    }
                  } else {
                    if (
                      val?.filterQuestion?.filterQuestion == '' ||
                      val?.filterQuestion?.filterResponse == ''
                    ) {
                      isAllFalse = false;
                      Alert.info({
                        title: 'Please add filter question',
                        text: '',
                      }).then(() => {
                        setFilterOpen(true);
                      });
                      return;
                    }
                  }
                }
              } else {
                // values.is_published = false;
                // if (state.action === 'Create') {
                //   setButton(true);
                //   dispatch(
                //     addAssessment(values, '', '', navigate, setButton)
                //   );
                // }

                // if (state.action === 'Version') {
                //   // if (state.version == true) {
                //   //   values.is_published = true;
                //   // } else {
                //   //   values.is_published = true;
                //   // }
                //   values.is_published = true;
                //   if (isVersionFirstTime && state.version) {
                //     setButton(true);
                //     dispatch(
                //       addAssessmentVersion(values, '', '', navigate, setButton)
                //     );
                //     setIsVersionFirstTime(false);
                //   } else {
                //     setButton(true);
                //     dispatch(
                //       editAssessmentData(
                //         values,
                //         state.action,
                //         state.version,
                //         navigate,
                //         setButton
                //       )
                //     );
                //   }
                // }
                // if (state.action === 'Update') {
                //   setButton(true);
                //   dispatch(
                //     editAssessmentData(
                //       values,
                //       state.action,
                //       state.version,
                //       navigate,
                //       setButton
                //     )
                //   );
                // }
                values.is_published = false;
                if (state.action === 'Create') {
                  setButton(true);
                  dispatch(
                    addAssessment(
                      values,
                      '',
                      '',
                      navigate,
                      setButton,
                      view,
                      setView
                    )
                  );
                }
                if (state.action === 'Version') {
                  setButton(true);
                  dispatch(
                    addAssessmentVersion(
                      values,
                      '',
                      '',
                      navigate,
                      setButton,
                      view,
                      setView
                    )
                  );
                }
                if (state.action === 'Update') {
                  setButton(true);
                  dispatch(
                    editAssessmentData(
                      values,
                      state.action,
                      state.version,
                      navigate,
                      setButton,
                      false,
                      view,
                      setView
                    )
                  );
                }
              }
            }

            if (filterData.length > 0) {
              if (isAllFalse) {
                if (state.action === 'Create') {
                  setButton(true);
                  dispatch(
                    addAssessment(
                      values,
                      '',
                      '',
                      navigate,
                      setButton,
                      view,
                      setView
                    )
                  );
                }

                if (state.action === 'Version') {
                  // if (state.version == true) {
                  //   values.is_published = true;
                  // } else {
                  //   values.is_published = true;
                  // }
                  values.is_published = true;

                  dispatch(
                    addAssessmentVersion(
                      values,
                      '',
                      '',
                      navigate,
                      setButton,
                      view,
                      setView
                    )
                  );
                }
                if (state.action === 'Update') {
                  setButton(true);
                  dispatch(
                    editAssessmentData(
                      values,
                      'Update',
                      '',
                      navigate,
                      setButton,
                      false,
                      view,
                      setView
                    )
                  );
                }
                if (view) {
                  window.open(
                    `${window.location.origin}/viewassessment?id=${stateData.question_id}`,
                    '_blank'
                  );
                  setView(false);
                }
                // if (state.action === 'Version') {
                //   setButton(true);
                //   dispatch(
                //     addAssessmentVersion(values, '', '', navigate, setButton)
                //   );
                // }
              } else {
                if (state.version) {
                  Alert.info({
                    title: 'Please fill all the required fields',
                    text: '',
                  });
                }
              }
            }
            setSubmitting(false);
            setIsTyping(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            isValid,
            setFieldValue,
            setFieldTouched,
            isSubmitting,
            handleBlur,
            handleChange,
          }) => (
            <Form>
              <Field
                name='discipline'
                as={Autocomplete}
                className='max-w-sm mb-8'
                value={values?.discipline || {}}
                size='small'
                options={disciplineData}
                getOptionLabel={(option: any) => option?.name}
                isOptionEqualToValue={(option: any, current: any) =>
                  option.id === current.id
                }
                onChange={(event: React.SyntheticEvent, discipline: any) => {
                  if (discipline?.name == 'ADD NEW DISCIPLINE') {
                    setOpenForm({
                      ...openForm,
                      isOpen: true,
                      callback: setFieldValue,
                    });
                    setInitialValue({ ...initialValues, discipline: '' });
                  } else {
                    setFieldValue('discipline', discipline);
                  }
                }}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    label='Discipline'
                    variant='outlined'
                    placeholder='Select'
                    error={
                      errors.discipline_id && touched.discipline_id
                        ? true
                        : false
                    }
                    helperText={
                      errors.discipline && touched.discipline
                        ? errors.discipline
                        : ''
                    }
                    required
                  />
                )}
              />

              <div className='grid grid-cols-3 gap-x-16 gap-y-8 mb-6'>
                <Field
                  value={values.officialAssessmentName}
                  as={TextField}
                  size='small'
                  label='Official Assessment Name'
                  variant='outlined'
                  required
                  name='officialAssessmentName'
                  // value={values.officialAssessmentName}
                  helperText={
                    errors.officialAssessmentName &&
                    touched.officialAssessmentName
                      ? errors.officialAssessmentName
                      : ''
                  }
                  fullWidth
                  error={
                    errors.officialAssessmentName &&
                    touched.officialAssessmentName
                  }
                  onChange={(e: any) => {
                    handleChange(e);
                    setFieldValue(
                      'officialAssessmentName',
                      e.target.value,
                      true
                    ); // force validation
                    setIsTyping(true);
                  }}
                  onBlur={(e: any) => {
                    handleBlur(e);
                    setIsTyping(false);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        {isTyping &&
                          (errors.officialAssessmentName ? (
                            <span style={{ color: 'red' }}>✖</span>
                          ) : (
                            values.officialAssessmentName && (
                              <span style={{ color: 'green' }}>✔</span>
                            )
                          ))}
                      </InputAdornment>
                    ),
                  }}
                />

                <Field
                  value={values.publicAssessmentName}
                  variant='outlined'
                  as={TextField}
                  size='small'
                  label='Public View Assessment Name'
                  required
                  name='publicAssessmentName'
                  helperText={
                    errors.publicAssessmentName && touched.publicAssessmentName
                      ? errors.publicAssessmentName
                      : ''
                  }
                  fullWidth
                  error={
                    errors.publicAssessmentName && touched.publicAssessmentName
                  }
                  onChange={(e: any) => {
                    handleChange(e);
                    setFieldValue('publicAssessmentName', e.target.value, true); // force validation
                    setIsTyping(true);
                  }}
                  onBlur={(e: any) => {
                    handleBlur(e);
                    setIsTyping(false);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        {isTyping &&
                          (errors.publicAssessmentName ? (
                            <span style={{ color: 'red' }}>✖</span>
                          ) : (
                            values.publicAssessmentName && (
                              <span style={{ color: 'green' }}>✔</span>
                            )
                          ))}
                      </InputAdornment>
                    ),
                  }}
                />

                <Field
                  variant='outlined'
                  as={TextField}
                  size='small'
                  label='Assessment Code'
                  required
                  name='assessmentCode'
                  helperText={
                    errors.assessmentCode && touched.assessmentCode
                      ? errors.assessmentCode
                      : ''
                  }
                  fullWidth
                  error={errors.assessmentCode && touched.assessmentCode}
                  onChange={(e: any) => {
                    handleChange(e);
                    setFieldValue('assessmentCode', e.target.value, true); // force validation
                    setIsTyping(true);
                  }}
                  onBlur={(e: any) => {
                    handleBlur(e);
                    setIsTyping(false);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        {isTyping &&
                          (errors.assessmentCode ? (
                            <span style={{ color: 'red' }}>✖</span>
                          ) : (
                            values.assessmentCode && (
                              <span style={{ color: 'green' }}>✔</span>
                            )
                          ))}
                      </InputAdornment>
                    ),
                  }}
                />

                <Field
                  variant='outlined'
                  as={TextField}
                  size='small'
                  label='Public Code:'
                  required
                  name='publicCode'
                  value={values.publicCode}
                  helperText={
                    errors.publicCode && touched.publicCode
                      ? errors.publicCode
                      : ''
                  }
                  fullWidth
                  error={errors.publicCode && touched.publicCode}
                  onChange={(e: any) => {
                    handleChange(e);
                    setFieldValue('publicCode', e.target.value, true); // force validation
                    setIsTyping(true);
                  }}
                  onBlur={(e: any) => {
                    handleBlur(e);
                    setIsTyping(false);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        {isTyping &&
                          (errors.publicCode ? (
                            <span style={{ color: 'red' }}>✖</span>
                          ) : (
                            values.publicCode && (
                              <span style={{ color: 'green' }}>✔</span>
                            )
                          ))}
                      </InputAdornment>
                    ),
                  }}
                />

                <Field
                  variant='outlined'
                  as={TextField}
                  size='small'
                  label='Minimum Duration(In Minutes)'
                  required
                  name='minimum_duration'
                  value={values.minimum_duration}
                  helperText={
                    errors.minimum_duration && touched.minimum_duration
                      ? errors.minimum_duration
                      : ''
                  }
                  fullWidth
                  error={errors.minimum_duration && touched.minimum_duration}
                  onChange={(e: any) => {
                    handleChange(e);
                    setFieldValue('minimum_duration', e.target.value, true); // force validation
                    setIsTyping(true);
                  }}
                  onBlur={(e: any) => {
                    handleBlur(e);
                    setIsTyping(false);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        {isTyping &&
                          (errors.minimum_duration ? (
                            <span style={{ color: 'red' }}>✖</span>
                          ) : (
                            values.minimum_duration && (
                              <span style={{ color: 'green' }}>✔</span>
                            )
                          ))}
                      </InputAdornment>
                    ),
                  }}
                />

                <FormControl fullWidth variant='outlined' size='small' required>
                  <InputLabel id='demo-simple-select-standard-label'>
                    Question Label Type
                  </InputLabel>
                  <Field
                    // disabled
                    id='demo-simple-select-standard'
                    as={Select}
                    name='questionType'
                    labelId='demo-simple-select-standard-label'
                    label=' Question Label Type'
                    onChange={(e: any) => {
                      if (e.target.value === '1,2,3') {
                        Swal.fire({
                          title: 'Are you sure?',
                          text: `You may lose your custom label's if it changes!`,
                          icon: 'warning',
                          showCancelButton: true,
                          confirmButtonColor: '#3085d6',
                          cancelButtonColor: '#d33',
                          confirmButtonText: 'Yes, change it!',
                        }).then((result) => {
                          if (result.isConfirmed) {
                            setVal((prevVal: any) => {
                              const value: any = { ...prevVal };
                              value.data.map((d: any, i: any) => {
                                if (d.type === 'question') {
                                  d.questionLabel = i + 1;
                                }
                                return value;
                              });
                              return value;
                            });
                            setStateData((data: any) => ({
                              ...data,
                              questionType: e.target.value,
                            }));
                            setFieldValue('questionType', e?.target?.value);
                          }
                        });
                      } else {
                        setVal((prevVal: any) => {
                          const value: any = { ...prevVal };
                          value.data.map((d: any, i: any) => {
                            if (d.type === 'question') {
                              d.questionLabel = '';
                            }
                            return value;
                          });
                          return value;
                        });
                        setStateData((data: any) => ({
                          ...data,
                          questionType: e.target.value,
                        }));
                        setFieldValue('questionType', e?.target?.value);
                      }
                    }}
                  >
                    <MenuItem value='Custom'>Custom</MenuItem>
                    <MenuItem value='1,2,3'>1,2,3</MenuItem>
                  </Field>
                  <ErrorMessage
                    name='questionType'
                    component='span'
                    className='text-red-600 ml-2 text-sm'
                  />
                </FormControl>

                <FormControl fullWidth variant='outlined' size='small' required>
                  <InputLabel id='demo-simple-select-standard-label'>
                    Option Label Type
                  </InputLabel>
                  <Field
                    // disabled
                    id='demo-simple-select-standard'
                    as={Select}
                    name='optionType'
                    labelId='demo-simple-select-standard-label'
                    label='Option Label Type'
                    onChange={(e: any) => {
                      if (values.optionType === 'Custom') {
                        Swal.fire({
                          title: 'Are you sure?',
                          text: `You may lose your custom label's if it changes!`,
                          icon: 'warning',
                          showCancelButton: true,
                          confirmButtonColor: '#3085d6',
                          cancelButtonColor: '#d33',
                          confirmButtonText: 'Yes, change it!',
                        }).then((result) => {
                          if (result.isConfirmed) {
                            setVal((prevVal: any) => {
                              const value: any = { ...prevVal };
                              value.data.map((d: any, i: any) => {
                                if (d.type === 'question') {
                                  if (e.target.value === 'a,b,c') {
                                    d.responseOptions.map(
                                      (data: any, index: any) => {
                                        data.optionLabel =
                                          getAlphabetFromNumber(index + 1);
                                        return data;
                                      }
                                    );

                                    if (
                                      stateData?.likert == 'true' ||
                                      stateData?.likert == true
                                    ) {
                                      likert.responseOptions.map(
                                        (data: any, index: any) => {
                                          data.optionLabel =
                                            getAlphabetFromNumber(index + 1);
                                          return data;
                                        }
                                      );
                                      setLikert(likert);
                                    }
                                  }
                                  if (e.target.value === '1,2,3') {
                                    d.responseOptions.map(
                                      (data: any, index: any) => {
                                        data.optionLabel = index + 1;
                                        return data;
                                      }
                                    );

                                    if (
                                      stateData?.likert == 'true' ||
                                      stateData?.likert == true
                                    ) {
                                      likert.responseOptions.map(
                                        (data: any, index: any) => {
                                          data.optionLabel = index + 1;
                                          return data;
                                        }
                                      );
                                      setLikert(likert);
                                    }
                                  }
                                }
                                return value;
                              });
                              return value;
                            });
                            setStateData((data: any) => ({
                              ...data,
                              optionType: e.target.value,
                            }));
                            setFieldValue('optionType', e?.target?.value);
                          }
                        });
                      } else {
                        setVal((prevVal: any) => {
                          const value: any = { ...prevVal };
                          value.data.map((d: any, i: any) => {
                            if (d.type === 'question') {
                              if (e.target.value === 'a,b,c') {
                                d.responseOptions.map(
                                  (data: any, index: any) => {
                                    data.optionLabel = getAlphabetFromNumber(
                                      index + 1
                                    );
                                    return data;
                                  }
                                );

                                if (
                                  stateData?.likert == 'true' ||
                                  stateData?.likert == true
                                ) {
                                  likert.responseOptions.map(
                                    (data: any, index: any) => {
                                      data.optionLabel = getAlphabetFromNumber(
                                        index + 1
                                      );
                                      return data;
                                    }
                                  );
                                  setLikert(likert);
                                }
                              }
                              if (e.target.value === '1,2,3') {
                                d.responseOptions.map(
                                  (data: any, index: any) => {
                                    data.optionLabel = index + 1;
                                    return data;
                                  }
                                );

                                if (
                                  stateData?.likert == 'true' ||
                                  stateData?.likert == true
                                ) {
                                  likert.responseOptions.map(
                                    (data: any, index: any) => {
                                      data.optionLabel = index + 1;
                                      return data;
                                    }
                                  );
                                  setLikert(likert);
                                }
                              }
                              if (e.target.value === 'Custom') {
                                d.responseOptions.map((data: any) => {
                                  data.optionLabel = '';
                                  return data;
                                });

                                if (
                                  stateData?.likert == 'true' ||
                                  stateData?.likert == true
                                ) {
                                  likert.responseOptions.map((data: any) => {
                                    data.optionLabel = '';
                                    return data;
                                  });
                                  setLikert(likert);
                                }
                              }
                            }
                            return value;
                          });
                          return value;
                        });
                        setStateData((data: any) => ({
                          ...data,
                          optionType: e.target.value,
                        }));
                        setFieldValue('optionType', e?.target?.value);
                      }
                    }}
                  >
                    <MenuItem value='Custom'>Custom</MenuItem>
                    <MenuItem value='a,b,c'>a,b,c</MenuItem>
                    <MenuItem value='1,2,3'>1,2,3</MenuItem>
                  </Field>
                  <ErrorMessage
                    name='optionType'
                    component='span'
                    className='text-red-600 ml-2 text-sm'
                  />
                </FormControl>

                <FormControl fullWidth variant='outlined' size='small' required>
                  <InputLabel id='demo-simple-select-standard-label'>
                    Assessment key
                  </InputLabel>
                  <Field
                    // disabled
                    id='demo-simple-select-standard'
                    as={Select}
                    name='key'
                    labelId='demo-simple-select-standard-label'
                    label='Assessment key'
                    onChange={(e: any) => {
                      if (e.target.value === 'true') {
                        setFieldValue('key', e?.target?.value);
                        setStateData((data: any) => ({
                          ...data,
                          key: e.target.value,
                        }));
                      } else {
                        Swal.fire({
                          title: 'Are you sure?',
                          text: 'You may lose your selected key if it changes!',
                          icon: 'warning',
                          showCancelButton: true,
                          confirmButtonColor: '#3085d6',
                          cancelButtonColor: '#d33',
                          confirmButtonText: 'Yes, change it!',
                        }).then((result) => {
                          if (result.isConfirmed) {
                            setFieldValue('key', e?.target?.value);
                            setStateData((data: any) => ({
                              ...data,
                              key: e.target.value,
                            }));

                            setVal((prevVal: any) => {
                              const value: any = { ...prevVal };
                              value.data.map((d: any, i: any) => {
                                if (d.type === 'question') {
                                  if (e.target.value === 'false') {
                                    d.defaultAnswer = '';
                                    d.isScored = 'No';
                                  }
                                }
                                return value;
                              });
                              return value;
                            });
                          }
                        });
                      }
                    }}
                  >
                    <MenuItem value='true'>Yes</MenuItem>
                    <MenuItem value='false'>No</MenuItem>
                  </Field>
                  <ErrorMessage
                    name='key'
                    component='span'
                    className='text-red-600 ml-2 text-sm'
                  />
                </FormControl>

                <FormControl fullWidth variant='outlined' size='small' required>
                  <InputLabel id='demo-simple-select-standard-label'>
                    Likert
                  </InputLabel>
                  <Field
                    // disabled
                    id='demo-simple-select-standard'
                    as={Select}
                    name='likert'
                    labelId='demo-simple-select-standard-label'
                    defaultValue='false'
                    label='Likert'
                    onChange={(e: any) => {
                      if (e?.target?.value === 'true') {
                        Swal.fire({
                          title: 'Are you sure?',
                          text: 'You may lose your option content if it changes!',
                          icon: 'warning',
                          showCancelButton: true,
                          confirmButtonColor: '#3085d6',
                          cancelButtonColor: '#d33',
                          confirmButtonText: 'Yes, change it!',
                        }).then((result) => {
                          if (result.isConfirmed) {
                            setFieldValue('likert', e?.target?.value);
                            setStateData((data: any) => ({
                              ...data,
                              likert: e.target.value,
                            }));

                            setVal((prevVal: any) => {
                              const value: any = { ...prevVal };
                              value.data.map((d: any, i: any) => {
                                if (d.type === 'question') {
                                  d.responseOptions = [];
                                  d.responseType = 'Single';
                                }
                                return value;
                              });
                              value.likert.responseOptions = [];
                              return value;
                            });
                          }
                        });
                      } else {
                        setFieldValue('likert', e?.target?.value);
                        setStateData((data: any) => ({
                          ...data,
                          likert: e.target.value,
                        }));
                        setLikert((prevVal: any) => ({
                          ...prevVal,
                          responseOptions: [],
                        }));
                        setVal((prevVal: any) => {
                          const value: any = { ...prevVal };
                          value.likert.responseOptions = [];
                          return value;
                        });
                      }
                    }}
                  >
                    <MenuItem value='true'>Yes</MenuItem>
                    <MenuItem value='false'>No</MenuItem>
                  </Field>
                  <ErrorMessage
                    name='likert'
                    component='span'
                    className='text-red-600 ml-2 text-sm'
                  />
                </FormControl>

                <FormControl fullWidth variant='outlined' size='small' required>
                  <InputLabel id='demo-simple-select-standard-label'>
                    Filter Question
                  </InputLabel>
                  <Field
                    // disabled
                    id='demo-simple-select-standard'
                    as={Select}
                    name='filter'
                    labelId='demo-simple-select-standard-label'
                    label='filter question'
                    onChange={(e: any) => {
                      setFieldValue('filter', e?.target?.value);
                      if (e?.target?.value === 'false') {
                        setFilterQuestion({
                          filterQuestion: '',
                          filterResponse: '',
                        });
                      }
                    }}
                  >
                    <MenuItem value='true'>Yes</MenuItem>
                    <MenuItem value='false'>No</MenuItem>
                  </Field>
                  <ErrorMessage
                    name='likert'
                    component='span'
                    className='text-red-600 ml-2 text-sm'
                  />
                </FormControl>
                <Grid item>
                  <label className='cursor-pointer'>
                    <Field
                      as={Checkbox}
                      sx={{ marginLeft: '-10px' }}
                      name='under_development' // Bind to under_development field
                      checked={values?.under_development}
                      onChange={(e: any) => {
                        // Update under_development field based on checkbox state
                        setFieldValue('under_development', e.target.checked);
                      }}
                    />
                    <span className='ml-1'>Under Development</span>
                  </label>
                </Grid>
                <Box
                  style={{
                    padding: '20px',
                    maxWidth: '400px',
                    margin: '0px 3rem 0px 0px',
                    backgroundColor: '#f9f9f9',
                    borderRadius: '8px',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                  }}
                >
                  <input
                    type='file'
                    accept='image/*'
                    style={{ display: 'none' }}
                    ref={fileInputRef}
                    onChange={(e) =>
                      handleImageChange(e, setFieldValue, clearImage)
                    }
                    onClick={(e) => ((e.target as HTMLInputElement).value = '')}
                  />
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={handleButtonClick}
                    style={{ marginBottom: '20px', width: '100%' }}
                  >
                    Choose Image
                  </Button>
                  {values.reference_image && (
                    <Box
                      style={{
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginBottom: '20px',
                        backgroundColor: '#fff',
                        padding: '10px',
                        borderRadius: '8px',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                      }}
                    >
                      <img
                        style={{
                          width: '100px',
                          height: '100px',
                          borderRadius: '8px',
                          marginBottom: '10px',
                          objectFit: 'cover',
                        }}
                        src={values.reference_image}
                        alt='Uploaded'
                      />
                      <IconButton
                        style={{
                          position: 'absolute',
                          top: 5,
                          right: 5,
                          backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        }}
                        onClick={() => clearImage(setFieldValue)}
                      >
                        <CancelRounded />
                      </IconButton>
                      <Field
                        as={TextField}
                        value={values.image_description}
                        variant='outlined'
                        size='small'
                        label='Image Description'
                        name='image_description'
                        fullWidth
                        style={{ marginTop: '10px' }}
                      />
                    </Box>
                  )}
                </Box>
              </div>
              <hr className='mb-6 border border-gray-200'></hr>
              <div className='flex flex-row flex-wrap items-center gap-5'>
                <Button
                  size='medium'
                  variant='contained'
                  onClick={() => {
                    setOpen(true);
                  }}
                  style={{ textTransform: 'capitalize', minWidth: '150px' }}
                  disabled={imageAlternateData.length <= 0}
                >
                  View Image Alternative Text
                </Button>
                <Button
                  disabled={values?.filter == 'false' ? true : false}
                  size='medium'
                  variant='contained'
                  onClick={() => {
                    setFilterOpen(true);
                  }}
                  style={
                    values?.filter == 'true' || values?.filter == true
                      ? { textTransform: 'capitalize', minWidth: '150px' }
                      : { display: 'none' }
                  }
                >
                  Filter Option
                </Button>
                <AssessSpecificQuestion
                  handleSaveAssessSpecQue={handleSaveAssessSpecQue}
                  question={assessSpecQuestion}
                />
                <Button
                  variant='contained'
                  size='medium'
                  onClick={() => {
                    Alert.confirm(() => {
                      navigate('/assessment');
                    });
                  }}
                  style={{ textTransform: 'capitalize', minWidth: '150px' }}
                >
                  Close
                </Button>
                <Button
                  size='medium'
                  variant='contained'
                  type='submit'
                  onClick={() => {
                    state.version = false;
                  }}
                  disabled={!isValid || button}
                  style={{ textTransform: 'capitalize', minWidth: '150px' }}
                >
                  {state?.action == 'Version' ? 'Update' : state?.action}
                </Button>
                <Button
                  size='medium'
                  variant='contained'
                  onClick={() => {
                    state.version = true;
                  }}
                  type='submit'
                  disabled={!isValid || button}
                  style={{ textTransform: 'none', minWidth: '150px' }}
                >
                  Save and Publish
                </Button>
                <Button
                  size='medium'
                  variant='contained'
                  type='submit'
                  onClick={() => {
                    state.version = false;
                    setView(true);
                  }}
                  disabled={!isValid || button}
                  style={{ textTransform: 'capitalize', minWidth: '150px' }}
                >
                  Save and View
                </Button>
                <Button
                  size='medium'
                  variant='contained'
                  onClick={() => setLikertOpen(true)}
                  style={
                    stateData?.likert == 'true' || stateData?.likert == true
                      ? { textTransform: 'none', minWidth: '150px' }
                      : { display: 'none' }
                  }
                >
                  Edit Likert Response
                </Button>
                <Button
                  size='medium'
                  variant='contained'
                  onClick={() => setItemOpen(true)}
                  style={{ textTransform: 'none', minWidth: '150px' }}
                >
                  Attach Test Items
                </Button>

                <Button
                  size='medium'
                  variant='contained'
                  onClick={() => setReportsOpen(true)}
                  style={{ textTransform: 'none', minWidth: '150px' }}
                >
                  Attach Reports
                </Button>
              </div>
              {/* <pre>
                {JSON.stringify(
                  {
                    key: values.key,
                    filter: values.filter,
                    likert: values.likert,

                    filterQuestion: filterQuestion,
                  },
                  null,
                  2
                )}
              </pre>
              <pre>{JSON.stringify(values, null, 2)}</pre> */}
            </Form>
          )}
        </Formik>
      </div>

      <div className='mb-5 max-w-7xl w-[92%] border-[#D5D5D5] grid grid-cols-8 gap-6 items-center'>
        <Button
          variant='contained'
          className='col-span-2'
          style={{ backgroundColor: '#335D88' }}
          onClick={() => {
            if (Instruction) {
              setVal((prevVal: any) => ({ ...prevVal, instruction: '' }));
              setInstruction(false);
            } else {
              setInstruction(true);
            }
          }}
        >
          {Instruction ? 'Remove Instruction' : 'Add Instruction'}
        </Button>
        <div className={`${!Instruction ? 'hidden' : 'h-full col-span-6'}`}>
          <SunEditor
            setContents={val?.instruction}
            height='100%'
            width='100%'
            name='instruction'
            autoFocus={false}
            placeholder='Instruction'
            onChange={(e: any) => {
              setVal((prevVal: any) => ({ ...prevVal, instruction: e }));
            }}
            setDefaultStyle='white-space: pre-wrap;'
            setOptions={{
              katex: katex,
              fontSize: fontSizeOptions,
              mathFontSize: mathFontSizeOptions,
              mode: 'classic',
              buttonList: [
                [
                  'undo',
                  'redo',
                  'font',
                  'fontSize',
                  'formatBlock',
                  'blockquote',
                  'bold',
                  'underline',
                  'italic',
                  'strike',
                  'subscript',
                  'superscript',
                  'fontColor',
                  'hiliteColor',
                  'textStyle',
                  'removeFormat',
                  'outdent',
                  'indent',
                  'align',
                  'horizontalRule',
                  'list',
                  'lineHeight',
                  'fullScreen',
                  'showBlocks',
                  'codeView',
                  'preview',
                  'print',
                  'link',
                  'horizontalRule',
                  'math',
                  'table',
                  'image',
                ],
              ],
            }}
          />
        </div>
        <Button
          variant='contained'
          className='col-span-2'
          onClick={handleadd}
          style={
            val.data?.length
              ? { display: 'none' }
              : { backgroundColor: '#335D88' }
          }
        >
          Add Question
        </Button>
      </div>

      <div className={`max-w-[85rem] flex flex-row`}>
        <div className='w-[94%]'>
          {val?.data &&
            // currentItems?.length > 0 &&
            val?.data?.map((data: any, i: any) => {
              const actionBar = (
                <Paper
                  elevation={3}
                  className={` ${
                    val?.length === 1
                      ? 'hidden'
                      : 'bg-[#F3F3F3] p-3 rounded-md ml-2 h-fit flex flex-col gap-y-3'
                  }`}
                >
                  <Tooltip title='Add question' placement='right' arrow>
                    <Fab
                      onClick={() => handleAddAfter(i)}
                      size='small'
                      color='primary'
                      aria-label='add'
                    >
                      <AddCircleOutlineIcon />
                    </Fab>
                  </Tooltip>
                  <div
                    className={` ${
                      (currentPageRef.current - 1) * itemsPerPage + i === 0
                        ? 'hidden'
                        : ''
                    }`}
                  >
                    <Tooltip title='Move up' placement='right' arrow>
                      <Fab
                        style={
                          (currentPageRef.current - 1) * itemsPerPage + i == 0
                            ? { display: 'none' }
                            : {}
                        }
                        onClick={() => moveUp(i)}
                        size='small'
                        color='primary'
                        aria-label='move up'
                      >
                        <ArrowCircleUpIcon />
                      </Fab>
                    </Tooltip>
                  </div>
                  <div
                    className={`${
                      (currentPageRef.current - 1) * itemsPerPage + i + 1 >=
                      val.data.length
                        ? 'hidden'
                        : ''
                    }`}
                  >
                    <Tooltip title='Move down' placement='right' arrow>
                      <Fab
                        style={
                          (currentPageRef.current - 1) * itemsPerPage + i + 1 >=
                          val.data.length
                            ? { display: 'none' }
                            : {}
                        }
                        onClick={() => moveDown(i)}
                        size='small'
                        color='primary'
                        aria-label='move down'
                      >
                        <ArrowCircleDownIcon />
                      </Fab>
                    </Tooltip>
                  </div>

                  <Tooltip title='Remove' placement='right' arrow>
                    <Fab
                      onClick={() => handledelete(i)}
                      size='small'
                      color='primary'
                      aria-label='remove'
                    >
                      <RemoveCircleOutlineIcon />
                    </Fab>
                  </Tooltip>

                  <Tooltip title='Add framing' placement='right' arrow>
                    <Fab
                      onClick={() =>
                        handleAddFrame(val?.framing?.length + 1, i)
                      }
                      size='small'
                      color='primary'
                      aria-label='Add Framing Content'
                    >
                      <QueueOutlinedIcon />
                    </Fab>
                  </Tooltip>

                  <Tooltip title='Copy' placement='right' arrow>
                    <Fab
                      onClick={() => handleCopy(i)}
                      size='small'
                      color='primary'
                      aria-label='Copy'
                    >
                      <ContentCopyIcon />
                    </Fab>
                  </Tooltip>
                </Paper>
              );
              if (data.type === 'question') {
                questionIndex =
                  questionIndex +
                  (currentPageRef.current - 1) * itemsPerPage +
                  1;
                return (
                  //{/* question box divided into four */}
                  <div className='flex flex-row items-center mb-5'>
                    <Accordion
                      // ref={contentBlocksRef[i]}
                      sx={{
                        borderRadius: '5px',
                        marginBottom: '10px',
                        backgroundColor: 'rgb(248, 250, 252)',
                      }}
                      className='p-3 w-full border border-[#D5D5D5]'
                      defaultExpanded={true}
                      // elevation={activeBlock === i ? 12 : 0}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant='h6' color='primary'>
                          Question
                        </Typography>{' '}
                        <TextField
                          disabled={
                            stateData?.questionType == 'Custom' ? false : true
                          }
                          size='small'
                          // className='col-span-1 '
                          sx={{ marginLeft: '21px', width: '250px' }}
                          variant='outlined'
                          // label='Question Label'
                          name='questionLabel'
                          onChange={(e) => {
                            columnchange(e, i);
                          }}
                          value={data.questionLabel}
                          type='text'
                          // inputProps={{ maxLength: 3 }}
                          error={
                            error?.[
                              (currentPageRef.current - 1) * itemsPerPage + i
                            ]?.questionLabel
                          }
                          onClick={(e: any) => {
                            e.stopPropagation();
                          }}
                        />
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className='grid grid-cols-12 gap-x-2 gap-y-4'>
                          {/* 1 */}
                          {/* <TextField
                            disabled={
                              stateData?.questionType == 'Custom' ? false : true
                            }
                            size='small'
                            className='col-span-1 '
                            sx={{ marginTop: '21px', width: '96px' }}
                            variant='outlined'
                            label='Question Label'
                            name='questionLabel'
                            onChange={(e) => {
                              columnchange(e, i);
                            }}
                            value={data.questionLabel}
                            type='text'
                            inputProps={{ maxLength: 3 }}
                            error={
                              error?.[
                                (currentPageRef.current - 1) * itemsPerPage + i
                              ]?.questionLabel
                            }
                          /> */}

                          {/* 2 */}
                          <div className='col-span-11 max-h-max pl-10'>
                            <label style={{ fontSize: '12px' }}>
                              Question Content:
                            </label>
                            {activeEditor === `Question-${i}` ? (
                              <SunEditor
                                // setContents="My contents"
                                setContents={data?.questionContent}
                                // height="100%"
                                // width="400"
                                // onBlur={() => {
                                //   setActiveEditor(null); // Reset the active editor when focus is lost
                                // }}
                                name='questionContent'
                                // autoFocus={false}
                                onChange={(e: any) => {
                                  columnchange(
                                    {
                                      target: {
                                        name: 'questionContent',
                                        value: e,
                                      },
                                    },
                                    i
                                  );
                                }}
                                // error={error?.[i]?.questionLabel}
                                setDefaultStyle={
                                  error?.[
                                    (currentPageRef.current - 1) *
                                      itemsPerPage +
                                      i
                                  ]?.questionContent
                                    ? 'height: auto; border : 1px solid red; white-space: pre-wrap;'
                                    : 'height: auto; white-space: pre-wrap;'
                                }
                                setOptions={{
                                  katex: katex,
                                  fontSize: fontSizeOptions,
                                  mathFontSize: mathFontSizeOptions,
                                  mode:
                                    stateData?.likert == 'true' ||
                                    stateData?.likert == true
                                      ? 'inline'
                                      : 'inline',
                                  plugins: [
                                    createMergeTag(
                                      'Question',
                                      filterData.filter(
                                        (_: any, index: any) => index < i
                                      )
                                    ),
                                    plugins.image,
                                    plugins.table,
                                    plugins.math, // for math
                                    plugins.horizontalRule,
                                    plugins.lineHeight,
                                    plugins.list,
                                    plugins.align,
                                    plugins.textStyle,
                                    plugins.fontColor,
                                    plugins.hiliteColor,
                                    plugins.blockquote,
                                    plugins.font,
                                    plugins.fontSize,
                                    plugins.formatBlock,
                                  ],
                                  buttonList: [
                                    [
                                      'undo',
                                      'redo',
                                      'font',
                                      'fontSize',
                                      'formatBlock',
                                      'blockquote',
                                      'bold',
                                      'underline',
                                      'italic',
                                      'strike',
                                      'subscript',
                                      'superscript',
                                      'fontColor',
                                      'hiliteColor',
                                      'textStyle',
                                      'removeFormat',
                                      'outdent',
                                      'indent',
                                      'align',
                                      'horizontalRule',
                                      'list',
                                      'lineHeight',
                                      'fullScreen',
                                      'showBlocks',
                                      'codeView',
                                      'preview',
                                      'print',
                                      'link',
                                      'math',
                                      'table',
                                      'image',
                                      'Attach Question',
                                    ],
                                  ],
                                }}
                              />
                            ) : (
                              <div
                                onDoubleClick={() => {
                                  setActiveEditor(`Question-${i}`);
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: `<div class="sun-editor-editable" style="padding-top: 0px; padding-bottom: 0px; max-width: 918px; min-height: 20px; white-space: pre-wrap;">${data?.questionContent?.replace(
                                    /\\/g,
                                    ''
                                  )}</div>`,
                                }}
                                style={{
                                  minHeight: '20px',
                                  border: error?.[
                                    (currentPageRef.current - 1) *
                                      itemsPerPage +
                                      i
                                  ]?.questionContent
                                    ? '1px solid red'
                                    : 'none',
                                  whiteSpace: 'pre-wrap',
                                }}
                              />
                            )}
                            {data?.responseType == 'Single' ||
                            data?.responseType == 'Multiple' ? (
                              error?.[
                                (currentPageRef.current - 1) * itemsPerPage + i
                              ]?.answer && data?.responseOptions?.length > 0 ? (
                                <div
                                  style={{
                                    color: 'red',
                                    fontSize: '14px',
                                    marginTop: '15px',
                                  }}
                                >
                                  Please select answer
                                </div>
                              ) : (
                                ''
                              )
                            ) : (
                              ''
                            )}

                            {error?.[
                              (currentPageRef.current - 1) * itemsPerPage + i
                            ]?.responseOption ? (
                              <div
                                style={{
                                  color: 'red',
                                  fontSize: '14px',
                                  marginTop: '15px',
                                }}
                              >
                                {' '}
                                Options are mandatory to publish assessment
                              </div>
                            ) : (
                              ''
                            )}
                          </div>

                          {/* 3 */}
                          <div className=' col-span-9'>
                            {/* option parts */}
                            <div className='grid gap-y-2'>
                              {data?.responseOptions?.map(
                                (d: any, index: any) => {
                                  if (
                                    data?.responseType == 'Single' ||
                                    data?.responseType == 'Multiple'
                                  ) {
                                    return (
                                      // single option divide into four part (2 + 10)
                                      <div className='grid grid-cols-12 gap-1'>
                                        {/* checkbox or radio button into 12 (2 + 10) */}
                                        <div className='mt-6 col-span-2 grid grid-cols-4 items-center'>
                                          {data?.responseType == 'Multiple' &&
                                          (stateData?.key == 'true' ||
                                            stateData?.key == true) ? (
                                            <Checkbox
                                              size='small'
                                              name={index + 1}
                                              checked={
                                                data?.defaultAnswer[
                                                  index + 1
                                                ] || false
                                              }
                                              disabled={
                                                data?.isScored == 'No' || false
                                              }
                                              onChange={(e) =>
                                                onCheckboxChange(
                                                  e,
                                                  i,
                                                  data?.responseOptions
                                                )
                                              }
                                            />
                                          ) : (
                                            ''
                                          )}
                                          {data?.responseType == 'Single' &&
                                          (stateData?.key === 'true' ||
                                            stateData?.key === true) ? (
                                            <Radio
                                              checked={
                                                JSON.stringify(index + 1) ==
                                                data?.defaultAnswer
                                              }
                                              disabled={
                                                data?.isScored == 'No' || false
                                              }
                                              // checked={selectedValue === 'a'}
                                              onChange={(e: any) =>
                                                handleRadioChange(e, i)
                                              }
                                              value={JSON.stringify(index + 1)}
                                              name='radio-buttons'
                                            />
                                          ) : (
                                            ''
                                          )}

                                          {/* text field displays option label */}
                                          <Tooltip
                                            title='Option Label: '
                                            placement='top'
                                          >
                                            <TextField
                                              error={
                                                error?.[
                                                  (currentPageRef.current - 1) *
                                                    itemsPerPage +
                                                    i
                                                ]?.responseOptions?.[index]
                                                  ?.optionLabel
                                              }
                                              inputProps={{ maxLength: 3 }}
                                              // disabled={
                                              //   stateData?.optionType !==
                                              //     'Custom' ||
                                              //   stateData?.likert == 'true' ||
                                              //   stateData?.likert == true
                                              //     ? true
                                              //     : false
                                              // }
                                              InputProps={{
                                                readOnly:
                                                  stateData?.optionType !==
                                                    'Custom' ||
                                                  stateData?.likert == 'true' ||
                                                  stateData?.likert == true
                                                    ? true
                                                    : false,
                                              }}
                                              style={{
                                                width: '60px',
                                              }}
                                              size='small'
                                              name='optionLabel'
                                              onChange={(e) =>
                                                columnOptionchange(e, i, index)
                                              }
                                              value={d.optionLabel}
                                              type='text'
                                            />
                                          </Tooltip>
                                        </div>

                                        <div className='col-span-10 mr-6'>
                                          <label style={{ fontSize: '12px' }}>
                                            Option Content:
                                          </label>
                                          {d?.optionType && (
                                            <span className='text-xs italic text-sky-600 font-bold'>
                                              ({d?.optionType})
                                            </span>
                                          )}

                                          {activeEditor ===
                                          `Question-${i}-response-${index}` ? (
                                            <SunEditor
                                              // setContents="My contents"
                                              setContents={d?.optionContent}
                                              // height="100%"
                                              // width="400"
                                              // onBlur={() => {
                                              //   setActiveEditor(null); // Reset the active editor when focus is lost
                                              // }}
                                              disable={(() => {
                                                if (
                                                  d?.optionType
                                                  // data?.responseType ==
                                                  //   'Multiple' &&
                                                  // (d?.optionContent ==
                                                  //   'None of the above' ||
                                                  //   d?.optionContent ==
                                                  //     '<p>None of the above</p>')
                                                ) {
                                                  setActiveEditor(null);
                                                  return true;
                                                } else return false;
                                              })()}
                                              name='optionContent'
                                              // autoFocus={false}
                                              onChange={(e: any) => {
                                                columnOptionchange(
                                                  {
                                                    target: {
                                                      name: 'optionContent',
                                                      value: e,
                                                    },
                                                  },
                                                  i,
                                                  index
                                                );
                                              }}
                                              setDefaultStyle={
                                                error?.[
                                                  (currentPageRef.current - 1) *
                                                    itemsPerPage +
                                                    i
                                                ]?.responseOptions?.[index]
                                                  ?.optionContent
                                                  ? 'height: auto; border : 1px solid red; white-space: pre-wrap;'
                                                  : 'height: auto; white-space: pre-wrap;'
                                              }
                                              setOptions={{
                                                katex: katex,
                                                fontSize: fontSizeOptions,
                                                mathFontSize:
                                                  mathFontSizeOptions,
                                                mode:
                                                  stateData?.likert == 'true' ||
                                                  stateData?.likert == true
                                                    ? 'inline'
                                                    : 'inline',
                                                buttonList: [
                                                  [
                                                    'undo',
                                                    'redo',
                                                    'font',
                                                    'fontSize',
                                                    'formatBlock',
                                                    'blockquote',
                                                    'bold',
                                                    'underline',
                                                    'italic',
                                                    'strike',
                                                    'subscript',
                                                    'superscript',
                                                    'fontColor',
                                                    'hiliteColor',
                                                    'textStyle',
                                                    'removeFormat',
                                                    'outdent',
                                                    'indent',
                                                    'align',
                                                    'horizontalRule',
                                                    'list',
                                                    'lineHeight',
                                                    'fullScreen',
                                                    'showBlocks',
                                                    'codeView',
                                                    'preview',
                                                    'print',
                                                    'link',
                                                    'horizontalRule',
                                                    'math',
                                                    'table',
                                                    'image',
                                                  ],
                                                ],
                                              }}
                                            />
                                          ) : (
                                            <div
                                              onDoubleClick={() => {
                                                if (
                                                  stateData?.likert ==
                                                    'false' ||
                                                  stateData?.likert == false
                                                ) {
                                                  setActiveEditor(
                                                    `Question-${i}-response-${index}`
                                                  ); // Set the active editor on double click
                                                }
                                              }}
                                              dangerouslySetInnerHTML={{
                                                __html: `<div class="sun-editor-editable" style="padding-top: 0px;
      padding-bottom: 0px; max-width: 918px; min-height: 20px;">${d?.optionContent?.replace(
        /\\/g,
        ''
      )}</div>`,
                                              }}
                                              style={{
                                                whiteSpace: 'pre-wrap',
                                                minHeight: '20px', // Add a minimum height of 20px
                                                border: error?.[
                                                  (currentPageRef.current - 1) *
                                                    itemsPerPage +
                                                    i
                                                ]?.responseOptions?.[index]
                                                  ?.optionContent
                                                  ? '1px solid red'
                                                  : 'none', // Conditionally apply border
                                                pointerEvents:
                                                  // (data?.responseType ==
                                                  //   'Multiple' &&
                                                  //   (d?.optionContent ==
                                                  //     'None of the above' ||
                                                  //     d?.optionContent ==
                                                  //       '<p>None of the above</p>')) ||
                                                  // ((data?.responseType ==
                                                  //   'Single' ||
                                                  //   data?.responseType ==
                                                  //     'Multiple') &&
                                                  //   (d?.optionContent ==
                                                  //     'Other' ||
                                                  //     d?.optionContent ==
                                                  //       '<p>Other</p>'))
                                                  d?.optionType
                                                    ? 'none'
                                                    : 'auto',
                                              }}
                                            />
                                          )}
                                        </div>
                                      </div>
                                    );
                                  }

                                  if (data?.responseType == 'Open') {
                                    return (
                                      <div className='grid grid-cols-12 mr-2'>
                                        <TextField
                                          className='col-start-3 col-span-full'
                                          error={
                                            error?.[
                                              (currentPageRef.current - 1) *
                                                itemsPerPage +
                                                i
                                            ]?.responseOptions?.[index]
                                              ?.optionContent
                                          }
                                          fullWidth
                                          multiline
                                          name='optionContent'
                                          label='Response Input'
                                          value={
                                            data?.isScored == 'Yes'
                                              ? d?.optionContent
                                              : ''
                                          }
                                          onChange={(e) =>
                                            columnOptionchange(e, i, index)
                                          }
                                          disabled={
                                            data?.isScored == 'No' ||
                                            stateData?.key == 'false' ||
                                            stateData?.key == false
                                          }
                                        />
                                      </div>
                                    );
                                  }
                                  if (data?.responseType == 'Number') {
                                    return (
                                      <Grid
                                        xs={12}
                                        className='pl-5'
                                        md={12}
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          marginBottom: '20px',
                                          marginLeft: '120px',
                                        }}
                                      >
                                        <TextField
                                          error={
                                            error?.[
                                              (currentPageRef.current - 1) *
                                                itemsPerPage +
                                                i
                                            ]?.responseOptions?.[index]
                                              ?.optionContent
                                          }
                                          size='small'
                                          label='Correct Response:'
                                          name='optionLabel'
                                          value={
                                            data?.isScored == 'Yes'
                                              ? Number(d?.optionLabel)
                                              : ''
                                          }
                                          onChange={(e) =>
                                            columnOptionchange(e, i, index)
                                          }
                                          type='number'
                                          inputMode='decimal'
                                          disabled={
                                            data?.isScored == 'No' ||
                                            stateData?.key == 'false' ||
                                            stateData?.key == false
                                          }
                                        />
                                        <TextField
                                          error={
                                            error?.[
                                              (currentPageRef.current - 1) *
                                                itemsPerPage +
                                                i
                                            ]?.responseOptions?.[index]
                                              ?.optionContent
                                          }
                                          size='small'
                                          label='Allowed Error (+/-):'
                                          name='optionContent'
                                          value={
                                            data?.isScored == 'Yes'
                                              ? Number(d?.optionContent)
                                              : ''
                                          }
                                          onChange={(e) =>
                                            columnOptionchange(e, i, index)
                                          }
                                          disabled={
                                            data?.isScored == 'No' ||
                                            stateData?.key == 'false' ||
                                            stateData?.key == false
                                          }
                                          // value={data.optionContent}
                                          type='number'
                                          inputMode='decimal'
                                        />
                                      </Grid>
                                    );
                                  }
                                  if (data?.responseType == 'Text') {
                                    return (
                                      <div className='grid grid-cols-12 mr-2'>
                                        <TextField
                                          className='col-start-3 col-span-full'
                                          error={
                                            error?.[
                                              (currentPageRef.current - 1) *
                                                itemsPerPage +
                                                i
                                            ]?.responseOptions?.[index]
                                              ?.optionContent
                                          }
                                          fullWidth
                                          // size="small"
                                          label='Correct Response'
                                          name='optionContent'
                                          value={
                                            data?.isScored == 'Yes'
                                              ? d?.optionContent
                                              : ''
                                          }
                                          onChange={(e) =>
                                            columnOptionchange(e, i, index)
                                          }
                                          type='text'
                                          disabled={
                                            data?.isScored == 'No' ||
                                            stateData?.key == 'false' ||
                                            stateData?.key == false
                                          }
                                        />
                                      </div>
                                    );
                                  }
                                }
                              )}
                            </div>

                            {/* button parts */}
                            <div
                              className={`${
                                data.responseType === '' ||
                                data.responseType === 'Number' ||
                                data.responseType === 'Text' ||
                                data.responseType === 'Open'
                                  ? 'hidden'
                                  : 'mt-5 grid grid-cols-4 gap-x-5'
                              }`}
                            >
                              <Button
                                size='small'
                                onClick={() => handleOptionAdd(i)}
                                disabled={
                                  stateData?.likert == 'true' ||
                                  stateData?.likert == true
                                }
                              >
                                Add Option
                              </Button>
                              {data.responseType === 'Multiple' &&
                                data?.responseOptions?.every((option: any) => {
                                  const res = option?.optionType !== 'none';
                                  return res;
                                }) && (
                                  <Button
                                    size='small'
                                    onClick={() => handleOptionAdd(i, 'none')}
                                  >
                                    Add None
                                  </Button>
                                )}

                              {(data.responseType === 'Single' ||
                                data.responseType === 'Multiple') &&
                                data?.responseOptions?.every((option: any) => {
                                  const res = option?.optionType !== 'other';
                                  return res;
                                }) && (
                                  <Button
                                    size='small'
                                    onClick={() => handleOptionAdd(i, 'other')}
                                    disabled={
                                      stateData?.likert == 'true' ||
                                      stateData?.likert == true
                                    }
                                    sx={{ display: `` }}
                                  >
                                    Add Other
                                  </Button>
                                )}

                              <Button
                                size='small'
                                onClick={() =>
                                  handleDeleteOption(data?.responseOptions, i)
                                }
                                disabled={
                                  stateData?.likert == 'true' ||
                                  stateData?.likert == true
                                }
                              >
                                Remove Option
                              </Button>
                            </div>
                          </div>

                          {/* 4 */}
                          <div
                            className={`col-span-3 flex flex-col justify-around gap-y-6 max-h-80 ${
                              data.responseType === 'Single' ||
                              data.responseType === 'Multiple'
                                ? 'py-10'
                                : ''
                            }`}
                          >
                            <FormControl size='small' variant='outlined'>
                              <InputLabel id='demo-select-small'>
                                Attach to Question
                              </InputLabel>
                              <Select
                                labelId='demo-select-small'
                                id='demo-select-small'
                                value={
                                  Boolean(data?.framing) ? data.framing : ''
                                }
                                name='framing'
                                label='Attach to Question'
                                onChange={(e) => columnchange(e, i)}
                              >
                                <MenuItem value=''>
                                  <em>None</em>
                                </MenuItem>
                                {val?.data.map((data: any, index: any) => {
                                  if (data.type === 'framing') {
                                    return (
                                      <MenuItem value={data?.framingLabel}>
                                        {data?.framingLabel}
                                      </MenuItem>
                                    );
                                  }
                                })}
                              </Select>
                            </FormControl>

                            <FormControl size='small' variant='outlined'>
                              <InputLabel id='demo-select-small'>
                                Response Type
                              </InputLabel>
                              <Select
                                labelId='demo-select-small'
                                id='demo-select-small'
                                value={data.responseType}
                                name='responseType'
                                label='Response Type:'
                                onChange={(e) => columnchange(e, i)}
                                defaultValue='Single'
                                disabled={
                                  stateData?.likert == 'true' ||
                                  stateData?.likert == true
                                }
                              >
                                <MenuItem value='Single'>Single</MenuItem>
                                <MenuItem value='Multiple'>Multiple</MenuItem>
                                <MenuItem value='Number'>Number</MenuItem>
                                <MenuItem value='Text'>Text</MenuItem>
                                <MenuItem value='Open'>Open</MenuItem>
                              </Select>
                            </FormControl>

                            <FormControl
                              size='small'
                              variant='outlined'
                              style={
                                stateData?.key == 'true' ||
                                stateData?.key == true
                                  ? { marginTop: '10px', marginBottom: '10px' }
                                  : { display: 'none' }
                              }
                            >
                              <InputLabel id='demo-select-small' shrink={true}>
                                is Scored
                              </InputLabel>
                              <Select
                                labelId='demo-select-small'
                                id='demo-select-small'
                                value={data.isScored}
                                name='isScored'
                                label='Is Scored:'
                                onChange={(e) => columnchange(e, i)}
                                // disabled={
                                //   stateData?.likert == 'true' ||
                                //   stateData?.likert == true
                                // }
                              >
                                <MenuItem value='Yes'>Yes</MenuItem>
                                <MenuItem value='No'>No</MenuItem>
                              </Select>
                            </FormControl>

                            <FormControl
                              size='small'
                              variant='outlined'
                              style={
                                data?.responseType == 'Single' ||
                                data?.responseType == 'Multiple'
                                  ? { marginTop: '10px', marginBottom: '10px' }
                                  : { display: 'none' }
                              }
                            >
                              <InputLabel id='demo-select-small'>
                                Options Orientation:
                              </InputLabel>
                              <Select
                                labelId='demo-select-small'
                                id='demo-select-small'
                                value={data?.optionOrientation}
                                name='optionOrientation'
                                label='Options Orientation:'
                                onChange={(e) => columnchange(e, i)}
                                defaultValue='Vertical'
                                disabled={
                                  stateData?.likert == 'true' ||
                                  stateData?.likert == true
                                }
                              >
                                <MenuItem value='Vertical'>Vertical</MenuItem>
                                <MenuItem value='Horizontal'>
                                  Horizontal
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    {actionBar}
                  </div>
                );
              }
              if (data.type === 'framing') {
                framingIndex = framingIndex + 1;
                return (
                  <div className='flex flex-row items-center mb-5'>
                    <Paper
                      // elevation={activeBlock === i ? 12 : 0}
                      // ref={contentBlocksRef[i]}
                      sx={{
                        backgroundColor: 'rgb(248, 250, 252)',
                      }}
                      className={`bg-red-400 p-3 mb-7 border border-[#D5D5D5] rounded-3xl w-full`}
                    >
                      <label style={{ fontSize: '12px' }}>
                        {data?.framingLabel}
                      </label>

                      {activeEditor === `Framing-${i}` ? (
                        <SunEditor
                          setContents={data?.framingContent}
                          name='questionContent'
                          onChange={(e) => {
                            columnchange(
                              {
                                target: { name: 'framingContent', value: e },
                              },
                              i
                            );
                          }}
                          setDefaultStyle='height: auto; white-space: pre-wrap;'
                          setOptions={{
                            katex: katex,
                            fontSize: fontSizeOptions,
                            mathFontSize: mathFontSizeOptions,
                            mode: 'inline',
                            buttonList: [
                              [
                                'undo',
                                'redo',
                                'font',
                                'fontSize',
                                'formatBlock',
                                'blockquote',
                                'bold',
                                'underline',
                                'italic',
                                'strike',
                                'subscript',
                                'superscript',
                                'fontColor',
                                'hiliteColor',
                                'textStyle',
                                'removeFormat',
                                'outdent',
                                'indent',
                                'align',
                                'horizontalRule',
                                'list',
                                'lineHeight',
                                'fullScreen',
                                'showBlocks',
                                'codeView',
                                'preview',
                                'print',
                                'link',
                                'horizontalRule',
                                'math',
                                'table',
                                'image',
                              ],
                            ],
                          }}
                        />
                      ) : (
                        <div
                          onDoubleClick={() => {
                            setActiveEditor(`Framing-${i}`); // Set the active editor on double click
                          }}
                          dangerouslySetInnerHTML={{
                            __html: `<div class="sun-editor-editable" style="padding-top: 0px;
                          padding-bottom: 0px; max-width: 918px; min-height: 20px;">${data?.framingContent?.replace(
                            /\\/g
                          )}</div>`,
                          }}
                          style={{ minHeight: '20px', whiteSpace: 'pre-wrap' }} // Add a minimum height of 20px
                        />
                      )}
                    </Paper>
                    {actionBar}
                  </div>
                );
              }
            })}
        </div>
      </div>

      {/* Image Alternate Text Dialog */}
      <ImageAlternateTextDialog
        open={open}
        setOpen={setOpen}
        imageAlternateData={imageAlternateData}
      />

      {/* Likert Dialog */}
      <LikertDialog
        likertOpen={likertOpen}
        setLikertOpen={setLikertOpen}
        likert={likert}
        setLikert={setLikert}
        val={val}
        setVal={setVal}
        stateData={stateData}
        activeEditor={activeEditor}
        setActiveEditor={setActiveEditor}
      />

      {/* Assessment Filter Dialog */}
      <FilterQuestionDialog
        open={filterOpen}
        setOpen={setFilterOpen}
        filterQuestion={filterQuestion}
        setFilterQuestion={setFilterQuestion}
        filterData={filterData}
        val={val}
        setVal={setVal}
        stateData={stateData}
      />

      {/* Attach Test Item  Dialog */}
      <TestItemsDialog
        open={itemOpen}
        setOpen={setItemOpen}
        state={state}
        name={name}
        setName={setName}
      />

      {/* Attach Reports Dialog */}
      <AttachReports
        open={reportsOpen}
        setOpen={setReportsOpen}
        state={state}
        name={reports}
        setName={setReports}
      />

      {/* Discipline Dialog */}
      <AssessmentDiscipline open={openForm} setOpenForm={setOpenForm} />
    </main>
  );
};

export default AddEditStaticAssessment;
