import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from '../Store';
import { AxiosResponse, AxiosRequestConfig } from 'axios';
import * as studentSignupRoute from '../route/studentSignupRoute';
import { NavigateFunction } from 'react-router-dom';
import { Unitroles } from '../../pages/unitroles/Unitroles';
import { PaginationProp } from '../../utils/globalInterface/GlobalInterfaces';
import {
  handleAddTransaction,
  handleDeleteTransaction,
  handleUpdateTransaction,
} from '../../utils/gridMethod/GridTransaction';
import { GridApi } from 'ag-grid-community';
import Alert from '../../utils/Alert/Alert';
import Swal from 'sweetalert2';

export interface AxiosError<T = any> extends Error {
  config: AxiosRequestConfig;
  code?: string;
  request?: object;
  data: object;
  response?: AxiosResponse<T>;
  isAxiosError: boolean;
  toJSON: () => object;
}

const initialState = {
  loading: false,
  studentConfirm: [],
};

export interface StudentConfirmationData {
  student_id: string;
  course_id: string;
  length: any;
}
const addStudentSignup = (data: any, pageName: any) => {
  return studentSignupRoute
    .addStudentSignup(data)
    .then((res: AxiosResponse) => {
      Swal.fire({
        title: ` updated successfully`,
        // text: 'Data inserted successfully',
        icon: 'success',
        confirmButtonText: 'OK',
      });
    })
    .catch((err: AxiosError) => {});
};

const updateStudentSignup = (data: any, id: any) => {
  //

  return studentSignupRoute
    .updateStudentSignup(data, data?.id)
    .then((res: AxiosResponse) => {})
    .catch((err: AxiosError) => {});
};

const getStudentSignup = (setApiKey: any) => async (dispatch: any) => {
  try {
    const response = await studentSignupRoute.getStudentSignup();
    const data = response.data;

    if (data || data.length) {
      //   dispatch(handleGetSystemSettings(data));
      setApiKey(data);
    } else {
      // Handle case when no data is returned
    }
  } catch (error) {
    // Handle error
  }
};

const getStudentConfirmation =
  (
    { studentConfirmationEmail }: { studentConfirmationEmail: string },
    getIds: (data: StudentConfirmationData) => void,
    navigate: any
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      const res: AxiosResponse =
        await studentSignupRoute.getStudentConfirmation(
          studentConfirmationEmail
        );
      if (res.data.message === 'Student already has an account') {
        navigate('/login', {
          state: { email: studentConfirmationEmail },
        });
        // Swal.fire({
        //   title: 'Student already has an account',
        //   icon: 'info',
        //   confirmButtonText: 'OK',
        // }).then(() => navigate('/login'));
      }
      const dataOption: StudentConfirmationData = res.data.data;
      getIds(dataOption); // Dispatch an action with the data
      dispatch({
        type: 'STUDENT_CONFIRMATION_SUCCESS',
        payload: dataOption, // You can use this payload in your reducer
      });
    } catch (error) {
      console.error(error);
    }
  };
const getAssessmentStudent =
  (data: any, navigate: any) => async (dispatch: AppDispatch) => {
    return studentSignupRoute
      .getAssessmentStudent(data)
      .then((res: AxiosResponse) => {
        if (res?.data) {
          dispatch(handleStudentConfirm(res?.data?.data));
        }
      })
      .catch((err: AxiosError) => {
        Alert.info({ title: 'Assessment Not Found', text: '' }).then(() =>
          navigate('/login')
        );
      });
  };
const activateStudentPassword =
  (data: any, navigate: any) => async (dispatch: AppDispatch) => {
    return await studentSignupRoute
      .activateStudentPassword(data)
      .then((res: any) => {
        if (res.data.message == 'Student already activated.') {
          Alert.info({ title: 'Student already activated', text: '' });
        } else {
          Alert.success({
            title: 'Student Account activated Successfully',
            text: '',
          });
        }
        navigate('/login');
        // if (res?.data?.message === 'Password Activation Link Expired') {
        //   Alert.info({ title: 'Password Activation Link Expired', text: '' });
        // } else if (res.data.message === 'Invalid Token.') {
        //   Alert.info({ title: 'Invalid Token.', text: '' });
        // } else {
        //   Alert.success({ title: 'Account activated Successfully', text: '' });
        //   navigate('/login');
        // }
      })
      .catch((err: any) => {
        Alert.error({ title: 'Password Activation Failed!', text: '' });
      });
  };

const StudentSignupSlice = createSlice({
  name: 'StudentSignupSlice',
  initialState,
  reducers: {
    handleLoading: (state, action) => {
      state.loading = action.payload;
    },
    handleStudentConfirm: (state, action) => {
      state.studentConfirm = action.payload;
    },
  },
});

//reducers export  to pass action state to reducer type
export const { handleLoading, handleStudentConfirm } =
  StudentSignupSlice.actions;

//action to calls in ui with dispatch methods
export {
  addStudentSignup,
  updateStudentSignup,
  getStudentSignup,
  activateStudentPassword,
  getStudentConfirmation,
  getAssessmentStudent,
};

export default StudentSignupSlice.reducer;
