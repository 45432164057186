import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
//   import {
//     getAdaptiveAssessmentListWithTestItems,
//     getAssessmentReports,
//     getSelectedAdaptiveAssessmentListWithTestItems,
//   } from '../../../reduxStore/reducer/assessmentReducer';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../reduxStore/Store';
import { getSelectedAssessmentReports } from '../../../reduxStore/reducer/assessmentReducer';

const Reports = (props: any) => {
  const { open, setOpen, courseAssessmentId } = props;

  const [name, setName] = useState('');
  const [itemData, setItemData] = useState([]);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getSelectedAssessmentReports(courseAssessmentId, setItemData));
  }, [dispatch]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setName(value);
  };

  return (
    <div>
      <Dialog
        open={open}
        style={{ zIndex: +10 }}
        sx={{
          '& .MuiDialog-paper': {
            width: '600px',
            height: '250px',
          },
        }}
      >
        <DialogTitle sx={{ backgroundColor: '#1663ab', color: 'white' }}>
          <div className='flex justify-between items-center'>
            <div>Reports</div>
            <div>
              <CloseIcon
                onClick={() => {
                  setOpen(false);
                }}
              />
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid xs={12} md={12} style={{ marginTop: '20px' }}>
            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id='demo-multiple-checkbox-label'>Reports</InputLabel>
              <Select
                labelId='demo-multiple-checkbox-label'
                id='demo-multiple-checkbox'
                value={name}
                onChange={handleChange}
                input={<OutlinedInput label='Reports' />}
                MenuProps={MenuProps}
              >
                {itemData?.map((data: any) => (
                  <MenuItem key={data} value={data}>
                    <ListItemText primary={data} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid
            xs={12}
            md={12}
            style={{
              marginTop: '20px',
              display: 'flex',
              justifyContent: 'end',
            }}
          >
            <Button
              variant='contained'
              style={{ marginRight: '10px' }}
              disabled={name === '' ? true : false}
              onClick={() => {
                console.log('Open Report');

                if (JSON.parse(localStorage.getItem('token') || '')) {
                  window.open(
                    `${
                      process.env.REACT_APP_REPORTS_APP_URL
                    }/${name}?id=${courseAssessmentId}&token=${JSON.parse(
                      localStorage.getItem('token') || ''
                    )}`,
                    '_blank'
                  );
                }
              }}
            >
              Open Report
            </Button>
            <Button
              variant='contained'
              onClick={() => {
                setOpen(false);
              }}
            >
              Close
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Reports;
