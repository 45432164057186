import { BrowserRouter } from "react-router-dom";
import { Provider, useDispatch } from "react-redux";
import store, { AppDispatch } from "./reduxStore/Store";
import MainRoutes from "./routes/MainRoutes";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import DarkModeToggle from "./pages/DarkModeToggle";
import React, { useState } from "react";
import { setTheme } from "./reduxStore/reducer/themeReducer";
import { LicenseManager } from "ag-grid-enterprise";

const theme = createTheme({
  typography: {
    fontFamily: "verdana, sans-serif",
  },
});
const lightTheme = createTheme({
  palette: {
    mode: "light",
  },
});
const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

LicenseManager.setLicenseKey(
 "Using_this_{AG_Grid}_Enterprise_key_{AG-051906}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Iowa_State_University,_School_of_Education}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{LASSO}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{LASSO}_need_to_be_licensed___{LASSO}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{6_December_2024}____[v3]_[01]_MTczMzQ0MzIwMDAwMA==f5a45cacc6be659c69930b9453ad84b6"
);

function App() {
  // const dispatch = useDispatch<any>();

  const [themeMode, setThemeMode] = useState("light");

  React.useEffect(() => {
    const theme: any = localStorage.getItem("theme");
    setThemeMode(theme == "dark" ? "dark" : "light");
  }, []);

  const toggleTheme = () => {
    localStorage.setItem("theme", themeMode === "light" ? "dark" : "light");
    setThemeMode(themeMode === "light" ? "dark" : "light");
  };
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={themeMode === "dark" ? darkTheme : lightTheme}>
          <CssBaseline />
          {/* <DarkModeToggle toggleTheme={toggleTheme} /> */}

          <MainRoutes toggleTheme={toggleTheme} />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
