const getAlphabetFromNumber = (_num: any) => {
  let str = '';

  const multiples = Math.ceil(_num / 26);
  const _charAtCode = (_num - 1) % 26; // Corrected calculation for alphabet letter

  for (let i = 0; i < multiples; i++)
    str += String.fromCharCode(_charAtCode + 65); // Using 65 instead of 64

  return str;
};

const fontSizeOptions = [
  8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 22, 24, 26, 28, 36, 48, 72,
];

const mathFontSizeOptions = [
  { text: '0.5', value: '0.5em' },
  { text: '0.6', value: '0.6em' },
  { text: '0.7', value: '0.7em' },
  { text: '0.8', value: '0.8em' },
  { text: '0.9', value: '0.9em' },
  { text: '1', value: '1em', default: true }, // Set default font size
  { text: '1.1', value: '1.1em' },
  { text: '1.2', value: '1.2em' },
  { text: '1.3', value: '1.3em' },
  { text: '1.4', value: '1.4em' },
  { text: '1.5', value: '1.5em' },
  { text: '1.6', value: '1.6em' },
  { text: '1.7', value: '1.7em' },
  { text: '1.8', value: '1.8em' },
  { text: '1.9', value: '1.9em' },
  { text: '2', value: '2em' },
  { text: '2.1', value: '2.1em' },
  { text: '2.2', value: '2.2em' },
  { text: '2.3', value: '2.3em' },
  { text: '2.4', value: '2.4em' },
  { text: '2.5', value: '2.5em' },
  { text: '2.6', value: '2.6em' },
  { text: '2.7', value: '2.7em' },
  { text: '2.8', value: '2.8em' },
  { text: '2.9', value: '2.9em' },
  { text: '3', value: '3em' },
  { text: '3.1', value: '3.1em' },
  { text: '3.2', value: '3.2em' },
  { text: '3.3', value: '3.3em' },
  { text: '3.4', value: '3.4em' },
  { text: '3.5', value: '3.5em' },
];

export default { getAlphabetFromNumber, fontSizeOptions, mathFontSizeOptions };
