import React from "react";

function Unauthorized() {
  return (
    <div className="flex flex-col h-screen justify-center items-center ">
      <div>
        <h1 className="text-9xl text-slate-800">401</h1>
      </div>
      <div>
        <p className="text-xl text-center">
          The page you are looking for is unauthorized.
        </p>
      </div>
      <div>
        <a
          href="/"
          className="text-xl text-sky-500 underline underline-offset-4"
        >
          Back to Home
        </a>
      </div>
    </div>
  );
}

export default Unauthorized;
