import React from 'react';
import {
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Fab,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface TermsOfServiceProps {
  termsOpen: boolean;
  handleTermsClose: () => void;
}

const TermsOfService = (props: TermsOfServiceProps) => {
  const { termsOpen, handleTermsClose } = props;

  return (
    <Dialog
      open={termsOpen}
      onClose={handleTermsClose}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title" style={{ textAlign: 'center' }}>
        <div className="flex justify-between items-center">
          <p>LASSO PLATFORM TERMS OF USE</p>
          <Fab size="small">
            <CloseIcon className="cursor-pointer" onClick={handleTermsClose} />
          </Fab>
        </div>
      </DialogTitle>
      <DialogContent style={{ overflow: 'auto' }}>
        <DialogContentText>
          <b>
            These Terms of Use (the “Terms”) govern your use of the Platform. BY
            CLICKING ACCEPT AND USING THE PLATFORM, YOU AGREE TO BE BOUND BY
            THESE TERMS. THIS IS A LEGALLY BINDING AGREEMENT. YOU REPRESENT AND
            WARRANT THAT YOU HAVE ALL THE NECESSARY APPROVALS FROM YOUR
            INSTITUTION, THAT YOUR USE OF THE PLATFORM COMPLIES WITH ALL
            APPLICABLE STATE AND FEDERAL LAWS AND INSTITUTIONAL POLICIES, AND
            THAT YOU HAVE THE REQUISITE LEGAL AUTHORITY TO BIND YOUR INSTITUTION
            TO THESE TERMS. IF YOU DO NOT AGREE WITH THESE TERMS OR IF YOU DO
            NOT HAVE THE APPROPRIATE INSTITUTION APPROVAL AND AUTHORITY TO USE
            THIS PLATFORM, YOU ARE NOT AUTHORIZED TO USE THIS PLATFORM. THE
            FOREGOING SHALL NOT APPLY TO IOWA STATE UNIVERSITY INSTRUCTORS USING
            THIS PLATFORM AS PART OF THEIR EDUCATIONAL INSTRUCTION MATERIALS AT
            IOWA STATE UNIVERSITY.
            <b>
              <br></br>
              <br></br>
            </b>
            ISU may change these Terms of the Platform at any time. If a change
            to these Terms is made, ISU will post the revised Terms on this
            website. Your continued use of the Platform after any such changes
            will indicate that you agree to be bound by such changes. If you or
            your institution do not agree with the changes, the sole remedy is
            to cease access and use of the Platform.{' '}
          </b>
          <br></br>
          <br></br>
          <p>
            <b>1.Platform Description.</b> The Learning About STEM Student
            Outcomes (“LASSO”) is hosted by Iowa State University of Science and
            Technology on behalf of its School of Education. LASSO is an online
            educational tool to aid instructors and their institutions in
            tracking and evaluating student progress using assessments and
            reporting regarding student performance at institutions of higher
            education within the United States (the “Platform”). LASSO supports
            instructors by measuring their students’ success throughout their
            course. LASSO does this utilizing research-based assessments (RBAs).
            LASSO administers, scores, and analyzes RBAs at the beginning and
            end of an instructor’s course. Instructors then have access to
            personally identifiable student assessment responses and scores and
            reports with aggregate results. Instructors will be responsible for
            identifying the appropriate educational assessment for their
            classroom use on the Platform and for identifying and inviting
            students to participate in the Platform and assessment. Instructors
            may only invite current students in their class to use the Platform.
            In addition, Instructor shall be responsible for determining that
            any assessment coordinator added to the instructor’s class
            assessment is authorized to access the Platform Data.
          </p>
          <br></br>
          <br></br>
          <p>
            <b>2.Data Description.</b> ISU will collect, use, and maintain the
            following data for the purposes providing Platform services (the
            “Platform Data”):
          </p>
          <ul style={{ listStyleType: 'none', paddingLeft: '1em' }}>
            <li>i. Instructor Name</li>
            <li>ii. Instructor Institutional Email address</li>
            <li>iii. Student Name</li>
            <li>iv. Student Institutional Email address</li>
            <li>v. Institution Name</li>
            <li>vi. Course Name and number</li>
            <li>
              vii. Course Information (e.g., delivery format, number of hours,
              etc.)
            </li>
            <li>viii. Student Assessment Responses and Scores</li>
          </ul>
          <br></br>
          <br></br>
          <p>
            <b>3.Data Use and Protection. </b>
          </p>
          <ul style={{ listStyleType: 'none', paddingLeft: '1em' }}>
            <li>
              <b>a.Use of Identifiable Platform Data.</b> ISU shall have a
              non-exclusive, royalty-free license to use the Platform Data as
              outlined in these Terms. ISU shall use the Platform Data only for
              the purpose of providing the Platform and complying with these
              Terms (the “Purpose”). ISU shall hold Platform Data in confidence
              and not disclose Platform Data to any third party except as
              otherwise provided herein. These Terms do not give ISU any rights
              to the Platform Data except as otherwise specified in these Terms.
              ISU agrees to store the Platform Data with the appropriate
              safeguards, to ensure that only authorized persons have access to
              the Platform Data, and to maintain appropriate control over the
              Platform Data at all times. ISU may disclose Platform Data to its
              employees and students to carry out the Purpose provided that ISU
              requires such employees and students to abide by these Terms. In
              the event ISU becomes aware of any use or disclosure of the
              Platform Data not provided for by these Terms, ISU shall take
              appropriate steps to minimize the impact of such unauthorized use
              or disclosure as soon as practicable and shall notify you of such
              use or disclosure promptly. If ISU is required by judicial or
              other governmental authority or by a subpoena or a public records
              request to disclose Platform Data, ISU shall promptly inform you
              in writing so that you may seek a protective order or take other
              appropriate measures. ISU will use the Platform Data for the
              purpose of fulfilling its duties and providing services under
              these Terms and for improving the Platform and services provided
              under these Terms. However, nothing in this section is intended to
              affect ISU’s right to use deidentified Platform Data as described
              below.
            </li>
            <li>
              <b>b.Student Records.</b> The Family Educational Rights and
              Privacy Act (20 U.S.C. § 1232g; 34 CFR Part 99) (“FERPA”) protects
              the privacy of student education records. To the extent the
              Platform Data includes “Educational Records,” you understand and
              agree that ISU will be considered an “Institution Official” and
              will comply with FERPA. You are providing student names and
              student institutional email addresses to ISU as “Directory
              Information.” For “Directory Information” any other “Education
              Records” that you provide to ISU as part of the Platform Data, you
              hereby represent and warrant that your institution has (a)
              complied with the “Directory Information” exception, which
              includes informing eligible students or their parents (as
              applicable) what information the institution deems to be
              “Directory Information” and giving them a reasonable opportunity
              to opt-out of the disclosure of such information; (b) complied
              with FERPA’s “Institution Official” exception by informing
              eligible students or their parents in your annual notification of
              FERPA rights that the institution defines “Institution Official”
              to include service providers, and defines “Legitimate Educational
              Interest” to include services such as the type provided by the
              Platform; and (c) obtained all necessary written consent to share
              the “Directory Information” and “Education Records” with ISU to
              enable ISU to provide the Platform.
            </li>
            <li>
              <b>c.Platform Data Privacy.</b> ISU shall maintain personally
              identifiable information in accordance with applicable laws and
              ISU’s Electronic Privacy Policy, which is incorporated into these
              Terms by reference.
            </li>
            <li>
              <b>d.ISU Use of Deidentified Platform Data.</b> ISU may use
              deidentified Platform Data for research and educational and
              non-profit purposes, including making such Platform Data available
              to third party researchers for analysis and providing aggregate
              course-level equity reports to instructors where available. ISU
              will deidentify the Platform Data as follows:
              <ul style={{ listStyleType: 'none', paddingLeft: '1em' }}>
                <b>i.</b>Remove
                <ul style={{ listStyleType: 'none', paddingLeft: '1em' }}>
                  <li>1.Instructor Name</li>
                  <li>2.Instructor Institutional Email address </li>
                  <li>3.Student Name</li>
                  <li>4.Student Institutional Email address </li>
                  <li>5.Institution Name </li>
                  <li>6.Course Name and number</li>
                </ul>
              </ul>
              <ul style={{ listStyleType: 'none', paddingLeft: '1em' }}>
                <b>ii.</b>Deidentified data from an instrument will only be
                shared once it has at least 5 instructors in the database who
                have agreed to have their data shared. ISU will make the
                deidentified Platform Data available to third parties through a
                secure file transfer system (e.g., Cybox). ISU may require third
                party researchers to reimburse ISU for the costs of preparation,
                compilation, and transfer of the deidentified Data to the
                third-party researchers. In addition, ISU agrees not to attempt
                to reidentify deidentified Platform Data and shall require any
                third parties receiving the deidentified data to agree to the
                same. ISU intends to invite users of the Platform to permit
                secondary research use of Platform Data they provide. Storage
                and dissemination of Platform Data for secondary research will
                operate in accordance with an approved protocol from ISU’s
                Institutional Review Board. ISU shall be responsible for
                obtaining all necessary permissions and informed consents from
                users prior to any research use. Although it is not anticipated
                at this time, should any third parties request identifiable
                Platform Data for research use, such use must first be approved
                by the third party recipient’s Institutional Review Board and
                comply with all applicable privacy and consent requirements.
              </ul>
            </li>
          </ul>
          <br></br>
          <br></br>
          <p>
            <b>4.Intellectual Property</b>
            <ul style={{ listStyleType: 'none', paddingLeft: '1em' }}>
              <li>
                <b>a.Ownership of the Platform.</b> These Terms do not confer
                title, ownership, or any other rights concerning your use of the
                Platform, the Platform name or logo, or ISU’s name or logo,
                other than those set forth herein. The Platform may not be
                adapted, copied, reproduced, or distributed in any manner or
                medium, in whole or in part, without prior written consent of
                ISU.
              </li>
              <li>
                <b>b.External Links and Content.</b> The Platform may include
                links to other websites and other third party content. You
                acknowledge and agree that such links and third party content
                are provided for your convenience and do not reflect any
                endorsement by ISU with respect to the link or its content.
              </li>
              <li>
                <b>c.Platform Feedback.</b> If you provide feedback to ISU
                regarding the Platform, you agree that ISU is free to use the
                feedback without any restriction or compensation to you.
              </li>
            </ul>
          </p>
          <br></br>
          <br></br>
          <p>
            <b>5.Platform Termination.</b> You understand and agree that this
            Platform is made available to you at the discretion of ISU. Although
            ISU will endeavor to provide advance notice if it no longer intends
            to continue to make available the Platform, you understand and agree
            that ISU may cease to host the Platform at any time without notice
            or liability to you.
          </p>
          <br></br>
          <br></br>
          <p>
            <b>6.Platform User Accounts. </b>
            <ul style={{ listStyleType: 'none', paddingLeft: '1em' }}>
              <li>
                <b>a.Creating User Account.</b> Each instructor using the
                Platform will be required to create a Platform user account.
                Students may create a user account to access their classroom
                assessments if desired, but it is not required for student
                participation in the Platform. As a condition of joining the
                Platform, each user agrees that the Platform may not be used:
                <ul style={{ listStyleType: 'none', paddingLeft: '1em' }}>
                  <li>
                    <b>i.</b>For any purpose or in any manner that violates any
                    local, state, or federal law or regulation.
                  </li>
                  <li>
                    <b>ii.</b>In any manner that violates the rights of ISU or
                    any third parties.
                  </li>
                  <li>
                    <b>iii.</b>To send any infringing, threatening, defamatory,
                    libelous, obscene, or pornographic material.
                  </li>
                  <li>
                    <b>iv.</b>To distribute commercial messages, “spam,” or
                    other unsolicited communications.
                  </li>
                  <li>
                    <b>v.</b>In any way to bypass measures ISU may use to
                    prevent or restrict access to the Platform.
                  </li>
                </ul>
              </li>
              <li>
                <b>b.User Account Termination.</b> ISU may block, suspend or
                terminate a user account for any of the prohibited activities
                described above or any violation of these Terms. In addition,
                ISU may terminate any user account at any time for any reason
                without notice. You may request termination of your account in
                writing at any time upon thirty (30) days prior written notice.
                In the event of termination, ISU shall cease use of identifiable
                Platform Data and, upon your written request, destroy your
                identifiable Platform Data. However, ISU may still retain
                information as needed to provide services or support or as
                required, allowed by these Terms, or permitted by law.
              </li>
            </ul>
          </p>
          <br></br>
          <br></br>
          <p>
            <b>7.Indemnification.</b> To the extent allowed by law, you agree to
            indemnify and hold harmless ISU, its officers, employees, and agents
            from and against liabilities, losses, damages, claims, or causes of
            action, and any connected expenses that are caused, directly or
            indirectly, by or as a result of your use of the Platform. In no
            event shall ISU be liable to you, your institution, or any other
            party for indirect, special, or consequential damages resulting from
            the Platform and these Terms.
          </p>{' '}
          <br></br>
          <br></br>
          <p>
            <b>8.NO WARRANTY.</b> THE PLATFORM IS PROVIDED ON AN “AS IS” AND “AS
            AVAILABLE” BASIS. ISU DISCLAIMS ANY AND ALL REPRESENTATIONS AND
            WARRANTIES, EXPRESS OR IMPLIED, WITH RESPECT TO THE PLATFORM OR ITS
            CONTENT, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
            NON-INFRINGEMENT. ISU DOES NOT REPRESENT OR WARRANT THAT USE THE
            PLATFORM WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE
            CORRECTED, OR THAT THE SERVICES WILL MEET YOUR REQUIREMENTS. YOU ARE
            SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR EQUIPMENT OR DEVICE, LOSS
            OF USE, OR LOSS OF DATA. YOU UNDERSTAND AND ACKNOWLEDGE THAT THE
            SOLE AND EXCLUSIVE REMEDY WITH RESPECT TO ANY DEFECT IN OR
            DISSATISFACTION WITH THE PLATFORM IS TO CEASE USE OF THE PLATFORM.
          </p>{' '}
          <br></br>
          <br></br>
          <p>
            <b>9.Limitation of Liability.</b> YOU AGREE THAT ISU WILL NOT BE
            LIABLE TO YOU FOR ANY LOSS OR DAMAGES, EITHER ACTUAL OR
            CONSEQUENTIAL, ARISING OUT OF OR RELATING TO THESE TERMS, OR TO YOUR
            (OR ANY THIRD PARTY'S) USE OR INABILITY TO USE THE PLATFORM. IN
            PARTICULAR, ISU WILL HAVE NO LIABILITY FOR ANY CONSEQUENTIAL,
            INDIRECT, PUNITIVE, SPECIAL OR INCIDENTAL DAMAGES, WHETHER
            FORESEEABLE OR UNFORESEEABLE, (INCLUDING, BUT NOT LIMITED TO, CLAIMS
            FOR DEFAMATION, ERRORS, LOSS OF DATA, OR INTERRUPTION IN
            AVAILABILITY OF DATA), ARISING OUT OF OR RELATING TO THESE TERMS,
            USE OF THE PLATFORM, OR INABILITY TO USE THE PLATFORM, WHETHER BASED
            IN CONTRACT, TORT, STATUTORY OR OTHER LAW, EVEN IF ISU OR ITS
            REPRESENTATIVES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGES.
          </p>
          <br></br>
          <br></br>
          <p>
            <b> 10.Miscellaneous</b>
            <ul style={{ listStyleType: 'none', paddingLeft: '1em' }}>
              <li>
                <b>a.Subcontractors.</b> ISU may use third parties to perform
                services in support of the Platform and these Terms. ISU will
                require any third parties to abide by these Terms in carrying
                out services on behalf of ISU.
              </li>
              <li>
                <b>b.Force Majeure.</b> ISU is not responsible for any delay,
                inability or failure to access the Platform due to any cause
                beyond its reasonable control, including but not limited to acts
                of God, natural disasters, government orders, labor unrest,
                malfunction of user hardware or software, interruption in
                internet service, and unanticipated suspension or cessation of
                service from any underlying service provider.
              </li>
              <li>
                <b>c.Governing Law.</b> To the extent permitted by law, these
                Terms and your use of the Platform will be governed by Iowa law,
                without regard to its conflicts of law provisions.
              </li>
              <li>
                <b>d.Publicity.</b> You agree not to use the name or trademarks
                or the name of any ISU employees in any publicity or
                advertising, including endorsements, without ISU’s prior written
                consent.
              </li>
              <li>
                <b> e.Entire Agreement.</b> These Terms constitute the entire
                agreement between you and ISU relating to your use of the
                Platform. There is no agency, partnership or joint venture
                relationship between you and ISU arising solely through the use
                of the Platform. If any provision of these Terms is found to be
                invalid by any court having competent jurisdiction, the
                invalidity of such provision shall not affect the validity of
                the remaining provisions of these Terms, which shall remain in
                full force and effect. The failure of ISU to exercise or enforce
                any right or provision of these Terms shall not constitute a
                waiver of such right or condition.
              </li>
            </ul>
          </p>
          <br></br>
          <br></br>
          <p>
            <b>11.Platform Contact Information.</b> If you have questions about
            these Terms or the Platform, you should contact{' '}
            <Link href="mailto:contact@lassoeducation.org">
              contact@lassoeducation.org
            </Link>
            .
          </p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleTermsClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TermsOfService;
