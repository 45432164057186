import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  MenuItem,
  Select,
  Tooltip,
  TextField,
  DialogTitle,
  FormControl,
  InputLabel,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SunEditor from 'suneditor-react';
import katex from 'katex';
import 'katex/dist/katex.min.css';
import AssessmentHelper from '../../../utils/Helpers/Assessment';
const { getAlphabetFromNumber, fontSizeOptions, mathFontSizeOptions } =
  AssessmentHelper;

interface LikertDialogProps {
  likertOpen: boolean;
  setLikertOpen: (open: boolean) => void;
  likert: any;
  setLikert: (likert: any) => void;
  val: any;
  setVal: (val: any) => void;
  stateData: any;
  activeEditor: string;
  setActiveEditor: (editor: string) => void;
}

const LikertDialog: React.FC<LikertDialogProps> = (props) => {
  const {
    likertOpen,
    setLikertOpen,
    likert,
    setLikert,
    val,
    setVal,
    stateData,
    activeEditor,
    setActiveEditor,
  } = props;

  const handleLikertChange = (e: any) => {
    const { name, value } = e.target;
    const inputdata: any = JSON.parse(JSON.stringify(likert));
    inputdata[name] = value;
    setLikert(inputdata);
  };

  const handleLikertOptionChange = (e: any, i: any) => {
    const { name, value } = e.target;
    setLikert((likert: any) => {
      const inputdata: any = JSON.parse(JSON.stringify(likert));
      inputdata.responseOptions[i][name] = value;
      return inputdata;
    });
  };

  const handleDeleteOptionLikert = () => {
    let data: any = JSON.parse(JSON.stringify(likert));
    data.responseOptions.pop();
    setLikert(data);
  };

  const handleOptionAddLikert = async () => {
    const data: any = JSON.parse(JSON.stringify(likert));
    // const overallData: any = { ...val };
    data.responseOptions.push({
      optionLabel:
        stateData?.optionType == '1,2,3'
          ? data.responseOptions?.length + 1
          : stateData?.optionType == 'a,b,c'
          ? `${getAlphabetFromNumber(data.responseOptions?.length + 1)}`
          : '',
      optionContent: '',
    });
    setLikert(data);
  };

  return (
    <div>
      <Dialog open={likertOpen} style={{ zIndex: +10 }}>
        <DialogTitle sx={{ backgroundColor: '#1663ab', color: 'white' }}>
          <div className='flex justify-between items-center'>
            <div>Likert Response Options</div>
            <div>
              <CloseIcon onClick={() => setLikertOpen(false)} />
            </div>
          </div>
        </DialogTitle>

        <DialogContent
          sx={{
            width: '600px',
            height: 'fitContent',
            overflowX: 'hidden',
            background: '#F3F3F3',
          }}
        >
          <Grid
            xs={12}
            md={12}
            style={{ marginTop: '40px', marginBottom: '20px' }}
          >
            <FormControl sx={{ minWidth: 120 }} size='small'>
              <InputLabel id='demo-select-small'>Response Type:</InputLabel>
              <Select
                labelId='demo-select-small'
                id='demo-select-small'
                value={likert?.responseType}
                disabled
                name='responseType'
                label='Response Type:'
                onChange={(e) => handleLikertChange(e)}
                defaultValue='Single'
              >
                <MenuItem value='Single'>Single</MenuItem>
                <MenuItem value='Multiple'>Multiple</MenuItem>
                <MenuItem value='Number'>Number</MenuItem>
                <MenuItem value='Text'>Text</MenuItem>
                <MenuItem value='Open'>Open</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid
            xs={12}
            md={12}
            style={
              (stateData?.key == 'true' || stateData?.key == true) &&
              (stateData?.likert == 'true' || stateData?.likert == true)
                ? { display: 'none' }
                : stateData?.key == 'true' || stateData?.key == true
                ? { paddingBottom: '20px', paddingTop: '20px' }
                : { display: 'none' }
            }
          >
            <FormControl sx={{ minWidth: 120 }} size='small'>
              <InputLabel id='demo-select-small'>is Scored:</InputLabel>
              <Select
                labelId='demo-select-small'
                id='demo-select-small'
                value={likert?.isScored}
                name='isScored'
                label='Is Scored:'
                onChange={(e) => handleLikertChange(e)}
              >
                <MenuItem value='Yes'>Yes</MenuItem>
                <MenuItem value='No'>No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={12} md={12} style={{ marginTop: '20px' }}>
            <FormControl sx={{ minWidth: 120 }} size='small'>
              <InputLabel id='demo-select-small'>
                Options Orientation:
              </InputLabel>
              <Select
                labelId='demo-select-small'
                id='demo-select-small'
                value={likert?.optionOrientation}
                name='optionOrientation'
                label='Options Orientation:'
                onChange={(e) => handleLikertChange(e)}
                defaultValue='Vertical'
              >
                <MenuItem value='Vertical'>Vertical</MenuItem>
                <MenuItem value='Horizontal'>Horizontal</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {likert?.responseOptions.map((data: any, index: any) => {
            return (
              <Grid
                container
                xs={12}
                md={12}
                style={{
                  marginTop: '20px',
                  marginBottom: '20px',
                }}
              >
                <Grid
                  item
                  xs={3}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Tooltip title='Option Label: ' placement='top'>
                    <TextField
                      disabled={
                        stateData?.optionType == 'Custom' ? false : true
                      }
                      style={{
                        width: '60px',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                      inputProps={{ maxLength: 3 }}
                      size='small'
                      name='optionLabel'
                      onChange={(e) => handleLikertOptionChange(e, index)}
                      type='text'
                      value={data?.optionLabel}
                    />
                  </Tooltip>
                </Grid>
                <Grid
                  item
                  xs={9}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <label style={{ fontSize: '12px' }}>Option Content:</label>
                  {activeEditor === `Likert-${index}` ? (
                    <SunEditor
                      setContents={data?.optionContent}
                      name='optionContent'
                      autoFocus={false}
                      onChange={(e: any) => {
                        handleLikertOptionChange(
                          {
                            target: {
                              name: 'optionContent',
                              value: e,
                            },
                          },
                          index
                        );
                      }}
                      setDefaultStyle='height: auto; white-space: pre-wrap;'
                      setOptions={{
                        katex: katex,
                        fontSize: fontSizeOptions,
                        mathFontSize: mathFontSizeOptions,
                        mode: 'inline',
                        buttonList: [
                          [
                            'undo',
                            'redo',
                            'font',
                            'fontSize',
                            'formatBlock',
                            'blockquote',
                            'bold',
                            'underline',
                            'italic',
                            'strike',
                            'subscript',
                            'superscript',
                            'fontColor',
                            'hiliteColor',
                            'textStyle',
                            'removeFormat',
                            'outdent',
                            'indent',
                            'align',
                            'horizontalRule',
                            'list',
                            'lineHeight',
                            'fullScreen',
                            'showBlocks',
                            'codeView',
                            'preview',
                            'print',
                            'link',
                            'horizontalRule',
                            'math',
                            'table',
                            'image',
                          ],
                        ],
                      }}
                    />
                  ) : (
                    <div
                      className='bg-white w-full'
                      onDoubleClick={() => {
                        setActiveEditor(`Likert-${index}`); // Set the active editor on double click
                      }}
                      dangerouslySetInnerHTML={{
                        __html: `<div class="sun-editor-editable" style="padding-top: 0px;
padding-bottom: 0px; max-width: 918px;">${data?.optionContent?.replace(
                          /\\/g,
                          ''
                        )}</div>`,
                      }}
                      style={{ minHeight: '20px' }} // Add a minimum height of 20px
                    />
                  )}
                </Grid>
              </Grid>
            );
          })}

          <Grid
            xs={12}
            md={12}
            style={{
              marginTop: '20px',
            }}
          >
            <Button
              variant='contained'
              style={{ marginRight: '20px' }}
              onClick={() => handleOptionAddLikert()}
            >
              Add Option
            </Button>
            <Button
              variant='contained'
              onClick={() => handleDeleteOptionLikert()}
            >
              Remove Option
            </Button>
          </Grid>
          <Grid
            xs={12}
            md={12}
            style={{
              marginTop: '20px',
              display: 'flex',
              justifyContent: 'end',
            }}
          >
            <Button
              variant='contained'
              onClick={() => {
                const value: any = { ...val };

                const likertData: any = [];

                value.data.map((d: any) => {
                  if (d.type == 'question') {
                    const data: any = d;
                    data.responseOptions = likert?.responseOptions;
                    data.optionOrientation = likert?.optionOrientation;
                    data.responseType = likert?.responseType;
                    data.isScored =
                      stateData?.key == 'true' || stateData?.key == true
                        ? 'Yes'
                        : 'No';
                    data.type = 'question';
                    // data.defaultAnswer =
                    //   Number(data.defaultAnswer) >
                    //   likert?.responseOptions.length
                    //     ? likert?.defaultAnswer
                    //     : d.defaultAnswer;
                    data.defaultAnswer = '';
                    likertData.push(data);
                  } else {
                    likertData.push(d);
                  }
                });
                if (
                  val?.filterQuestion?.filterResponse >
                  value?.data[val.filterQuestion?.filterQuestion - 1]
                    ?.responseOptions?.length
                ) {
                  const filterData = value.filterQuestion;
                  filterData.filterResponse = '';
                  setVal({ ...val, filterQuestion: filterData });
                }
                setVal({ ...value, data: likertData, likert: likert });
                setLikertOpen(false);
              }}
            >
              Save
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default LikertDialog;
