import { Input, Button, IconButton } from '@mui/material';
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { MdModeEdit, MdDelete } from 'react-icons/md';
import { ErrorMessage, Field, Form, Formik, useField } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';

import './style.css';
import { AppDispatch } from '../../../reduxStore/Store';
import { updateUserDetails } from '../../../reduxStore/reducer/registerReducer';
import {
  addSystemSettings,
  getSystemSettingsList,
} from '../../../reduxStore/reducer/systemSettingsReducer';
import { Scrollbar } from 'react-scrollbars-custom';

const MailSettings = ({ userData }: any) => {
  const [initialValues, setInitialValue] = useState<any>({
    admin_email: '',
    email_provider: '',
    send_grid_api_key: '',
    graph_api_key: {},
  });
  const [selectedApi, setSelectedApi] = useState<string>(
    initialValues?.email_provider ? initialValues?.email_provider : ''
  );
  const [prevSelectedApi, setPrevSelectedApi] = useState<string>(''); // Add this state

  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(getSystemSettingsList(setApiKey));
  }, [dispatch]);
  const [apiKey, setApiKey] = useState<any>({});

  //

  //

  // const validation = Yup.object({
  //   admin_email: Yup.string()
  //     .email('Email must be a valid email.')
  //     .nullable()
  //     .required('Entered text is not a valid email.'),
  //   mail_provider: Yup.string().required('Required Field'),
  //   send_grid_api_key: Yup.string().when('mail_provider', {
  //     is: 'send_grid',
  //     then: Yup.string().required('Required Field'),
  //     otherwise: Yup.string(),
  //   }),
  //   graph_api_key: Yup.object({
  //     tenant_id: Yup.string().required('Required Field'),
  //     client_id: Yup.string().required('Required Field'),
  //     client_secret: Yup.string().required('Required Field'),
  //   }).when('mail_provider', {
  //     is: 'graph_api',
  //     then: Yup.object({
  //       tenant_id: Yup.string().required('Required Field'),
  //       client_id: Yup.string().required('Required Field'),
  //       client_secret: Yup.string().required('Required Field'),
  //     }),
  //     otherwise: Yup.object({
  //       tenant_id: Yup.string(),
  //       client_id: Yup.string(),
  //       client_secret: Yup.string(),
  //     }),
  //   }),
  // });

  // const validation = Yup.object({
  //   admin_email: Yup.string()
  //     .email('Email must be a valid email.')
  //     .nullable()
  //     .required('Entered text is not a valid email.'),
  //   email_provider: Yup.string().required('Required Field'),
  // });

  // if (initialValues?.email_provider === 'send_grid') {
  //   validation.shape({
  //     send_grid_api_key: Yup.string().required('Required Field'),
  //   });
  // }

  // if (initialValues?.email_provider === 'graph_api') {
  //   validation.shape({
  //     graph_api_key: Yup.object({
  //       tenant_id: Yup.string().required('Required Field'),
  //       client_id: Yup.string().required('Required Field'),
  //       client_secret: Yup.string().required('Required Field'),
  //     }),
  //   });
  // }

  const validation = Yup.object({
    admin_email: Yup.string()
      .email('Email must be a valid email.')
      .nullable()
      .required('Entered text is not a valid email.'),
    email_provider: Yup.string().required('Required Field'),
    send_grid_api_key: Yup.string().when('email_provider', {
      is: 'send_grid',
      then: Yup.string().required('Required Field'),
    }),
    graph_api_key: Yup.object().when('email_provider', {
      is: 'graph_api',
      then: Yup.object({
        reminder_email: Yup.string().email().required('Required Field'),
        principal_name: Yup.string().email().required('Required Field'),
        tenant_id: Yup.string().required('Required Field'),
        client_id: Yup.string().required('Required Field'),
        client_secret: Yup.string().required('Required Field'),
      }),
    }),
  });

  useEffect(() => {
    //
    if (
      apiKey &&
      apiKey.data &&
      apiKey.data.admin_email &&
      apiKey.data.send_grid_api_key &&
      apiKey.data.graph_api_key
    ) {
      setInitialValue({
        email_provider: apiKey.data.email_provider || '',
        admin_email: apiKey.data.admin_email || '',
        send_grid_api_key: apiKey.data.send_grid_api_key || '',
        graph_api_key: apiKey.data.graph_api_key || [],
      });
    }
  }, [apiKey]);
  const [showPassword, setShowPassword] = useState(false);

  const handleViewClick = () => {
    setShowPassword(!showPassword);
  };
  // useEffect(() => {
  //   // Update the selected API and the previous selected API
  //   setSelectedApi(initialValues.apiSelect || 'send_grid');
  //   setPrevSelectedApi(initialValues.apiSelect || 'send_grid');
  // }, [initialValues]);
  return (
    <div>
      <div className="bg-sky-800 text-white h-10 p-2 font-bold">
        <p>Mail Settings</p>
      </div>
      <Formik
        enableReinitialize
        initialValues={{
          ...initialValues,
          apiSelect: selectedApi, // Update the selected API in initialValues
        }}
        validationSchema={validation}
        onSubmit={(values, { setSubmitting }) => {
          //

          if (selectedApi === 'graph_api') {
            const graphApiKey = {
              tenant_id: values.graph_api_key.tenant_id || '',
              client_id: values.graph_api_key.client_id || '',
              client_secret: values.graph_api_key.client_secret || '',
            };

            values.graph_api_key = graphApiKey;
          }

          dispatch(addSystemSettings(values, 'Mail settings', setSubmitting));
        }}
      >
        {({ values, errors, touched, isValid, isSubmitting }) => (
          <Form>
            <Scrollbar style={{ height: 'calc(100vh - 18rem)' }}>
              <div className="grid gap-y-2 max-w-lg sm:max-w-3xl mt-4 ml-2 sm:grid-cols-2">
                <label htmlFor="institution" className="">
                  Admin Email
                  <span className="text-red-500 text-lg"> *</span>
                </label>
                <div>
                  <Field
                    id="admin_email"
                    name="admin_email"
                    className="input-field h-9"
                    error={
                      errors.admin_email && touched.admin_email ? true : false
                    }
                    helperText={
                      errors.admin_email && touched.admin_email
                        ? 'Required Field'
                        : ''
                    }
                    required
                  />
                  <ErrorMessage
                    name="admin_email"
                    component="span"
                    className="text-red-600 ml-2 text-sm"
                  />
                </div>

                <>
                  <label htmlFor="apiSelect" className="">
                    Select API
                    <span className="text-red-500 text-lg"> *</span>
                  </label>
                  <div>
                    <select
                      id="apiSelect"
                      name="apiSelect"
                      className="input-field h-9"
                      value={initialValues?.email_provider}
                      onChange={(e) => {
                        setSelectedApi(e.target.value);
                        setInitialValue(() => ({
                          ...initialValues,
                          email_provider: e.target.value,
                        }));
                      }}
                    >
                      <option value="send_grid">SendGrid API</option>
                      <option value="graph_api">Graph API</option>
                    </select>
                  </div>

                  {initialValues?.email_provider === 'send_grid' && (
                    <>
                      <label htmlFor="send_grid_api_key" className="mt-2">
                        SendGrid API Key
                        <span className="text-red-500 text-lg"> *</span>
                      </label>
                      <div>
                        <Field
                          type={showPassword ? 'text' : 'password'}
                          id="send_grid_api_key"
                          name="send_grid_api_key"
                          className="input-field h-9"
                        />
                        <ErrorMessage
                          name="send_grid_api_key"
                          component="span"
                          className="text-red-600 ml-2 text-sm"
                        />
                      </div>
                    </>
                  )}

                  {initialValues?.email_provider === 'graph_api' && (
                    <>
                      {/* ...other JSX code... */}
                      <label
                        htmlFor="graph_api_reminder_email"
                        className="mt-2"
                      >
                        Graph API Reminder Email
                        <span className="text-red-500 text-lg"> *</span>
                      </label>
                      <div>
                        <Field
                          type="text"
                          id="graph_api_key.reminder_email"
                          name="graph_api_key.reminder_email"
                          className="input-field h-9"
                        />
                        <ErrorMessage
                          name="graph_api_key.reminder_email"
                          component="span"
                          className="text-red-600 ml-2 text-sm"
                        />
                      </div>

                      <label
                        htmlFor="graph_api_principal_name"
                        className="mt-2"
                      >
                        Graph API Principal Name
                        <span className="text-red-500 text-lg"> *</span>
                      </label>
                      <div>
                        <Field
                          type="text"
                          id="graph_api_key.principal_name"
                          name="graph_api_key.principal_name"
                          className="input-field h-9"
                        />
                        <ErrorMessage
                          name="graph_api_key.principal_name"
                          component="span"
                          className="text-red-600 ml-2 text-sm"
                        />
                      </div>

                      <label
                        htmlFor="graph_api_principal_name"
                        className="mt-2"
                      >
                        Graph API Tenant ID
                        <span className="text-red-500 text-lg"> *</span>
                      </label>
                      <div>
                        <Field
                          type={showPassword ? 'text' : 'password'}
                          id="graph_api_key.tenant_id"
                          name="graph_api_key.tenant_id"
                          className="input-field h-9"
                        />
                        <ErrorMessage
                          name="graph_api_key.tenant_id"
                          component="span"
                          className="text-red-600 ml-2 text-sm"
                        />
                      </div>
                      <label htmlFor="graph_api_client_id" className="mt-2">
                        Graph API Client ID
                        <span className="text-red-500 text-lg"> *</span>
                      </label>
                      <div>
                        <Field
                          type={showPassword ? 'text' : 'password'}
                          id="graph_api_key.client_id"
                          name="graph_api_key.client_id"
                          className="input-field h-9"
                        />
                        <ErrorMessage
                          name="graph_api_key.client_id"
                          component="span"
                          className="text-red-600 ml-2 text-sm"
                        />
                      </div>
                      <label htmlFor="graph_api_client_secret" className="mt-2">
                        Graph API Client Secret
                        <span className="text-red-500 text-lg"> *</span>
                      </label>
                      <div>
                        <Field
                          type={showPassword ? 'text' : 'password'}
                          id="graph_api_key.client_secret"
                          name="graph_api_key.client_secret"
                          className="input-field h-9"
                        />
                        <ErrorMessage
                          name="graph_api_key.client_secret"
                          component="span"
                          className="text-red-600 ml-2 text-sm"
                        />
                      </div>
                    </>
                  )}
                </>

                <div className="mt-2">
                  <Button
                    style={{ textTransform: 'capitalize' }}
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting || !isValid}
                  >
                    Save
                  </Button>
                  <Button
                    style={{ marginLeft: '10px', textTransform: 'capitalize' }}
                    type="button"
                    variant="contained"
                    onClick={handleViewClick}
                  >
                    {showPassword ? 'Hide' : 'View'}
                  </Button>
                </div>
              </div>
            </Scrollbar>
            {/* <div className="flex justify-center gap-10 mt-7 mb-5">
              <pre>{JSON.stringify(values, null, 2)}</pre>
            </div> */}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MailSettings;
