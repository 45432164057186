import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Button,
} from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Swal from 'sweetalert2';
import Alert from '../../../utils/Alert/Alert';

interface FilterQuestionDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  filterQuestion: any;
  setFilterQuestion: (filterQuestion: any) => void;
  filterData: any;
  val: any;
  setVal: (val: any) => void;
  stateData: any;
}

const FilterQuestionDialog: React.FC<FilterQuestionDialogProps> = (props) => {
  const {
    open,
    setOpen,
    filterQuestion,
    setFilterQuestion,
    filterData,
    val,
    setVal,
    stateData,
  } = props;

  return (
    <div>
      <Dialog
        open={open}
        style={{ zIndex: +10 }}
        sx={{
          '& .MuiDialog-paper': {
            width: '600px',
            height: '350px',
          },
        }}
      >
        <DialogTitle sx={{ backgroundColor: '#1663ab', color: 'white' }}>
          <div className='flex justify-between items-center'>
            <div>Assessment Filter Options</div>
            <div>
              <CloseIcon
                onClick={() => {
                  Alert.confirm(() => {
                    setFilterQuestion({
                      filterQuestion: val?.filterQuestion?.filterQuestion
                        ? val?.filterQuestion?.filterQuestion
                        : '',
                      filterResponse: val?.filterQuestion?.filterResponse
                        ? val?.filterQuestion?.filterResponse
                        : '',
                    });
                    setOpen(false);
                  });
                }}
              />
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid xs={12} md={12} style={{ marginTop: '20px' }}>
            <FormControl sx={{ minWidth: 120 }} size='small'>
              <label id='demo-select-small'>Filter Question:</label>
              <Select
                labelId='demo-select-small'
                id='demo-select-small'
                value={filterQuestion?.filterQuestion}
                name='optionOrientation'
                label='Options Orientation:'
                onChange={(e) => {
                  setFilterQuestion({
                    ...filterQuestion,
                    filterQuestion: e.target.value,
                  });
                }}
                defaultValue={filterQuestion?.filterQuestion}
              >
                {filterData?.map((d: any, i: any) => (
                  <MenuItem value={`${i + 1}`}>{i + 1}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            xs={12}
            md={12}
            style={
              stateData?.key == 'true' || stateData?.key == true
                ? { display: 'none' }
                : { marginTop: '20px' }
            }
          >
            <FormControl sx={{ minWidth: 120 }} size='small'>
              <label id='demo-select-small'>
                Filter Question Correct Response:
              </label>
              <Select
                labelId='demo-select-small'
                id='demo-select-small'
                value={filterQuestion?.filterResponse}
                name='optionOrientation'
                label='Options Orientation:'
                onChange={(e) => {
                  setFilterQuestion({
                    ...filterQuestion,
                    filterResponse: e.target.value,
                  });
                }}
                defaultValue={filterQuestion?.filterResponse}
              >
                {filterQuestion?.filterQuestion
                  ? filterData[
                      filterQuestion?.filterQuestion - 1
                    ]?.responseOptions?.map((e: any, i: any) => {
                      return <MenuItem value={`${i + 1}`}>{i + 1}</MenuItem>;
                    })
                  : filterData[
                      filterQuestion?.filterQuestion - 1
                    ]?.responseOptions?.map((e: any, i: any) => {
                      return <MenuItem value={`${i + 1}`}>{i + 1}</MenuItem>;
                    })}
              </Select>
            </FormControl>
          </Grid>

          <Grid
            xs={12}
            md={12}
            style={{
              marginTop: '20px',
              display: 'flex',
              justifyContent: 'end',
            }}
          >
            <Button
              variant='contained'
              disabled={
                stateData?.key === true || stateData?.key === 'true'
                  ? filterQuestion?.filterQuestion == ''
                  : filterQuestion?.filterQuestion == '' ||
                    filterQuestion?.filterResponse == ''
              }
              onClick={() => {
                setVal((prev: any) => {
                  return {
                    ...prev,
                    filterQuestion: filterQuestion,
                  };
                });
                setOpen(false);

                Swal.fire({
                  title: 'Filter Question Saved Successfully',
                  text: '',
                  icon: 'success',
                  customClass: {
                    popup: 'custom-swal',
                  },
                });
              }}
              style={{ marginRight: '8px' }}
            >
              Save
            </Button>
            <Button
              variant='contained'
              onClick={() => {
                Alert.confirm(() => {
                  setFilterQuestion({
                    filterQuestion: val?.filterQuestion?.filterQuestion
                      ? val?.filterQuestion?.filterQuestion
                      : '',
                    filterResponse: val?.filterQuestion?.filterResponse
                      ? val?.filterQuestion?.filterResponse
                      : '',
                  });
                  setOpen(false);
                });
              }}
            >
              Cancel
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default FilterQuestionDialog;
