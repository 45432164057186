import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from '../Store';
import { AxiosResponse, AxiosRequestConfig } from 'axios';
import * as emailingUsersRoute from '../route/emailingUsersRoute';
import { GridApi } from 'ag-grid-community';
import Alert from '../../utils/Alert/Alert';
import moment from 'moment';

export interface AxiosError<T = any> extends Error {
  config: AxiosRequestConfig;
  code?: string;
  request?: object;
  data: object;
  response?: AxiosResponse<T>;
  isAxiosError: boolean;
  toJSON: () => object;
}

const initialState = {
  getPreferenceFileData: [],
  loading: false,
};

const getPreferenceFileData =
  (setFileRecords: any) => async (dispatch: AppDispatch) => {
    return emailingUsersRoute
      .getPreferenceFile()
      .then((res: AxiosResponse) => {
        if (res?.data?.name) {
          const fileRecords = res.data.name;
          setFileRecords(fileRecords);
        }
      })
      .catch((err: AxiosError) => {});
  };

const PreferenceFileSlice = createSlice({
  name: 'PreferenceFileSlice',
  initialState,
  reducers: {
    handleGetProgram: (state, action) => {
      state.getPreferenceFileData = action.payload;
    },

    handleLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

//reducers export  to pass action state to reducer type
export const { handleGetProgram, handleLoading } = PreferenceFileSlice.actions;

//action to calls in ui with dispatch methods
export { getPreferenceFileData };

export default PreferenceFileSlice.reducer;
